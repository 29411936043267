import { API } from 'aws-amplify';
import ngeohash from 'ngeohash';
import { useEffect, useState } from 'react';
import { getDeal } from '../../graphql/queries';
import { inStoreDealGeoHashesByGeohash4V2 } from './custom_queries';

export default function Map_v2_API_Geohash({ myGeoHash }) {
  const [geoHashDeals, setGeoHashDeals] = useState([]);
  const [geoHashStores, setGeoHashStores] = useState([]);
  const [geoHashStoreDetails, setGeoHashStoreDetails] = useState([]);
  const [geoHashStoreMarkers, setGeoHashStoreMarkers] = useState([]);
  const [geoHashDealId2DetailsDict, setGeoHashDealId2DetailsDict] = useState(
    {}
  );

  useEffect(() => {
    if (geoHashStoreMarkers && geoHashStoreMarkers.length > 0) {
      (async () => {
        const myDealDetails = await fetchGeoHashDealDetails(geoHashDeals);
      })();
    }
  }, [geoHashDeals]);

  useEffect(() => {
    const searchDealsByGeoHash = async (myGeoHash) => {
      try {
        // setMarkers([]);

        const geohash4 = myGeoHash.substring(0, 4);
        // console.log(`geohash4: ${geohash4}`);
        let area = ngeohash.neighbors(geohash4);
        // console.log(`Neighbors: ${neighbors}`);

        // Add the original geohash4 to the neighbors array
        area.unshift(geohash4);
        // console.log(`Updated Neighbors: ${area}`);

        let promises = area.map(fetchDealsByGeoHash);
        // do a total check

        const results = await Promise.all(promises);

        const deals = [].concat(...results);

        return deals;
      } catch (error) {
        // console.error("Error fetching deals by geohash:", error);
      }
      return null;
    };

    // console.log('myGeoHash: ', myGeoHash);
    if (myGeoHash && myGeoHash.length > 3) {
      (async () => {
        setGeoHashStoreDetails([]);

        const deals = await searchDealsByGeoHash(myGeoHash);
        // need to check deals are valid before fetching details and stores
        setGeoHashDeals(deals);
        const stores = sortByStores(deals);
        setGeoHashStores(stores);
        const markers = generateStoreMarkers(stores);
        setGeoHashStoreMarkers(markers);
      })();
    }
  }, [myGeoHash]);

  const generateStoreMarkers = (stores) => {
    if (Object.keys(stores).length > 0) {
      const storeMarkers = Object.values(stores).map((store) => ({
        lat: ngeohash.decode(store.geohash).latitude,
        lng: ngeohash.decode(store.geohash).longitude,
        name: store.storeName,
        deals: store.num_of_deals,
        placeId: store.placeId,
        address: store.address,
        geohash: store.geohash,
        dealIds: store.dealIds,
      }));
      // console.log(`Store Markers: ${JSON.stringify(storeMarkers)}`);
      return storeMarkers;
    }
    return [];
  };

  const fetchGeoHashDealDetails = async (allDeals) => {
    try {
      // // console.log("allDeals: ", JSON.stringify(allDeals));
      // allDeals.map((deal) => console.log(deal.deal_id));
      const promises = allDeals.map((deal) =>
        API.graphql(
          /*
          graphqlOperation(listDeals, {
            filter: {
              and: [
                {id: { eq: deal.deal_id }},
                {_deleted: { ne: true }},
                { expired: { ne: true }}
              ]
            },
          })*/
          {
            query: getDeal,
            variables: {
              id: deal.deal_id,
              filter: {
                and: [{ _deleted: { ne: true } }, { expired: { ne: true } }],
              },
            },
          }
        )
      );
      const results = await Promise.all(promises);
      // console.log("dealDetails promises results: ", results);
      let dealDetails = {};
      let dealList = [];

      //console.log(results);
      results.forEach((result, index) => {
        const deal = result.data.getDeal;
        //console.log(deal);
        if (deal) {
          dealDetails[allDeals[index].deal_id] = deal;
          dealList.push(deal);
        }
      });

      // console.log("non-null-deals ", dealDetails);

      //console.log('dealDetails', dealDetails);
      setGeoHashDealId2DetailsDict(dealDetails);
      // setDealDetailsList(dealList);

      fetchGeoHashStoreDetails(geoHashStores)
      .then((myStoreDetails) => {
        // console.log("StoreDetails: ", myStoreDetails); // Add this line
        setGeoHashStoreDetails(myStoreDetails);
      })
      .catch((error) => {
        setGeoHashStoreDetails([]);
        console.error("Error fetching store details:", error); // Add this line
      });
      return dealDetails;
    } catch (error) {
      // // console.error("Error fetching deal details:", error);
    }
  };

  const fetchDealsByGeoHash = async (geohash4) => {
    try {
      // inStoreDealGeoHashesByGeohash4

      const deals = await API.graphql({
        query: inStoreDealGeoHashesByGeohash4V2,
        variables: {
          geohash4: geohash4,
          filter: {
            _deleted: { ne: true },
          },
        },
      });
      //console.log(geohash4, deals);
      // Check if the response has the expected structure
      if (
        deals.data &&
        deals.data.inStoreDealGeoHashesByGeohash4 &&
        deals.data.inStoreDealGeoHashesByGeohash4.items.length > 0
      ) {
          //console.log(geohash4, "found", deals.data.inStoreDealGeoHashesByGeohash4.items);
          let valid_res = [];
          // console.log(geohash4, "found", deals.data.inStoreDealGeoHashesByGeohash4.items);
          const promises = deals.data.inStoreDealGeoHashesByGeohash4.items.map((deal,index) =>
            API.graphql(
              {
                query: getDeal,
                variables: {
                  id: deal.deal_id,
                  filter: {
                    and: [{ _deleted: { ne: true } }, { expired: { ne: true } }],
                  },
                },
              }
            )
          );
          const results = await Promise.all(promises);
          // console.log("valid results", results);

          results.map(async (res, index) => {
            if(res.data.getDeal){
              valid_res.push(deals.data.inStoreDealGeoHashesByGeohash4.items[index]);
            }
          });

          // console.log("valid_res", valid_res);
          // return deals.data.inStoreDealGeoHashesByGeohash4.items;
          return valid_res;
      } else {
        //console.log('not found')
        //return { data: { inStoreDealGeoHashesByGeohash4: { items: [] } } }; // Return an object with the expected structure
        return [];
      }
    } catch (error) {
      console.error('Error fetching deals by geohash:', error);
      //return { data: { inStoreDealGeoHashesByGeohash4: { items: [] } } }; // Return an object with the expected structure
      return [];
    }
  };

  const fetchGeoHashStoreDetails = (myGeoHashStores) => {
    const promises = Object.values(myGeoHashStores).map((store) => {
      return new Promise((resolve, reject) => {
        if (store.geohash) {
          // // console.log("fetching stores data");
          const { latitude, longitude } = ngeohash.decode(store.geohash);
          const location = new window.google.maps.LatLng(latitude, longitude);
          const service = new window.google.maps.places.PlacesService(
            document.createElement('div')
          );

          service.nearbySearch(
            {
              location,
              radius: 8, // 8 m
              type: ['store'],
              keyword: store.storeName,
            },
            (results, status) => {
              // console.log('Nearby Search Results:', results);
              if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                const place = results[0]; // Assuming the first result is the correct place
                // Get detailed information
                service.getDetails(
                  {
                    placeId: place.place_id,
                    fields: [
                      'name',
                      'rating',
                      'formatted_phone_number',
                      'opening_hours',
                    ],
                  },
                  (place, status) => {
                    if (
                      status ===
                      window.google.maps.places.PlacesServiceStatus.OK
                    ) {
                      store.rating = place.rating || ''; // Get the rating
                      store.status = place.opening_hours
                        ? place.opening_hours.open_now
                          ? 'Open'
                          : 'Closed'
                        : '';
                      store.phone = place.formatted_phone_number || '';
                      // Get the current day of the week (0 for Sunday, 1 for Monday, etc.)
                      const currentDayOfWeek = new Date().getDay();
                      // Assuming the store closes at the end of the current period
                      store.closingTime =
                        place.opening_hours &&
                        place.opening_hours.periods &&
                        place.opening_hours.periods[currentDayOfWeek]
                          ? place.opening_hours.periods[currentDayOfWeek].close
                              .time
                          : '';
                      // Assuming the store opens at the start of the current period
                      store.openingTime =
                        place.opening_hours &&
                        place.opening_hours.periods &&
                        place.opening_hours.periods[currentDayOfWeek]
                          ? place.opening_hours.periods[currentDayOfWeek].open
                              .time
                          : '';
                      store._type = 'ByGeoHash';

                      resolve(store);
                    } else {
                      resolve(store); // Resolve the promise even if status is not OK
                    }
                    // // console.log("fetched store data: ", place);
                  }
                );
              } else {
                resolve(store); // Resolve the promise even if status is not OK
              }
            }
          );
        } else {
          resolve(store); // Resolve the promise even if there is no geohash
        }
      });
    });
    return Promise.all(promises);
  };

  const sortByStores = (deals) => {
    return deals.reduce((stores, deal) => {
      if (!stores[deal.googlemap_placeid]) {
        stores[deal.googlemap_placeid] = {
          storeName: deal.store_name,
          address: deal.address,
          num_of_deals: 1,
          geohash: deal.geohash,
          placeId: deal.googlemap_placeid,
          dealIds: [deal.deal_id], // Add deal ids to the new store
        };
      } else {
        stores[deal.googlemap_placeid].num_of_deals += 1;
        stores[deal.googlemap_placeid].dealIds.push(deal.deal_id); // Add deal id to the existing store
      }
      return stores;
    }, {});
  };
  // console.log("Map_v2_API_Geohash: geoHashStoreDetails: ")
  // console.log(geoHashStoreDetails)
  return {
    geoHashDeals,
    geoHashStoreDetails,
    geoHashStoreMarkers,
    geoHashDealId2DetailsDict,
  };
}
