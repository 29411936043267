import * as React from 'react';

import { useState } from 'react';

import { API } from 'aws-amplify';
import { createExpiredMarker } from '../../graphql/mutations';

import { AUTH_MODE } from '../constants/AppSyncAuthMode';

import { Box, Button, Card, CardContent, TextField } from '@mui/material';

export function ApiCreateExpiredMarker(props) {
  const [dealId, setDealId] = useState('');

  async function createExpiredMarkerHandler(deal_id) {
    try {
      const response = await API.graphql({
        query: createExpiredMarker,
        variables: { deal_id: deal_id, to_delete: false },
        authMode: AUTH_MODE.USER_POOLS,
      });
      console.error('response: ', response);
      alert(response.data.createExpiredMarker);
    } catch (error) {
      console.error('Error creating expired marker: ', error);
      alert('Error creating expired marker: ', error);
    }
  }

  async function deleteExpiredMarkerHandler(deal_id) {
    try {
      const response = await API.graphql({
        query: createExpiredMarker,
        variables: { deal_id: deal_id, to_delete: true },
        authMode: AUTH_MODE.USER_POOLS,
      });
      console.error('response: ', response);
      alert(response.data.createExpiredMarker);
    } catch (error) {
      console.error('Error deleting expired marker: ', error);
      alert('Error deleting expired marker: ', error);
    }
  }

  return (
    <Card sx={{ bgcolor: '#f5f5f5', width: '100%' }}>
      <CardContent>
        Deal Expire API
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <TextField
            sx={{ margin: 1 }}
            type="text"
            value={dealId}
            placeholder="dealId"
            onChange={(e) => setDealId(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            sx={{ margin: 1, textTransform: 'none' }}
            onClick={() => createExpiredMarkerHandler(dealId)}
          >
            createExpiredMarker
          </Button>
          <Button
            variant="outlined"
            color="primary"
            sx={{ margin: 1, textTransform: 'none' }}
            onClick={() => deleteExpiredMarkerHandler(dealId)}
          >
            deleteExpiredMarker
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}
