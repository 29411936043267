import EditIcon from '@mui/icons-material/Edit';
import Fab from '@mui/material/Fab';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getDealApi } from '../../api/deal';
import useListTopVotedDeals from '../apiHooks/useListTopVotedDeals';
import VerticalPostGrid from '../bookmarks/VerticalPostGrid';
import Section from '../home/Section';
import Comments from './Comments';
import Deal from './Deal';

function DealDetails_() {
  const otherDeals = [];
  const [isAdmin, setIsAdmin] = useState(false);
  const [cognitoSub, setCognitoSub] = useState(null);
  const navigate = useNavigate();

  const { topVotedDeals, loading } = useListTopVotedDeals();
  const { id } = useParams();
  const [deal, setDeal] = useState(null);

  const getDeal = async () => {
    setDeal(await getDealApi(id));
  };

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 150);
  }, []);

  // getDeal();
  useEffect(() => {
    getDeal();
  }, [id]);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((User) => {
        const groups =
          User.signInUserSession.accessToken.payload['cognito:groups'];
        const sub = User.attributes.sub;
        setCognitoSub(sub);
        if (groups && groups.includes('admin')) {
          setIsAdmin(true);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      <Section
        content={
          <div className="tw-w-full">
            <div className="tw-w-full">
              <Deal />
              <div className="tw-flex tw-gap-x-5 tw-pt-8">
                <div className="tw-w-full lg:tw-w-[100%]">
                  <Comments />
                </div>
                {/* <div className="tw-hidden tw-w-0 lg:tw-w-[30%] lg:tw-block">
                  {otherDeals && <YouMayLike />}
                </div> */}
              </div>
            </div>
          </div>
        }
      />

      {!loading &&
        topVotedDeals.length >
        (
          <Section
            name="Current Deals"
            title="Related Products"
            content={<VerticalPostGrid posts={topVotedDeals.slice(4, 8)} />}
          />
        )}
      {(isAdmin || (deal && deal.poster_id === cognitoSub)) && (
        <Fab
          size="medium"
          color="primary"
          aria-label="add"
          onClick={() => {
            const pathArray = window.location.pathname.split('/');
            const lastSegment = pathArray.pop() || pathArray.pop(); // handle potential trailing slash
            navigate(`/DealEditor?id=${lastSegment}`);
          }}
          style={{ position: 'fixed', bottom: 100, right: 60 }}
        >
          <EditIcon />
        </Fab>
      )}
    </div>
  );
}

export default DealDetails_;
