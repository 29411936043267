/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCommentVote = /* GraphQL */ `
  mutation CreateCommentVote(
    $input: CreateCommentVoteInput!
    $condition: ModelCommentVoteConditionInput
  ) {
    createCommentVote(input: $input, condition: $condition) {
      id
      user_id
      comment_id
      comment_user_id
      owner
      upvote
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCommentVote = /* GraphQL */ `
  mutation UpdateCommentVote(
    $input: UpdateCommentVoteInput!
    $condition: ModelCommentVoteConditionInput
  ) {
    updateCommentVote(input: $input, condition: $condition) {
      id
      user_id
      comment_id
      comment_user_id
      owner
      upvote
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCommentVote = /* GraphQL */ `
  mutation DeleteCommentVote(
    $input: DeleteCommentVoteInput!
    $condition: ModelCommentVoteConditionInput
  ) {
    deleteCommentVote(input: $input, condition: $condition) {
      id
      user_id
      comment_id
      comment_user_id
      owner
      upvote
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDealVote = /* GraphQL */ `
  mutation CreateDealVote(
    $input: CreateDealVoteInput!
    $condition: ModelDealVoteConditionInput
  ) {
    createDealVote(input: $input, condition: $condition) {
      id
      user_id
      deal_id
      deal_user_id
      owner
      upvote
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDealVote = /* GraphQL */ `
  mutation UpdateDealVote(
    $input: UpdateDealVoteInput!
    $condition: ModelDealVoteConditionInput
  ) {
    updateDealVote(input: $input, condition: $condition) {
      id
      user_id
      deal_id
      deal_user_id
      owner
      upvote
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDealVote = /* GraphQL */ `
  mutation DeleteDealVote(
    $input: DeleteDealVoteInput!
    $condition: ModelDealVoteConditionInput
  ) {
    deleteDealVote(input: $input, condition: $condition) {
      id
      user_id
      deal_id
      deal_user_id
      owner
      upvote
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createTopDealAlertSettings = /* GraphQL */ `
  mutation CreateTopDealAlertSettings(
    $input: CreateTopDealAlertSettingsInput!
    $condition: ModelTopDealAlertSettingsConditionInput
  ) {
    createTopDealAlertSettings(input: $input, condition: $condition) {
      id
      user_id
      username
      enabled
      notification_email
      notification_cell
      notification_app
      frequency_instant
      frequency_daily
      frequency_weekly
      hash_key
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateTopDealAlertSettings = /* GraphQL */ `
  mutation UpdateTopDealAlertSettings(
    $input: UpdateTopDealAlertSettingsInput!
    $condition: ModelTopDealAlertSettingsConditionInput
  ) {
    updateTopDealAlertSettings(input: $input, condition: $condition) {
      id
      user_id
      username
      enabled
      notification_email
      notification_cell
      notification_app
      frequency_instant
      frequency_daily
      frequency_weekly
      hash_key
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteTopDealAlertSettings = /* GraphQL */ `
  mutation DeleteTopDealAlertSettings(
    $input: DeleteTopDealAlertSettingsInput!
    $condition: ModelTopDealAlertSettingsConditionInput
  ) {
    deleteTopDealAlertSettings(input: $input, condition: $condition) {
      id
      user_id
      username
      enabled
      notification_email
      notification_cell
      notification_app
      frequency_instant
      frequency_daily
      frequency_weekly
      hash_key
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createLightningDealAlertSettings = /* GraphQL */ `
  mutation CreateLightningDealAlertSettings(
    $input: CreateLightningDealAlertSettingsInput!
    $condition: ModelLightningDealAlertSettingsConditionInput
  ) {
    createLightningDealAlertSettings(input: $input, condition: $condition) {
      id
      user_id
      username
      enabled
      notification_email
      notification_cell
      notification_app
      frequency_instant
      frequency_daily
      frequency_weekly
      hash_key
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateLightningDealAlertSettings = /* GraphQL */ `
  mutation UpdateLightningDealAlertSettings(
    $input: UpdateLightningDealAlertSettingsInput!
    $condition: ModelLightningDealAlertSettingsConditionInput
  ) {
    updateLightningDealAlertSettings(input: $input, condition: $condition) {
      id
      user_id
      username
      enabled
      notification_email
      notification_cell
      notification_app
      frequency_instant
      frequency_daily
      frequency_weekly
      hash_key
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteLightningDealAlertSettings = /* GraphQL */ `
  mutation DeleteLightningDealAlertSettings(
    $input: DeleteLightningDealAlertSettingsInput!
    $condition: ModelLightningDealAlertSettingsConditionInput
  ) {
    deleteLightningDealAlertSettings(input: $input, condition: $condition) {
      id
      user_id
      username
      enabled
      notification_email
      notification_cell
      notification_app
      frequency_instant
      frequency_daily
      frequency_weekly
      hash_key
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePointToPointMessages = /* GraphQL */ `
  mutation UpdatePointToPointMessages(
    $input: UpdatePointToPointMessagesInput!
    $condition: ModelPointToPointMessagesConditionInput
  ) {
    updatePointToPointMessages(input: $input, condition: $condition) {
      id
      sender
      receiver
      createdAt
      _ttl
      type
      title
      body
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePointToPointMessages = /* GraphQL */ `
  mutation DeletePointToPointMessages(
    $input: DeletePointToPointMessagesInput!
    $condition: ModelPointToPointMessagesConditionInput
  ) {
    deletePointToPointMessages(input: $input, condition: $condition) {
      id
      sender
      receiver
      createdAt
      _ttl
      type
      title
      body
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateGlobalMessages = /* GraphQL */ `
  mutation UpdateGlobalMessages(
    $input: UpdateGlobalMessagesInput!
    $condition: ModelGlobalMessagesConditionInput
  ) {
    updateGlobalMessages(input: $input, condition: $condition) {
      id
      sender
      createdAt
      _ttl
      type
      title
      body
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteGlobalMessages = /* GraphQL */ `
  mutation DeleteGlobalMessages(
    $input: DeleteGlobalMessagesInput!
    $condition: ModelGlobalMessagesConditionInput
  ) {
    deleteGlobalMessages(input: $input, condition: $condition) {
      id
      sender
      createdAt
      _ttl
      type
      title
      body
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPreferredUsernameMapping = /* GraphQL */ `
  mutation CreatePreferredUsernameMapping(
    $input: CreatePreferredUsernameMappingInput!
    $condition: ModelPreferredUsernameMappingConditionInput
  ) {
    createPreferredUsernameMapping(input: $input, condition: $condition) {
      preferred_username
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePreferredUsernameMapping = /* GraphQL */ `
  mutation UpdatePreferredUsernameMapping(
    $input: UpdatePreferredUsernameMappingInput!
    $condition: ModelPreferredUsernameMappingConditionInput
  ) {
    updatePreferredUsernameMapping(input: $input, condition: $condition) {
      preferred_username
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePreferredUsernameMapping = /* GraphQL */ `
  mutation DeletePreferredUsernameMapping(
    $input: DeletePreferredUsernameMappingInput!
    $condition: ModelPreferredUsernameMappingConditionInput
  ) {
    deletePreferredUsernameMapping(input: $input, condition: $condition) {
      preferred_username
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createStore = /* GraphQL */ `
  mutation CreateStore(
    $input: CreateStoreInput!
    $condition: ModelStoreConditionInput
  ) {
    createStore(input: $input, condition: $condition) {
      id
      storeName
      storeNameLowerCase
      count
      recentUpdatesDateTime
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const updateStore = /* GraphQL */ `
  mutation UpdateStore(
    $input: UpdateStoreInput!
    $condition: ModelStoreConditionInput
  ) {
    updateStore(input: $input, condition: $condition) {
      id
      storeName
      storeNameLowerCase
      count
      recentUpdatesDateTime
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const deleteStore = /* GraphQL */ `
  mutation DeleteStore(
    $input: DeleteStoreInput!
    $condition: ModelStoreConditionInput
  ) {
    deleteStore(input: $input, condition: $condition) {
      id
      storeName
      storeNameLowerCase
      count
      recentUpdatesDateTime
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const createVotedExpired = /* GraphQL */ `
  mutation CreateVotedExpired(
    $input: CreateVotedExpiredInput!
    $condition: ModelVotedExpiredConditionInput
  ) {
    createVotedExpired(input: $input, condition: $condition) {
      id
      user_id
      deal_id
      deal_user_id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateVotedExpired = /* GraphQL */ `
  mutation UpdateVotedExpired(
    $input: UpdateVotedExpiredInput!
    $condition: ModelVotedExpiredConditionInput
  ) {
    updateVotedExpired(input: $input, condition: $condition) {
      id
      user_id
      deal_id
      deal_user_id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteVotedExpired = /* GraphQL */ `
  mutation DeleteVotedExpired(
    $input: DeleteVotedExpiredInput!
    $condition: ModelVotedExpiredConditionInput
  ) {
    deleteVotedExpired(input: $input, condition: $condition) {
      id
      user_id
      deal_id
      deal_user_id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createReportedDeal = /* GraphQL */ `
  mutation CreateReportedDeal(
    $input: CreateReportedDealInput!
    $condition: ModelReportedDealConditionInput
  ) {
    createReportedDeal(input: $input, condition: $condition) {
      id
      user_id
      deal_id
      deal_user_id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateReportedDeal = /* GraphQL */ `
  mutation UpdateReportedDeal(
    $input: UpdateReportedDealInput!
    $condition: ModelReportedDealConditionInput
  ) {
    updateReportedDeal(input: $input, condition: $condition) {
      id
      user_id
      deal_id
      deal_user_id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteReportedDeal = /* GraphQL */ `
  mutation DeleteReportedDeal(
    $input: DeleteReportedDealInput!
    $condition: ModelReportedDealConditionInput
  ) {
    deleteReportedDeal(input: $input, condition: $condition) {
      id
      user_id
      deal_id
      deal_user_id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      deal_id
      user_name
      text
      reply_to
      likes
      dislikes
      poster_img_url
      createdAt
      updatedAt
      owner
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      deal_id
      user_name
      text
      reply_to
      likes
      dislikes
      poster_img_url
      createdAt
      updatedAt
      owner
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      deal_id
      user_name
      text
      reply_to
      likes
      dislikes
      poster_img_url
      createdAt
      updatedAt
      owner
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createDeal = /* GraphQL */ `
  mutation CreateDeal(
    $input: CreateDealInput!
    $condition: ModelDealConditionInput
  ) {
    createDeal(input: $input, condition: $condition) {
      id
      store_sku
      title
      title_lowercase
      description
      description_lowercase
      price_drop
      deal_link
      affiliate_link
      img_link
      has_promotional_code
      promotional_code
      has_amazon_subscribe_save
      amazon_subscribe_save
      has_coupon
      coupon
      expiration_date
      poster_id
      poster_name
      forum_type
      forum_type_lowercase
      sub_category
      sub_category_lowercase
      dealer_type
      dealer_type_lowercase
      instore
      dealType
      isTopDeal
      isTrendingDeal
      isLightningDeal
      specific_states
      available_states
      specific_stores
      available_store_addresses
      available_store_zipcodes
      available_store_geohashes
      available_store_placeID
      vote
      price
      expired
      expired_status_string
      expired_ttl
      poster_img_url
      prev_price
      uploaded_img_links
      free_shipping
      free_pickup
      down_vote
      posted_date
      updated_date
      ExpiredVotedNumber
      ExpiredVotedNumberAccumulated
      ReportedNumber
      ReportedNumberAccumulated
      highest_votes
      highest_ratio
      createdAt
      updatedAt
      owner
      search_by_time
      search_by_vote
      additionalTitles
      additionalLinks
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateDeal = /* GraphQL */ `
  mutation UpdateDeal(
    $input: UpdateDealInput!
    $condition: ModelDealConditionInput
  ) {
    updateDeal(input: $input, condition: $condition) {
      id
      store_sku
      title
      title_lowercase
      description
      description_lowercase
      price_drop
      deal_link
      affiliate_link
      img_link
      has_promotional_code
      promotional_code
      has_amazon_subscribe_save
      amazon_subscribe_save
      has_coupon
      coupon
      expiration_date
      poster_id
      poster_name
      forum_type
      forum_type_lowercase
      sub_category
      sub_category_lowercase
      dealer_type
      dealer_type_lowercase
      instore
      dealType
      isTopDeal
      isTrendingDeal
      isLightningDeal
      specific_states
      available_states
      specific_stores
      available_store_addresses
      available_store_zipcodes
      available_store_geohashes
      available_store_placeID
      vote
      price
      expired
      expired_status_string
      expired_ttl
      poster_img_url
      prev_price
      uploaded_img_links
      free_shipping
      free_pickup
      down_vote
      posted_date
      updated_date
      ExpiredVotedNumber
      ExpiredVotedNumberAccumulated
      ReportedNumber
      ReportedNumberAccumulated
      highest_votes
      highest_ratio
      createdAt
      updatedAt
      owner
      search_by_time
      search_by_vote
      additionalTitles
      additionalLinks
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteDeal = /* GraphQL */ `
  mutation DeleteDeal(
    $input: DeleteDealInput!
    $condition: ModelDealConditionInput
  ) {
    deleteDeal(input: $input, condition: $condition) {
      id
      store_sku
      title
      title_lowercase
      description
      description_lowercase
      price_drop
      deal_link
      affiliate_link
      img_link
      has_promotional_code
      promotional_code
      has_amazon_subscribe_save
      amazon_subscribe_save
      has_coupon
      coupon
      expiration_date
      poster_id
      poster_name
      forum_type
      forum_type_lowercase
      sub_category
      sub_category_lowercase
      dealer_type
      dealer_type_lowercase
      instore
      dealType
      isTopDeal
      isTrendingDeal
      isLightningDeal
      specific_states
      available_states
      specific_stores
      available_store_addresses
      available_store_zipcodes
      available_store_geohashes
      available_store_placeID
      vote
      price
      expired
      expired_status_string
      expired_ttl
      poster_img_url
      prev_price
      uploaded_img_links
      free_shipping
      free_pickup
      down_vote
      posted_date
      updated_date
      ExpiredVotedNumber
      ExpiredVotedNumberAccumulated
      ReportedNumber
      ReportedNumberAccumulated
      highest_votes
      highest_ratio
      createdAt
      updatedAt
      owner
      search_by_time
      search_by_vote
      additionalTitles
      additionalLinks
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createInStoreDealByState = /* GraphQL */ `
  mutation CreateInStoreDealByState(
    $input: CreateInStoreDealByStateInput!
    $condition: ModelInStoreDealByStateConditionInput
  ) {
    createInStoreDealByState(input: $input, condition: $condition) {
      id
      deal_id
      store_name
      available_state
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateInStoreDealByState = /* GraphQL */ `
  mutation UpdateInStoreDealByState(
    $input: UpdateInStoreDealByStateInput!
    $condition: ModelInStoreDealByStateConditionInput
  ) {
    updateInStoreDealByState(input: $input, condition: $condition) {
      id
      deal_id
      store_name
      available_state
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteInStoreDealByState = /* GraphQL */ `
  mutation DeleteInStoreDealByState(
    $input: DeleteInStoreDealByStateInput!
    $condition: ModelInStoreDealByStateConditionInput
  ) {
    deleteInStoreDealByState(input: $input, condition: $condition) {
      id
      deal_id
      store_name
      available_state
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createInStoreDealGeoHash = /* GraphQL */ `
  mutation CreateInStoreDealGeoHash(
    $input: CreateInStoreDealGeoHashInput!
    $condition: ModelInStoreDealGeoHashConditionInput
  ) {
    createInStoreDealGeoHash(input: $input, condition: $condition) {
      id
      deal_id
      geohash
      address
      store_name
      zipcode
      googlemap_placeid
      geohash3
      geohash4
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateInStoreDealGeoHash = /* GraphQL */ `
  mutation UpdateInStoreDealGeoHash(
    $input: UpdateInStoreDealGeoHashInput!
    $condition: ModelInStoreDealGeoHashConditionInput
  ) {
    updateInStoreDealGeoHash(input: $input, condition: $condition) {
      id
      deal_id
      geohash
      address
      store_name
      zipcode
      googlemap_placeid
      geohash3
      geohash4
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteInStoreDealGeoHash = /* GraphQL */ `
  mutation DeleteInStoreDealGeoHash(
    $input: DeleteInStoreDealGeoHashInput!
    $condition: ModelInStoreDealGeoHashConditionInput
  ) {
    deleteInStoreDealGeoHash(input: $input, condition: $condition) {
      id
      deal_id
      geohash
      address
      store_name
      zipcode
      googlemap_placeid
      geohash3
      geohash4
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAlertSettings = /* GraphQL */ `
  mutation CreateAlertSettings(
    $input: CreateAlertSettingsInput!
    $condition: ModelAlertSettingsConditionInput
  ) {
    createAlertSettings(input: $input, condition: $condition) {
      id
      alert_title
      user_id
      username
      enabled
      keywords
      stores
      categories
      sub_categories
      price_min
      price_max
      threshold_net_upvote
      threshold_upvote_ratio
      notification_email
      notification_cell
      notification_app
      frequency_instant
      frequency_daily
      frequency_weekly
      last_changed_date
      hash_key
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAlertSettings = /* GraphQL */ `
  mutation UpdateAlertSettings(
    $input: UpdateAlertSettingsInput!
    $condition: ModelAlertSettingsConditionInput
  ) {
    updateAlertSettings(input: $input, condition: $condition) {
      id
      alert_title
      user_id
      username
      enabled
      keywords
      stores
      categories
      sub_categories
      price_min
      price_max
      threshold_net_upvote
      threshold_upvote_ratio
      notification_email
      notification_cell
      notification_app
      frequency_instant
      frequency_daily
      frequency_weekly
      last_changed_date
      hash_key
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAlertSettings = /* GraphQL */ `
  mutation DeleteAlertSettings(
    $input: DeleteAlertSettingsInput!
    $condition: ModelAlertSettingsConditionInput
  ) {
    deleteAlertSettings(input: $input, condition: $condition) {
      id
      alert_title
      user_id
      username
      enabled
      keywords
      stores
      categories
      sub_categories
      price_min
      price_max
      threshold_net_upvote
      threshold_upvote_ratio
      notification_email
      notification_cell
      notification_app
      frequency_instant
      frequency_daily
      frequency_weekly
      last_changed_date
      hash_key
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createBookmark = /* GraphQL */ `
  mutation CreateBookmark(
    $input: CreateBookmarkInput!
    $condition: ModelBookmarkConditionInput
  ) {
    createBookmark(input: $input, condition: $condition) {
      id
      user_id
      deal_id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateBookmark = /* GraphQL */ `
  mutation UpdateBookmark(
    $input: UpdateBookmarkInput!
    $condition: ModelBookmarkConditionInput
  ) {
    updateBookmark(input: $input, condition: $condition) {
      id
      user_id
      deal_id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteBookmark = /* GraphQL */ `
  mutation DeleteBookmark(
    $input: DeleteBookmarkInput!
    $condition: ModelBookmarkConditionInput
  ) {
    deleteBookmark(input: $input, condition: $condition) {
      id
      user_id
      deal_id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPointToPointMessages = /* GraphQL */ `
  mutation CreatePointToPointMessages(
    $input: CreatePointToPointMessagesInput!
    $condition: ModelPointToPointMessagesConditionInput
  ) {
    createPointToPointMessages(input: $input, condition: $condition) {
      id
      sender
      receiver
      createdAt
      _ttl
      type
      title
      body
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createGlobalMessages = /* GraphQL */ `
  mutation CreateGlobalMessages(
    $input: CreateGlobalMessagesInput!
    $condition: ModelGlobalMessagesConditionInput
  ) {
    createGlobalMessages(input: $input, condition: $condition) {
      id
      sender
      createdAt
      _ttl
      type
      title
      body
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const LosDealVoteFunc = /* GraphQL */ `
  mutation LosDealVoteFunc(
    $deal_id: ID!
    $upvote: Boolean!
    $downvote: Boolean!
  ) {
    LosDealVoteFunc(deal_id: $deal_id, upvote: $upvote, downvote: $downvote)
  }
`;
export const createExpiredMarker = /* GraphQL */ `
  mutation CreateExpiredMarker($deal_id: ID!, $to_delete: Boolean!) {
    createExpiredMarker(deal_id: $deal_id, to_delete: $to_delete)
  }
`;
export const reportDeal = /* GraphQL */ `
  mutation ReportDeal($deal_id: ID!, $to_delete: Boolean!) {
    reportDeal(deal_id: $deal_id, to_delete: $to_delete)
  }
`;
export const LosCommentVoteFunc = /* GraphQL */ `
  mutation LosCommentVoteFunc(
    $comment_id: ID!
    $upvote: Boolean!
    $downvote: Boolean!
  ) {
    LosCommentVoteFunc(
      comment_id: $comment_id
      upvote: $upvote
      downvote: $downvote
    )
  }
`;
export const topDealAlertSubscribe = /* GraphQL */ `
  mutation TopDealAlertSubscribe(
    $enabled: Boolean!
    $notification_email: Boolean!
    $notification_cell: Boolean!
    $notification_app: Boolean!
    $frequency_instant: Boolean!
    $frequency_daily: Boolean!
    $frequency_weekly: Boolean!
  ) {
    topDealAlertSubscribe(
      enabled: $enabled
      notification_email: $notification_email
      notification_cell: $notification_cell
      notification_app: $notification_app
      frequency_instant: $frequency_instant
      frequency_daily: $frequency_daily
      frequency_weekly: $frequency_weekly
    )
  }
`;
export const lightningDealAlertSubscribe = /* GraphQL */ `
  mutation LightningDealAlertSubscribe(
    $enabled: Boolean!
    $notification_email: Boolean!
    $notification_cell: Boolean!
    $notification_app: Boolean!
    $frequency_instant: Boolean!
    $frequency_daily: Boolean!
    $frequency_weekly: Boolean!
  ) {
    lightningDealAlertSubscribe(
      enabled: $enabled
      notification_email: $notification_email
      notification_cell: $notification_cell
      notification_app: $notification_app
      frequency_instant: $frequency_instant
      frequency_daily: $frequency_daily
      frequency_weekly: $frequency_weekly
    )
  }
`;
export const postDeal = /* GraphQL */ `
  mutation PostDeal($input: PostDealInput!) {
    postDeal(input: $input)
  }
`;
export const sendMessage = /* GraphQL */ `
  mutation SendMessage(
    $isGlobalMessage: Boolean!
    $receiver: String
    $type: String
    $title: String
    $body: String
  ) {
    sendMessage(
      isGlobalMessage: $isGlobalMessage
      receiver: $receiver
      type: $type
      title: $title
      body: $body
    )
  }
`;
export const changePreferredName = /* GraphQL */ `
  mutation ChangePreferredName($newPreferredName: String!) {
    changePreferredName(newPreferredName: $newPreferredName)
  }
`;
