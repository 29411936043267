import GridOnIcon from '@mui/icons-material/GridOn';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { loadingDemoDataList } from '../../api/deal';
import usePropState from '../../utils/usePropState';
// import useListDeals from '../apiHooks/useListDeals';
import useListLightningDeals from '../apiHooks/useListLightningDeals';
import Section from '../home/Section';
import CustomPaginationEndlessMode from '../utils/CustomPaginationEndlessMode';
import Post from '../utils/Post';
import Filter from './Filter';
import FilterPills from './FilterPills';
// import Carousel from 'react-bootstrap/Carousel';
// import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useNavigate } from 'react-router-dom';

function DealsLightning() {
  const {
    deals,
    filter,
    loading,
    setLoading,
    setFilter,
    sortDeals,
    selectedCount,
    selectedFiltersCount,
    updateSearchParameters,
    getListDeals,
    setPageSize,
    nextTokenRef,
  } = useListLightningDeals();
  const [view, setView] = useState('grid');
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const [sortBy, setSortBy] = usePropState(filter.sort_by);
  const [headerNav, setHeaderNav] = useState('Lightning Most Recent');

  const handleView = (event, value) => {
    setView(value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const showPosts = (items) =>
    view === 'grid' ? (
      <div
        className="tw-grid tw-gap-1 sm:tw-gap-2 md:tw-gap-3 xl:tw-gap-5
         tw-grid-cols-2 sm:tw-grid-cols-3 md:tw-grid-cols-3 lg:tw-grid-cols-3 xl:tw-grid-cols-4 2xl:tw-grid-cols-5"
      >
        {items.map((item, index) => (
          <Post item={item} orientation="vertical" type="Lightning Deal" />
        ))}
      </div>
    ) : (
      <Grid
        spacing={{ xs: 0.5, sm: 1, md: 2, lg: 3 }}
        container
        direction="column"
        justifyContent="center"
        alignItems="stretch"
      >
        {items.map((item, index) => (
          <Grid key={index} item xs={12} sm={12} md={12} lg={12}>
            <Post item={item} orientation="horizontal" type="Lightning Deal" />
          </Grid>
        ))}
      </Grid>
    );

  const handleHeaderNav = (event, newValue) => {
    //console.log(event.target.innerText);// "Lightning Deals" -> Tab label
    //console.log(newValue); // "Lightning Most Recent" -> Tab value
    if (newValue == 'Lightning Most Recent') {
      setSortBy('Most Recent');
      sortDeals('Most Recent');
      setHeaderNav(newValue);
      const filterCopy = structuredClone(filter);
      filterCopy.sort_by = 'Most Recent';
      setFilter(filterCopy);
    } else if (newValue == 'Online Most Recent') {
      navigate('/deals');
    } else {
      setSortBy(newValue);
      sortDeals(newValue);
      setHeaderNav(newValue);
      const filterCopy = structuredClone(filter);
      filterCopy.sort_by = newValue;
      setFilter(filterCopy);
    }
  };

  const handleSortBy = (event) => {
    setSortBy(event.target.value);
    sortDeals(event.target.value);
    if (event.target.value == 'Most Recent') {
      setHeaderNav('Lightning Most Recent');
    } else {
      setHeaderNav(event.target.value);
    }
    const filterCopy = structuredClone(filter);
    filterCopy.sort_by = event.target.value;
    setFilter(filterCopy);
  };

  if (loading) {
    return (
      <>
        <Section
          title="Lightning Deals"
          content={
            <div className="tw-flex tw-flex-col tw-gap-y-4 lg:tw-gap-y-8 tw-w-full">
              {/*
               ** Adding sorting header for mobile
               */}
              <Box
                fullWidth
                className="tw-py-1"
                sx={{ display: { xs: 'block', md: 'none' }, width: '100%' }}
              >
                <Tabs
                  value={headerNav}
                  onChange={handleHeaderNav}
                  variant="scrollable"
                  scrollButtons={false}
                  textColor="inherit"
                  className="tw-text-string-base"
                  // allowScrollButtonsMobile
                  // centered
                  /*
                      sx={{
                        color:'rgb(255,255,255)'
                      }}
                      */
                >
                  <Tab
                    label="Online Deals"
                    iconPosition="start"
                    icon={<img src="/Lightning_New.png" alt="" />}
                    value="Online Most Recent"
                    sx={{ textTransform: 'none' }}
                  />
                  <Tab
                    label="Top Deals"
                    iconPosition="start"
                    icon={<img src="/Lightning_Top.png" alt="" />}
                    value="Top Deals"
                    sx={{ textTransform: 'none' }}
                  />
                  <Tab
                    label="Lightning Deals"
                    iconPosition="start"
                    icon={<img src="/Lightning_Flash.png" alt="" />}
                    value="Lightning Most Recent"
                    sx={{ textTransform: 'none' }}
                  />
                  <Tab
                    label="Trend Deals"
                    iconPosition="start"
                    icon={<img src="/Lightning_Trending.png" alt="" />}
                    value="Trending Deals"
                    sx={{ textTransform: 'none' }}
                  />
                  <Tab
                    label="Most Percent Off Deals"
                    iconPosition="start"
                    icon={<img src="/Lightning_Percent.png" alt="" />}
                    value="Percentage Off"
                    sx={{ textTransform: 'none' }}
                  />
                  <Tab
                    label="Promo Code"
                    iconPosition="start"
                    icon={<img src="/Lightning_Promo.png" alt="" />}
                    value="Promo code included"
                    sx={{ textTransform: 'none' }}
                  />
                </Tabs>
              </Box>
              {/*
               ** Adding sorting header for tablet + website
               */}
              <Box
                fullWidth
                className="tw-py-1"
                sx={{ display: { xs: 'none', md: 'block' }, width: '100%' }}
              >
                <Tabs
                  value={headerNav}
                  onChange={handleHeaderNav}
                  variant="fullWidth"
                  //scrollButtons={false}
                  textColor="inherit"
                  className="tw-text-string-base"
                  // allowScrollButtonsMobile
                  centered
                  /*
                      sx={{
                        color:'rgb(255,255,255)'
                      }}
                      */
                >
                  <Tab
                    label="Online Deals"
                    iconPosition="start"
                    icon={<img src="/Lightning_New.png" alt="" />}
                    value="Online Most Recent"
                    sx={{ textTransform: 'none' }}
                  />
                  <Tab
                    label="Top Deals"
                    iconPosition="start"
                    icon={<img src="/Lightning_Top.png" alt="" />}
                    value="Top Deals"
                    sx={{ textTransform: 'none' }}
                  />
                  <Tab
                    label="Lightning Deals"
                    iconPosition="start"
                    icon={<img src="/Lightning_Flash.png" alt="" />}
                    value="Lightning Most Recent"
                    sx={{ textTransform: 'none' }}
                  />
                  <Tab
                    label="Trend Deals"
                    iconPosition="start"
                    icon={<img src="/Lightning_Trending.png" alt="" />}
                    value="Trending Deals"
                    sx={{ textTransform: 'none' }}
                  />
                  <Tab
                    label="Most Percent Off Deals"
                    iconPosition="start"
                    icon={<img src="/Lightning_Percent.png" alt="" />}
                    value="Percentage Off"
                    sx={{ textTransform: 'none' }}
                  />
                  <Tab
                    label="Promo Code"
                    iconPosition="start"
                    icon={<img src="/Lightning_Promo.png" alt="" />}
                    value="Promo code included"
                    sx={{ textTransform: 'none' }}
                  />
                </Tabs>
              </Box>
              <Box fullWidth className="tw-py-1">
                <div className="tw-rounded-sm tw-flex tw-gap-x-1 tw-p-1">
                  <img
                    className="tw-w-full"
                    src="/Lightning-3.png"
                    alt="First banner"
                  />
                </div>
              </Box>
              <div className="tw-flex tw-items-center tw-gap-x-4 tw-w-full">
                <div className="tw-py-3 tw-w-full lg:tw-w-3/6 xl:tw-w-[300px]">
                  <Button
                    fullWidth
                    variant="outlined"
                    startIcon={
                      <TuneRoundedIcon
                        sx={{
                          color: selectedCount > 0 ? '#3054F5' : 'black',
                        }}
                      />
                    }
                    size="large"
                    color="black"
                    sx={{
                      height: { xs: 'undefined', md: '3.75rem' },
                    }}
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <div
                      className={`tw-whitespace-nowrap ${
                        selectedCount > 0
                          ? 'tw-text-string-primary tw-font-semibold'
                          : 'tw-text-string-base'
                      }`}
                    >{`Filters (${selectedCount})`}</div>
                  </Button>
                </div>
                <Box
                  sx={{ display: { xs: 'none', md: 'block' }, width: '100%' }}
                >
                  <div className="tw-bg-surface-raised tw-flex tw-flex-1 tw-items-center tw-p-3 tw-px-8 tw-rounded-lg tw-border tw-border-surface-border tw-text-string-base">
                    <p className="tw-w-[55%] tw-flex tw-items-center tw-m-0">
                      0 deals
                    </p>

                    <div className="tw-flex-1 tw-flex tw-gap-x-3 tw-justify-between tw-items-center">
                      <div className="tw-flex-1">
                        <FormControl fullWidth size="small">
                          <Select value={sortBy} onChange={handleSortBy}>
                            <MenuItem value={'Most Recent'}>
                              Most Recent
                            </MenuItem>
                            <MenuItem value={'Top Deals'}>Top Deals</MenuItem>
                            <MenuItem value={'Percentage Off'}>
                              Percentage Off
                            </MenuItem>
                            <MenuItem value={'Trending Deals'}>
                              Trending Deals
                            </MenuItem>
                            {/*
                                    <MenuItem value={'Alphabetically'}>
                                    Alphabetically
                                    </MenuItem>
                                    */}
                            <MenuItem value={'Promo code included'}>
                              Promo code included
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <ToggleButtonGroup
                        size="small"
                        value={view}
                        exclusive
                        aria-label="view"
                        onChange={handleView}
                      >
                        <ToggleButton value="grid" aria-label="left aligned">
                          <GridOnIcon />
                        </ToggleButton>
                        <ToggleButton
                          value="horizontal"
                          aria-label="left aligned"
                        >
                          <ViewHeadlineIcon />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </div>
                </Box>
                <Box
                  sx={{ display: { xs: 'block', md: 'none' }, width: '100%' }}
                >
                  <div className="tw-flex-1">
                    <FormControl fullWidth size="small">
                      <Select value={sortBy} onChange={handleSortBy}>
                        <MenuItem value={'Most Recent'}>Most Recent</MenuItem>
                        <MenuItem value={'Top Deals'}>Top Deals</MenuItem>
                        <MenuItem value={'Percentage Off'}>
                          Percentage Off
                        </MenuItem>
                        <MenuItem value={'Trending Deals'}>
                          Trending Deals
                        </MenuItem>
                        {/*
                                <MenuItem value={'Alphabetically'}>
                                Alphabetically
                                </MenuItem>
                                */}
                        <MenuItem value={'Promo code included'}>
                          Promo code included
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </Box>
              </div>
              <FilterPills
                filter={filter}
                setFilter={setFilter}
                count={selectedFiltersCount(filter)}
              />
              <CustomPaginationEndlessMode
                items={loadingDemoDataList}
                component={(items) => showPosts(items)}
                fetchNextPage={getListDeals}
                hasMore={nextTokenRef.current === null ? false : true}
              />
            </div>
          }
        />
        <Filter
          open={showFilter}
          filter={filter}
          onClose={() => setShowFilter(!showFilter)}
          setFilter={setFilter}
          selectedFiltersCount={selectedFiltersCount}
        />
      </>
    );
  }

  return (
    <div>
      {loading == true ? (
        <>
          <Section
            title="Lightning Deals"
            content={
              <div className="tw-flex tw-flex-col tw-gap-y-4 lg:tw-gap-y-8 tw-w-full">
                {/*
                 ** Adding sorting header for mobile
                 */}
                <Box
                  fullWidth
                  className="tw-py-1"
                  sx={{ display: { xs: 'block', md: 'none' }, width: '100%' }}
                >
                  <Tabs
                    value={headerNav}
                    onChange={handleHeaderNav}
                    variant="scrollable"
                    scrollButtons={false}
                    textColor="inherit"
                    className="tw-text-string-base"
                    // allowScrollButtonsMobile
                    // centered
                    /*
                      sx={{
                        color:'rgb(255,255,255)'
                      }}
                      */
                  >
                    <Tab
                      label="Online Deals"
                      iconPosition="start"
                      icon={<img src="/Lightning_New.png" alt="" />}
                      value="Online Most Recent"
                      sx={{ textTransform: 'none' }}
                    />
                    <Tab
                      label="Top Deals"
                      iconPosition="start"
                      icon={<img src="/Lightning_Top.png" alt="" />}
                      value="Top Deals"
                      sx={{ textTransform: 'none' }}
                    />
                    <Tab
                      label="Lightning Deals"
                      iconPosition="start"
                      icon={<img src="/Lightning_Flash.png" alt="" />}
                      value="Lightning Most Recent"
                      sx={{ textTransform: 'none' }}
                    />
                    <Tab
                      label="Trend Deals"
                      iconPosition="start"
                      icon={<img src="/Lightning_Trending.png" alt="" />}
                      value="Trending Deals"
                      sx={{ textTransform: 'none' }}
                    />
                    <Tab
                      label="Most Percent Off Deals"
                      iconPosition="start"
                      icon={<img src="/Lightning_Percent.png" alt="" />}
                      value="Percentage Off"
                      sx={{ textTransform: 'none' }}
                    />
                    <Tab
                      label="Promo Code"
                      iconPosition="start"
                      icon={<img src="/Lightning_Promo.png" alt="" />}
                      value="Promo code included"
                      sx={{ textTransform: 'none' }}
                    />
                  </Tabs>
                </Box>
                {/*
                 ** Adding sorting header for tablet + website
                 */}
                <Box
                  fullWidth
                  className="tw-py-1"
                  sx={{ display: { xs: 'none', md: 'block' }, width: '100%' }}
                >
                  <Tabs
                    value={headerNav}
                    onChange={handleHeaderNav}
                    variant="fullWidth"
                    //scrollButtons={false}
                    textColor="inherit"
                    className="tw-text-string-base"
                    // allowScrollButtonsMobile
                    centered
                    /*
                      sx={{
                        color:'rgb(255,255,255)'
                      }}
                      */
                  >
                    <Tab
                      label="Online Deals"
                      iconPosition="start"
                      icon={<img src="/Lightning_New.png" alt="" />}
                      value="Online Most Recent"
                      sx={{ textTransform: 'none' }}
                    />
                    <Tab
                      label="Top Deals"
                      iconPosition="start"
                      icon={<img src="/Lightning_Top.png" alt="" />}
                      value="Top Deals"
                      sx={{ textTransform: 'none' }}
                    />
                    <Tab
                      label="Lightning Deals"
                      iconPosition="start"
                      icon={<img src="/Lightning_Flash.png" alt="" />}
                      value="Lightning Most Recent"
                      sx={{ textTransform: 'none' }}
                    />
                    <Tab
                      label="Trend Deals"
                      iconPosition="start"
                      icon={<img src="/Lightning_Trending.png" alt="" />}
                      value="Trending Deals"
                      sx={{ textTransform: 'none' }}
                    />
                    <Tab
                      label="Most Percent Off Deals"
                      iconPosition="start"
                      icon={<img src="/Lightning_Percent.png" alt="" />}
                      value="Percentage Off"
                      sx={{ textTransform: 'none' }}
                    />
                    <Tab
                      label="Promo Code"
                      iconPosition="start"
                      icon={<img src="/Lightning_Promo.png" alt="" />}
                      value="Promo code included"
                      sx={{ textTransform: 'none' }}
                    />
                  </Tabs>
                </Box>
                <Box fullWidth className="tw-py-1">
                  <div className="tw-rounded-sm tw-flex tw-gap-x-1 tw-p-1">
                    <img
                      className="tw-w-full"
                      src="/Lightning-3.png"
                      alt="First banner"
                    />
                  </div>
                </Box>
                <div className="tw-flex tw-items-center tw-gap-x-4 tw-w-full">
                  <div className="tw-py-3 tw-w-full lg:tw-w-3/6 xl:tw-w-[300px]">
                    <Button
                      fullWidth
                      variant="outlined"
                      startIcon={
                        <TuneRoundedIcon
                          sx={{
                            color: selectedCount > 0 ? '#3054F5' : 'black',
                          }}
                        />
                      }
                      size="large"
                      color="black"
                      sx={{
                        height: { xs: 'undefined', md: '3.75rem' },
                      }}
                      onClick={() => setShowFilter(!showFilter)}
                    >
                      <div
                        className={`tw-whitespace-nowrap ${
                          selectedCount > 0
                            ? 'tw-text-string-primary tw-font-semibold'
                            : 'tw-text-string-base'
                        }`}
                      >{`Filters (${selectedCount})`}</div>
                    </Button>
                  </div>
                  <Box
                    sx={{ display: { xs: 'none', md: 'block' }, width: '100%' }}
                  >
                    <div className="tw-bg-surface-raised tw-flex tw-flex-1 tw-items-center tw-p-3 tw-px-8 tw-rounded-lg tw-border tw-border-surface-border tw-text-string-base">
                      <p className="tw-w-[55%] tw-flex tw-items-center tw-m-0">
                        0 deals
                      </p>

                      <div className="tw-flex-1 tw-flex tw-gap-x-3 tw-justify-between tw-items-center">
                        <div className="tw-flex-1">
                          <FormControl fullWidth size="small">
                            <Select value={sortBy} onChange={handleSortBy}>
                              <MenuItem value={'Most Recent'}>
                                Most Recent
                              </MenuItem>
                              <MenuItem value={'Top Deals'}>Top Deals</MenuItem>
                              <MenuItem value={'Percentage Off'}>
                                Percentage Off
                              </MenuItem>
                              <MenuItem value={'Trending Deals'}>
                                Trending Deals
                              </MenuItem>
                              {/*
                              <MenuItem value={'Alphabetically'}>
                                Alphabetically
                              </MenuItem>
                              */}
                              <MenuItem value={'Promo code included'}>
                                Promo code included
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <ToggleButtonGroup
                          size="small"
                          value={view}
                          exclusive
                          aria-label="view"
                          onChange={handleView}
                        >
                          <ToggleButton value="grid" aria-label="left aligned">
                            <GridOnIcon />
                          </ToggleButton>
                          <ToggleButton
                            value="horizontal"
                            aria-label="left aligned"
                          >
                            <ViewHeadlineIcon />
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                    </div>
                  </Box>
                  <Box
                    sx={{ display: { xs: 'block', md: 'none' }, width: '100%' }}
                  >
                    <div className="tw-flex-1">
                      <FormControl fullWidth size="small">
                        <Select value={sortBy} onChange={handleSortBy}>
                          <MenuItem value={'Most Recent'}>Most Recent</MenuItem>
                          <MenuItem value={'Top Deals'}>Top Deals</MenuItem>
                          <MenuItem value={'Percentage Off'}>
                            Percentage Off
                          </MenuItem>
                          <MenuItem value={'Trending Deals'}>
                            Trending Deals
                          </MenuItem>
                          {/*
                          <MenuItem value={'Alphabetically'}>
                            Alphabetically
                          </MenuItem>
                          */}
                          <MenuItem value={'Promo code included'}>
                            Promo code included
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Box>
                </div>
                <FilterPills
                  filter={filter}
                  setFilter={setFilter}
                  count={selectedFiltersCount(filter)}
                />
                <CustomPaginationEndlessMode
                  items={loadingDemoDataList}
                  component={(items) => showPosts(items)}
                  fetchNextPage={getListDeals}
                  hasMore={nextTokenRef.current === null ? false : true}
                />
              </div>
            }
          />
          <Filter
            open={showFilter}
            filter={filter}
            onClose={() => setShowFilter(!showFilter)}
            setFilter={setFilter}
            selectedFiltersCount={selectedFiltersCount}
          />
        </>
      ) : (
        <>
          <Section
            title="Lightning Deals"
            content={
              <div className="tw-flex tw-flex-col tw-gap-y-4 lg:tw-gap-y-8 tw-w-full">
                {/*
                 ** Adding sorting header for mobile
                 */}
                <Box
                  fullWidth
                  className="tw-py-1"
                  sx={{ display: { xs: 'block', md: 'none' }, width: '100%' }}
                >
                  <Tabs
                    value={headerNav}
                    onChange={handleHeaderNav}
                    variant="scrollable"
                    scrollButtons={false}
                    textColor="inherit"
                    className="tw-text-string-base"
                    // centered
                    /*
                      sx={{
                        color:'rgb(255,255,255)'
                      }}
                      */
                  >
                    <Tab
                      label="Online Deals"
                      iconPosition="start"
                      icon={<img src="/Lightning_New.png" alt="" />}
                      value="Online Most Recent"
                      sx={{ textTransform: 'none' }}
                    />
                    <Tab
                      label="Top Deals"
                      iconPosition="start"
                      icon={<img src="/Lightning_Top.png" alt="" />}
                      value="Top Deals"
                      sx={{ textTransform: 'none' }}
                    />
                    <Tab
                      label="Lightning Deals"
                      iconPosition="start"
                      icon={<img src="/Lightning_Flash.png" alt="" />}
                      value="Lightning Most Recent"
                      sx={{ textTransform: 'none' }}
                    />
                    <Tab
                      label="Trend Deals"
                      iconPosition="start"
                      icon={<img src="/Lightning_Trending.png" alt="" />}
                      value="Trending Deals"
                      sx={{ textTransform: 'none' }}
                    />
                    <Tab
                      label="Most Percent Off Deals"
                      iconPosition="start"
                      icon={<img src="/Lightning_Percent.png" alt="" />}
                      value="Percentage Off"
                      sx={{ textTransform: 'none' }}
                    />
                    <Tab
                      label="Promo Code"
                      iconPosition="start"
                      icon={<img src="/Lightning_Promo.png" alt="" />}
                      value="Promo code included"
                      sx={{ textTransform: 'none' }}
                    />
                  </Tabs>
                </Box>
                {/*
                 ** Adding sorting header for tablet + website
                 */}
                <Box
                  fullWidth
                  className="tw-py-1"
                  sx={{ display: { xs: 'none', md: 'block' }, width: '100%' }}
                >
                  <Tabs
                    value={headerNav}
                    onChange={handleHeaderNav}
                    variant="fullWidth"
                    //scrollButtons={false}
                    textColor="inherit"
                    className="tw-text-string-base"
                    // allowScrollButtonsMobile
                    centered
                    /*
                      sx={{
                        color:'rgb(255,255,255)'
                      }}
                      */
                  >
                    <Tab
                      label="Online Deals"
                      iconPosition="start"
                      icon={<img src="/Lightning_New.png" alt="" />}
                      value="Online Most Recent"
                      sx={{ textTransform: 'none' }}
                    />
                    <Tab
                      label="Top Deals"
                      iconPosition="start"
                      icon={<img src="/Lightning_Top.png" alt="" />}
                      value="Top Deals"
                      sx={{ textTransform: 'none' }}
                    />
                    <Tab
                      label="Lightning Deals"
                      iconPosition="start"
                      icon={<img src="/Lightning_Flash.png" alt="" />}
                      value="Lightning Most Recent"
                      sx={{ textTransform: 'none' }}
                    />
                    <Tab
                      label="Trend Deals"
                      iconPosition="start"
                      icon={<img src="/Lightning_Trending.png" alt="" />}
                      value="Trending Deals"
                      sx={{ textTransform: 'none' }}
                    />
                    <Tab
                      label="Most Percent Off Deals"
                      iconPosition="start"
                      icon={<img src="/Lightning_Percent.png" alt="" />}
                      value="Percentage Off"
                      sx={{ textTransform: 'none' }}
                    />
                    <Tab
                      label="Promo Code"
                      iconPosition="start"
                      icon={<img src="/Lightning_Promo.png" alt="" />}
                      value="Promo code included"
                      sx={{ textTransform: 'none' }}
                    />
                  </Tabs>
                </Box>
                <Box fullWidth className="tw-py-1">
                  <div className="tw-rounded-sm tw-flex tw-gap-x-1 tw-p-1">
                    <img
                      className="tw-w-full"
                      src="/Lightning-3.png"
                      alt="First banner"
                    />
                  </div>
                </Box>
                <div className="tw-flex tw-items-center tw-gap-x-4 tw-w-full">
                  <div className="tw-py-3 tw-w-full lg:tw-w-3/6 xl:tw-w-[300px]">
                    <Button
                      fullWidth
                      variant="outlined"
                      startIcon={
                        <TuneRoundedIcon
                          sx={{
                            color: selectedCount > 0 ? '#398fed' : 'black',
                          }}
                        />
                      }
                      size="large"
                      color="black"
                      sx={{
                        height: { xs: 'undefined', md: '3.75rem' },
                      }}
                      onClick={() => setShowFilter(!showFilter)}
                    >
                      <div
                        className={`tw-whitespace-nowrap ${
                          selectedCount > 0
                            ? 'tw-text-string-primary tw-font-semibold'
                            : 'tw-text-string-base'
                        }`}
                      >{`Filters (${selectedCount})`}</div>
                    </Button>
                  </div>
                  <Box
                    sx={{ display: { xs: 'none', md: 'block' }, width: '100%' }}
                  >
                    <div className="tw-bg-surface-raised tw-flex tw-flex-1 tw-items-center tw-p-3 tw-px-8 tw-rounded-lg tw-border tw-border-surface-border tw-text-string-base">
                      <p className="tw-w-[55%] tw-flex tw-items-center tw-m-0">
                        {deals ? deals.length : 0} deals
                      </p>

                      <div className="tw-flex-1 tw-flex tw-gap-x-3 tw-justify-between tw-items-center">
                        <div className="tw-flex-1">
                          <FormControl fullWidth size="small">
                            <Select value={sortBy} onChange={handleSortBy}>
                              <MenuItem value={'Most Recent'}>
                                Most Recent
                              </MenuItem>
                              <MenuItem value={'Top Deals'}>Top Deals</MenuItem>
                              <MenuItem value={'Percentage Off'}>
                                Percentage Off
                              </MenuItem>
                              <MenuItem value={'Trending Deals'}>
                                Trending Deals
                              </MenuItem>
                              {/*
                              <MenuItem value={'Alphabetically'}>
                                Alphabetically
                              </MenuItem>
                              */}
                              <MenuItem value={'Promo code included'}>
                                Promo code included
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <ToggleButtonGroup
                          size="small"
                          value={view}
                          exclusive
                          aria-label="view"
                          onChange={handleView}
                        >
                          <ToggleButton value="grid" aria-label="left aligned">
                            <GridOnIcon />
                          </ToggleButton>
                          <ToggleButton
                            value="horizontal"
                            aria-label="left aligned"
                          >
                            <ViewHeadlineIcon />
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                    </div>
                  </Box>
                  <Box
                    sx={{ display: { xs: 'block', md: 'none' }, width: '100%' }}
                  >
                    <div className="tw-flex-1">
                      <FormControl fullWidth size="small">
                        <Select value={sortBy} onChange={handleSortBy}>
                          <MenuItem value={'Most Recent'}>Most Recent</MenuItem>
                          <MenuItem value={'Top Deals'}>Top Deals</MenuItem>
                          <MenuItem value={'Percentage Off'}>
                            Percentage Off
                          </MenuItem>
                          <MenuItem value={'Trending Deals'}>
                            Trending Deals
                          </MenuItem>
                          {/*
                          <MenuItem value={'Alphabetically'}>
                            Alphabetically
                          </MenuItem>
                          */}
                          <MenuItem value={'Promo code included'}>
                            Promo code included
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Box>
                </div>
                <FilterPills
                  filter={filter}
                  setFilter={setFilter}
                  count={selectedFiltersCount(filter)}
                />
                <CustomPaginationEndlessMode
                  items={deals}
                  component={(items) => showPosts(items)}
                  fetchNextPage={getListDeals}
                  hasMore={nextTokenRef.current === null ? false : true}
                />
              </div>
            }
          />
          <Filter
            open={showFilter}
            filter={filter}
            onClose={() => setShowFilter(!showFilter)}
            setFilter={setFilter}
            selectedFiltersCount={selectedFiltersCount}
          />
        </>
      )}
    </div>
  );
}

export default DealsLightning;
