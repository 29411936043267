import { useLoadScript } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import Post from '../utils/Post';
import Map_v2_API_ByState from './Map_v2_API_ByState';
import Map_v2_API_Geohash from './Map_v2_API_Geohash';
import Map_v2_API_Location from './Map_v2_API_Location';

// import SwipeableDrawer from '@mui/material/SwipeableDrawer';
// import { LuRectangleVertical } from "react-icons/lu";

const REACT_APP_GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const options = {
  styles: [
    {
      featureType: 'all',
      elementType: 'all',
      stylers: [{ saturation: -46 }, { lightness: -20 }],
    },
  ],
  gestureHandling: 'greedy',
  streetViewControl: false,
  mapTypeControl: false,
};

const libraries = ['places'];

function Map_v2_API() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
  });

  const [deals, setDeals] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [storeDetails, setStoreDetails] = useState([]);
  const [dealId2DetailsDict, setDealId2DetailsDict] = useState({});

  const mapRef = React.useRef();
  // const [mapLoaded, setMapLoaded] = useState(false);

  const [selectedMarkerPlaceId, setSelectedMarkerPlaceId] = useState(null);
  const [hoveredMarker, setHoveredMarker] = useState(null);

  const [storeDetailOpen, setStoreDetailOpen] = useState(false);

  const { myGeoHash, mapCenter, zoom, myZipcode, setMyZipcode } =
    Map_v2_API_Location({ isLoaded });

  const {
    dealsByState,
    storesByStateDetails,
    storesByStateMarkers,
    dealsByStateId2DetailsDict,
  } = Map_v2_API_ByState({ myGeoHash, zoom });

  const {
    geoHashDeals,
    geoHashStoreDetails,
    geoHashStoreMarkers,
    geoHashDealId2DetailsDict,
  } = Map_v2_API_Geohash({
    myGeoHash,
  });

  useEffect(() => {
    setDeals(geoHashDeals.concat(dealsByState));
    // console.log(`deals:`);
    // console.log(geoHashDeals.concat(dealsByState));
  }, [geoHashDeals, dealsByState]);

  useEffect(() => {
    const mergedMarkers = [
      ...geoHashStoreMarkers,
      ...storesByStateMarkers,
    ].reduce((acc, marker) => {
      // merge same placeId
      const existingMarker = acc.find((m) => m.placeId === marker.placeId);
      if (existingMarker) {
        existingMarker.dealIds = [
          ...new Set([...existingMarker.dealIds, ...marker.dealIds]),
        ];
      } else {
        acc.push(marker);
      }
      return acc;
    }, []);
    setMarkers(mergedMarkers);
  }, [geoHashStoreMarkers, storesByStateMarkers]);

  useEffect(() => {
    const mergedStoreDetails = [
      ...geoHashStoreDetails,
      ...storesByStateDetails,
    ].reduce((acc, detail) => {
      // merge same placeId
      const existingDetail = acc.find((d) => d.placeId === detail.placeId);
      if (existingDetail) {
        existingDetail.dealIds = [
          ...new Set([...existingDetail.dealIds, ...detail.dealIds]),
        ];
      } else {
        acc.push(detail);
      }
      return acc;
    }, []);
    setStoreDetails(mergedStoreDetails);
  }, [geoHashStoreDetails, storesByStateDetails]);

  useEffect(() => {
    setDealId2DetailsDict({
      ...geoHashDealId2DetailsDict,
      ...dealsByStateId2DetailsDict,
    });
  }, [geoHashDealId2DetailsDict, dealsByStateId2DetailsDict]);

  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
    // setMapLoaded(true);
  }, []);

  useEffect(() => {
    if (selectedMarkerPlaceId != null) {
      // // console.log(
      //   "Opening store detail dialog. StoreDetailPlaceId: ",
      //   selectedMarkerPlaceId
      // );
      setStoreDetailOpen(true);
    }
  }, [selectedMarkerPlaceId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const showPosts = (items) => (
    <div
        className="tw-grid tw-gap-1 sm:tw-gap-2 md:tw-gap-3 xl:tw-gap-5
         tw-grid-cols-2 sm:tw-grid-cols-3 md:tw-grid-cols-3 lg:tw-grid-cols-3 xl:tw-grid-cols-4 2xl:tw-grid-cols-5"
    >
        {items.map((item, index) => (
          <Post item={item} orientation="vertical" />
        ))}
    </div>
  );

  return {
    isLoaded,
    loadError,
    options,
    mapCenter,
    deals,
    storeDetails,
    geoHashStoreDetails,
    myZipcode,
    setMyZipcode,
    zoom,
    markers,
    dealId2DetailsDict,
    dealsInMostLocation: dealsByState,
    selectedMarkerPlaceId,
    setSelectedMarkerPlaceId,
    hoveredMarker,
    setHoveredMarker,
    storeDetailOpen,
    setStoreDetailOpen,
    onMapLoad,
    showPosts,
  };
}

export default Map_v2_API;
