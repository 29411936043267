import GridOnIcon from '@mui/icons-material/GridOn';
import ViewHeadlineIcon from '@mui/icons-material/ViewHeadline';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext_ } from '../../AppContextProvider';
import { AUTH_MODE } from '../constants/AppSyncAuthMode';
import Section from '../home/Section';

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import FormGroup from '@mui/material/FormGroup';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { categoryList } from '../constants/CategoryConstants';
import { storeList } from '../constants/StoreConstants';

import { API } from 'aws-amplify';
import { loadingDemoDataList } from '../../api/deal';
import { bookmarksByUser_id, getDeal } from '../../graphql/queries';
import HorizontalPostGrid from './HorizontalPostGrid';
import VerticalPostGrid from './VerticalPostGrid';
import useBookmarksApis from './useBookmarksApis';

function promo_alphabetically(ascending) {
  return function (a, b) {
    // equal items sort equally
    if (a.promotional_code === b.promotional_code) {
        return 0;
    }

    // nulls sort after anything else
    if (a.promotional_code === null) {
        return 1;
    }
    if (b.promotional_code === null) {
        return -1;
    }

    // otherwise, if we're ascending, lowest sorts first
    if (ascending) {
        return a.promotional_code < b.promotional_code ? -1 : 1;
    }

    // if descending, highest sorts first
    return a.promotional_code < b.promotional_code ? 1 : -1;
  };
}

function price_drop_alphabetically(ascending) {
  return function (a, b) {

    // nulls sort after anything else
    if (a.prev_price === null) {
      if (b.prev_price === null) {
        return 0;
      }
      else if(b.prev_price < b.price){
        return 0;
      }
      return 1;
    }
    if (b.prev_price === null) {
        if (a.prev_price < a.price) {
          return 0;
        }
        return -1;
    }

    // otherwise, if we're ascending, lowest sorts first
    let a_price_drop = (a.prev_price - a.price) / a.prev_price;
    let b_price_drop = (b.prev_price - b.price) / b.prev_price;
    if (ascending) {
        return a_price_drop < b_price_drop ? -1 : 1;
    }

    // if descending, highest sorts first
    return a_price_drop < b_price_drop ? 1 : -1;
  };
}

function Bookmarks() {
  const [view, setView] = useState('grid');
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { getBookmarks } = useBookmarksApis();
  const { user } = useAppContext_();
  const navigate = useNavigate();

  const handleView = (event, view) => {
    setView(view);
  };

  const [priceMin, setPriceMin] = useState(0);
  const [priceMax, setPriceMax] = useState(99999);
  // price
  const [prices, setPrices] = React.useState({
    priceMin: 0,
    priceMax: 9999,
  });

  const handlePriceChange = (event) => {
    setPrices({
      ...prices,
      [event.target.name]: event.target.value,
    });
  };

  /*for the new drawer
   */

  const typographyStyle = {
    fontFamily: 'Poppins',
    fontSize: '25px',
    fontWeight: '400',
    lineHeight: '16px',
    marginTop: '20px',
    marginBottom: '20px',
  };

  const filterFontStyle = {
    fontSize: '13px',
  };


  const handleDealSortChange = (event, newValue) => {
    setSortMetric(newValue);
  };

  /*Handling the collapse/uncollapse of left side search bar*/
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  /*Handling the collapse/uncollapse of left side search bar*/
  const [catsOpen, setCatsOpen] = React.useState(true);
  const handleCatsOpenClick = () => {
    setCatsOpen(!catsOpen);
  };

  /*Handling the collapse/uncollapse of left side search bar*/
  const [brandsOpen, setBrandsOpen] = React.useState(true);
  const handleBrandsOpenClick = () => {
    setBrandsOpen(!brandsOpen);
  };

  /*Handling the collapse/uncollapse of left side search bar*/
  const [priceOpen, setPriceOpen] = React.useState(false);
  const handlePriceOpenClick = () => {
    setPriceOpen(!priceOpen);
  };

  /*Handling the collapse/uncollapse of left side search bar*/
  const [lastOpen, setLastOpen] = React.useState(false);
  const handleClick = () => {
    setLastOpen(!lastOpen);
  };

  /*Handler for checking the leftside shipping tags*/
  const [delivery, setDelivery] = React.useState({
    // delivery.freeShipping, delivery.inStore
    free_shipping: null,
    instore: null,
  });

  const handleDeliveryChange = (event) => {
    setDelivery({
      ...delivery,
      [event.target.name]: event.target.checked,
    });
  };
  const [cats, setCats] = React.useState({
    'Arts & Craft Materials': true, // location.state.name
    Automotives: true,
    'Baby Products': true,
    'Beauty & Personal Care': true,
    Electronics: true,
    'Computers & Tablets': true,
    'Clothing & Fashion': true,
    'Health & Wellness': true,
    'Home & Furniture': true,
    'Gift Cards & Digital Goods': true,
    'Grocery, Food, & Drinks': true,
    'Kitchen & Appliances': true,
    'Miscellaneous (Other)': true,
    'Movies, Music, & Television': true,
    'Patio & Outdoor': true,
    'Pet Supplies': true,
    'Television & Video': true,
    'Tools & Home Improvement': true,
    'Toys, Games, & Collectibles': true,
  });

  /*handler for checking the left side category*/
  const handleCatsChange = async (event) => {
    await setCats({
      ...cats,
      [event.target.name]: event.target.checked,
    });
    // console.log(brands["Amazon"],brands["Best Buy"])
  };

  /*handler for checking the left side brands*/
  const [brands, setBrands] = React.useState({
    // delivery.freeShipping, delivery.inStore
    Amazon: true,
    'Best Buy': true,
    Target: true,
    Walmart: true,
    Costco: true,
    "Sam's Club": true,
    Adorama: true,
    'B&H Photo': true,
    MicroCenter: true,
    BrandsMartUSA: true,
    'Woot!': true,
    "Lowe's": true,
    'The Home Depot': true,
    'CVS Pharmacy': true,
    Walgreens: true,
    'PC Richard & Son': true,
    "Macy's": true,
    "Kohl's": true,
    Verizon: true,
    "BJ's Wholesale Club": true,
    Wayfair: true,
    "Dick's Sporting Goods": true,
    'Office Depot': true,
    Staples: true,
    Nike: true,
    Adidas: true,
    'Champs Sports': true,
    "JC Penney's": true,
    Lululemon: true,
    'Shop My Exchange': true,
    PetSmart: true,
    Petco: true,
    'Tractor Supply Co.': true,
    'AT&T': true,
    GameStop: true,
    Sephora: true,
    'Foot Locker': true,
    GAP: true,
    'Big Lots': true,
    Belk: true,
    'Neiman Marcus': true,
    IKEA: true,
    'Shop Premium Outlets': true,
    'Ace Hardware': true,
    AutoZone: true,
    Other: true,
  });

  const handleBrandsChange = async (event) => {
    await setBrands({
      ...brands,
      [event.target.name]: event.target.checked,
    });
    // console.log(brands["Amazon"],brands["Best Buy"])
  };

  /*
        for the view selection bar
    */
  const [alignment, setAlignment] = React.useState('column');

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const [sortMetric, setSortMetric] = React.useState('Top Deals');

  const handleSortMetric = (event) => {
    setSortMetric(event.target.value);
  };

  const [viewNum, setViewNum] = React.useState(20);
  const handleViewNum = (event) => {
    setViewNum(event.target.value);
  };

  /*Implment the left side filter bar.*/
  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation">
      <List
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            color="primary"
          >
            <Typography sx={typographyStyle}>Filters</Typography>
          </ListSubheader>
        }
      >
        <ListItemButton onClick={handleCatsOpenClick}>
          <ListItemText primary="Categories" />
          {catsOpen ? <ExpandMore /> : <ExpandLess />}
        </ListItemButton>
        <Collapse in={catsOpen} timeout="auto" unmountOnExit>
          <FormControl
            required
            component="fieldset"
            sx={{ marginTop: 1, marginLeft: 3 }}
            variant="standard"
          >
            <Box
              sx={{
                mb: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 210,
                width: 210,
                overflow: 'hidden',
                overflowY: 'scroll',
                // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
              }}
            >
              <FormGroup>
                {categoryList.map(function (cat, i) {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={cats[cat]}
                          onChange={handleCatsChange}
                          name={cat}
                          sx={{ marginBottom: '3px' }}
                        />
                      }
                      label={
                        <Typography sx={filterFontStyle}>{cat}</Typography>
                      }
                    />
                  );
                })}
              </FormGroup>
            </Box>
          </FormControl>
        </Collapse>

        <ListItemButton onClick={handleBrandsOpenClick}>
          <ListItemText primary="Brands" />
          {brandsOpen ? <ExpandMore /> : <ExpandLess />}
        </ListItemButton>
        <Collapse in={brandsOpen} timeout="auto" unmountOnExit>
          <FormControl
            required
            component="fieldset"
            sx={{ marginTop: 1, marginLeft: 3 }}
            variant="standard"
          >
            <Box
              sx={{
                mb: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 210,
                width: 210,
                overflow: 'hidden',
                overflowY: 'scroll',
                // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
              }}
            >
              <FormGroup>
                {storeList.map(function (store, i) {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={brands[store]}
                          onChange={handleBrandsChange}
                          name={store}
                          size="small"
                        />
                      }
                      label={
                        <Typography sx={filterFontStyle}>{store}</Typography>
                      }
                    />
                  );
                })}
              </FormGroup>
            </Box>
          </FormControl>
        </Collapse>

        <ListItemButton onClick={handlePriceOpenClick}>
          <ListItemText primary="Prices(Min-Max)" />
          {priceOpen ? <ExpandMore /> : <ExpandLess />}
        </ListItemButton>
        <Collapse in={priceOpen} timeout="auto" unmountOnExit>
          <FormControl
            required
            component="fieldset"
            sx={{ marginTop: 1, marginLeft: 3 }}
            variant="standard"
          >
            <Stack
              component="form"
              sx={{
                width: '20ch',
              }}
              spacing={2}
              noValidate
              autoComplete="off"
              direction="row"
            >
              <TextField
                hiddenLabel
                id="min-price"
                name="priceMin"
                defaultValue="Min"
                value={prices['priceMin']}
                size="small"
                onChange={handlePriceChange}
              />
              <TextField
                hiddenLabel
                id="max-price"
                name="priceMax"
                defaultValue="Max"
                value={prices['priceMax']}
                size="small"
                onChange={handlePriceChange}
              />
            </Stack>
          </FormControl>
        </Collapse>

        <ListItemButton onClick={handleClick}>
          <ListItemText primary="Delivery Requirements" />
          {lastOpen ? <ExpandMore /> : <ExpandLess />}
        </ListItemButton>
        <Collapse in={lastOpen} timeout="auto" unmountOnExit>
          <FormControl
            required
            component="fieldset"
            sx={{ marginLeft: 3 }}
            variant="standard"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={delivery['free_shipping']}
                    onChange={handleDeliveryChange}
                    name="free_shipping"
                  />
                }
                label="Free Shipping"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={delivery['instore']}
                    onChange={handleDeliveryChange}
                    name="instore"
                  />
                }
                label="In-store Deals"
              />
            </FormGroup>
          </FormControl>
        </Collapse>
      </List>
    </Box>
  );

  const [filters, setFilters] = useState({
    filter: {
      and: [
        { id: { ne: '1' } },
        { price: { ge: prices['priceMin'] } },
        { price: { le: prices['priceMax'] } },
        { free_shipping: { ne: null } },
        { instore: { ne: null } },
        { _deleted: { ne: true } },
        { expired: { ne: true } },
      ],
      or: [
        { forum_type: { eq: 'Arts & Craft Materials' } },
        { forum_type: { eq: 'Automotives' } },
        { forum_type: { eq: 'Baby Products' } },
        { forum_type: { eq: 'Beauty & Personal Care' } },
        { forum_type: { eq: 'Electronics' } },
        { forum_type: { eq: 'Computers & Tablets' } },
        { forum_type: { eq: 'Clothing & Fashion' } },
        { forum_type: { eq: 'Health & Wellness' } },
        { forum_type: { eq: 'Home & Furniture' } },
        { forum_type: { eq: 'Gift Cards & Digital Goods' } },
        { forum_type: { eq: 'Grocery, Food, & Drinks' } },
        { forum_type: { eq: 'Kitchen & Appliances' } },
        { forum_type: { eq: 'Miscellaneous (Other)' } },
        { forum_type: { eq: 'Movies, Music, & Television' } },
        { forum_type: { eq: 'Patio & Outdoor' } },
        { forum_type: { eq: 'Pet Supplies' } },
        { forum_type: { eq: 'Television & Video' } },
        { forum_type: { eq: 'Tools & Home Improvement' } },
        { forum_type: { eq: 'Toys, Games, & Collectibles' } },
      ],
    },
  });
  /* Update filters based on left side filters*/
  useEffect(() => {
    // change current filters
    // console.log("Changing filters")
    let copy = structuredClone(filters);

    //update prices
    copy.filter.and[1].price.ge = prices['priceMin'];
    copy.filter.and[2].price.le = prices['priceMax'];

    // update free_shipping and instore
    // console.log(copy.filter)
    if (delivery['free_shipping'] != null) {
      copy.filter.and[3]['free_shipping'].ne = !delivery['free_shipping'];
    }
    if (delivery['instore'] != null) {
      copy.filter.and[4]['instore'].ne = !delivery['instore'];
    }

    //update categories
    for (const [cat, value] of Object.entries(cats)) {
      // console.log(cat,value);
      if (value == true) {
        // check
        // console.log(cat, value, copy['filter'])

        if (copy.filter.or == null) {
          copy['filter']['or'] = [{ forum_type: { eq: cat } }];
          let new_and_filter = copy['filter']['and'].filter(function (el) {
            return el.forum_type == null || el.forum_type.eq != 'NA';
          });
          copy.filter.and = new_and_filter;
        } else {
          let new_or_filter = copy['filter']['or'].filter(function (el) {
            return el.forum_type == null || el.forum_type.eq != cat;
          });
          copy.filter.or = new_or_filter;
          copy['filter']['or'].push({ forum_type: { eq: cat } });
        }
      } else {
        //uncheck
        //console.log(cat, value, copy['filter'])

        if (copy['filter']['or']) {
          let new_filter = copy['filter']['or'].filter(function (el) {
            return el.forum_type == null || el.forum_type.eq != cat;
          });

          if (new_filter.length == 0) {
            delete copy.filter.or;
            copy['filter']['and'].push({ forum_type: { eq: 'NA' } });
          } else {
            copy.filter.or = new_filter;
          }
        }
      }
    }
    // update brands
    for (const [brand, value] of Object.entries(brands)) {
      if (value == true) {
        // check a brand
        //console.log(brand, value, copy['filter'])
        let new_filter = copy['filter']['and'].filter(function (el) {
          return el.dealer_type == null || el.dealer_type.ne != brand;
        });
        copy.filter.and = new_filter;
      } else {
        // uncheck a brand
        // console.log(brand, value, copy['filter'])
        let new_and_filter = copy['filter']['and'].filter(function (el) {
          return el.dealer_type == null || el.dealer_type.ne != brand;
        });
        copy.filter.and = new_and_filter;
        copy['filter']['and'].push({ dealer_type: { ne: brand } });
      }
    }
    // console.log(copy);
    setFilters(copy);
  }, [open]);

  /* Filter bookmarked deals according to filters*/
  const filterDeals = async (deal_id) => {
    let copy = structuredClone(filters);
    //console.log("filter each bookmarked deals");
    // console.log(copy);
    copy['id'] = deal_id;
    const deal = await API.graphql({
      query: getDeal,
      variables: copy,
    });
    if (!deal.data.getDeal) {
      // console.log('This deal is filtered');
      return null;
    } else if (deal.data.getDeal.expired) {
      return null;
    }
    return deal.data.getDeal;
  };

  const getFilteredDeals = async () => {
    if (user) {
      const bookmarks = await API.graphql({
        query: bookmarksByUser_id,
        variables: {
          // user_id: user.attributes.sub,
          user_id: user.attributes.sub,
          filter: {
            _deleted: { ne: true },
          },
        },
        authMode: AUTH_MODE.USER_POOLS,
      });
      // console.log("all bookmarks:", bookmarks.data.bookmarksByUser_id.items)
      let deals = [];

      for (const bookmark of bookmarks.data.bookmarksByUser_id.items) {
        const deal = await filterDeals(bookmark.deal_id);
        if (deal !== null) {
          // console.log('this deal is selected', deal)
          deals.push(deal);
        }
      }
      for (let i = 0; i < deals.length; i++) {
        let item = structuredClone(deals[i]);
        if (item.prev_price !== null && item.prev_price > item.price) {
          let my_price_drop =
            100 * ((item.prev_price - item.price) / item.prev_price);
          deals[i].price_drop = my_price_drop;
        } else {
          deals[i].price_drop = 0;
        }
      }
      //deals.sort((a, b) => (b.vote - b.down_vote) - (a.vote - b.down_vote))

      //console.log('filtered deals');
      //console.log(deals);
      switch (sortMetric) {
        case 'All Deals':
          // "Alphabetically"
          deals.sort((a, b) => String(a.title).localeCompare(String(b.title)));
          break;
        case 'Most Recent':
          deals.sort((a, b) =>
            String(b.updatedAt).localeCompare(String(a.updatedAt))
          );
          break;
        case 'Top Deals':
          deals.sort((a, b) => b.vote - b.down_vote - (a.vote - b.down_vote));
          break;
        /*
        case 'Percentage Off':
          // price_drop
          // deals.sort((a, b) => b.price_drop - a.price_drop);
          deals.sort(price_drop_alphabetically(false));
          break;

        case "Trending Deals":
            tempDeals.sort((a, b) => (b.trending_tag - a.trending_tag))
            break;
        */
        case 'Promo code included':
          deals.sort(promo_alphabetically(false));
          break;
      }
      setPosts(deals);
    } else {
      setPosts([]);
    }
  };

  const getDeals = async () => {
    let tempDeals = await getBookmarks();

    // tempDeals.sort((a, b) => String(a.title).localeCompare(String(b.title)));
    switch (sortMetric) {
      case 'All Deals':
        // "Alphabetically"
        tempDeals.sort((a, b) =>
          String(a.title).localeCompare(String(b.title))
        );
        break;
      case 'Most Recent':
        tempDeals.sort((a, b) =>
          String(b.updatedAt).localeCompare(String(a.updatedAt))
        );
        break;
      case 'Top Deals':
        tempDeals.sort((a, b) => b.vote - b.down_vote - (a.vote - b.down_vote));
        break;
      /*
      case 'Percentage Off':
        // price_drop
        // tempDeals.sort((a, b) => b.price_drop - a.price_drop);
        tempDeals.sort(price_drop_alphabetically(false));
        break;

      case "Trending Deals":
          tempDeals.sort((a, b) => (b.trending_tag - a.trending_tag))
          break;
      */
      case 'Promo code included':
        tempDeals.sort(promo_alphabetically(false));
        break;
    }
    // console.log('1', sortMetric, tempDeals);
    if (tempDeals && tempDeals.length < 100) {
      setPosts(tempDeals);
    } else {
      setPosts(tempDeals.slice(0, 100));
    }
    // setPosts(await getBookmarks());
  };

  useEffect(() => {
    const loadData = async () => {
      if (user) {
        // setIsLoading(true);
        await getDeals();
        setIsLoading(false);
      } else {
        navigate('/login');
      }
    };
    loadData();
  }, []);

  /*reflect bookmarked deals*/
  /*
  useEffect(() => {
    // console.log(filters)
    getFilteredDeals();
  }, [filters]);
  */

  useEffect(() => {
    let tempDeals = structuredClone(posts);
    console.log(tempDeals);
    // console.log(sortMetric, tempDeals)
    switch (sortMetric) {
      case 'All Deals':
        // "Alphabetically"
        tempDeals.sort((a, b) =>
          String(a.title).localeCompare(String(b.title))
        );
        break;
      case 'Most Recent':
        tempDeals.sort((a, b) =>
          String(b.updatedAt).localeCompare(String(a.updatedAt))
        );
        break;
      case 'Top Deals':
        tempDeals.sort((a, b) => b.vote - b.down_vote - (a.vote - b.down_vote));
        break;
      /*
      case 'Percentage Off':
        // price_drop
        // tempDeals.sort((a, b) => b.price_drop - a.price_drop);
        tempDeals.sort(price_drop_alphabetically(false));
        break;

        case "Trending Deals":
            tempDeals.sort((a, b) => (b.trending_tag - a.trending_tag))
            break;
      */
      case 'Promo code included':
        tempDeals.sort(promo_alphabetically(false));
        break;
    }
    // console.log('2',sortMetric,tempDeals);
    setPosts(tempDeals);
  }, [sortMetric]);

  return (
    <div>
      <Section
        startIcon="/bookmark_heart.svg"
        title="Bookmarks"
        content={
          <div className="tw-flex tw-flex-col tw-gap-y-4 lg:tw-gap-y-8  tw-w-full">
            <div className="tw-flex tw-items-center tw-gap-x-4">
              {/*
              <div className="tw-py-3 tw-flex-1 sm:tw-flex-initial">
                <Button
                  variant="outlined"
                  startIcon={<TuneRoundedIcon />}
                  size="large"
                  color="black"
                  sx={{ height: "100%" }}
                  onClick={toggleDrawer(true)}
                >
                  Filter
                </Button>
                <Drawer open={open} onClose={toggleDrawer(false)}>
                    {DrawerList}
                </Drawer>
              </div>
              */}
              <Box sx={{ display: { xs: 'none', md: 'block' }, width: '100%' }}>
                <div className="tw-text-string-base tw-bg-surface-raised tw-flex tw-flex-1 tw-items-center tw-p-3 tw-px-8 tw-rounded-lg tw-border tw-border-surface-border">
                  <p className="tw-w-[55%] tw-flex tw-items-center tw-m-0">
                    {posts ? posts.length : 0} items
                  </p>

                  <div className="tw-flex-1 tw-flex tw-gap-x-3 tw-justify-between tw-items-center">
                    <div className="tw-flex-1">
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">
                          Sort By
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={sortMetric}
                          label="Sort"
                          onChange={handleSortMetric}
                        >
                          <MenuItem value={'Most Recent'}>Most Recent</MenuItem>
                          <MenuItem value={'Top Deals'}>Top Deals</MenuItem>
                          {/*<MenuItem value={'Percentage Off'}>
                            Percentage Off
                          </MenuItem>*/}
                          {/*<MenuItem value={'Trending Deals'}>Trending Deals</MenuItem>*/}
                          {/*<MenuItem value={'All Deals'}>All Deals</MenuItem>*/}
                          <MenuItem value={'Promo code included'}>
                            Promo code included
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <ToggleButtonGroup
                      size="small"
                      value={view}
                      exclusive
                      aria-label="view"
                      onChange={handleView}
                    >
                      <ToggleButton value="grid" aria-label="left aligned">
                        <GridOnIcon />
                      </ToggleButton>
                      <ToggleButton
                        value="horizontal"
                        aria-label="left aligned"
                      >
                        <ViewHeadlineIcon />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                </div>
              </Box>
              <Box sx={{ display: { xs: 'block', md: 'none' }, width: '100%' }}>
                <div className="tw-flex-1">
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Sort By
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={sortMetric}
                      label="Sort"
                      onChange={handleSortMetric}
                    >
                      <MenuItem value={'Most Recent'}>Most Recent</MenuItem>
                      <MenuItem value={'Top Deals'}>Top Deals</MenuItem>
                      {/*<MenuItem value={'Percentage Off'}>
                        Percentage Off
                      </MenuItem>*/}
                      {/*<MenuItem value={'Trending Deals'}>Trending Deals</MenuItem>*/}
                      {/*<MenuItem value={'All Deals'}>All Deals</MenuItem>*/}
                      <MenuItem value={'Promo code included'}>
                            Promo code included
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Box>
            </div>
            {view === 'grid' ? (
              <>
                {isLoading == true ? (
                  <VerticalPostGrid posts={loadingDemoDataList} />
                ) : (
                  <VerticalPostGrid posts={posts} />
                )}
              </>
            ) : (
              <>
                {isLoading == true ? (
                  <HorizontalPostGrid posts={loadingDemoDataList} />
                ) : (
                  <HorizontalPostGrid posts={posts} />
                )}
              </>
            )}
            <Typography
              variant="body1"
              sx={{ mt: 2, mb: 8, textAlign: 'center' }}
              className='tw-text-string-neutral'
            >
              {'We are displaying the most 100 recent bookmarks shared now'}
              <br />
              {'for more, please use the filter to search on the Deals page'}
              <br />
            </Typography>
          </div>
        }
      />
    </div>
  );
}

export default Bookmarks;
