import * as React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/header/Header';

import { ThemeProvider } from '@mui/material';
import Home from './components/home/Home';

import { AppContextProvider, useAppContext_ } from './AppContextProvider';

// import OnWebNotification from './components/alert/OnWebNotification';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import ResetPassword from './components/auth/ResetPassword';
import Verify from './components/auth/Verify';

import Bookmarks from './components/bookmarks/Bookmarks';
import BrandsList from './components/brands/BrandsList';
import DealEditorIndex from './components/deal/DealEditorIndex';
import Comments from './components/dealDetails/Comments';
import DealDetails_ from './components/dealDetails/DealDetails_';

import Footer from './components/footer/Footer';
import DealThreads from './components/forum/DealThreads';
import CookiesPolicy from './components/policies/CookiesPolicy';
import Disclaimer from './components/policies/Disclaimer';
import PrivacyPolicy from './components/policies/PrivacyPolicy';
import TermsConditions from './components/policies/TermsConditions';
import Profile from './components/profile/Profile';
import PublicProfile from './components/profile/PublicProfile';

import { Container } from '@mui/material';
import BottomNavigation from '../src/BottomNavigation';
import AdminPage from './components/admin/AdminPage';
import MySharedDealListPage from './components/deal/MySharedDealListPage';
import Filter_v2 from './components/filter/Filter_v2';
import Contacts from './components/forum/Contacts';
import ProfileSidebar from './components/sidebar/ProfileSidebar';
import ApiTestPage from './components/test/ApiTestPage';

// import Nearby from './components/map/NearbyInstoreDealsMap';

import { useState } from 'react';
import Alerts from './components/alerts/Alerts';
import Deals from './components/deals/Deals';
import Map_v2 from './components/map/Map_v2';
import { dark, light } from './theme';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import FederatedUsernameCheck from './components/FederatedUsernameCheck';
import SetUsername from './components/auth/SetUsername';
import DealsLightning from './components/dealsLightning/DealsLightning';
import { NotificationListener } from './components/notification/NotificationListener';

const queryClient = new QueryClient();

// Parent component for rendering child routes
function AppLayout() {
  const { theme } = useAppContext_();

  return (
    <ThemeProvider theme={theme === 'light' ? light : dark}>
      <div className="tw-bg-surface-base tw-flex tw-flex-col tw-h-full">
        <Header />
        <div className="tw-flex-1">
          <Outlet />
        </div>
        <Footer />
        <BottomNavigation />
      </div>
    </ThemeProvider>
  );
}

function App__() {
  const [sidebarWidth, setSidebarWidth] = useState(null);

  return (
    <>
      <AppContextProvider>
        {/* <ThemeProvider theme={theme === 'light' ? light : dark}> */}
        <QueryClientProvider client={queryClient}>
          {/* <ReactQueryDevtools initialIsOpen={true} /> */}
          <Routes>
            <Route path="/" element={<AppLayout />}>
              <Route element={<FederatedUsernameCheck />}>
                <Route path="/" element={<Home />} />
                {/* Authentication flow. */}
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/set-username" element={<SetUsername />} />
                <Route path="/verify" element={<Verify />} />
                <Route path="/reset-password" element={<ResetPassword />} />

                <Route
                  path="/profile"
                  element={
                    <Container
                      sx={{
                        marginTop: '15px',
                        marginLeft: sidebarWidth,
                        width: `calc(100% - ${sidebarWidth})`,
                      }}
                    >
                      <ProfileSidebar sidebarIndex={0} />
                      <Profile />
                    </Container>
                  }
                />
                <Route path="/comments" element={<Comments />} />
                {/*
              <Route element={<PrivateRoute />}>
                <Route path="/comments" element={<Comments />} />
              </Route>
              */}
                <Route
                  path="/alerts"
                  element={
                    <Container
                      sx={{
                        marginTop: '15px',
                        marginLeft: sidebarWidth,
                        width: `calc(100% - ${sidebarWidth})`,
                      }}
                    >
                      <ProfileSidebar sidebarIndex={1} />
                      <Alerts />
                    </Container>
                  }
                />
                <Route path="/AlertList" element={<Alerts />} />
                <Route path="/alerts" element={<Alerts />} />
                <Route path="/bookmarks" element={<Bookmarks />} />
                <Route path="/brands" element={<BrandsList />} />
                <Route path="/map" element={<Map_v2 />} />
                {/* <Route path="/categoryForum" element={<ElecForum />} /> */}
                {/* <Route path="/DealerForum" element={<DealerForum />} /> */}
                <Route path="/threads" element={<DealThreads />} />
                <Route path="/share-deal" element={<DealEditorIndex />} />
                <Route path="/cookies-policy" element={<CookiesPolicy />} />
                <Route path="/disclaimer" element={<Disclaimer />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route
                  path="/terms-and-conditions"
                  element={<TermsConditions />}
                />
                <Route path="/deal-details/:id" element={<DealDetails_ />} />
                <Route path="/dealDetails/:id" element={<DealDetails_ />} />
                <Route path="/filter" element={<Filter_v2 />} />
                <Route path="/CookiesPolicy" element={<CookiesPolicy />} />
                <Route path="/Disclaimer" element={<Disclaimer />} />
                <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                <Route path="/TermsConditions" element={<TermsConditions />} />
                {/* <Route path="/contacts" element={<Contacts />} /> */}
                <Route path="/apitest" element={<ApiTestPage />} />

                <Route
                  path="/AdminPage"
                  element={
                    <Container
                      sx={{
                        marginTop: '15px',
                        marginLeft: sidebarWidth,
                        width: `calc(100% - ${sidebarWidth})`,
                      }}
                    >
                      <ProfileSidebar sidebarIndex={3} />
                      <AdminPage />
                    </Container>
                  }
                />

                <Route
                  path="/MySharedDealList"
                  element={
                    <Container
                      sx={{
                        marginTop: '15px',
                        marginLeft: sidebarWidth,
                        width: `calc(100% - ${sidebarWidth})`,
                      }}
                    >
                      <ProfileSidebar sidebarIndex={2} />
                      <MySharedDealListPage />
                    </Container>
                  }
                />
                <Route
                  path="/DealEditor"
                  element={
                    <Container
                      sx={{
                        marginTop: '15px',
                        marginLeft: sidebarWidth,
                        width: `calc(100% - ${sidebarWidth})`,
                      }}
                    >
                      <ProfileSidebar sidebarIndex={2} />
                      <DealEditorIndex />
                    </Container>
                  }
                />
                <Route path="/deals" element={<Deals />} />
                <Route path="/lightning" element={<DealsLightning />} />
                <Route path="/filter" element={<Filter_v2 show={true} />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route
                  path="/testPublicProfile/:id/:username"
                  element={<PublicProfile />}
                />
              </Route>
            </Route>
          </Routes>
        </QueryClientProvider>
        {/* </ThemeProvider> */}
        <NotificationListener />
      </AppContextProvider>
    </>
  );
}

export default App__;
