import { API } from 'aws-amplify';
import { getPreferredUsernameMapping } from '../../graphql/queries';

export const getUsernameByPreferredName = async (PreferredName) => {
  try {
    const response = await API.graphql({
      query: getPreferredUsernameMapping,
      variables: { preferred_username: PreferredName.toLowerCase() },
      //   authMode: AUTH_MODE.USER_POOLS,
    });
    // console.log(response);
    if (response.data && response.data.getPreferredUsernameMapping) {
      const owner = response.data.getPreferredUsernameMapping.owner;
      //   const username = owner.split('::')[1];
      const username = owner;
      return username;
    }
  } catch (error) {
    console.error('Error fetching username', error);
  }
  return PreferredName;
};
