export const twitterTemplateMap = {
  'Lightning Deal': `{title} now \${price} via {dealer_type}

⚡️Add Lightning Deal Price When Available. Check Often For Change In Availability!

✌️Clip {coupon} Off Coupon

🏷️ Use code {promoCode} at checkout

🔗 - {link}

#LRDeal | #AD`,

  'Coupon Only': `{title} now \${price} via {dealer_type}

✂️ Clip {coupon} Off Coupon

🔗 - {link}

#LRDeal | #AD`,

  'Promo Code Only': `{title} now \${price} via {dealer_type}

✅ Use code {promoCode} at checkout

🔗 - {link}

#LRDeal | #AD`,

  'Coupon And Promo Code': `{title} now \${price} via {dealer_type}

✌️ Clip {coupon} Off Coupon

✅ Use code {promoCode} at checkout

🔗 - {link}

#LRDeal | #AD`,

  'Price Drop': `{title} now \${price} via {dealer_type}

⬇️ {discountPercentage}% Price Drop

🔗 - {link}

#LRDeal | #AD`,

  'Subscribe And Save': `{title} now \${price} via {dealer_type}

✂️ Clip {coupon} Off Coupon

♻️ Subscribe & Save For Additional Savings

🔗 - {link}

#LRDeal | #AD`,

  'Regular Link Creation': `{title} now \${price} via {dealer_type}

✍️ {descritption}

🔗 - {link}

#LRDeal | #AD`,
};
