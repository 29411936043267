import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Box, FormGroup } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { URL2Store } from '../constants/URL2Store';
import { autoFillingHandler } from './DealEditorAPIs';

export function DealEditorTextField({
  dealDetails,
  setDealDetails,
  inStorePickup,
}) {
  const price_regex = /^[0-9,]*\.?[0-9]*$/;

  const handleDescriptionChange = async (new_des) => {
    // const cat = categorizeProduct(dealDetails.title + ' ' + new_des);
    // console.log('matchedCategory: ' + cat);
    setDealDetails({
      ...dealDetails,
      description: new_des,
      // forum_type: cat ? cat : null,
    });
  };

  const handleLinkChange = async (deal_link) => {
    // match store with link
    const matchedStore = URL2Store(deal_link);
    setDealDetails({
      ...dealDetails,
      deal_link: deal_link,
      dealer_type: matchedStore || dealDetails.dealer_type,
    });

    fetchImageFromAPI(deal_link, matchedStore);
  };

  const handleTitleChange = async (new_title) => {
    // const cat = categorizeProduct(new_title + ' ' + dealDetails.description);
    // console.log('matchedCategory: ' + cat);
    setDealDetails({
      ...dealDetails,
      title: new_title,
      // forum_type: cat ? cat : null,
    });
  };

  const fetchImageFromAPI = async (deal_link, matchedStore) => {
    if (!deal_link) {
      return;
    }

    const isSupportedLink =
      deal_link.includes('//www.amazon.com/') ||
      deal_link.includes('//a.co/') ||
      deal_link.includes('//amzn.to/');

    if (!isSupportedLink) {
      return;
    }

    try {
      const response = await autoFillingHandler(deal_link);
      // console.log('Fetched data from amazon: ', response);
      const parsedResponse = JSON.parse(response);
      if (parsedResponse.status !== '200') {
        return;
      }
      setDealDetails((prevDetails) => ({
        ...prevDetails,
        deal_link: deal_link,
        dealer_type: matchedStore || prevDetails.dealer_type,
        title: parsedResponse.title || prevDetails.title,
        // let user enter the current price manually
        // price: parsedResponse.price
        //   ? parseFloat(parsedResponse.price.replace(/[^0-9.-]+/g, ''))
        //   : prevDetails.price,
        prev_price: parsedResponse.prev_price
          ? parseFloat(parsedResponse.prev_price.replace(/[^0-9.-]+/g, ''))
          : prevDetails.prev_price,
        uploaded_img_links:
          parsedResponse.img_links || prevDetails.uploaded_img_links,
      }));
    } catch (error) {
      console.error('Error fetching img from amazon: ', error);
    }
  };

  return (
    <>
      <FormGroup className="mb-3" controlId="formBasicText">
        <Box position="relative">
          <TextField
            type="text"
            // placeholder="URL *"
            label={
              <span>
                URL <span style={{ color: 'red' }}>*</span>
              </span>
            }
            value={dealDetails.deal_link}
            onChange={async (evt) => {
              const newLink = evt.target.value;
              await handleLinkChange(newLink);
            }}
            fullWidth
            multiline
            rows={dealDetails?.deal_link?.length > 20 ? 4 : 1}
            InputLabelProps={{
              shrink: dealDetails.deal_link ? true : false,
            }}
          />
          <IconButton
            onClick={async () => {
              const text = await navigator.clipboard.readText();
              await handleLinkChange(text);
            }}
            style={{
              position: 'absolute',
              right: 5,
              bottom: 7,
              opacity: 0.7,
            }}
            tabIndex="-1"
          >
            <FileCopyIcon />
          </IconButton>
        </Box>
      </FormGroup>
      <FormGroup className="mb-3" controlId="formBasicText">
        <Box position="relative">
          <TextField
            type="text"
            // placeholder="Title"
            label={
              <span>
                Title <span style={{ color: 'red' }}>*</span>
              </span>
            }
            value={dealDetails.title}
            onChange={(evt) => handleTitleChange(evt.target.value)}
            fullWidth
            multiline
            rows={dealDetails?.title?.length > 20 ? 4 : 1}
            inputProps={{
              maxLength: 200,
            }}
            InputLabelProps={{
              shrink: dealDetails.title ? true : false,
            }}
          />
          <IconButton
            onClick={async () => {
              const text = await navigator.clipboard.readText();
              await handleTitleChange(text);
            }}
            style={{
              position: 'absolute',
              right: 5,
              bottom: 7,
              opacity: 0.7,
            }}
            tabIndex="-1"
          >
            <FileCopyIcon />
          </IconButton>
        </Box>
      </FormGroup>
      <FormGroup className="mb-3" controlId="formBasicText">
        <Box position="relative">
          <TextField
            type="text"
            // placeholder="Price *"
            label={
              <span>
                Price <span style={{ color: 'red' }}>*</span>
              </span>
            }
            value={dealDetails.price}
            onChange={(evt) => {
              const value = evt.target.value;
              if (price_regex.test(value)) {
                setDealDetails({
                  ...dealDetails,
                  price: value,
                });
              }
            }}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
          <IconButton
            onClick={async () => {
              const value = await navigator.clipboard.readText();
              if (price_regex.test(value)) {
                setDealDetails({
                  ...dealDetails,
                  price: value,
                });
              }
            }}
            style={{
              position: 'absolute',
              right: 5,
              bottom: 7,
              opacity: 0.7,
            }}
            tabIndex="-1"
          >
            <FileCopyIcon />
          </IconButton>
        </Box>
      </FormGroup>
      <FormGroup className="mb-3" controlId="formBasicText">
        <Box position="relative">
          <TextField
            type="text"
            // placeholder="Previous Price"
            label={<span>Previous Price</span>}
            value={dealDetails.prev_price}
            onChange={(evt) => {
              const value = evt.target.value;
              if (price_regex.test(value)) {
                setDealDetails({
                  ...dealDetails,
                  prev_price: value,
                });
              }
            }}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
          <IconButton
            onClick={async () => {
              const value = await navigator.clipboard.readText();
              if (price_regex.test(value)) {
                setDealDetails({
                  ...dealDetails,
                  prev_price: value,
                });
              }
            }}
            style={{
              position: 'absolute',
              right: 5,
              bottom: 7,
              opacity: 0.7,
            }}
            tabIndex="-1"
          >
            <FileCopyIcon />
          </IconButton>
        </Box>
      </FormGroup>
      <FormGroup className="mb-3" controlId="formBasicText">
        <Box position="relative">
          <TextField
            type="text"
            // placeholder="Description"
            label="Description"
            value={dealDetails.description}
            onChange={(evt) => handleDescriptionChange(evt.target.value)}
            fullWidth
            multiline
            rows={dealDetails?.description?.length > 20 ? 4 : 1}
            inputProps={{
              maxLength: 200,
            }}
            InputLabelProps={{
              shrink: dealDetails.description ? true : false,
            }}
          />
          <IconButton
            onClick={async () => {
              const text = await navigator.clipboard.readText();
              await handleDescriptionChange(text);
            }}
            style={{
              position: 'absolute',
              right: 5,
              bottom: 7,
              opacity: 0.7,
            }}
            tabIndex="-1"
          >
            <FileCopyIcon />
          </IconButton>
        </Box>
      </FormGroup>
    </>
  );
}

export default DealEditorTextField;
