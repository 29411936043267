import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Auth } from 'aws-amplify';

import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ShareIcon from '@mui/icons-material/Share';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  SIDEBARWIDTHLARGE,
  SIDEBARWIDTHMEDIUM,
  SIDEBARWIDTHSMALL,
} from '../constants/SidebarWidth';

import { Breakpoint } from 'react-socks';

export default function ProfileSidebar(props) {
  const [sidebarWidth, setSidebarWidth] = useState(null);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (isSmall) {
      setSidebarWidth(SIDEBARWIDTHSMALL);
    } else if (isMedium) {
      setSidebarWidth(SIDEBARWIDTHMEDIUM);
    } else if (isLarge) {
      setSidebarWidth(SIDEBARWIDTHLARGE);
    }
  }, [isSmall, isMedium, isLarge]);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const groups =
          user.signInUserSession.accessToken.payload['cognito:groups'];
        if (groups && groups.includes('admin')) {
          setIsAdmin(true);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      {(isSmall || isMedium) && (
        <Box
          sx={{
            mx: 'auto',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Tabs
            value={props.sidebarIndex}
            // onChange={handleChange}
            variant="scrollable"
            // variant="fullWidth"
            scrollButtons={true}
            aria-label="tabs"
            //centered
          >
            <Link
              to="/profile"
              className="Link-no-change-color !tw-text-string-base"
            >
              <Tab icon={<AccountBoxIcon />} label="Profile" />
            </Link>
            <Link
              to="/alerts"
              className="Link-no-change-color !tw-text-string-base"
            >
              <Tab icon={<NotificationsIcon />} label="Notification" />
            </Link>
            <Link
              to="/MySharedDealList"
              className="Link-no-change-color !tw-text-string-base"
            >
              <Tab icon={<ShareIcon />} label="Deals Shared" />
            </Link>
            {isAdmin && (
              <Link to="/AdminPage" className="Link-no-change-color">
                <Tab icon={<AdminPanelSettingsIcon />} label="Admin Page" />
              </Link>
            )}
          </Tabs>
        </Box>
      )}

      {isLarge && (
        <div>
          <Breakpoint customQuery="(min-width: 900px) and (max-width: 1600px)">
            <Box
              sx={{
                mx: 'auto',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Tabs
                value={props.sidebarIndex}
                // onChange={handleChange}
                // variant="scrollable"
                variant="fullWidth"
                scrollButtons={false}
                aria-label="tabs"
                centered
              >
                <Link
                  to="/profile"
                  className="Link-no-change-color !tw-text-string-base"
                >
                  <Tab icon={<AccountBoxIcon />} label="Profile" />
                </Link>
                <Link
                  to="/alerts"
                  className="Link-no-change-color !tw-text-string-base"
                >
                  <Tab icon={<NotificationsIcon />} label="Notification" />
                </Link>
                <Link
                  to="/MySharedDealList"
                  className="Link-no-change-color !tw-text-string-base"
                >
                  <Tab icon={<ShareIcon />} label="Deals Shared" />
                </Link>
                {isAdmin && (
                  <Link
                    to="/AdminPage"
                    className="Link-no-change-color !tw-text-string-base"
                  >
                    <Tab icon={<AdminPanelSettingsIcon />} label="Admin Page" />
                  </Link>
                )}
              </Tabs>
            </Box>
          </Breakpoint>
          <Breakpoint customQuery="(min-width: 1600px)">
            <div
              className="sidebar"
              style={{ width: sidebarWidth, zIndex: 0, position: 'sticky' }}
            />
            <Box
              className="sidebar-buttons"
              sx={{
                width: '100%',
                maxWidth: sidebarWidth,
                position: 'absolute',
                zIndex: 2,
              }}
            >
              <List component="nav" aria-label="main mailbox folders">
                <Link
                  to="/profile"
                  className="Link-no-change-color !tw-text-string-base"
                >
                  <ListItemButton selected={props.sidebarIndex === 0}>
                    <ListItemIcon>
                      <AccountBoxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Profile" />
                  </ListItemButton>
                </Link>
                <Divider />
                <Link
                  to="/alerts"
                  className="Link-no-change-color !tw-text-string-base"
                >
                  <ListItemButton selected={props.sidebarIndex === 1}>
                    <ListItemIcon>
                      <NotificationsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Subscription" />
                  </ListItemButton>
                </Link>
                <Divider />
                <Link
                  to="/MySharedDealList"
                  className="Link-no-change-color !tw-text-string-base"
                >
                  <ListItemButton selected={props.sidebarIndex === 2}>
                    <ListItemIcon>
                      <ShareIcon />
                    </ListItemIcon>
                    <ListItemText primary="Deals Shared" />
                  </ListItemButton>
                </Link>
                <Divider />
                {isAdmin && (
                  <Link to="/AdminPage" className="Link-no-change-color">
                    <ListItemButton selected={props.sidebarIndex === 3}>
                      <ListItemIcon>
                        <AdminPanelSettingsIcon />
                      </ListItemIcon>
                      <ListItemText primary="Admin Page" />
                    </ListItemButton>
                  </Link>
                )}
              </List>
            </Box>
          </Breakpoint>
        </div>
      )}
    </div>
  );
}
