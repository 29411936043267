import {
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material';
import { API, Auth } from 'aws-amplify';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { LosCommentVoteFunc } from '../../graphql/mutations';
import { getCommentVote } from '../../graphql/queries';
import { AUTH_MODE } from '../constants/AppSyncAuthMode';

export function ApiCommentVote() {
  const [commentId, setCommentId] = useState('');
  const [upvote, setUpvote] = useState(false);
  const [downvote, setDownvote] = useState(false);
  const [commentVote, setCommentVote] = useState(null);
  const [userId, setUserId] = useState('');

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        console.error('user id: ', user.attributes.sub);
        setUserId(user.attributes.sub);
      } catch (error) {
        console.error('Error fetching user id: ', error);
      }
    };
    fetchUserId();
  }, []);

  const handleUpvoteToggle = (event) => {
    setUpvote(event.target.checked);
  };

  const handleDownvoteToggle = (event) => {
    setDownvote(event.target.checked);
  };

  async function fetchCommentVote(commentId) {
    try {
      const id = `${commentId}-${userId}`;
      const response = await API.graphql({
        query: getCommentVote,
        variables: { id },
        authMode: AUTH_MODE.USER_POOLS,
      });
      console.log('response: ', response);
      setCommentVote(response.data.getCommentVote);
    } catch (error) {
      console.error('Error fetching comment vote: ', error);
    }
  }

  async function votingHandler(comment_id, upvote, downvote) {
    try {
      const response = await API.graphql({
        query: LosCommentVoteFunc,
        variables: {
          comment_id: comment_id, // string
          upvote: upvote, // boolean
          downvote: downvote, // boolean
        },
        authMode: AUTH_MODE.USER_POOLS,
      });
      console.error('response: ', response);
      alert(response.data.LosCommentVoteFunc);
    } catch (error) {
      console.error('Error: ', error);
      alert('Error: ', error);
    }
    setTimeout(() => {
      fetchCommentVote(commentId);
    }, 1000);
    fetchCommentVote(commentId);
  }

  const handleCommentIdchange = (e) => {
    setCommentId(e.target.value);
    fetchCommentVote(e.target.value);
  };

  return (
    <Card sx={{ bgcolor: '#f5f5f5', width: '100%' }}>
      <CardContent>
        Comment Vote API
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <TextField
              sx={{ margin: 1 }}
              type="text"
              value={commentId}
              placeholder="commentId"
              onChange={(e) => handleCommentIdchange(e)}
            />
            <Button
              variant="outlined"
              color="primary"
              onClick={() => fetchCommentVote(commentId)}
            >
              {commentVote &&
                !commentVote._deleted &&
                (commentVote.upvote ? 'upvoted' : 'downvoted')}
            </Button>
          </Box>

          <FormControlLabel
            control={<Switch checked={upvote} onChange={handleUpvoteToggle} />}
            label="Upvote"
          />
          <FormControlLabel
            control={
              <Switch checked={downvote} onChange={handleDownvoteToggle} />
            }
            label="Downvote"
          />
          <Button
            variant="contained"
            color="primary"
            sx={{ margin: 1, textTransform: 'none' }}
            onClick={() => {
              votingHandler(commentId, upvote, downvote);
            }}
          >
            set vote
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}
