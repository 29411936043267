import { Auth } from 'aws-amplify';
import ngeohash from 'ngeohash';
import { useEffect, useState } from 'react';

const REACT_APP_GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

export default function Map_v2_API_Location({ isLoaded }) {
  const [myZipcode, setMyZipcode] = useState('');
  const [myGeoHash, setMyGeoHash] = useState('');

  const defaultCenter = { lat: 39.8283, lng: -98.5795 }; // Center of the US
  const defaultZoom = 4; // Zoom level to show the entire US

  // Define state for the map center and zoom level
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [zoom, setZoom] = useState(defaultZoom);

  useEffect(() => {
    if (isLoaded) {
      (async () => {
        if (myZipcode && myZipcode.length === 5) {
          console.log('myZipcode: ', myZipcode);
          handleSaveZipcodeToCognito(myZipcode);
          await setMapCenterByZipcode(myZipcode);
        } else if (!myZipcode) {
          handleSaveZipcodeToCognito(myZipcode);
          setMapCenterByIpAddress();
        }
      })();
    }
  }, [myZipcode, isLoaded]);

  useEffect(() => {
    (async () => {
      if (!(await fetchUrlZipcode()) && !(await fetchUserZipcode())) {
        // fetchUserIp();
        setMapCenterByIpAddress();
      }
    })();
  }, []);

  const handleSaveZipcodeToCognito = async (zipcode) => {
    if (zipcode && zipcode.length !== 5) {
      return;
    }
    try {
      const user = await Auth.currentAuthenticatedUser();
      const result = await Auth.updateUserAttributes(user, {
        address: zipcode,
      });
      if (result === 'SUCCESS') {
        // // console.log("SUCCESS updating zipcode");
      }
    } catch (error) {
      // // console.log("Error updating zipcode: ", error);
    }
  };

  const fetchUrlZipcode = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlZipcode = urlParams.get('zipcode');
    if (urlZipcode) {
      setMyZipcode(urlZipcode);
      return true;
    }
    return false;
  };

  const fetchUserZipcode = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user?.attributes?.address) {
        setMyZipcode(user?.attributes?.address);
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  const setMapCenterByZipcode = async (zipcode) => {
    let position;
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: zipcode }, (results, status) => {
      if (status === 'OK') {
        // Check if the location is in the United States
        if (
          results[0].formatted_address.includes('USA') ||
          results[0].formatted_address.includes('United States')
        ) {
          position = results[0].geometry.location;
          const geohash = ngeohash.encode(position.lat(), position.lng());
          setMyGeoHash(geohash);
          // console.log('myGeoHash: ', geohash);
          setMapCenter({
            lat: position.lat(),
            lng: position.lng(),
          });
          setZoom(12); // Zoom level to show the area around the zipcode
        }
      }
    });
    return position;
  };

  const setMapCenterByIpAddress = async () => {
    try {
      const response = await fetch(
        `https://www.googleapis.com/geolocation/v1/geolocate?key=${REACT_APP_GOOGLE_MAP_API_KEY}`,
        {
          method: 'POST',
          body: JSON.stringify({
            considerIp: true,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const data = await response.json();
      // console.log('ip location data: ');
      // console.log(data);
      const position = data.location;
      const geohash = ngeohash.encode(position.lat, position.lng);
      setMyGeoHash(geohash);
      // console.log('myGeoHash: ', geohash);

      setMapCenter({
        lat: position.lat,
        lng: position.lng,
      });
      setZoom(11); // Zoom level to show the area around the IP address
    } catch (error) {
      console.error('setMapCenterByIpAddress Error:', error);
    }
  };

  return { myGeoHash, mapCenter, zoom, myZipcode, setMyZipcode };
}
