import React, { useEffect } from 'react';

export default function Disclaimer() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ margin: '20px' }} className="tw-text-string-base">
      <h1>Disclaimer</h1>
      <p>Last updated: January 24, 2024</p>
      <h2>Interpretation and Definitions</h2>
      <h3>Interpretation</h3>
      <p>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>
      <h3>Definitions</h3>
      <p>For the purposes of this Disclaimer:</p>
      <ul>
        <li>
          <strong>Company</strong> (referred to as either &quot;the
          Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in
          this Disclaimer) refers to Restocks LLC., 871 Venetia Bay Blvd., #203,
          Venice, FL, 34285.
        </li>
        <li>
          <strong>Service</strong> refers to the Website.
        </li>
        <li>
          <strong>You</strong> means the individual accessing the Service, or
          the company, or other legal entity on behalf of which such individual
          is accessing or using the Service, as applicable.
        </li>
        <li>
          <strong>Website</strong> refers to Lord Of Savings, accessible from{' '}
          <a
            className="tw-text-string-primary"
            href="https://www.lordofsavings.com"
            rel="external nofollow noopener"
            target="_blank"
          >
            https://www.lordofsavings.com
          </a>
        </li>
      </ul>
      <h2>Disclaimer</h2>
      <p>
        The information contained on the Service is for general information
        purposes only.
      </p>
      <p>
        The Company assumes no responsibility for errors or omissions in the
        contents of the Service.
      </p>
      <p>
        In no event shall the Company be liable for any special, direct,
        indirect, consequential, or incidental damages or any damages
        whatsoever, whether in an action of contract, negligence or other tort,
        arising out of or in connection with the use of the Service or the
        contents of the Service. The Company reserves the right to make
        additions, deletions, or modifications to the contents on the Service at
        any time without prior notice.
      </p>
      <p>
        The Company does not warrant that the Service is free of viruses or
        other harmful components.
      </p>
      <h2>FTC Affiliate Disclaimer</h2>
      <p>
        The disclosure that follows is intended to fully comply with the Federal
        Trade Commission's policy of the United States that requires the Company
        to be transparent about any and all affiliate relations the Company may
        have on the Service.
      </p>
      <p>
        You should assume that some of the links are &quot;affiliate
        links&quot;, a link with a special tracking code.
      </p>
      <p>
        This means that if You click on an affiliate link and purchase the item,
        the Company may receive an affiliate commission. This is a legitimate
        way to monetize and pay for the operation of the Service and the Company
        gladly reveal its affiliate relationships to You.
      </p>
      <p>
        The price of the item is the same whether it is an affiliate link or
        not. Regardless, the Company only recommends products or services the
        Company believes will add value to users.
      </p>
      <p>Affiliate advertising programs that the Service uses are:</p>
      <ul>
        <li>
          <p>Amazon Services LLC Associates Program</p>
          <p>As an Amazon Associate, I earn from qualifying purchases.</p>
          <p>
            The Company is a participant in the Amazon Services LLC Associates
            Program, an affiliate advertising program designed to provide a
            means for sites to earn advertising fees by advertising and linking
            to Amazon.com or endless.com, MYHABIT.com, SmallParts.com, or
            AmazonWireless.com.
          </p>
          <p>
            Pages on this Service may include affiliate links to Amazon and its
            affiliate sites on which the owner of this Service, Restocks LLC.,
            871 Venetia Bay Blvd., #203, Venice, FL, 34285, will make a referral
            commission.
          </p>
        </li>
        <li>
          <p>Commission Junction</p>
        </li>
        <li>
          <p>Mavely</p>
        </li>
        <li>
          <p>Planet Howl</p>
        </li>
      </ul>
      <h2>External Links Disclaimer</h2>
      <p>
        The Service may contain links to external websites that are not provided
        or maintained by or in any way affiliated with the Company.
      </p>
      <p>
        Please note that the Company does not guarantee the accuracy, relevance,
        timeliness, or completeness of any information on these external
        websites.
      </p>
      <h2>Advertising Disclaimer</h2>
      <p>
        Third party advertisements and links to third party websites may also
        appear while using the Service. The Company does not make any
        representation as to the accuracy or suitability of any of the
        information contained in those advertisements or websites and does not
        accept any responsibility or liability for the conduct or content of
        those advertisements and websites and the offerings made by the
        third-parties.
      </p>
      <p>
        Third party advertisements and links to other websites where goods or
        services are advertised are not endorsements or recommendations by the
        Company of the third party sites, goods or services. The Company takes
        no responsibility for the content of the ads, promises made, or the
        quality/reliability of the products or services offered in all
        advertisements.
      </p>
      <h2>Errors and Omissions Disclaimer</h2>
      <p>
        The information given by the Service is for general guidance on matters
        of interest only. Even if the Company takes every precaution to ensure
        that the content of the Service is both current and accurate, errors can
        occur. Plus, given the changing nature of laws, rules and regulations,
        there may be delays, omissions or inaccuracies in the information
        contained on the Service.
      </p>
      <p>
        The Company is not responsible for any errors or omissions, or for the
        results obtained from the use of this information.
      </p>
      <h2>Fair Use Disclaimer</h2>
      <p>
        The Company may use copyrighted material which has not always been
        specifically authorized by the copyright owner. The Company is making
        such material available for criticism, comment, news reporting,
        teaching, scholarship, or research.
      </p>
      <p>
        The Company believes this constitutes a &quot;fair use&quot; of any such
        copyrighted material as provided for in section 107 of the United States
        Copyright law.
      </p>
      <p>
        If You wish to use copyrighted material from the Service for your own
        purposes that go beyond fair use, You must obtain permission from the
        copyright owner.
      </p>
      <h2>Views Expressed Disclaimer</h2>
      <p>
        The Service may contain views and opinions which are those of the
        authors and do not necessarily reflect the official policy or position
        of any other author, agency, organization, employer or company,
        including the Company.
      </p>
      <p>
        Comments published by users are their sole responsibility and the users
        will take full responsibility, liability and blame for any libel or
        litigation that results from something written in or as a direct result
        of something written in a comment. The Company is not liable for any
        comment published by users and reserves the right to delete any comment
        for any reason whatsoever.
      </p>
      <h2>No Responsibility Disclaimer</h2>
      <p>
        The information on the Service is provided with the understanding that
        the Company is not herein engaged in rendering legal, accounting, tax,
        or other professional advice and services. As such, it should not be
        used as a substitute for consultation with professional accounting, tax,
        legal or other competent advisers.
      </p>
      <p>
        In no event shall the Company or its suppliers be liable for any
        special, incidental, indirect, or consequential damages whatsoever
        arising out of or in connection with your access or use or inability to
        access or use the Service.
      </p>
      <h2>&quot;Use at Your Own Risk&quot; Disclaimer</h2>
      <p>
        All information in the Service is provided &quot;as is&quot;, with no
        guarantee of completeness, accuracy, timeliness or of the results
        obtained from the use of this information, and without warranty of any
        kind, express or implied, including, but not limited to warranties of
        performance, merchantability and fitness for a particular purpose.
      </p>
      <p>
        The Company will not be liable to You or anyone else for any decision
        made or action taken in reliance on the information given by the Service
        or for any consequential, special or similar damages, even if advised of
        the possibility of such damages.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Disclaimer, You can contact Us:
      </p>
      <ul>
        <li>
          <p>By email: nate@lordofrestock.com</p>
        </li>
        <li>
          <p>
            By visiting this page on our website:{' '}
            <a
              className="tw-text-string-primary"
              href="https://www.lordofsavings.com/contacts"
              rel="external nofollow noopener"
              target="_blank"
            >
              <div className="tw-truncate">
                https://www.lordofsavings.com/contacts
              </div>
            </a>
          </p>
        </li>
      </ul>
      <script
        data-cfasync="false"
        src="/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"
      ></script>
    </div>
  );
}
