import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Container, Grid, IconButton, Typography } from '@mui/material';

import { Auth } from 'aws-amplify';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import EditIcon from '@mui/icons-material/Edit';

import { DataGrid } from '@mui/x-data-grid';

export default function AdminUsersPage(props) {
    const navigate = useNavigate()
    const REACT_APP_PROFILE_BUCKET_NAME = process.env.REACT_APP_PROFILE_BUCKET_NAME;
    const [dialogInfo, setDialogInfo] = useState("");

    const [cognitoUser, setCognitoUser] = useState(null);

    const [bodyFontSize, setBodyFontSize] = useState('1.2em');
    const [headerFontSize, setHeaderFontSize] = useState('1.8em');
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isLarge = useMediaQuery(theme.breakpoints.up('md'));


    useEffect(() => {
        if (isSmall) {
            setHeaderFontSize('1.6em');
            setBodyFontSize('1em');
        } else if (isMedium) {
            setHeaderFontSize('1.6em');
            setBodyFontSize('1em');
        } else if (isLarge) {
            setHeaderFontSize('2em');
            setBodyFontSize('1.2em');
        }
    }, [isSmall, isMedium, isLarge]);


    const handleOpenDialog = (info) => {
        setDialogInfo(info);
    };

    const handleCloseDialog = () => {
        setDialogInfo("");
    };

    useEffect(() => {
        Auth.currentAuthenticatedUser()
          .then(user => {
            const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
            if (! groups || ! groups.includes('admin')) {
                navigate('/'); // Redirect to home page if user is not an admin
            } else {
                setCognitoUser(user); // Set the authenticated user to the coguser state
            }
          })
          .catch(err => {
            console.log(err);
            navigate('/'); // Redirect to home page if user is not logged in
          });
      }, []);


      const columns = [
        {
            field: 'id', headerName: 'id',
        },
        {
            field: 'fullName',
            headerName: 'Full name',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 160,
            valueGetter: (params) =>
                `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        },
        {
            field: 'edit',
            headerName: 'Edit',
            sortable: false,
            width: 100,
            renderCell: (params) => (
                <IconButton
                    variant="contained"
                    onClick={() => handleOpenDialog("coming soon")}
                >
                    <EditIcon />
                </IconButton>
            ),
        },
      ];

      const rows = [
        { id: 1, lastName: 'test', firstName: 'test'},
      ];

    return (
        <Container>

            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 2, margin: 2  }}>
                        <Typography variant="h6" sx={{ fontSize: headerFontSize}} className='tw-text-string-base'>User List</Typography>
                    </Box>
                </Grid>


                <Grid item xs={12} >
                <Box sx={{width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 10,
                            },
                        },
                        }}
                        // pageSizeOptions={[5]}
                        // checkboxSelection
                        // disableRowSelectionOnClick
                    />
                </Box>
                </Grid>

                {/* <Grid item xs={12} ><Divider variant="middle" sx={{borderWidth: 0.8, borderColor: 'black'}} /></Grid> */}

            </Grid>
            &nbsp;&nbsp;

        <Dialog
            open={dialogInfo}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            Info
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialogInfo}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
        </Container>
    );
  }
