import { useEffect, useState } from 'react';

function usePropState(state) {
  const [value, setValue] = useState(structuredClone(state));

  const setPropValue = (newValue) => {
    setValue(newValue);
  };

  useEffect(() => setValue(state), [state]);

  return [value, setPropValue];
}

export default usePropState;
