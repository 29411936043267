import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useEffect, useState } from 'react';
import { SubCategoryKeyList } from '../constants/CategoryConstants';

function FilterPills({ filter, setFilter, count }) {
  const [showAllFilters, setShowAllFilters] = useState(count < 17);
  const [show, setShow] = useState(count < 17);

  const fetchCategory = (label) => {
    let res = "Other";
    Object.entries(SubCategoryKeyList).map(([category, sub_list]) => {
      //console.log(category, sub_list);
      // console.log(label);
      //console.log(sub_list.findIndex(ele => ele == label));
      if(sub_list.findIndex(ele => ele == label) > -1){
        res = category
      }
    })
    return res;
  }

  const handleClick = (type, label) => {
    setFilter((prevState) => {
      let value;
      if ((type === 'forum_types') | (type === 'dealer_types') | (type === 'sub_categories')) {
        value = prevState[type].filter((options) => options !== label);
      } else if ((type === 'price_min') | (type === 'price_max')) {
        value = 0;
      } else if (type === 'free_shipping') {
        value = false;
      } else if (type === 'instore') {
        value = false;
      }else if (type === 'promo_code_included') {
        value = false;
      }

      return {
        ...prevState,
        [type]: value,
      };
    });
  };

  const handleShow = () => setShow(!show);

  useEffect(() => {
    setShowAllFilters(count < 17);
    setShow(count < 17);
  }, [count]);

  return (
    <div className="tw-flex tw-flex-col">
      <div
        className={`tw-flex tw-gap-4 tw-flex-wrap tw-transition-all tw-overflow-hidden ${
          !show && 'tw-h-24'
        }`}
      >
        {Object.entries(filter).map(([key, value]) => {
          let label = '';
          if (
            (key === 'free_shipping' || key === 'instore') &&
            value === true
          ) {
            label = key === 'free_shipping' ? 'Free Shipping' : 'In Store';
            return <Pill label={label} onClick={() => handleClick(key, '')} />;
          } else if (
            (key === 'promo_code_included') &&
            value === true
          ) {
            label = 'Promo Code Included';
            return <Pill label={label} onClick={() => handleClick(key, '')} />;
          } else if (
            (key === 'price_min' || key === 'price_max') &&
            value > 0
          ) {
            label =
              key === 'price_min' ? `Price ≥ $${value}` : `Price ≤ $${value}`;
            return <Pill label={label} onClick={() => handleClick(key, '')} />;
          } else if (key === 'dealer_types') {
            return value.map((label, index) => (
              <Pill
                key={index}
                label={label}
                onClick={() => handleClick(key, label)}
              />
            ));
          } else if (key === 'forum_types') {
            return value.map((label, index) => (
              <Pill
                key={index}
                label={'All ' + label + ' included'}
                onClick={() => handleClick(key, label)}
              />
            ));
          } else if (key === 'sub_categories'){
            return value.map((label, index) => (
              <Pill
                key={index}
                label={fetchCategory(label) + '->' + label}
                onClick={() => handleClick(key, label)}
              />
            ));
          }
        })}
      </div>
      {!showAllFilters && (
        <div
          className="tw-py-4 tw-w-full tw-text-center tw-flex tw-gap-x-4 tw-items-center hover:tw-opacity-50 tw-transition-all hover:tw-cursor-pointer"
          onClick={handleShow}
        >
          <div className="tw-h-[2px] tw-rounded-full tw-w-full tw-bg-surface-primary"></div>
          <div className="tw-flex-1 tw-w-full tw-whitespace-nowrap tw-font-semibold tw-text-string-primary">
            {!show ? `See All ${count} Selected Filters` : 'See Less'}
          </div>
          <div className="tw-h-[2px] tw-rounded-full tw-w-full tw-bg-surface-primary"></div>
        </div>
      )}
    </div>
  );
}

function Pill({ label, onClick }) {
  return (
    <div
      className="hover:tw-cursor-pointer tw-h-fit tw-transition-all hover:tw-bg-opacity-15 tw-border-2 tw-border-surface-primary tw-bg-surface-base tw-bg-opacity-[0.04] tw-text-string-primary tw-font-medium tw-rounded-md tw-py-1 tw-px-3 tw-flex tw-justify-center tw-items-center tw-gap-x-2 tw-text-sm md:tw-text-base"
      onClick={onClick}
    >
      {label}
      <div className="tw-bg-surface-base tw-border tw-border-surface-primary tw-flex tw-justify-center tw-items-center tw-rounded-full tw-w-fit tw-p-[2px]">
        <CloseRoundedIcon
          fontSize="small"
          className="!tw-text-string-primary"
          sx={{ width: '16px', height: '16px' }}
        />
      </div>
    </div>
  );
}

export default FilterPills;