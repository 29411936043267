import * as React from 'react';

import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export default function MapDealCard({
  storeName = '',
  dealTitle = '',
  dealPrice = '',
  dealDealerType = '',
  dealAddress = '',
  dealImageLink = '',
  dealId = '',
}) {
  const card = (
    <React.Fragment>
      <CardContent>
        <Link
          to={`/deal-details/${dealId}`}
          style={{
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            color: 'inherit',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <img
                src={dealImageLink}
                alt={dealTitle}
                style={{
                  width: 'auto',
                  height: 'auto',
                  maxHeight: '80px',
                  maxWidth: '80px',
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <Grid container direction="column">
                <Grid item>
                  <Typography
                    sx={{ fontSize: 16, fontWeight: 'bold' }}
                    color="text.secondary"
                    gutterBottom
                  >
                    {dealTitle}
                  </Typography>
                  <Typography
                    sx={{ fontSize: 16, mt: '2px', fontWeight: 'bold' }}
                    color="error"
                  >
                    ${dealPrice}
                  </Typography>
                  {/* <Typography sx={{ fontSize: 14, mt: '2px' }} color="text.secondary" >
                                {dealDealerType}
                            </Typography> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Link>
      </CardContent>
    </React.Fragment>
  );

  return (
    <Box sx={{}}>
      <Card variant="outlined" sx={{ backgroundColor: '' }}>
        {card}
      </Card>
    </Box>
  );
}
