import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


import { Box, Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';


import { Auth } from 'aws-amplify';


import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import { API, graphqlOperation } from 'aws-amplify';
import { dealsByPosted_date } from '../../graphql/queries';


export default function AdminDashboardPage(props) {
    const navigate = useNavigate()
    const REACT_APP_PROFILE_BUCKET_NAME = process.env.REACT_APP_PROFILE_BUCKET_NAME;
    const [dialogInfo, setDialogInfo] = useState("");

    const [cognitoUser, setCognitoUser] = useState(null);
    const [bodyFontSize, setBodyFontSize] = useState('1.2em');
    const [headerFontSize, setHeaderFontSize] = useState('1.8em');
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isLarge = useMediaQuery(theme.breakpoints.up('md'));

    const [todayDealsCount, setTodayDealsCount] = useState("loading...");
    const [yesterdayDealsCount, setYesterdayDealsCount] = useState("loading...");
    const [dBYDealsCount, setDBYDealsCount] = useState("loading...");



    const fetchDBYDeals = async () => {
        const DBY = new Date();
        DBY.setDate(DBY.getDate() - 2);
        const yesterdayDateString = DBY.toISOString().split('T')[0];

        const result = await API.graphql(graphqlOperation(dealsByPosted_date, { posted_date: yesterdayDateString }));
        setDBYDealsCount(result.data.dealsByPosted_date.items.length);
    };

    const fetchYesterdayDeals = async () => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const yesterdayDateString = yesterday.toISOString().split('T')[0];

        const result = await API.graphql(graphqlOperation(dealsByPosted_date, { posted_date: yesterdayDateString }));
        setYesterdayDealsCount(result.data.dealsByPosted_date.items.length);
    };

    const fetchTodayDeals = async () => {
        const today = new Date();
        const todayDateString = today.toISOString().split('T')[0];

        const result = await API.graphql(graphqlOperation(dealsByPosted_date, { posted_date: todayDateString }));
        setTodayDealsCount(result.data.dealsByPosted_date.items.length);
    };

    useEffect(() => {
        fetchDBYDeals();
        fetchYesterdayDeals();
        fetchTodayDeals();
    }, []);

    useEffect(() => {
        if (isSmall || isMedium) {
            setHeaderFontSize('1.6em');
            setBodyFontSize('1em');

        } else if (isLarge) {
            setHeaderFontSize('2em');
            setBodyFontSize('1.2em');
        }
    }, [isSmall, isMedium, isLarge]);


    const handleOpenDialog = (info) => {
        setDialogInfo(info);
    };

    const handleCloseDialog = () => {
        setDialogInfo("");
    };

    useEffect(() => {
        Auth.currentAuthenticatedUser()
          .then(user => {
            const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
            if (! groups || ! groups.includes('admin')) {
                navigate('/'); // Redirect to home page if user is not an admin
            } else {
                setCognitoUser(user); // Set the authenticated user to the coguser state
            }
          })
          .catch(err => {
            console.log(err);
            navigate('/'); // Redirect to home page if user is not logged in
          });
    }, []);


    return (
        <Container className='tw-text-string-base'>

            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 2, margin: 2  }}>
                        <Typography sx={{ fontSize: headerFontSize}}>Welcome Admin {cognitoUser?.username}</Typography>
                    </Box>
                </Grid>

                <Grid key={1} item xs={6} sm={6} md={6} lg={3} >
                    <Card sx={{ bgcolor: '#f5f5f5'}}>
                        <CardContent sx = {{color: "181a1b"}}>
                            <Typography variant="h6">Today</Typography>
                            <Typography paragraph>
                                #Posts: {todayDealsCount}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid key={2} item xs={6} sm={6} md={6} lg={3} >
                    <Card sx={{ bgcolor: '#f5f5f5'}}>
                        <CardContent sx = {{color: "181a1b"}}>
                            <Typography variant="h6">Yesterday</Typography>
                            <Typography paragraph>
                                #Posts: {yesterdayDealsCount}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid key={2} item xs={6} sm={6} md={6} lg={3} >
                    <Card sx={{ bgcolor: '#f5f5f5'}}>
                        <CardContent sx = {{color: "181a1b"}}>
                            <Typography variant="h6">DBY</Typography>
                            <Typography paragraph>
                                #Posts: {dBYDealsCount}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} />
                {/* <Grid item xs={12} ><Divider variant="middle" sx={{borderWidth: 0.8, borderColor: 'black'}} /></Grid> */}
                <Grid item xs={12} />

            </Grid>
            &nbsp;&nbsp;

        <Dialog
            open={dialogInfo}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            Info
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialogInfo}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
        </Container>
    );
  }
