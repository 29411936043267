import { useTheme } from '@emotion/react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { Dialog, IconButton, useMediaQuery } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

function CustomReportDialog({ open, onClose, title, description, content }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const contentRef = useRef();
  const [showDescription, setShowDescription] = useState(open);

  const handleScroll = (event) => {
    if (event.target.scrollTop <= 1) {
      setShowDescription(true);
    } else {
      setShowDescription(false);
    }
  };

  useEffect(() => setShowDescription(true), [open]);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth={false}
      PaperProps={{
        style: {
          height: 'auto',
          width: 'auto',
          borderRadius: isSmallScreen ? '0px' : '4px',
          margin: '0px',
          maxHeight: '100%',
          maxWidth: '100%',
        },
      }}
    >
      <div className="tw-relative">
        <div className="tw-overflow-auto tw-flex tw-flex-col tw-justify-between tw-h-[330px] tw-w-full md:tw-h-[350px] md:tw-w-[80vw] lg:tw-h-[300px] lg:tw-w-[50vw]">
          <div>
            <div className="tw-p-4 md:tw-p-5 md:tw-pl-7 tw-m-0">
              <div className="tw-w-full tw-flex tw-flex-col tw-py-2 tw-items-center tw-justify-center ">
                <img
                    key={'Report icon'}
                    src={'/reporting_icon.png'}
                    className="hover:tw-cursor-pointer tw-h-29-icon tw-w-29-icon md:tw-w-29-icon md:tw-h-29-icon"
                />
              </div>
              <h1 className="tw-text-xl md:tw-text-2xl tw-font-bold tw-m-0 tw-py-2 tw-text-string-primary tw-text-center">
                {title}
              </h1>
              <div className="tw-text-sm tw-text-string-neutral tw-py-2 tw-text-center">
                  {description}
                </div>
              {/*
              <Collapse in={showDescription} timeout="auto">
                <div className="tw-text-sm tw-text-string-neutral tw-pt-1">
                  {description}
                </div>
              </Collapse>
              */}
            </div>
          </div>
          {/*
          <div
            className="tw-relative tw-flex-1"
            onScroll={handleScroll}
            ref={contentRef}
          >
            {cloneElement(content, { contentRef })}
          </div>
          */}
        </div>
        <div className="tw-absolute tw-top-3 tw-right-3" onClick={onClose}>
          <IconButton size="large">
            <CloseRoundedIcon />
          </IconButton>
        </div>
      </div>
    </Dialog>
  );
}

export default CustomReportDialog;
