import * as React from 'react';

import { Box, Container, Grid, Typography } from '@mui/material';

import { ApiCommentVote } from './ApiCommentVote';
import { ApiCreateExpiredMarker } from './ApiCreateExpiredMarker';
import { ApiDealVote } from './ApiDealVote';
import { ApiGetAffiliateLink } from './ApiGetAffiliateLink';
import { ApiGetAutoFilling } from './ApiGetAutoFilling';
import { ApiMessaging } from './ApiMessaging';
import { ApiNotification } from './ApiNotification';
import { ApiReportDeal } from './ApiReportDeal';
import { ApiS3 } from './ApiS3';

function ApiTestPage(props) {
  return (
    <Container>
      <Grid container spacing={2} sx={{ mb: 5 }}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              px: 4,
              my: 5,
            }}
          >
            <Typography variant="h4">API Test Page</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} md={4}>
          <ApiGetAffiliateLink />
        </Grid>
        <Grid item xs={6} md={4}>
          <ApiCreateExpiredMarker />
        </Grid>
        <Grid item xs={6} md={4}>
          <ApiReportDeal />
        </Grid>
        <Grid item xs={6} md={4}>
          <ApiDealVote />
        </Grid>
        <Grid item xs={6} md={4}>
          <ApiCommentVote />
        </Grid>
        <Grid item xs={6} md={4}>
          <ApiS3 />
        </Grid>
        <Grid item xs={6} md={4}>
          <ApiNotification />
        </Grid>
        <Grid item xs={6} md={4}>
          <ApiMessaging />
        </Grid>
        <Grid item xs={6} md={4}>
          <ApiGetAutoFilling />
        </Grid>
      </Grid>
    </Container>
  );
}

export default ApiTestPage;
