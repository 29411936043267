import { Box, FormGroup, FormLabel, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import * as React from 'react';

export function DealEditorDealLabel({ dealDetails, setDealDetails }) {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Typography variant="h6" className="tw-text-string-base">
          {' '}
          Deal Label (admin only)
        </Typography>
      </Box>
      <FormGroup className="mb-3" controlId="formBasicText">
        <Box display="flex" flexDirection="column" justifyContent="flex-start">
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={dealDetails.isLightningDeal === 'true'}
              onChange={(evt) =>
                setDealDetails({
                  ...dealDetails,
                  isLightningDeal:
                    dealDetails.isLightningDeal === 'true' ? '' : 'true',
                })
              }
            />
            <FormLabel>Lightning Deal</FormLabel>
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={dealDetails.isTopDeal === 'true'}
              onChange={(evt) =>
                setDealDetails({
                  ...dealDetails,
                  isTopDeal: dealDetails.isTopDeal === 'true' ? '' : 'true',
                })
              }
            />
            <FormLabel>Top Deal</FormLabel>
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={dealDetails.isTrendingDeal === 'true'}
              onChange={(evt) =>
                setDealDetails({
                  ...dealDetails,
                  isTrendingDeal:
                    dealDetails.isTrendingDeal === 'true' ? '' : 'true',
                })
              }
            />
            <FormLabel>Trending Deal</FormLabel>
          </Box>
        </Box>
      </FormGroup>
    </>
  );
}

export default DealEditorDealLabel;
