import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
  Box,
  Button,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Switch,
  TextField,
} from '@mui/material';
import * as React from 'react';

export function DealEditorSwitches({ dealDetails, setDealDetails }) {
  return (
    <>
      <FormGroup className="mb-2" controlId="formBasicText">
        <Box display="flex" flexDirection="column" justifyContent="flex-start">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box display="flex" alignItems="center" mb={0}>
                <Switch
                  checked={dealDetails.has_amazon_subscribe_save === true}
                  onChange={(evt) =>
                    setDealDetails({
                      ...dealDetails,
                      has_amazon_subscribe_save: evt.target.checked,
                    })
                  }
                />
                <FormLabel>
                  Amazon Subscribe & Save for Additional Savings
                </FormLabel>
              </Box>
            </Grid>
            {/* {dealDetails.has_amazon_subscribe_save === true && (
              <Grid item xs={12} md={6} mb={4}>
                <Box position="relative">
                  <TextField
                    type="text"
                    label={<span>&nbsp; &nbsp; Subscribe & Save Discount</span>}
                    value={dealDetails.amazon_subscribe_save}
                    onChange={(evt) =>
                      setDealDetails({
                        ...dealDetails,
                        amazon_subscribe_save:
                          evt.target.value === '' ? null : evt.target.value,
                      })
                    }
                    fullWidth
                    InputLabelProps={{
                      shrink: dealDetails.amazon_subscribe_save ? true : false,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                  <IconButton
                    onClick={async () => {
                      const text = await navigator.clipboard.readText();
                      setDealDetails({
                        ...dealDetails,
                        amazon_subscribe_save: text === '' ? null : text,
                      });
                    }}
                    style={{
                      position: 'absolute',
                      right: 5,
                      bottom: 7,
                      opacity: 0.7,
                    }}
                    tabIndex="-1"
                  >
                    <FileCopyIcon />
                  </IconButton>
                </Box>
              </Grid>
            )} */}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormGroup className="mb-0" controlId="formBasicText">
                <Box display="flex" alignItems="center">
                  <Switch
                    checked={dealDetails.has_promotional_code === true}
                    onChange={(evt) =>
                      setDealDetails({
                        ...dealDetails,
                        has_promotional_code: evt.target.checked,
                      })
                    }
                  />
                  <FormLabel>Promotion Code</FormLabel>
                </Box>
              </FormGroup>
            </Grid>
            {dealDetails.has_promotional_code === true && (
              <Grid item xs={12} md={6} mb={4}>
                <Box position="relative">
                  <TextField
                    type="text"
                    // placeholder="Promotional Code"
                    label="Promotion Code"
                    value={dealDetails.promotional_code}
                    onChange={(evt) =>
                      setDealDetails({
                        ...dealDetails,
                        promotional_code:
                          evt.target.value === '' ? null : evt.target.value,
                      })
                    }
                    multiline
                    rows={dealDetails?.promotional_code?.length > 20 ? 4 : 1}
                    fullWidth
                    InputLabelProps={{
                      shrink: dealDetails.promotional_code ? true : false,
                    }}
                  />
                  <IconButton
                    onClick={async () => {
                      const text = await navigator.clipboard.readText();
                      setDealDetails({
                        ...dealDetails,
                        promotional_code: text === '' ? null : text,
                      });
                    }}
                    style={{
                      position: 'absolute',
                      right: 5,
                      bottom: 7,
                      opacity: 0.7,
                    }}
                    tabIndex="-1"
                  >
                    <FileCopyIcon />
                  </IconButton>
                </Box>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box display="flex" alignItems="center">
                <Switch
                  checked={dealDetails.has_coupon}
                  onChange={(evt) =>
                    setDealDetails({
                      ...dealDetails,
                      has_coupon: evt.target.checked,
                    })
                  }
                />
                <FormLabel>Coupon</FormLabel>
              </Box>
            </Grid>
            {dealDetails.has_coupon === true && (
              <Grid
                item
                xs={12}
                md={6}
                mb={4}
                display="flex"
                // position="relative"
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    setDealDetails((prevDetails) => {
                      const newCouponType = prevDetails?.coupon?.includes('%')
                        ? '$'
                        : '%';
                      let newCoupon = prevDetails.coupon
                        ? prevDetails.coupon.replace(/[$%]/g, '')
                        : '';
                      newCoupon =
                        newCouponType === '$'
                          ? `$${newCoupon}`
                          : `${newCoupon}%`;
                      return {
                        ...prevDetails,
                        coupon: newCoupon,
                      };
                    });
                  }}
                >
                  <span style={{ fontSize: 'larger' }}>
                    {dealDetails?.coupon?.includes('%') ? '%' : '$'}
                  </span>
                </Button>
                <Box position="relative" width="100%" marginLeft={0}>
                  <TextField
                    type="text"
                    label="Coupon"
                    value={dealDetails.coupon || '$'}
                    onChange={(evt) =>
                      setDealDetails({
                        ...dealDetails,
                        coupon:
                          evt.target.value === '' ? '$' : evt.target.value,
                      })
                    }
                    fullWidth
                    InputLabelProps={true}
                  />
                  <IconButton
                    onClick={async () => {
                      const text = await navigator.clipboard.readText();
                      setDealDetails({
                        ...dealDetails,
                        coupon: text === '' ? null : text,
                      });
                    }}
                    style={{
                      position: 'absolute',
                      right: 5,
                      bottom: 7,
                      opacity: 0.7,
                    }}
                    tabIndex="-1"
                  >
                    <FileCopyIcon />
                  </IconButton>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </FormGroup>
    </>
  );
}

export default DealEditorSwitches;
