import AccessAlarmRoundedIcon from '@mui/icons-material/AccessAlarmRounded';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Slider,
  Switch,
  TextField,
  useTheme,
} from '@mui/material';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useAppContext_ } from '../../AppContextProvider';
import useAlerts from '../apiHooks/useAlerts';
import Section from '../home/Section';
import AutocompleteChip from '../utils/AutocompleteChip';
import CustomDialog from '../utils/CustomDialog';
import { BRANDS, CATEGORIES } from '../utils/constants';

function Alerts() {
  return (
    <Section
      startIcon="/bookmark_heart.svg"
      title="Alerts"
      content={
        <div className="tw-flex tw-flex-col tw-w-full tw-gap-y-16">
          <SubSection
            title="Browser Notification"
            content={<DesktopAlerts />}
          />
          <SubSection
            title="Lightning Deal Alerts"
            content={<LightningDealAlert />}
          />
          <SubSection title="Top Deal Alerts" content={<TopDealAlert />} />
          <SubSection title="Customized Alerts" content={<ManageAlerts />} />
        </div>
      }
    />
  );
}

function SubSection({ title, content }) {
  return (
    <div>
      <h2 className="tw-text-base md:tw-text-xl tw-font-semibold tw-text-string-base">
        {title}
      </h2>
      <hr />
      <div className="tw-w-full md:tw-rounded-xl md:tw-border md:tw-border-surface-border md:tw-bg-surface-raised md:tw-px-8 md:tw-py-8">
        {content}
      </div>
    </div>
  );
}

function LightningDealAlert() {
  const {
    globalNotificationListenerEnabled,
    setGlobalNotificationListenerEnabled,
  } = useAppContext_();

  const {
    alerts,
    topDealAlert,
    lightningDealAlert,
    loading,
    postCreateAlert,
    postDeleteAlert,
    postEditAlert,
    postEnableDisableTopDeal,
    postEnableDisableLightningDeal,
  } = useAlerts();

  const handleEnabledDisableLightningDeal = async () => {
    await postEnableDisableLightningDeal();
  };

  return (
    <div>
      <div className="tw-w-full tw-flex tw-flex-col">
        <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-12 tw-pt-0">
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      !lightningDealAlert ||
                      lightningDealAlert.enabled === false
                        ? false
                        : true
                    }
                    onChange={handleEnabledDisableLightningDeal}
                    name="gilad"
                  />
                }
                label={
                  <div
                    className={`${
                      !lightningDealAlert ||
                      lightningDealAlert.enabled === false
                        ? 'tw-text-string-neutral'
                        : 'tw-text-string-primary'
                    }`}
                  >
                    {!lightningDealAlert || lightningDealAlert.enabled === false
                      ? 'Lightning Deals Email Alert Off'
                      : 'Lightning Deals Email Alert On'}
                  </div>
                }
              />
            </FormGroup>
          </FormControl>
          {/* <img
            src="/desktopNotificationIcon.png"
            alt="Desktop Notification Icon"
            style={{ width: '120px', height: '80px' }}
          /> */}
        </div>
      </div>
    </div>
  );
}

function TopDealAlert() {
  const {
    globalNotificationListenerEnabled,
    setGlobalNotificationListenerEnabled,
  } = useAppContext_();

  const {
    alerts,
    topDealAlert,
    lightningDealAlert,
    loading,
    postCreateAlert,
    postDeleteAlert,
    postEditAlert,
    postEnableDisableTopDeal,
    postEnableDisableLightningDeal,
  } = useAlerts();

  const handleEnabledDisableTopDeal = async () => {
    await postEnableDisableTopDeal();
  };

  return (
    <div>
      <div className="tw-w-full tw-flex tw-flex-col">
        <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-12 tw-pt-0">
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      !topDealAlert || topDealAlert.enabled === false
                        ? false
                        : true
                    }
                    onChange={handleEnabledDisableTopDeal}
                    name="gilad"
                  />
                }
                label={
                  <div
                    className={`${
                      !topDealAlert || topDealAlert.enabled === false
                        ? 'tw-text-string-neutral'
                        : 'tw-text-string-primary'
                    }`}
                  >
                    {!topDealAlert || topDealAlert.enabled === false
                      ? 'Top Deals Email Alert Off'
                      : 'Top Deals Email Alert On'}
                  </div>
                }
              />
            </FormGroup>
          </FormControl>
          {/* <img
            src="/desktopNotificationIcon.png"
            alt="Desktop Notification Icon"
            style={{ width: '120px', height: '80px' }}
          /> */}
        </div>
      </div>
    </div>
  );
}

function DesktopAlerts() {
  const {
    globalNotificationListenerEnabled,
    setGlobalNotificationListenerEnabled,
  } = useAppContext_();

  const handleEnabledDisableDesktopNotification = async () => {
    setGlobalNotificationListenerEnabled(!globalNotificationListenerEnabled);
  };

  return (
    <div>
      <div className="tw-w-full tw-flex tw-flex-col">
        <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-12 tw-pt-0">
          <FormControl component="fieldset" variant="standard">
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={globalNotificationListenerEnabled}
                    onChange={handleEnabledDisableDesktopNotification}
                    name="gilad"
                  />
                }
                label={
                  <div
                    className={`${
                      globalNotificationListenerEnabled === false
                        ? 'tw-text-string-neutral'
                        : 'tw-text-string-primary'
                    }`}
                  >
                    {globalNotificationListenerEnabled === false
                      ? 'Notification for New Deals Off'
                      : 'Notification for New Deals On'}
                  </div>
                }
              />
            </FormGroup>
          </FormControl>
          {/* <img
            src="/desktopNotificationIcon.png"
            alt="Desktop Notification Icon"
            style={{ width: '120px', height: '80px' }}
          /> */}
        </div>
      </div>
    </div>
  );
}

function ManageAlerts() {
  const {
    alerts,
    topDealAlert,
    loading,
    postCreateAlert,
    postDeleteAlert,
    postEditAlert,
    postEnableDisableTopDeal,
  } = useAlerts();
  const [openDialog, setOpenDialog] = useState(false);
  const [alert, setAlert] = useState(null);

  const handleOpenDialog = (alert) => {
    setOpenDialog(!openDialog);
  };

  const handleCreateAlert = () => {
    setAlert(null);
    handleOpenDialog();
  };

  if (!loading) {
    return (
      <div>
        <div className="tw-w-full tw-flex tw-flex-col">
          {alerts
            .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
            .map((alert, index) => (
              <Fragment key={index}>
                <Alert
                  alert={alert}
                  setAlert={setAlert}
                  handleOpenDialog={handleOpenDialog}
                  postDeleteAlert={postDeleteAlert}
                />
              </Fragment>
            ))}

          <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-12 tw-pt-6">
            <Button variant="contained" onClick={handleCreateAlert}>
              Create Alert
            </Button>
          </div>
        </div>
        <CustomDialog
          deal_link={''}
          open={openDialog}
          onClose={handleOpenDialog}
          title={alert !== null ? 'Edit Alert' : 'Create Alert'}
          description={
            'Tailor your alert settings to receive timely updates on deals by specifying keywords, categories, stores, deal types, and preferred notification frequency and methods.'
          }
          content={
            <AddEditCustomAlert
              alert={alert}
              onClose={handleOpenDialog}
              action={alert !== null ? postEditAlert : postCreateAlert}
            />
          }
        />
      </div>
    );
  }
}

function Alert({ alert, setAlert, handleOpenDialog, postDeleteAlert }) {
  const [show, setShow] = useState(false);

  const handleShowDiv = (event) => {
    if (event.target.nodeName === 'DIV') {
      setShow(!show);
    }
  };

  const handleShow = (event) => {
    setShow(!show);
  };

  const handleEditAlert = (alert) => {
    setAlert(alert);
    handleOpenDialog();
  };

  const daysDifference = (date) => {
    const past = new Date(date);
    const now = new Date();

    const seconds = Math.floor((now - past) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    // > 24 hours -> expired.
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (seconds < 60) {
      return `${seconds} second${seconds === 1 ? '' : 's'} ago`;
    } else if (minutes < 60) {
      return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours === 1 ? '' : 's'} ago`;
    } else if (days < 7) {
      return `${days} day${days === 1 ? '' : 's'} ago`;
    } else if (weeks < 4) {
      return `${weeks} week${weeks === 1 ? '' : 's'} ago`;
    } else if (months < 12) {
      return `${months} month${months === 1 ? '' : 's'} ago`;
    } else {
      return `${years} year${years === 1 ? '' : 's'} ago`;
    }
  };

  // const HourDifference
  /*
    return hours.
  */

  const formatFrequencyLabel = (alert) => {
    let string = [];

    if (alert.frequency_instant) {
      string.push('Instant');
    }
    if (alert.frequency_daily) {
      string.push('Daily');
    }
    if (alert.frequency_weekly) {
      string.push('Weekly');
    }

    return string.join(', ');

    /*
    if (alert.frequency_instant === true) {
      return 'Instant';
    } else if (alert.frequency_daily === true) {
      return 'Daily';
    } else if (alert.frequency_weekly === true) {
      return 'Weekly';
    }
    */
  };

  const formatNotificationLabel = (alert) => {
    let string = [];

    if (alert.notification_app) {
      string.push('In-App');
    }
    if (alert.notification_cell) {
      string.push('SMS');
    }
    if (alert.notification_email) {
      string.push('Email');
    }

    return string.join(', ');
  };

  return (
    <div>
      <div className="tw-flex tw-flex-col tw-gap-4 md:tw-gap-x-6 md:tw-flex-row tw-justify-between tw-items-center">
        <div className="tw-w-full tw-text-sm tw-pl-1 tw-py-1 tw-font-semibold tw-text-string-base tw-flex tw-flex-col tw-gap-y-2">
          <span
            className="tw-text-base tw-flex tw-items-center tw-gap-x-6"
            onClick={handleShowDiv}
          >
            <div className="tw-flex-1 tw-flex tw-gap-x-2 tw-min-w-0">
              <span onClick={handleShow}>
                {show ? <ExpandLess /> : <ExpandMore />}
              </span>
              <div className="tw-truncate">
                {alert.keywords.length > 0
                  ? alert.keywords.join(', ')
                  : `${alert.categories.slice(0, 3).join(', ')} ${
                      alert.categories.length > 3
                        ? `(+${alert.categories.length - 3} more)`
                        : ''
                    }`}
              </div>
            </div>

            <div className="tw-flex tw-w-fit md:tw-w-fit tw-gap-x-2 tw-h-fit">
              <Button
                fullWidth
                variant="outlined"
                color="black"
                startIcon={<BorderColorOutlinedIcon />}
                component="label"
                onClick={() => handleEditAlert(alert)}
              >
                Edit
              </Button>
              <Button
                fullWidth
                variant="outlined"
                color="red"
                startIcon={<DeleteOutlined />}
                component="label"
                onClick={async () => await postDeleteAlert(alert)}
              >
                Delete
              </Button>
            </div>
          </span>
          <Collapse in={show} timeout="auto" unmountOnExit>
            <div className="tw-w-full tw-flex tw-flex-col tw-gap-y-2 tw-pl-4">
              {alert.keywords.length > 0 && (
                <div className="tw-flex tw-gap-x-3">
                  <SearchOutlinedIcon />
                  <div>
                    Keywords:{' '}
                    <span className="tw-font-normal">
                      {alert.keywords.join(', ')}
                    </span>
                  </div>
                </div>
              )}
              {alert.categories.length > 0 && (
                <div className="tw-flex tw-gap-x-3">
                  <CategoryOutlinedIcon />
                  <div>
                    Categories:{' '}
                    <span className="tw-font-normal">
                      {alert.categories.join(', ')}
                    </span>
                  </div>
                </div>
              )}
              {alert.stores.length > 0 && (
                <div className="tw-flex tw-gap-x-3">
                  <StorefrontOutlinedIcon />
                  <div>
                    Stores:{' '}
                    <span className="tw-font-normal">
                      {alert.stores.join(', ')}
                    </span>
                  </div>
                </div>
              )}

              <div className="tw-flex tw-gap-x-3">
                <AccessAlarmRoundedIcon />
                <div>
                  Frequency:{' '}
                  <span className="tw-font-normal">
                    {formatFrequencyLabel(alert)}
                  </span>
                </div>
              </div>

              <div className="tw-flex tw-gap-x-3">
                <AttachMoneyRoundedIcon />
                <div>
                  Price Range:{' '}
                  <span className="tw-font-normal">{`$${alert.price_min} USD—$${alert.price_max} USD`}</span>
                </div>
              </div>

              <div className="tw-flex tw-gap-x-3">
                <ArrowUpwardRoundedIcon />
                <div>
                  Net upvote threshold:{' '}
                  <span className="tw-font-normal">
                    {alert.threshold_net_upvote}
                  </span>
                </div>
              </div>

              <div className="tw-flex tw-gap-x-3">
                <NotificationsNoneOutlinedIcon />
                <div>
                  Notifications:{' '}
                  <span className="tw-font-normal">
                    {formatNotificationLabel(alert)}
                  </span>
                </div>
              </div>

              <div className="tw-flex tw-gap-x-3">
                <CalendarMonthOutlinedIcon />
                <div>
                  Last Updated:{' '}
                  <span className="tw-font-normal">
                    {daysDifference(alert.updatedAt)}
                  </span>
                </div>
              </div>
            </div>
          </Collapse>
        </div>
      </div>
      <hr className="tw-my-3 tw-mx-0" />
    </div>
  );
}

/**
 * Renders a body to be put into CustomDialog component for creating and editing
 * custom alerts, handling various notification settings such as keywords, categories,
 * stores, notification preferences, and alert frequency.
 *
 * @param {Object} props.contentRef - A reference to the parent content container for scrolling purposes.
 * @param {Object} props.alert - The alert object containing the default values for the notification form. Used for editing alerts.
 * @param {Function} props.onClose - The function to call to close form based on parent state.
 * @param {Function} props.action - The function to call to create or apply edits to an alert.
 * @returns {JSX.Element} The rendered custom notification form.
 */
function AddEditCustomAlert({
  contentRef,
  alert: initialAlert,
  onClose,
  action,
}) {
  const theme = useTheme();
  const [mode, setMode] = useState(initialAlert !== null ? 'edit' : 'create');
  // If alert !== null it is in edit mode and displays existing settings
  // else, create a stubbed alert with defaults.
  const [alert, setAlert] = useState(
    initialAlert !== null
      ? structuredClone(initialAlert)
      : {
          alert_title: new Date().toISOString().split('T')[0],
          user_id: '',
          username: '',
          enabled: true,
          keywords: [],
          stores: [],
          categories: [],
          price_min: 0,
          price_max: 5000,
          threshold_net_upvote: 0,
          threshold_upvote_ratio: 80,
          notification_email: true,
          notification_cell: false,
          notification_app: false,
          frequency_instant: true,
          frequency_daily: false,
          frequency_weekly: false,
          last_changed_date: new Date().toISOString().split('T')[0],
          hash_key: '',
        }
  );

  // Used to scroll up back to input in the event of an error.
  const inputRefs = {
    keywords: useRef(),
    categories: useRef(),
    stores: useRef(),
    frequency: useRef(),
    notification: useRef(),
  };

  // State controlling error helperText on inputs
  const [alertErrors, setAlertErrors] = useState({
    notification: '',
    frequency: '',
    stores: '',
    categories: '',
    keywords: '',
  });

  // Handles general input changes and resets error state to remove
  // red outline and helper text.
  const handleChange = (key, newValue) => {
    setAlertErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [key]: '',
    }));

    setAlert((prevState) => ({
      ...prevState,
      [key]: newValue,
    }));
  };

  const handleFrequencyChange = (key, value) => {
    setAlertErrors((prevFormErrors) => ({
      ...prevFormErrors,
      frequency: '',
    }));

    setAlert((prevState) => ({
      ...prevState,
      [key]: value,
    }));

    /*
    setAlert((prevState) => {
      if (value === 'Instant') {
        return {
          ...prevState,
          frequency_instant: true,
          frequency_daily: false,
          frequency_weekly: false,
        };
      } else if (value === 'Daily') {
        return {
          ...prevState,
          frequency_instant: false,
          frequency_daily: true,
          frequency_weekly: false,
        };
      } else if (value === 'Weekly') {
        return {
          ...prevState,
          frequency_instant: false,
          frequency_daily: false,
          frequency_weekly: true,
        };
      }
      return prevState;
    });
    */
  };

  const handleUpdateHashKey = () => {
    setAlert((prevState) => ({
      ...prevState,
      hash_key: [
        alert.enabled ? 'T' : 'F',
        '-',
        alert.frequency_instant ? 'T' : 'F',
        alert.frequency_daily ? 'T' : 'F',
        alert.frequency_weekly ? 'T' : 'F',
        '-',
        alert.threshold_net_upvote.toString(),
        '-',
        alert.threshold_upvote_ratio.toString(),
      ].join(''),
    }));
  };

  const handleNotificationChange = (key, value) => {
    setAlertErrors((prevFormErrors) => ({
      ...prevFormErrors,
      notification: '',
    }));

    setAlert((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleEditCreateAlert = async () => {
    if (isValidForm()) {
      if (initialAlert !== null) {
        await action(alert);
      } else {
        await action(alert);
      }
      onClose();
    }
  };

  /**
   * Validates the form inputs and sets the appropriate error messages. The validation checks are ordered in reverse order of their appearance on the form to ensure the scroll focuses on the first input with an error.
   *
   * @returns {boolean} True if the form is valid, false otherwise.
   */
  const isValidForm = () => {
    let scrollToElement = null;
    const errors = {};

    if (
      alert.notification_app === false &&
      alert.notification_cell === false &&
      alert.notification_email === false
    ) {
      errors.notification = 'Notification preference must be specified';
      scrollToElement = inputRefs.notification;
    }
    if (
      alert.frequency_daily === false &&
      alert.frequency_instant === false &&
      alert.frequency_weekly === false
    ) {
      errors.frequency = 'Frequency must be specified';
      scrollToElement = inputRefs.frequency;
    }
    if (alert.stores.length < 1) {
      errors.stores = 'At least 1 store must be selected';
      scrollToElement = inputRefs.stores;
    }
    if (alert.categories.length < 1) {
      errors.categories = 'At least 1 category must be selected';
      scrollToElement = inputRefs.categories;
    }
    /*
    if (alert.categories.length < 1 || alert.categories.length > 5) {
      errors.categories = 'Must select between 1-5 categories';
      scrollToElement = inputRefs.categories;
    }
    //
    if (alert.keywords.length > 5) {
      errors.keywords = 'Keywords are limited to 5';
      scrollToElement = inputRefs.keywords;
    }
    */
    if (scrollToElement !== null) {
      scrollTo(scrollToElement, contentRef, -20);
    }

    setAlertErrors(errors);

    return Object.keys(errors).length === 0;
  };

  /**
   * Smoothly scrolls a parent element to ensure a child element is visible, with additional padding.
   *
   * This function calculates the scroll offset needed to bring a child element into view within a
   * parent element, adding an extra padding to adjust the final position. It ensures that the
   * scrolling happens smoothly.
   *
   * Note: This function is used to scroll within the content window of CustomDialog, so the
   * parentElement is specified to avoid scrolling the entire <body> HTML.
   *
   * @param {Object} element - The child element to scroll into view. Must have a `current` property that refers to a DOM element.
   * @param {Object} parentElement - The parent element within which to scroll. Must have a `current` property that refers to a DOM element.
   * @param {number} padding - Additional padding to adjust the final scroll position.
   */
  const scrollTo = (element, parentElement, padding) => {
    if (element.current && parentElement.current) {
      const elementRect = element.current.getBoundingClientRect();
      const parentRect = parentElement.current.getBoundingClientRect();

      const scrollOffset = elementRect.top - parentRect.top + padding;

      parentElement.current.scrollBy({
        top: scrollOffset,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  // Configuration array for form fields
  const autocompleteChipProps = [
    {
      inputRef: inputRefs.keywords,
      label: 'Keywords',
      name: 'keywords',
      value: alert.keywords,
      onChange: handleChange,
      //maxSelections: 5,
      options: [],
      freeSolo: true,
      placeholder: 'Separate keywords by pressing Enter',
      error: !!alertErrors.keywords,
      helperText:
        alertErrors.keywords || 'Press return/enter to confirm a keyword',
    },
    {
      inputRef: inputRefs.categories,
      label: 'Categories',
      name: 'categories',
      value: alert.categories,
      onChange: handleChange,
      required: true,
      //maxSelections: 5,
      options: CATEGORIES,
      placeholder: 'Select Categories',
      error: !!alertErrors.categories,
      helperText: alertErrors.categories || 'Type to select from Dropdown',
      all: true,
    },
    {
      inputRef: inputRefs.stores,
      label: 'Stores',
      name: 'stores',
      value: alert.stores,
      onChange: handleChange,
      // maxSelections: 5,
      required: true,
      options: BRANDS,
      placeholder: 'Select Stores',
      error: !!alertErrors.stores,
      helperText: alertErrors.stores || 'Type to select from Dropdown',
      all: true,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Update hash_key as changes are made
  useEffect(() => handleUpdateHashKey(), [alert]);

  return (
    <div className="tw-flex tw-flex-col tw-gap-4 tw-p-5 tw-pt-6 md:tw-p-7 md:tw-pt-7">
      {autocompleteChipProps.map((props, index) => (
        <form key={index}>
          <AutocompleteChip {...props} />
        </form>
      ))}
      <FormControl error={!!alertErrors.frequency} ref={inputRefs.frequency}>
        <FormLabel
          sx={{
            color:
              theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.7)'
                : 'rgba(0, 0, 0, 0.6)',
            '&.Mui-focused': {
              color:
                theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.7)'
                  : 'rgba(0, 0, 0, 0.6)',
            },
          }}
        >
          Frequency
        </FormLabel>
        <FormHelperText sx={{ marginLeft: '0px' }}>
          Weekly and Daily notifications will be available in a future update.
        </FormHelperText>
        <FormControlLabel
          control={
            <Checkbox
              name="Instant"
              checked={alert.frequency_instant}
              onChange={(event) =>
                handleNotificationChange(
                  'frequency_instant',
                  event.target.checked
                )
              }
              disabled
            />
          }
          label="Instant"
        />
        <FormHelperText sx={{ marginLeft: '0px' }}>
          {alertErrors.frequency || ' '}
        </FormHelperText>
      </FormControl>

      <FormControl
        error={!!alertErrors.notification}
        ref={inputRefs.notification}
      >
        <FormGroup>
          <FormLabel
            sx={{
              color:
                theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, 0.7)'
                  : 'rgba(0, 0, 0, 0.6)',
              '&.Mui-focused': {
                color:
                  theme.palette.mode === 'dark'
                    ? 'rgba(255, 255, 255, 0.7)'
                    : 'rgba(0, 0, 0, 0.6)',
              },
            }}
          >
            Notification Preferences
          </FormLabel>
          <FormHelperText sx={{ marginLeft: '0px' }}>
            SMS and In-App will be available in a future update.
          </FormHelperText>
          <FormControlLabel
            control={
              <Checkbox
                name="Email"
                checked={alert.notification_email}
                onChange={(event) =>
                  handleNotificationChange(
                    'notification_email',
                    event.target.checked
                  )
                }
                disabled
              />
            }
            label="Email"
          />
          <FormHelperText sx={{ marginLeft: '0px' }}>
            {alertErrors.notification || ' '}
          </FormHelperText>
        </FormGroup>
      </FormControl>

      {/* <FormGroup className="tw-pb-8">
        <div className="tw-pb-2">
          <FormLabel>Net Upvote</FormLabel>
          <FormHelperText sx={{ marginLeft: '0px' }}>
            Upvotes count deals have after likes minus dislikes
          </FormHelperText>
        </div>
        <div className="tw-px-4 tw-w-full">
          <Slider
            fullWidth
            step={5}
            min={0}
            max={100}
            value={alert.threshold_net_upvote}
            onChange={(event, newValue) =>
              handleChange('threshold_net_upvote', newValue)
            }
            valueLabelDisplay="auto"
            marks={Array.from({ length: 11 }, (_, i) => ({
              value: i * 10,
              label: `${i * 10}`,
            }))}
          />
        </div>
      </FormGroup> */}

      <FormGroup>
        <div className="tw-pb-2">
          <FormLabel>Prices (Min-Max)</FormLabel>
          <FormHelperText sx={{ marginLeft: '0px' }}>
            Price range deals should fall into
          </FormHelperText>
        </div>
        <div className="tw-px-4 tw-w-full tw-pb-6">
          <Slider
            fullWidth
            value={[alert.price_min, alert.price_max]}
            max={5000}
            onChange={(event, newValue) => {
              handleChange('price_min', newValue[0]);
              handleChange('price_max', newValue[1]);
            }}
            valueLabelDisplay="auto"
          />
        </div>
        <div className="tw-flex tw-gap-6">
          <TextField
            fullWidth
            type="number"
            size="small"
            name="price_min"
            label="Min"
            defaultValue={0}
            value={alert.price_min}
            onChange={(event) => handleChange('price_min', event.target.value)}
          />
          <TextField
            fullWidth
            type="number"
            size="small"
            name="price_max"
            label="Max"
            defaultValue={0}
            value={alert.price_max}
            onChange={(event) => handleChange('price_max', event.target.value)}
          />
        </div>
      </FormGroup>

      <div className="tw-pt-10 tw-w-full tw-flex tw-gap-x-3 md:tw-gap-x-6">
        <Button fullWidth variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          fullWidth
          variant="contained"
          size="large"
          onClick={handleEditCreateAlert}
        >
          {mode === 'edit' ? 'Edit Alert' : 'Create Alert'}
        </Button>
      </div>
    </div>
  );
}

export default Alerts;

/*
  AlertSettings type definition
  id: '',
  alert_title: '',
  user_id: '',
  username: '',
  enabled: true,
  keywords: [],
  stores: [],
  categories: [],
  price_min: 0,
  price_max: 9999999,
  threshold_net_upvote: 80,
  threshold_upvote_ratio: 80,
  notification_email: false,
  notification_cell: false,
  notification_app: false,
  frequency_instant: false,
  frequency_daily: false,
  frequency_weekly: false,
  last_changed_date: false,
  hash_key: 'F-TFF-5-80',
  owner: '',
  createdAt: '',
  updatedAt: '',
  _version: -1,
  _deleted: null,
  _lastChangedAt: -1,
  __typename: 'AlertSettings',
*/

/*
  Create new alert type definition
  (Excludes id, owner, created_at, updated_at, _version, _deleted, _lastChangedAt, and __typename)
  alert_title: '',
  user_id: '',
  username: '',
  enabled: true,
  keywords: [],
  stores: [],
  categories: [],
  price_min: 0,
  price_max: 9999999,
  threshold_net_upvote: 80,
  threshold_upvote_ratio: 80,
  notification_email: false,
  notification_cell: false,
  notification_app: false,
  frequency_instant: false,
  frequency_daily: false,
  frequency_weekly: false,
  last_changed_date: false,
  hash_key: 'F-TFF-5-80',
*/
