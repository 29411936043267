import { useNavigate } from 'react-router-dom';
import Post from '../utils/Post';

import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { dealsByIsTopDealAndUpdatedAt, dealsByVote } from '../../graphql/queries';

function TopDeals() {
  const navigate = useNavigate();
  // navigate("/categoryForum", {state:{name:demoData.forum_type}});
  const [spotlightDeals, setSpotlightDeals] = useState([]);
  const [DST,setDST] = useState(false);

  const hoursDifferenceVal = (date) => {
    const past = new Date(date); // posted time according to user current TimeFrame
    const now = new Date(); // Current EST TIME
    //console.log('now',now);
    // console.log('Is DST:',DST);
    let threeAM;

    if(DST){
      // + 4 UTC
      threeAM = new Date().setUTCHours(7,0,0,0); // !!! actual is 7
      let threeAMDate = new Date(threeAM);
      if(threeAMDate.getDate() !== now.getDate()){
        threeAM -= 24 * 60 * 60 * 1000;
      }
      threeAMDate = new Date(threeAM);
      //console.log('threeAMDST',threeAM, threeAMDate);
    }
    else{
      // + 5 UTC
      threeAM = new Date().setUTCHours(8,0,0,0); // !!! actual is 8
      let threeAMDate = new Date(threeAM);
      if(threeAMDate.getDate() !== now.getDate()){
        threeAM -= 24 * 60 * 60 * 1000;
      }
      threeAMDate = new Date(threeAM);
      // console.log('threeAM',threeAM, threeAMDate);
    }


    const secondsA = Math.floor((past - threeAM) / 1000);
    const minutesA = Math.floor(secondsA / 60);
    const hoursA = Math.floor(minutesA / 60);
    //console.log('past:', past)
    // console.log('hoursA: ', hoursA, minutesA, secondsA);

    if (hoursA < 0) {
      return 'deleted';
    } else {
      const seconds = Math.floor((now - past) / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      // console.log('hours: ',hours);
      if (hours >= 0 && hours < 3) { /// ! actual is 3
        return `${hours} hour${hours === 1 ? '' : 's'} ago`;
      }
      else{
        return 'expired';
      }
    }
  }

  const getSpotlightDeals = async () => {
    const cur_date = new Date();
    let jan = new Date(cur_date.getFullYear(), 0, 1).getTimezoneOffset();
    let jul = new Date(cur_date.getFullYear(), 6, 1).getTimezoneOffset();
    let isDST = Math.max(jan, jul) != cur_date.getTimezoneOffset();
    setDST(isDST);
    try {
      const dealData = await API.graphql({
        query: dealsByIsTopDealAndUpdatedAt,
        variables: {
          isTopDeal: 'true',
          filter: {
            and: [{ _deleted: { ne: true } }, { expired: { ne: true } }],
          },
          sortDirection: 'DESC',
        },
      })
      //console.log(dealData.data.listDeals.items)
      let filtered_admin_top_deals = [];
      const top_deals_set = new Set();
      dealData.data.dealsByIsTopDealAndUpdatedAt.items.map(async (deal, indx) => {
        if (deal.dealType && deal.dealType == 'lightning') {
          let expired_status = hoursDifferenceVal(deal.updatedAt);
          // console.log(expired_status);
          if (expired_status !== 'deleted' && expired_status !== 'expired') {
            filtered_admin_top_deals.push(deal);
            top_deals_set.add(deal.id);
          }
        }
        else if (deal.isLightningDeal && deal.isLightningDeal == 'true') {
          let expired_status = hoursDifferenceVal(deal.updatedAt);
          // console.log(expired_status);
          if (expired_status !== 'deleted'  && expired_status !== 'expired') {
            filtered_admin_top_deals.push(deal);
            top_deals_set.add(deal.id);
          }
        }
        else{
          filtered_admin_top_deals.push(deal);
          top_deals_set.add(deal.id);
        }
      });
      /*
        Add more top voted deals if Top Deals < 8
      */
      if(filtered_admin_top_deals.length < 8){
        let count = 8 - filtered_admin_top_deals.length;
        let topVotedDeals = await API.graphql({
          query: dealsByVote,
          variables: {
            search_by_vote: 'byVote',
            filter: {
              and: [{ _deleted: { ne: true } }, { expired: { ne: true } }],
            },
            sortDirection: 'DESC',
          },
        })
        let filtered_top_voted_deals = []
        let top_voted_deals_num = 0;
        topVotedDeals.data.dealsByVote.items.map(async (deal, indx) => {
          if(top_voted_deals_num < count){
            if (deal.dealType && deal.dealType == 'lightning') {
              let expired_status = hoursDifferenceVal(deal.updatedAt);
              // console.log(expired_status);
              if (expired_status !== 'deleted' && expired_status !== 'expired'
                && top_deals_set.has(deal.id) === false
              ) {
                filtered_top_voted_deals.push(deal);
                top_voted_deals_num += 1;
              }
            }
            else if (deal.isLightningDeal && deal.isLightningDeal == 'true'
              && top_deals_set.has(deal.id) === false
            ) {
              let expired_status = hoursDifferenceVal(deal.updatedAt);
              // console.log(expired_status);
              if (expired_status !== 'deleted'  && expired_status !== 'expired') {
                filtered_top_voted_deals.push(deal);
                top_voted_deals_num += 1;
              }
            }
            else{
              if(top_deals_set.has(deal.id) === false){
                filtered_top_voted_deals.push(deal);
                top_voted_deals_num += 1;
              }
            }
          }
        });
        let final_top_deals = filtered_admin_top_deals.concat(filtered_top_voted_deals);
        setSpotlightDeals(final_top_deals);
      }
      else{
        setSpotlightDeals(filtered_admin_top_deals);
      }
    } catch (err) {
      console.log('error', err);
    }
  };

  useEffect(() => {
    getSpotlightDeals();
  }, []);

  return (
    <div
      className="tw-grid tw-gap-1 sm:tw-gap-2 md:tw-gap-3 xl:tw-gap-5
                     tw-grid-cols-2 sm:tw-grid-cols-3 md:tw-grid-cols-3 lg:tw-grid-cols-3 xl:tw-grid-cols-4 2xl:tw-grid-cols-5"
    >
      {spotlightDeals.slice(0, 8).map((item, index) => (
        <Post key = {index} item={item} orientation="vertical" type="Top Deal" />
      ))}
    </div>
  );
}

export default TopDeals;
