import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppContext_ } from '../../AppContextProvider';
import {
  fetchDealVote,
  getBookmarkApi,
  getDealApi,
  getExpiredApi,
  getReportApi,
  postCreateBookmarkApi,
  postCreateExpiredApi,
  postCreateReportApi,
  postDeleteBookmarkApi,
  postDeleteExpiredApi,
  postDeleteReportApi,
  postVoteApi,
} from '../../api/deal';

function useDealDetailsApi() {
  const { id } = useParams();
  const { user } = useAppContext_();
  const [deal, setDeal] = useState(null);
  const [upvoted, setUpvoted] = useState(false);
  const [downvoted, setDownVoted] = useState(false);
  const [likeNumberOffset, setLikeNumberOffset] = useState(0); // Hard code to immediately show likes change
  const [dislikeNumberOffset, setDislikeNumberOffset] = useState(0);
  const [bookmark, setBookmark] = useState(null);
  const [expired, setExpired] = useState(null);
  const [report, setReport] = useState(null);
  const [deal_loading, setLoading] = useState(true);

  useEffect(() => console.log(''), [bookmark]);

  const getDeal = useCallback(async () => {
    setDeal(await getDealApi(id));
  }, [id]);

  const getVote = useCallback(async () => {
    const dealvote = await fetchDealVote(id, user?.attributes.sub);
    if (dealvote && dealvote._deleted !== true) {
      if (dealvote.upvote === true) {
        setUpvoted(true);
      } else {
        setDownVoted(true);
      }
    }
  }, [id, user]);

  const getBookmark = useCallback(async () => {
    const bookmarks = await getBookmarkApi(id, user?.attributes.sub);
    if (bookmarks.length > 0) {
      setBookmark(bookmarks[0]);
    }
  }, [id, user]);

  const getExpired = useCallback(async () => {
    const expireds = await getExpiredApi(id, user?.attributes.sub);
    if (expireds.length > 0) {
      setExpired(expireds[0]);
    }
  }, [id, user]);

  const getReport = useCallback(async () => {
    const reports = await getReportApi(id, user?.attributes.sub);
    if (reports.length > 0) {
      setReport(reports[0]);
    }
  }, [id, user]);

  const postVote = async (type) => {
    if (!user) {
      alert('Please login to vote. Thank you!');
      return;
    }

    const upvotedTemp = structuredClone(upvoted);
    const downvotedTemp = structuredClone(downvoted);

    if (upvoted || downvoted) {
      setUpvoted(false);
      setDownVoted(false);
      setLikeNumberOffset(0);
      setDislikeNumberOffset(0);

      if (
        (upvotedTemp && type === 'upvoted') ||
        (downvotedTemp && type === 'downvoted')
      ) {
        await postVoteApi(id, false, false);
        return;
      }
    }

    if (type === 'upvoted') {
      setUpvoted(true);
      setLikeNumberOffset(likeNumberOffset + 1);
      await postVoteApi(id, true, false);
    } else if (type === 'downvoted') {
      setDownVoted(true);
      setDislikeNumberOffset(dislikeNumberOffset + 1);
      await postVoteApi(id, false, true);
    }

    // console.log(event);

    // const isChecked = event.target.checked;
    // const checkboxName = event.target.name;

    // if (!isChecked) {
    //   DealVotingHandler(id, false, false);
    //   if (upvoted === true) setLikeNumberOffset(likeNumberOffset - 1);
    //   if (downvoted === true) setDislikeNumberOffset(dislikeNumberOffset - 1);
    //   setUpvoted(false);
    //   setDownVoted(false);
    // } else if (checkboxName === 'Upvoted') {
    //   DealVotingHandler(id, true, false);
    //   if (downvoted === true) setDislikeNumberOffset(dislikeNumberOffset - 1);
    //   setUpvoted(true);
    //   setDownVoted(false);
    //   setLikeNumberOffset(likeNumberOffset + 1);
    // } else if (checkboxName === 'Downvoted') {
    //   DealVotingHandler(id, false, true);
    //   if (upvoted === true) setLikeNumberOffset(likeNumberOffset - 1);
    //   setUpvoted(false);
    //   setDownVoted(true);
    //   setDislikeNumberOffset(dislikeNumberOffset + 1);
    // }
  };

  const postBookmark = async () => {
    if (!user) {
      alert('Please login to use bookmarking. Thank you!');
      return;
    }
    if (bookmark !== null) {
      // Copies state for API calls before changing to null
      const tempBookmark = { ...bookmark };

      // Hard code to immediately show animation
      setBookmark(null);

      await postDeleteBookmarkApi(tempBookmark);
    } else {
      // Hard code to immediately show animation
      setBookmark({});

      setBookmark(await postCreateBookmarkApi(id, user?.attributes.sub));
    }
  };

  const postExpired = async () => {
    if (!user) {
      alert('Please login to mark expire. Thank you!');
      return;
    }
    if (expired !== null) {
      // Hard code to immediately show animation
      setExpired(null);

      await postDeleteExpiredApi(id);
    } else {
      // Hard code to immediately show animation
      setExpired({});

      setExpired(await postCreateExpiredApi(id));
    }
  };

  const postReport = async () => {
    if (!user) {
      alert('Please login to report. Thank you!');
      return;
    }
    if (report !== null) {
      // Hard code to immediately show animation
      setReport(null);

      await postDeleteReportApi(id);
    } else {
      // Hard code to immediately show animation
      setReport({});

      setReport(await postCreateReportApi(id));
    }
  };

  useEffect(() => {
    const asyncFunction = async () => {
      await Promise.all([
        getDeal(),
        getVote(),
        getBookmark(),
        getReport(),
        getExpired(),
      ]);
      setLoading(false);
    };
    asyncFunction();
  }, []);

  return {
    deal,
    upvoted,
    downvoted,
    bookmark,
    expired,
    report,
    deal_loading,
    getVote,
    postVote,
    postBookmark,
    postExpired,
    postReport,
    likeNumberOffset,
    dislikeNumberOffset,
  };
}

export default useDealDetailsApi;
