import { FormControl, Grid, TextField } from '@mui/material';
import { Auth } from 'aws-amplify';
import * as React from 'react';
import { useEffect, useState } from 'react';

export function DealEditorSocalTemplate({ dealDetails, setDealDetails }) {
  const [isSocialManager, setIsSocialManager] = useState(false);
  const [cognitoSub, setCognitoSub] = useState(null);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const groups =
          user.signInUserSession.accessToken.payload['cognito:groups'];
        const sub = user.attributes.sub;
        setCognitoSub(sub);
        if (groups && groups.includes('social-manager')) {
          setIsSocialManager(true);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    isSocialManager && (
      <>
        <Grid container spacing={2}>
          {/* <Grid item xs={12} md={12} lg={12}>
            <FormLabel sx={{ mt: 1   }} component="legend">
              Templates
            </FormLabel>
          </Grid> */}
          {/* <Grid item xs={12} md={12} lg={12} xl={2}>

          </Grid> */}
          <Grid item xs={12} md={12} lg={12} xl={12} sx={{ mt: 4 }}>
            <FormControl sx={{ width: '100%' }}>
              <TextField
                label="Template"
                variant="outlined"
                value={dealDetails.socials_twitter_content}
                onChange={(e) =>
                  setDealDetails({
                    ...dealDetails,
                    socials_twitter_content: e.target.value,
                    socials_threads_content: e.target.value,
                    socials_discord_content: e.target.value,
                  })
                }
                fullWidth
                multiline
                rows={10}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid>
          {/* <Grid item xs={12} md={12} lg={6} xl={4}>
            <FormControl sx={{ width: '100%' }}>
              <TextField
                label="Discord Template"
                variant="outlined"
                value={dealDetails.socials_discord_content}
                onChange={(e) =>
                  setDealDetails({
                    ...dealDetails,
                    socials_discord_content: e.target.value,
                  })
                }
                fullWidth
                multiline
                rows={10}
                InputLabelProps={{ shrink: true }}
              />
            </FormControl>
          </Grid> */}
        </Grid>
      </>
    )
  );
}

export default DealEditorSocalTemplate;
