import { useEffect, useState } from "react";
import { getDealApi, getTopVotedDealsApi } from "../../api/deal";
import { useParams } from "react-router-dom";

function useListTopVotedDeals() {
  const { id } = useParams();
  const [topVotedDeals, setTopVotedDeals] = useState([]);
  const [loading, setLoading] = useState(true);

  const getTopVotedDeals = async () => {
    const deal = await getDealApi(id);
    setTopVotedDeals(
      (await getTopVotedDealsApi(id, deal.forum_type, -1))
    );
  };

  useEffect(() => {
    const asyncFunction = async () => {
      await getTopVotedDeals();
      setLoading(false);
    };
    asyncFunction();
  }, []);

  return {
    topVotedDeals,
    loading,
  };
}

export default useListTopVotedDeals;
