import { API } from 'aws-amplify';
import ngeohash from 'ngeohash';
import { useEffect, useState } from 'react';
import {
  getDeal
} from '../../graphql/queries';
import { MapSearch_Zoom_Radius } from '../constants/MapConstants';
import { inStoreDealByStatesByAvailable_state_V2 } from './custom_queries';

export default function Map_v2_API_ByState({ myGeoHash, zoom }) {
  const [dealsByState, setDealsByState] = useState([]);
  const [storesByStateDetails, setStoresByStateDetails] = useState([]);
  const [storesByStateMarkers, setStoresByStateMarkers] = useState([]);
  const [dealId2DetailsDict, setDealId2DetailsDict] = useState({});

  useEffect(() => {
    const fetchDeals = async () => {
      const deals = await getDealsByState();
      if (deals) { // Check if deals is not undefined
        setDealsByState(deals);
        setDealId2DetailsDict(generateDealId2DetailsDict(deals));

        const storeName2DealIds = generateStoreName2DealIds(deals);

        const storeNames = getAllStoreNames(deals);
        const storeDetails = await fetchGeoHashStoreDetails(
          myGeoHash,
          zoom,
          storeNames,
          storeName2DealIds
        );
        setStoresByStateDetails(storeDetails);

        const uniqueStores = mergeNearbyResults(storeDetails);

        const markers = generateStoreMarkers(uniqueStores, storeName2DealIds);
        setStoresByStateMarkers(markers);
      }
    };
    fetchDeals();
  }, [myGeoHash, zoom]);

  const generateStoreName2DealIds = (deals) => {
    // each deal contain dealer_type, use dealer_type as key, extract ids as a list
    const storeName2DealIds = {};
    deals.forEach((deal) => {
      if (!storeName2DealIds[deal.dealer_type]) {
        storeName2DealIds[deal.dealer_type] = [];
      }
      storeName2DealIds[deal.dealer_type].push(deal.id);
    });
    return storeName2DealIds;
  };

  const generateDealId2DetailsDict = (deals) => {
    // each deal contain id, use id as key, extract deal as dict
    const dealId2DetailsDict = {};
    deals.forEach((deal) => {
      dealId2DetailsDict[deal.id] = deal;
    });
    return dealId2DetailsDict;
  };

  const getDealsByState = async () => {
    try {
      const inStoreDealByStatesData = await API.graphql({
        query: inStoreDealByStatesByAvailable_state_V2,
        variables: {
          available_state: 'ALL',
          limit: 100,
          filter: {
            _deleted: { ne: true },
          },
        },
      });

      let inStoreDealByStates =
        inStoreDealByStatesData.data.inStoreDealByStatesByAvailable_state.items.filter(Boolean); // Add .filter(Boolean) here

      const promises = inStoreDealByStates
        .filter(deal => deal) // Add this line to filter out null deals
        .map((deal) =>
          API.graphql({
            query: getDeal,
            variables: {
              id: deal.deal_id,
              filter: {
                and: [{ _deleted: { ne: true } }, { expired: { ne: true } }],
              },
            },
          })
        );

      const results = await Promise.all(promises).then(results => results.filter(Boolean));

      let mostDealList = [];

      // console.log("inStoreDealByStates results: ", results);

      results.forEach((result, index) => {
        const deal = result.data.getDeal;
        // console.log("fetched deal: ", deal);
        if (deal) {
          mostDealList.push(deal);
        }
      });

      mostDealList.sort((a, b) =>
        String(b.updatedAt).localeCompare(String(a.updatedAt))
      );
      return mostDealList;

    } catch (err) {
      console.log('error', err);
    }
  };

  const getAllStoreNames = (deals) => {
    const storeNames = deals.map((deal) => deal.dealer_type);
    const uniqueStoreNames = [...new Set(storeNames)];
    return uniqueStoreNames;
  };

  const calculateRadius = (zoom) => {
    const radius = MapSearch_Zoom_Radius[zoom];
    // console.log(`zoom: ${zoom}, radius: ${radius}`);
    return radius;
  };

  const fetchGeoHashStoreDetails = async (
    myGeoHash,
    zoom,
    storeNames,
    storeName2DealIds
  ) => {
    if (!myGeoHash || !storeNames) {
      return [];
    }

    const { latitude, longitude } = ngeohash.decode(myGeoHash);
    const location = new window.google.maps.LatLng(latitude, longitude);
    const service = new window.google.maps.places.PlacesService(
      document.createElement('div')
    );

    const fetchStoreDetails = (storeName) => {
      return new Promise((resolve) => {
        service.nearbySearch(
          {
            location,
            radius: calculateRadius(zoom),
            type: ['store'],
            keyword: storeName,
          },
          (results, status) => {
            // console.log('Nearby Search Results:', results);
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              const stores = results.map((place) => {
                return {
                  nameFromMap: place.name,
                  storeName: storeName,
                  placeId: place.place_id,
                  rating: place.rating || '',
                  status: place.business_status
                    ? place.business_status === 'OPERATIONAL'
                      ? 'Open'
                      : 'Closed'
                    : '',
                  address: place.vicinity,
                  phone: place.formatted_phone_number || '',
                  geohash: ngeohash.encode(
                    place.geometry.location.lat(),
                    place.geometry.location.lng()
                  ),
                  geohash7: ngeohash
                    .encode(
                      place.geometry.location.lat(),
                      place.geometry.location.lng()
                    )
                    .slice(0, 7),
                  location: place.geometry.location,
                  closingTime: '',
                  openingTime: '',
                  dealIds: storeName2DealIds[storeName],
                  _type: 'ByState',
                };
              });
              resolve(stores);
            } else {
              resolve(null);
            }
          }
        );
      });
    };

    const storesPromises = storeNames.map(fetchStoreDetails);
    const stores = await Promise.all(storesPromises);
    const flatStores = stores.flat().filter(Boolean); // Use flat() to merge all arrays into one
    const noDupStores = Array.from(
      new Map(flatStores.map((store) => [store.placeId, store])).values()
    ); // Remove duplicates
    const validStores = noDupStores.filter((store) => {
      const nameFromMap = store.nameFromMap
        .replace(/[^a-zA-Z]/g, '')
        .toLowerCase();
      const storeName = store.storeName.replace(/[^a-zA-Z]/g, '').toLowerCase();

      // Calculate the difference in character count
      const charCountDiff = Math.abs(nameFromMap.length - storeName.length);

      return nameFromMap.includes(storeName) && charCountDiff <= 3;
    });
    return validStores;
  };

  const mergeNearbyResults = (stores) => {
    const seenGeohashes = {};
    const mergedStores = [];

    for (const store of stores) {
      const neighbors = ngeohash.neighbors(store.geohash7);

      if (
        seenGeohashes[store.geohash7] &&
        seenGeohashes[store.geohash7] === store.storeName
      ) {
        continue;
      }

      seenGeohashes[store.geohash7] = store.storeName;
      neighbors.forEach((neighbor) => {
        seenGeohashes[neighbor] = store.storeName;
      });

      mergedStores.push(store);
    }

    return mergedStores;
  };

  const generateStoreMarkers = (stores, storeName2DealIds) => {
    if (Object.keys(stores).length > 0) {
      const storeMarkers = Object.values(stores).map((store) => {
        if (store && store.geohash) {
          // Check if store and geohash are not undefined
          return {
            lat: ngeohash.decode(store.geohash).latitude,
            lng: ngeohash.decode(store.geohash).longitude,
            name: store.storeName,
            deals: store.num_of_deals,
            placeId: store.placeId,
            address: store.address,
            geohash: store.geohash,
            dealIds: storeName2DealIds[store.storeName],
          };
        }
      });
      // Filter out any undefined values
      return storeMarkers.filter(Boolean);
    }
    return [];
  };

  return {
    dealsByState,
    storesByStateDetails,
    storesByStateMarkers,
    dealsByStateId2DetailsDict: dealId2DetailsDict,
  };
}
