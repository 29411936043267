import { Box } from "@mui/material";
import * as React from "react";
import { useEffect } from 'react';
import Map from "../map/Map";
import Banner from "./Banner";
import Brands from "./Brands";
import Categories from "./Categories";
import LightningHome from "./LightningHome";
import Section from "./Section";
import SectionBrands from "./SectionBrands";
import SectionLightning from "./SectionLightning";
import TopDeals from "./TopDeals";
import TrendingPostGrid from "./TrendingPostGrid";


function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  return (
    <div>
      <Box fullWidth>
        <Section content={<Banner />}/>
      </Box>
      <Section
        name={"This Week"}
        title={"Top Deals"}
        link={"/deals"}
        content={<TopDeals />}
      />

      <SectionLightning
        name={"Limited Time Offer"}
        title={"Lightning Deals"}
        link={"/lightning"}
        content={<LightningHome />}
      />

      <Section
        name={"Categories"}
        title={"Browse By Category"}
        link={""}
        content={<Categories />}
      />
      <SectionBrands
        name={"Brands"}
        title={"Browse By Brands"}
        link={"/brands"}
        content={<Brands />}
      />
      <Section
        name={"In-Store Deals"}
        title={"Find In-Store Deals"}
        link={"/map"}
        content={<Map />}
      />
      <Section
        name={"Deals"}
        title={"Trending Nearby"}
        link={""}
        content={<TrendingPostGrid/>}
      />
    </div>
  );
}

export default Home;
