import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box, Grid, Typography } from '@mui/material';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import * as React from 'react';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const onlineDealSteps = [
  {
    label: 'URL',
    // description: `abc.`,
  },
  {
    label: 'Title',
  },
  {
    label: 'Price',
  },
  {
    label: 'Category',
  },
  {
    label: 'Store',
  },
];

const instoreDealSteps = [
  {
    label: 'URL',
  },
  {
    label: 'Title',
  },
  {
    label: 'Price',
  },
  {
    label: 'Category',
  },
  {
    label: 'Store',
  },
  {
    label: 'Address',
  },
];

export function DealEditorStepper({
  dealDetails,
  inStorePickup,
  isMedium,
  isLarge,
  isSmall,
  setDisabledSubmit,
  selectedKindOfDealOption,
  completedSteps,
  setCompletedSteps,
}) {
  React.useEffect(() => {
    setCompletedSteps((prevCompletedSteps) => ({
      ...prevCompletedSteps,
      URL: !!dealDetails?.deal_link,
      Title: !!dealDetails?.title,
      Price:
        dealDetails?.price !== undefined &&
        dealDetails?.price !== null &&
        dealDetails?.price !== '',
      Category: !!dealDetails?.forum_type,
      Store: !!dealDetails?.dealer_type,
      Address:
        dealDetails?.available_states?.length > 0 ||
        dealDetails?.available_store_addresses?.length > 0,
      Img: dealDetails?.uploaded_img_links?.length > 0,
    }));
  }, [dealDetails]);

  React.useEffect(() => {
    if (!inStorePickup) {
      setDisabledSubmit(
        !onlineDealSteps.every((step) => completedSteps[step.label])
      );
    } else if (inStorePickup) {
      setDisabledSubmit(
        !instoreDealSteps.every((step) => completedSteps[step.label])
      );
    } else {
      setDisabledSubmit(true);
    }
  }, [completedSteps, selectedKindOfDealOption]);

  return (
    <>
      {(isMedium || isLarge) && (
        <>
          <Grid item xs={2} md={2}>
            <Box sx={{ px: 0, my: 6, position: 'sticky', top: 20, zIndex: 1 }}>
              <Stepper nonLinear orientation="vertical" activeStep={null}>
                {(inStorePickup ? instoreDealSteps : onlineDealSteps).map(
                  (step, index) => (
                    <Step
                      key={step.label}
                      completed={completedSteps[step.label]}
                    >
                      <StepLabel>{step.label}</StepLabel>
                      <StepContent>
                        <Typography>{step.description}</Typography>
                      </StepContent>
                    </Step>
                  )
                )}
              </Stepper>
            </Box>
          </Grid>
        </>
      )}
      {isSmall && (
        <>
          <Grid
            item
            xs={12}
            sx={{
              my: 4,
              position: 'sticky',
              top: 0,
              zIndex: 9,
              backdropFilter: 'blur(15px)',
            }}
          >
            <Box sx={{ width: '100%' }}>
              <Stepper
                nonLinear
                orientation="horizontal"
                alternativeLabel
                activeStep={null}
              >
                {(inStorePickup ? instoreDealSteps : onlineDealSteps).map(
                  (step, index) => (
                    <Step
                      key={step.label}
                      completed={completedSteps[step.label]}
                    >
                      <StepLabel>{step.label}</StepLabel>
                      {/* <StepContent>
                                            </StepContent> */}
                    </Step>
                  )
                )}
              </Stepper>
            </Box>
          </Grid>
        </>
      )}
    </>
  );
}

export default DealEditorStepper;
