export const inStoreDealGeoHashesByGeohash4V2 = /* GraphQL */ `
  query InStoreDealGeoHashesByGeohash4(
    $geohash4: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInStoreDealGeoHashFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inStoreDealGeoHashesByGeohash4(
      geohash4: $geohash4
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deal_id
        geohash
        address
        store_name
        zipcode
        googlemap_placeid
        geohash3
        geohash4
        owner
        _deleted
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;


export const listInStoreDealGeoHashesV2 = /* GraphQL */ `
  query ListInStoreDealGeoHashes(
    $filter: ModelInStoreDealGeoHashFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInStoreDealGeoHashes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deal_id
        geohash
        address
        store_name
        zipcode
        googlemap_placeid
        geohash3
        geohash4
        owner
        _deleted
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;


export const inStoreDealByStatesByAvailable_state_V2 = /* GraphQL */ `
  query InStoreDealByStatesByAvailable_state(
    $available_state: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInStoreDealByStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inStoreDealByStatesByAvailable_state(
      available_state: $available_state
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deal_id
        store_name
        available_state
        owner
        _deleted
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;