/*
export const CATEGORIES = [
  'Arts & Craft Materials',
  'Automotives',
  'Baby Products',
  'Beauty & Personal Care',
  'Electronics',
  'Computers & Tablets',
  'Clothing & Fashion',
  'Health & Wellness',
  'Home & Furniture',
  'Gift Cards & Digital Goods',
  'Grocery, Food, & Drinks',
  'Kitchen & Appliances',
  'Miscellaneous (Other)',
  'Movies, Music, & Television',
  'Patio & Outdoor',
  'Pet Supplies',
  'Television & Video',
  'Tools & Home Improvement',
  'Toys, Games, & Collectibles',
];
*/
export const CATEGORIES = [
    "Arts and Crafts",
    "Automotive",
    'Baby Products',
    'Beauty and Personal Care',
    "Books and Fine Art",
    'Camera and Photo',
    'Cell Phones and Accessories',
    'Clothing, Shoes, and Jewelry',
    "Electronics",
    'Entertainment Collectibles',
    "Groceries and Gourmet Food",
    "Health and Household",
    "Home and Kitchen",
    'In Demand Items',
    'Industrial and Scientific',
    "Kitchen and Home Appliances",
    'Luggage and Travel',
    'Musical Instruments',
    'Office and School Supplies',
    'Patio, Lawn, and Garden',
    "Pet Supplies",
    "Sports and Outdoors",
    "Tools and Home Improvement",
    "Toys and Games",
    "Video Games",
    'Gift Cards',
    'Everything Else',
];

/*
export const BRANDS = [
  'AT&T',
  'Ace Hardware',
  'Adidas',
  'Adorama',
  'Amazon',
  'AutoZone',
  'B&H Photo',
  "BJ's Wholesale Club",
  'Belk',
  'Best Buy',
  'Big Lots',
  'BrandsMartUSA',
  'CVS Pharmacy',
  'Champs Sports',
  'Costco',
  "Dick's Sporting Goods",
  'Foot Locker',
  'GAP',
  'GameStop',
  'IKEA',
  "JC Penney's",
  "Kohl's",
  "Lowe's",
  'Lululemon',
  "Macy's",
  'MicroCenter',
  'Neiman Marcus',
  'Nike',
  'Office Depot',
  'Other',
  'PC Richard & Son',
  'PetSmart',
  'Petco',
  "Sam's Club",
  'Sephora',
  'Shop My Exchange',
  'Shop Premium Outlets',
  'Staples',
  'Target',
  'The Home Depot',
  'Tractor Supply Co.',
  'Verizon',
  'Walgreens',
  'Walmart',
  'Wayfair',
  'Woot!',
];
*/

export const BRANDS = [
  "Amazon",
  "Best Buy",
  "Target",
  "Walmart",
  "Costco",
  "Sam's Club",
  "Adorama",
  "B&H Photo",
  "MicroCenter",
  "BrandsMartUSA",
  "Woot!",
  "Lowe's",
  "The Home Depot",
  "CVS Pharmacy",
  "Walgreens",
  "PC Richard & Son",
  "Macy's",
  "Kohl's",
  "Verizon",
  "BJ's Wholesale Club",
  "Wayfair",
  "Dick's Sporting Goods",
  "Office Depot",
  "Staples",
  "Nike",
  "Adidas",
  "Champs Sports",
  "JC Penney's",
  "Lululemon",
  "Shop My Exchange",
  "PetSmart",
  "Petco",
  "Tractor Supply Co.",
  "AT&T",
  "GameStop",
  "Sephora",
  "Foot Locker",
  "GAP",
  "Big Lots",
  "Belk",
  "Neiman Marcus",
  "IKEA",
  "Shop Premium Outlets",
  "Ace Hardware",
  "AutoZone",
  "Other",
  "7-Eleven",
  "ABC WIN & Liquor",
  "Abercrombie & Fitch",
  "Academy Sports",
  "Advance Auto",
  "Aeropostale",
  "Albertson's",
  "Aldi",
  "Allbirds",
  "Alo Yoga",
  "AMC",
  "American Eagle Outfitters",
  "Anker",
  "Ann Taylor",
  "App Store",
  "ASOS",
  "Athleta",
  "ASUS Store",
  "Barnes & Nobles",
  "Bass Pro Shops",
  "Bath & Body Works",
  "Bealls",
  "Bealls Outlet",
  "Bebe",
  "Bissell",
  "Black & Decker",
  "Bloomingdale's",
  // "BrandsMart USA",
  "Brita",
  "Brooks Running",
  "Buckle",
  "Burberry",
  "Burlington",
  "Camping World",
  "Callaway Golf",
  "Calphalon",
  "Calvin Klein",
  "Casey's General Store",
  "Champ's",
  "Chewy.com",
  "Children's Place",
  "Clinique",
  "Coach Outlet",
  "Coleman",
  "Crocs",
  "Crate & Barrel",
  "Crutchfield",
  "Cuisinart",
  "Dell",
  "Dillard's",
  "Discount Tire",
  "Disney Store",
  "DSW",
  "DKNY",
  "Dollar General",
  "Dollar Tree",
  "DoorDash",
  "DTLR",
  "Dyson",
  "Eddie Bauer",
  "Elf Cosmetics",
  "Eufy",
  "Fabletics",
  "FinishLine",
  "Fred Meyer",
  "Free People",
  "Food Lion",
  // "Footlocker",
  "Giant Eagle",
  "GNC",
  "Godiva",
  "Golf Discount",
  "Google Store",
  "Groupon",
  "Gucci",
  "Guitar Center",
  "Hanes",
  "Harbor Freight Tools",
  "Hobby Lobby",
  "Hoka",
  "Hurley",
  "Hydroflask",
  "Hy Vee",
  "Instacart",
  "JBL",
  "J. Crew",
  "Joann's",
  "Jos. A Bank",
  "Journey's",
  "Jewel Osco",
  "Kate Spade",
  "Kendra Scott",
  "Kenneth Cole",
  "Keurig",
  "Kirkland's",
  "Kohler",
  "Lenovo",
  "Lids",
  "LG",
  "Loft",
  "Logitech",
  "Lounge Fly",
  "Marshall's",
  "Meijer",
  "Menard's",
  "Michael's",
  "Michael Kors",
  "Microsoft Store",
  "Nautica",
  "Navy Exchange (NEX)",
  "Nebraska Furniture Mart (NFM)",
  "Nespresso",
  "New Balance",
  "Newegg",
  "Nintendo",
  "Nordstrom Rack",
  "The North Face",
  "Olay",
  "Old Navy",
  "Ollie's",
  "On Running",
  "Oofos",
  "O'Reilly Auto Parts",
  "Pandora",
  "Patagonia",
  "Piggly Wiggly",
  "PlayStation Store",
  "Postmates",
  "Publix",
  "Puma",
  "Reebok",
  "Revolve",
  "Ring",
  "Rockport",
  "Safeway",
  "Saks Fifth Avenue",
  "Samsung",
  "Save-A-Lot",
  "Sierra",
  "Shark Clean",
  "Sherwin Williams",
  "Shoprite",
  "Skechers",
  "Soundcore",
  "Sperry",
  "Sprouts Farmers Market",
  "Stanley",
  "Stop N' Shop",
  "Sun Bum",
  "Sunglasses Hut",
  "TJ Maxx",
  "Total Wine",
  "Tory Burch",
  "Uber",
  "Uber Eats",
  "Under Armour",
  "Ulta Beauty",
  "Victoria's Secret",
  // "Verizon Wireless",
  "Von's",
  "Wawa",
  "Wegman's Food Market",
  "Xbox Store",
  "Zappos",
  "Zotac Store"
];
