import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Link } from 'react-router-dom';

const OnWebNotification = ({
  notificationContent,
  openOnWebNotification,
  setOpenOnWebNotification,
}) => {
  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenOnWebNotification(false);
  };

  const SnackBarContent = () => (
    <React.Fragment>
      {notificationContent?.img_link && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginLeft: '10px',
            marginRight: '16px',
            color: 'black',
            // backgroundColor: 'rgba(255, 255, 255, 1)',
          }}
        >
          <Link
            to={notificationContent?.link}
            onClick={handleCloseSnackBar}
            style={{ color: 'black' }} // Ensure text color is black
          >
            <img
              src={notificationContent?.img_link}
              alt=""
              style={{
                width: window.innerWidth <= 480 ? '90px' : '100px',
                height: window.innerWidth <= 480 ? '100px' : '110px',
                objectFit: 'contain',
              }}
            />
          </Link>
        </div>
      )}
      <div style={{ flex: 1 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            style={{ color: 'black', fontSize: '1em', fontWeight: 'bold' }}
          >
            🛒 New Deal
          </Typography>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackBar}
          >
            <CloseIcon fontSize="small" style={{ color: 'black' }} />
          </IconButton>
        </div>
        <Link
          to={notificationContent?.link}
          onClick={handleCloseSnackBar}
          style={{ textDecoration: 'none', color: 'black' }} // Ensure text color is black
        >
          <Typography style={{ color: 'black', fontSize: '1em' }}>
            {window.innerWidth <= 480
              ? notificationContent?.title?.length > 50
                ? `${notificationContent?.title?.substring(0, 50)}...`
                : notificationContent?.title
              : notificationContent?.title?.length > 70
              ? `${notificationContent?.title?.substring(0, 70)}...`
              : notificationContent?.title}
          </Typography>
        </Link>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            color: 'black',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Typography
              style={{
                color: 'red',
                fontSize: '1.2em',
                fontWeight: 'bold',
              }}
            >
              {typeof notificationContent?.price === 'number'
                ? notificationContent.price.toFixed(2)
                : notificationContent?.price}
            </Typography>
            <Typography
              style={{
                textDecoration: 'line-through',
                fontSize: '1em',
                marginLeft: '10px',
                marginBottom: '3px',
              }}
            >
              {typeof notificationContent?.orig_price === 'number'
                ? notificationContent.orig_price.toFixed(2)
                : notificationContent?.orig_price}
            </Typography>
          </div>
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Typography
              style={{
                textDecoration: 'underline',
                fontSize: '0.8em',
                marginBottom: '3px',
                marginRight: '7px',
                color: 'blue',
                cursor: 'pointer',
              }}
              onClick={() => (window.location.href = '/alerts')}
            >
              {window.innerWidth <= 480 ? 'Manage' : 'Manage Alert'}
            </Typography>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  const handleSlideTransition = (props) => {
    return <Slide {...props} direction="left" />;
  };

  return (
    <div>
      <Snackbar
        open={openOnWebNotification}
        autoHideDuration={120000} // 2 mins
        onClose={handleCloseSnackBar}
        message={'New: ' + notificationContent?.title}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        // TransitionComponent={handleSlideTransition}
        sx={{
          backgroundColor: '#F5F5F5',
          color: 'black',
          opacity: 0.9,
          border: '2px solid #BABABA',
          borderRadius: '10px',
          '&:hover': {
            opacity: 1,
          },
        }}
      >
        <div
          style={{
            margin: '12px',
            width: '400px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <SnackBarContent />
        </div>
      </Snackbar>
    </div>
  );
};

export default OnWebNotification;
