
export const keywordOptions = [

];


export const storeList = [
    "Amazon",
    "Best Buy",
    "Target",
    "Walmart",
    "Costco",
    "Sam's Club",
    "Adorama",
    "B&H Photo",
    "MicroCenter",
    "BrandsMartUSA",
    "Woot!",
    "Lowe's",
    "The Home Depot",
    "CVS Pharmacy",
    "Walgreens",
    "PC Richard & Son",
    "Macy's",
    "Kohl's",
    "Verizon",
    "BJ's Wholesale Club",
    "Wayfair",
    "Dick's Sporting Goods",
    "Office Depot",
    "Staples",
    "Nike",
    "Adidas",
    "Champs Sports",
    "JC Penney's",
    "Lululemon",
    "Shop My Exchange",
    "PetSmart",
    "Petco",
    "Tractor Supply Co.",
    "AT&T",
    "GameStop",
    "Sephora",
    "Foot Locker",
    "GAP",
    "Big Lots",
    "Belk",
    "Neiman Marcus",
    "IKEA",
    "Shop Premium Outlets",
    "Ace Hardware",
    "AutoZone",
    "Other",
    //
    "7-Eleven",
    "ABC WIN & Liquor",
    "Abercrombie & Fitch",
    "Academy Sports",
    "Advance Auto",
    "Aeropostale",
    "Albertson's",
    "Aldi",
    "Allbirds",
    "Alo Yoga",
    "AMC",
    "American Eagle Outfitters",
    "Anker",
    "Ann Taylor",
    "App Store",
    "ASOS",
    "Athleta",
    "ASUS Store",
    "Barnes & Nobles",
    "Bass Pro Shops",
    "Bath & Body Works",
    "Bealls",
    "Bealls Outlet",
    "Bebe",
    "Bissell",
    "Black & Decker",
    "Bloomingdale's",
    // "BrandsMart USA",
    "Brita",
    "Brooks Running",
    "Buckle",
    "Burberry",
    "Burlington",
    "Camping World",
    "Callaway Golf",
    "Calphalon",
    "Calvin Klein",
    "Casey's General Store",
    "Champ's",
    "Chewy.com",
    "Children's Place",
    "Clinique",
    "Coach Outlet",
    "Coleman",
    "Crocs",
    "Crate & Barrel",
    "Crutchfield",
    "Cuisinart",
    "Dell",
    "Dillard's",
    "Discount Tire",
    "Disney Store",
    "DSW",
    "DKNY",
    "Dollar General",
    "Dollar Tree",
    "DoorDash",
    "DTLR",
    "Dyson",
    "Eddie Bauer",
    "Elf Cosmetics",
    "Eufy",
    "Fabletics",
    "FinishLine",
    "Fred Meyer",
    "Free People",
    "Food Lion",
    // "Footlocker",
    "Giant Eagle",
    "GNC",
    "Godiva",
    "Golf Discount",
    "Google Store",
    "Groupon",
    "Gucci",
    "Guitar Center",
    "Hanes",
    "Harbor Freight Tools",
    "Hobby Lobby",
    "Hoka",
    "Hurley",
    "Hydroflask",
    "Hy Vee",
    "Instacart",
    "JBL",
    "J. Crew",
    "Joann's",
    "Jos. A Bank",
    "Journey's",
    "Jewel Osco",
    "Kate Spade",
    "Kendra Scott",
    "Kenneth Cole",
    "Keurig",
    "Kirkland's",
    "Kohler",
    "Lenovo",
    "Lids",
    "LG",
    "Loft",
    "Logitech",
    "Lounge Fly",
    "Marshall's",
    "Meijer",
    "Menard's",
    "Michael's",
    "Michael Kors",
    "Microsoft Store",
    "Nautica",
    "Navy Exchange (NEX)",
    "Nebraska Furniture Mart (NFM)",
    "Nespresso",
    "New Balance",
    "Newegg",
    "Nintendo",
    "Nordstrom Rack",
    "The North Face",
    "Olay",
    "Old Navy",
    "Ollie's",
    "On Running",
    "Oofos",
    "O'Reilly Auto Parts",
    "Pandora",
    "Patagonia",
    "Piggly Wiggly",
    "PlayStation Store",
    "Postmates",
    "Publix",
    "Puma",
    "Reebok",
    "Revolve",
    "Ring",
    "Rockport",
    "Safeway",
    "Saks Fifth Avenue",
    "Samsung",
    "Save-A-Lot",
    "Sierra",
    "Shark Clean",
    "Sherwin Williams",
    "Shoprite",
    "Skechers",
    "Soundcore",
    "Sperry",
    "Sprouts Farmers Market",
    "Stanley",
    "Stop N' Shop",
    "Sun Bum",
    "Sunglasses Hut",
    "TJ Maxx",
    "Total Wine",
    "Tory Burch",
    "Uber",
    "Uber Eats",
    "Under Armour",
    "Ulta Beauty",
    "Victoria's Secret",
    // "Verizon Wireless",
    "Von's",
    "Wawa",
    "Wegman's Food Market",
    "Xbox Store",
    "Zappos",
    "Zotac Store"

].sort();

export const lowerCaseStoreList = storeList.map(store => store.toLowerCase());



export const storeIcon = {
    "Amazon": "/brand_icons/brand_icons_amazon.png",
    "Best Buy": "/brand_icons/brand_icons_bestbuy.png",
    "Target": "/brand_icons/brand_icons_target.png",
    "Walmart": "/brand_icons/brand_icons_walmart.png",
    "Costco": "/brand_icons/brand_icons_costco.png",
    "Sam's Club": "/brand_icons/brand_icons_SamsClub.png",
    "Adorama": "/brand_icons/brand_icons_adorama.png",
    "B&H Photo": "/brand_icons/brand_icons_bh.png",
    "MicroCenter": "/brand_icons/brand_icons_micro_center.png",
    "BrandsMartUSA": "/brand_icons/brand_icons_brands_mart.png",
    "Woot!": "/brand_icons/brand_icons_woot.png",
    "Lowe's": "/brand_icons/brand_icons_lowes.png",
    "The Home Depot": "/brand_icons/brand_icons_home_depot.png",
    "CVS Pharmacy": "/brand_icons/brand_icons_cvs.png",
    "Walgreens": "/brand_icons/brand_icons_walgreens.png",
    "PC Richard & Son": "/brand_icons/brand_icons_pc_richard.png",
    "Macy's": "/brand_icons/brand_icons_macys.png",
    "Kohl's": "/brand_icons/brand_icons_kohls.png",
    "Verizon": "/brand_icons/brand_icons_verizon.png",
    "BJ's Wholesale Club": "/brand_icons/brand_icons_bj.png",
    "Wayfair": "/brand_icons/brand_icons_wayfair.png",
    "Dick's Sporting Goods": "/brand_icons/brand_icons_dicks_sporting.png",
    "Office Depot": "/brand_icons/brand_icons_patio.png",
    "Staples": "/brand_icons/brand_icons_staples.png",
    "Nike": "/brand_icons/Nike.png",
    "Adidas": "/brand_icons/brand_icons_adidas.png",
    "Champs Sports": "/brand_icons/brand_icons_champs.png",
    "JC Penney's": "/brand_icons/brand_icons_jc_penny.png",
    "Lululemon": "/brand_icons/brand_icons_lulu.png",
    "Shop My Exchange": "/brand_icons/brand_icons_exchange.png",
    "PetSmart": "/brand_icons/brand_icons_pets_smart.png",
    "Petco": "/brand_icons/brand_icons_petco.png",
    "Tractor Supply Co.": "/brand_icons/brand_icons_Tractor.png",
    "AT&T": "/brand_icons/brand_icons_att.png",
    "GameStop": "/brand_icons/brand_icons_game_stop.png",
    "Sephora": "/brand_icons/brand_icons_sephora.png",
    "Foot Locker": "/brand_icons/brand_icons_foot_locker.png",
    "GAP": "/brand_icons/brand_icons_gap.png",
    "Big Lots": "/brand_icons/brand_icons_big_lots.png",
    "Belk": "/brand_icons/brand_icons_belk.png",
    "Neiman Marcus":"/brand_icons/brand_icons_neiman_marcus.png",
    "IKEA": "/brand_icons/brand_icons_ikea.png",
    "Shop Premium Outlets": "/brand_icons/brand_icons_premium_outlet_shops.png",
    "Ace Hardware":"/brand_icons/brand_icons_ace.png",
    "AutoZone":"/brand_icons/brand_icons_autozone.png",
    //"Other":"/white_icon.png",
    //"other":"/white_icon.png",
    //
    "7-Eleven":"/brand_icons/7-Eleven.png",
    "ABC WIN & Liquor":"/brand_icons/ABC WIN & Liquor.png",
    "Abercrombie & Fitch":"/brand_icons/Abercrombie & Fitch.png",
    "Academy Sports":"/brand_icons/Academy Sports.png",
    "Advance Auto":"/brand_icons/Advance Auto.png",
    "Aeropostale":"/brand_icons/Aeropostale.png",
    "Albertson's":"/brand_icons/Albertson’s.png",
    "Aldi":"/brand_icons/Aldi.png",
    "Allbirds":"/brand_icons/Allbirds.png",
    "Alo Yoga":"/brand_icons/Alo Yoga.png",
    "AMC":"/brand_icons/AMC.png",
    "American Eagle Outfitters":"/brand_icons/American Eagle Outfitters.png",
    "Anker":"/brand_icons/Anker.png",
    "Ann Taylor":"/brand_icons/Ann Taylor.png",
    "App Store":"/brand_icons/App Store.png",
    "ASOS":"/brand_icons/ASOS.png",
    "Athleta":"/brand_icons/Athleta.png",
    "ASUS Store":"/brand_icons/ASUS Store.png",
    "Barnes & Nobles":"/brand_icons/Barnes & Nobles.png",
    "Bass Pro Shops":"/brand_icons/Bass Pro Shops.png",
    "Bath & Body Works":"/brand_icons/Bath & Body Works.png",
    "Bealls":"/brand_icons/Bealls.png",
    "Bealls Outlet":"/brand_icons/Bealls Outlet.png",
    "Bebe":"/brand_icons/Bebe.png",
    "Bissell":"/brand_icons/Bissell.png",
    "Black & Decker":"/brand_icons/Black & Decker.png",
    "Bloomingdale's":"/brand_icons/Bloomingdale’s.png",
    // "BrandsMart USA":"/brand_icons/BrandsMart USA.png",
    "Brita":"/brand_icons/Brita.png",
    "Brooks Running":"/brand_icons/Brooks Running.png",
    "Buckle":"/brand_icons/Buckle.png",
    "Burberry":"/brand_icons/Burberry.png",
    "Burlington":"/brand_icons/Burlington.png",
    "Camping World":"/brand_icons/Camping World.png",
    "Callaway Golf":"/brand_icons/Callaway Golf.png",
    "Calphalon":"/brand_icons/Calphalon.png",
    "Calvin Klein":"/brand_icons/Calvin Klein.png",
    "Casey's General Store":"/brand_icons/Casey’s General Store.png",
    "Champ's":"/brand_icons/Champ’s.png",
    "Chewy.com":"/brand_icons/Chewy.png",
    "Children's Place":"/brand_icons/Children’s Place.png",
    "Clinique":"/brand_icons/Clinique.png",
    "Coach Outlet":"/brand_icons/Coach Outlet.png",
    "Coleman":"/brand_icons/Coleman.png",
    "Crocs":"/brand_icons/Crocs.png",
    "Crate & Barrel":"/brand_icons/Crate & Barrel.png",
    "Crutchfield":"/brand_icons/Crutchfield.png",
    "Cuisinart":"/brand_icons/Cuisinart.png",
    "Dell":"/brand_icons/dell.png",
    "Dillard's":"/brand_icons/Dillard’s.png",
    "Discount Tire":"/brand_icons/Discount Tire.png",
    "Disney Store":"/brand_icons/Disney Store.png",
    "DSW":"/brand_icons/DSW.png",
    "DKNY":"/brand_icons/DKNY.png",
    "Dollar General":"/brand_icons/Dollar General.png",
    "Dollar Tree":"/brand_icons/Dollar Tree.png",
    "DoorDash":"/brand_icons/DoorDash.png",
    "DTLR":"/brand_icons/DTLR.png",
    "Dyson":"/brand_icons/Dyson.png",
    "Eddie Bauer":"/brand_icons/Eddie Bauer.png",
    "Elf Cosmetics":"/brand_icons/Elf Cosmetics.png",
    "Eufy":"/brand_icons/Eufy.png",
    "Fabletics":"/brand_icons/Fabletics.png",
    "FinishLine":"/brand_icons/FinishLine.png",
    "Fred Meyer":"/brand_icons/Fred Meyer.png",
    "Free People":"/brand_icons/Free People.png",
    "Food Lion":"/brand_icons/Food Lion.png",
    // "Footlocker":"/brand_icons/Footlocker.png",
    "Giant Eagle":"/brand_icons/Giant Eagle.png",
    "GNC":"/brand_icons/GNC.png",
    "Godiva":"/brand_icons/Godiva.png",
    "Golf Discount":"/brand_icons/Golf Discount.png",
    "Google Store":"/brand_icons/Google Store.png",
    "Groupon":"/brand_icons/Groupon.png",
    "Gucci":"/brand_icons/Gucci.png",
    "Guitar Center":"/brand_icons/Guitar Center.png",
    "Hanes":"/brand_icons/Hanes.png",
    "Harbor Freight Tools":"/brand_icons/Harbor Freight Tools.png",
    "Hobby Lobby":"/brand_icons/Hobby Lobby.png",
    "Hoka":"/brand_icons/Hoka.png",
    "Hurley":"/brand_icons/Hurley.png",
    "Hydroflask":"/brand_icons/Hydroflask.png",
    "Hy Vee":"/brand_icons/Hy Vee.png",
    "Instacart":"/brand_icons/Instacart.png",
    "JBL":"/brand_icons/JBL.png",
    "J. Crew":"/brand_icons/J. Crew.png",
    "Joann's":"/brand_icons/Joann.png",
    "Jos. A Bank":"/brand_icons/Jos. A Bank.png",
    "Journey's":"/brand_icons/Journey.png",
    "Jewel Osco":"/brand_icons/Jewel Osco.png",
    "Kate Spade":"/brand_icons/Kate Spade.png",
    "Kendra Scott":"/brand_icons/Kendra Scott.png",
    "Kenneth Cole":"/brand_icons/Kenneth Cole.png",
    "Keurig":"/brand_icons/Keurig.png",
    "Kirkland's":"/brand_icons/Kirkland’s.png",
    "Kohler":"/brand_icons/Kholer.png",
    "Lenovo":"/brand_icons/Lenovo.png",
    "Lids":"/brand_icons/Lids.png",
    "LG":"/brand_icons/LG.png",
    "Loft":"/brand_icons/Loft.png",
    "Logitech":"/brand_icons/Logitech.png",
    "Lounge Fly":"/brand_icons/Lounge Fly.png",
    "Marshall's":"/brand_icons/Marshall’s.png",
    "Meijer": "/brand_icons/Meijer.png",
    "Menard's":"/brand_icons/Menard’s.png",
    "Michael's":"/brand_icons/Michael’s.png",
    "Michael Kors":"/brand_icons/Michael Kors.png",
    "Microsoft Store":"/brand_icons/Microsoft Store.png",
    "Nautica":"/brand_icons/Nautica.png",
    "Navy Exchange (NEX)":"/brand_icons/Navy Exchange.png",
    "Nebraska Furniture Mart (NFM)":"/brand_icons/Nebraska Furniture Mart (NFM).png",
    "Nespresso":"/brand_icons/Nespresso.png",
    "New Balance":"/brand_icons/New Balance.png",
    "Newegg":"/brand_icons/Newegg.png",
    "Nintendo":"/brand_icons/Nintendo.png",
    "Nordstrom Rack":"/brand_icons/Nordstrom Rack.png",
    "The North Face":"/brand_icons/The North Face.png",
    "Olay":"/brand_icons/Olay.png",
    "Old Navy":"/brand_icons/Old Navy.png",
    "Ollie's":"/brand_icons/Ollie.png",
    "On Running":"/brand_icons/On Running.png",
    "Oofos":"/brand_icons/Oofos.png",
    "O'Reilly Auto Parts":"/brand_icons/O’Reilly Auto Parts.png",
    "Pandora":"/brand_icons/Pandora.png",
    "Patagonia":"/brand_icons/Patagonia.png",
    "Piggly Wiggly":"/brand_icons/Piggly Wiggly.png",
    "PlayStation Store":"/brand_icons/PlayStation Store.png",
    "Postmates":"/brand_icons/Postmates.png",
    "Publix":"/brand_icons/Publix.png",
    "Puma":"/brand_icons/Puma.png",
    "Reebok":"/brand_icons/Reebok.png",
    "Revolve":"/brand_icons/Revolve.png",
    "Ring":"/brand_icons/Ring.png",
    "Rockport":"/brand_icons/Rockport.png",
    "Safeway":"/brand_icons/Safeway.png",
    "Saks Fifth Avenue":"/brand_icons/Saks Fifth Avenue.png",
    "Samsung":"/brand_icons/Samsung.png",
    "Save-A-Lot":"/brand_icons/Save-A-Lot.png",
    "Sierra":"/brand_icons/Sierra.png",
    "Shark Clean":"/brand_icons/Shark Clean.png",
    "Sherwin Williams":"/brand_icons/Sherwin Williams.png",
    "Shoprite":"/brand_icons/Shoprite.png",
    "Skechers":"/brand_icons/Skechers.png",
    "Soundcore":"/brand_icons/Soundcore.png",
    "Sperry":"/brand_icons/Sperry.png",
    "Sprouts Farmers Market":"/brand_icons/Sprouts Farmers Market.png",
    "Stanley":"/brand_icons/Stanley.png",
    "Stop N' Shop":"/brand_icons/Stop N’ Shop.png",
    "Sun Bum":"/brand_icons/Sun Bum.png",
    "Sunglasses Hut":"/brand_icons/Sunglasses Hut.png",
    "TJ Maxx":"/brand_icons/TJ Max.png",
    "Total Wine":"/brand_icons/Total Wine.png",
    "Tory Burch":"/brand_icons/Tory Burch.png",
    "Uber":"/brand_icons/Uber.png",
    "Uber Eats":"/brand_icons/Uber Eat.png",
    "Under Armour":"/brand_icons/Under Armour.png",
    "Ulta Beauty":"/brand_icons/Ulta Beauty.png",
    "Victoria's Secret":"/brand_icons/Victoria’s Secret.png",
    // "Verizon Wireless":"/brand_icons/Verizon Wireless.png",
    "Von's":"/brand_icons/Von’s.png",
    "Wawa":"/brand_icons/Wawa.png",
    "Wegman's Food Market":"/brand_icons/Wegman’s.png",
    "Xbox Store":"/brand_icons/Xbox Store.png",
    "Zappos":"/brand_icons/Zappos.png",
    "Zotac Store":"/brand_icons/Zotac.png",

};
