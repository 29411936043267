import { API } from "aws-amplify";
import {
  listComments,
} from "../graphql/queries";
import {
  createComment,
  deleteComment,
  updateComment,
} from "../graphql/mutations";
import { AUTH_MODE } from "../components/constants/AppSyncAuthMode";

export async function getCommentsApi(deal_id) {
  const comments = await API.graphql({
    query: listComments,
    variables: {
      filter: {
        and: [{ deal_id: { eq: deal_id } }, { _deleted: { ne: true } }],
      },
    },
  });

  return comments.data.listComments.items;
}

export async function postCreateCommentApi(deal_id, user_name, text, reply_to, poster_img_url) {
  await API.graphql({
    query: createComment,
    variables: {
      input: {
        deal_id: deal_id,
        user_name: user_name,
        text: text,
        reply_to: reply_to,
        poster_img_url: poster_img_url,
      },
    },
    authMode: AUTH_MODE.USER_POOLS,
  });
}

export async function postEditCommentApi(comment) {
  await API.graphql({
    query: updateComment,
    variables: {
      input: {
        id: comment.id,
        text: comment.text,
        _version: comment._version,
      },
    },
    authMode: AUTH_MODE.USER_POOLS,
  });
}

export async function postDeleteCommentApi(comment) {
  await API.graphql({
    query: deleteComment,
    variables: {
      input: {
        id: comment.id,
        _version: comment._version,
      },
    },
    authMode: AUTH_MODE.USER_POOLS,
  });
}
