import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Switch,
  FormControlLabel,
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { useAppContext_ } from '../../AppContextProvider';
import { sendMessage } from '../../graphql/mutations';
import { AUTH_MODE } from '../constants/AppSyncAuthMode';
import { API, Auth } from 'aws-amplify';

export function ApiMessaging(props) {
  const [isGlobalMessage, setIsGlobalMessage] = useState(true);
  const [receiver, setReceiver] = useState('');
  const [type, setType] = useState('New Deal');
  const [title, setTitle] = useState('New Deal');
  const [body, setBody] = useState('New Deal');

  async function messagingHandler() {
    try {
      const response = await API.graphql({
        query: sendMessage,
        variables: {
          isGlobalMessage: isGlobalMessage,
          receiver: receiver,
          type: type,
          title: title,
          body: body,
        },
        authMode: AUTH_MODE.USER_POOLS,
      });
      console.log('response: ', response);
      alert(response.data.LosDealVoteFunc);
    } catch (error) {
      console.error('Error: ', error);
      alert('Error: ', error);
    }
  }

  return (
    <Card sx={{ bgcolor: '#f5f5f5', width: '100%' }}>
      <CardContent>
        Messaging
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={isGlobalMessage}
                onChange={(event) => setIsGlobalMessage(event.target.checked)}
                name="isGlobalMessage"
              />
            }
            label="Is Global Message"
          />
          <TextField
            id="receiver"
            label="Receiver"
            value={receiver}
            onChange={(event) => setReceiver(event.target.value)}
          />
          <TextField
            id="type"
            label="Type"
            value={type}
            onChange={(event) => setType(event.target.value)}
          />
          <TextField
            id="title"
            label="Title"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          />
          <TextField
            id="body"
            label="Body"
            value={body}
            onChange={(event) => setBody(event.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            sx={{ margin: 1, textTransform: 'none' }}
            onClick={() => {
              messagingHandler();
            }}
          >
            Send
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}
