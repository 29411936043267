import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MobileStepper from "@mui/material/MobileStepper";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { IconButton } from "@mui/material";

const images = [
  {
    label: "San Francisco – Oakland Bay Bridge, United States",
    imgPath:
      "https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60",
  },
  {
    label: "Bird",
    imgPath:
      "https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60",
  },
  {
    label: "Bali, Indonesia",
    imgPath:
      "https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250",
  },
  {
    label: "Goč, Serbia",
    imgPath:
      "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",
  },
];

function Carousel({ images, startingIndex, open, onClose }) {
  const [index, setIndex] = useState(startingIndex);
  const handleImage = (index) => setIndex(index);

  const handleClick = (event) => {
    console.log(event.target.nodeName);
    if (event.target.nodeName !== "IMG") {
      onClose();
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      aria-labelledby="full-screen-dialog-title"
      PaperProps={{
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.7)",
        },
      }}
    >
      <DialogContent
        onClick={handleClick}
        className="tw-relative tw-flex tw-flex-col tw-gap-y-12 tw-items-center"
      >
        <div className="tw-h-[85%] tw-flex tw-justify-center tw-items-center">
          <img
            src={images[index]}
            className="tw-rounded-lg tw-object-contain"
          />
        </div>
        <div className="tw-h-[15%] tw-flex-1 tw-flex tw-justify-center tw-overflow-auto tw-gap-8 tw-w-full">
          {images.map((imgLink, index) => (
            <img
              className="hover:tw-opacity-80 tw-cursor-pointer tw-object-contain tw-rounded-lg"
              key={index}
              src={imgLink}
              alt={`Image ${index}`}
              onClick={() => handleImage(index)}
            />
          ))}
        </div>
        <div className="tw-absolute tw-top-3 tw-right-3" onClick={onClose}>
          <IconButton
            size="large"
            sx={{
              color: "white",
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default Carousel;
