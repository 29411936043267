export const MapSearch_Zoom_Radius = {
  0: '130000',
  1: '130000',
  2: '130000',
  3: '130000',
  4: '130000',
  5: '130000',
  6: '120000',
  7: '110000',
  8: '100000',
  9: '90000',
  10: '80000',
  11: '70000',
  12: '60000',
  13: '50000',
  14: '40000',
  15: '30000',
  16: '20000',
  17: '10000',
  18: '5000',
  19: '5000',
  20: '5000',
  21: '5000',
};

export const MapSearch_Keyword_Mapping = {
  costco: 'Costco Wholesale',
};
