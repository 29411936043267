import { Link, createSearchParams } from 'react-router-dom';

function Categories() {
  const categories = [
    {
      name: 'Electronics',
      search_key: 'Electronics',
      description: 'Computers, Tablets, TVs, Camera, and Drones',
      icon: '/electro.png',
    },
    {
      name: 'Beauty & Personal Care',
      search_key: "Beauty and Personal Care",
      description: 'Skincare, Haircare, and Makeup',
      icon: '/beauty_health.png',
    },
    {
      name: 'Clothing & Fashion',
      search_key: "Clothing, Shoes, and Jewelry",
      description: 'T-shirts, Jeans, Sneakers, Watches, and Sunglasses',
      icon: '/clothing.png',
    },
    {
      name: 'Pet Supplies',
      search_key: "Pet Supplies",
      description: 'Pet Food, Pet Toys, Pet Equipment, & More.',
      icon: '/pet.png',
    },
    {
      name: 'Home & Furniture',
      search_key: "Home and Kitchen",
      description: 'Furniture, Home Decor, Kitchen and Dining',
      icon: '/home.png',
    },
    {
      name: 'Arts & Craft Materials',
      search_key: "Arts and Crafts",
      description: 'Painting Supplies, Drawing Supplies, DIY Craft Kits',
      icon: '/art.png',
    },
    {
      name: 'Automotives',
      search_key: "Automotive",
      description: 'Tires, Engine Oil, and Car Batteries',
      icon: '/automotives1.png',
    },
    {
      name: 'Grocery, Food, & Drinks',
      search_key: "Groceries and Gourmet Food",
      description: 'Grocery Products, Food, Drinks, & More.',
      icon: '/grocery.png',
    },
  ];
  return (
    <div className="tw-flex tw-w-full">
      <div className="tw-flex-1 tw-flex tw-flex-wrap">
        {categories.map((entry, index) => (
          <Link
            //to="/categoryForum"
            // state={{name:entry.name}}
            to={{
              pathname: '/deals',
              search: createSearchParams({
                forum_types: [entry.search_key],
              }).toString(),
            }}
            key={index}
            className="tw-bg-surface-raised tw-ring-1 tw-ring-surface-border tw-flex-shrink tw-w-1/2 md:tw-w-1/4 tw-p-4 tw-text-string-base tw-no-underline hover:tw-bg-surface-base tw-transition-all"
          >
            <p className="tw-text-sm md:tw-text-2xl tw-font-bold tw-pb-3">
              {entry.name}
            </p>
            <div className="tw-flex">
              <p className="tw-text-xs md:tw-text-sm tw-text-string-neutral tw-flex-1">
                {entry.description}
              </p>
              <img
                src={entry.icon}
                className="tw-w-16 tw-h-16 tw-flex-1 tw-object-contain"
              />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Categories;
