import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppContext_ } from '../AppContextProvider';
import { useEffect } from 'react';
import useLocalStorage from '../utils/useLocalStorage';

function FederatedUsernameCheck() {
  const { user } = useAppContext_();
  const navigate = useNavigate();
  const location = useLocation();
  const [federatedUsernameChanged, setFederatedUsernameChanged] = useLocalStorage('federatedUsernameChanged', false);

  const isFederatedUser = () => {
    if (
      user &&
      user.attributes.sub &&
      user.attributes.sub.match('/google_\d+/')
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (isFederatedUser() && !federatedUsernameChanged) {
      setFederatedUsernameChanged(true);
      navigate(
        'set-username',
        { state: { destination: location.pathname } },
        { replace: true }
      );
    }
  }, []);

  return <Outlet />;
}

export default FederatedUsernameCheck;
