import { Button, CircularProgress } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import usePropState from '../../utils/usePropState';

function CustomPaginationEndlessMode({
  items,
  component,
  fetchNextPage,
  hasMore,
}) {
  const [itemsCopy, setItemsCopy] = usePropState(items);
  const [loading, setLoading] = useState(false);
  const container = useRef(null);
  const loadMoreButton = useRef(null);

  const handleButtomMore = async (event, value) => {
    if (loading || !hasMore) return;

    setLoading(true);
    await fetchNextPage(true);
    setLoading(false);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      async (entries) => {
        // make this function async
        if (entries[0].isIntersecting && hasMore) {
          await handleButtomMore();
        }
      },
      { threshold: 1.0 }
    );

    if (loadMoreButton.current) {
      observer.observe(loadMoreButton.current);
    }

    return () => {
      if (loadMoreButton.current) {
        observer.unobserve(loadMoreButton.current);
      }
    };
  }, [hasMore, loadMoreButton.current]);

  return (
    <div
      className="tw-flex tw-flex-col tw-items-center tw-gap-y-8"
      ref={container}
    >
      {component(itemsCopy)}
      <Button
        variant="contained"
        color="primary"
        onClick={handleButtomMore}
        ref={loadMoreButton}
        disabled={loading || !hasMore}
      >
        {loading ? (
          <CircularProgress size={24} />
        ) : hasMore ? (
          'Load More Deals'
        ) : (
          'You have reached the end.'
        )}
      </Button>
    </div>
  );
}

export default CustomPaginationEndlessMode;
