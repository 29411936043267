import * as React from 'react';
import Post from '../../components/utils/Post';

function VerticalPostGrid({ posts }) {
  return (
    <div
        className="tw-grid tw-gap-1 sm:tw-gap-2 md:tw-gap-3 xl:tw-gap-5
         tw-grid-cols-2 sm:tw-grid-cols-3 md:tw-grid-cols-3 lg:tw-grid-cols-3 xl:tw-grid-cols-4 2xl:tw-grid-cols-5"
    >
        {posts.map((item, index) => (
          <Post item={item} orientation="vertical" />
        ))}
    </div>
  );
}

export default VerticalPostGrid;
