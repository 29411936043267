import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { Button, Card, CardContent } from '@mui/material';
import { Auth, Storage } from 'aws-amplify';
import * as React from 'react';
import { useAppContext_ } from '../../AppContextProvider';

export function ApiS3(props) {
  const { user, setUser } = useAppContext_();
  const REACT_APP_PROFILE_BUCKET_NAME =
    process.env.REACT_APP_PROFILE_BUCKET_NAME;
  const cur_uuid = 'test_user_sub';

  const handleImageUploadButton = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (file.size > 4000000) {
      alert('Each image is limited to a maximum size of 4 MB.');
      return;
    }
    await handleFileUpload(file);
  };

  const handleFileUpload = async (profilePic) => {
    try {
      if (profilePic !== '') {
        Storage.configure({ region: 'us-east-1' });
        if (profilePic) {
          const cur_uuid = 'test_user_sub';
          const { key } = await Storage.put(
            `profile-img/${cur_uuid}.png`,
            profilePic,
            { contentType: 'image/*' }
          );
          const profilePicPathURI = `https://${REACT_APP_PROFILE_BUCKET_NAME}.s3.amazonaws.com/public/${key}`;

          await Auth.updateUserAttributes(user, {
            picture: profilePicPathURI,
          });

          setUser(await Auth.currentAuthenticatedUser());
        } else {
          alert(
            'Please re-select a valid profile image or leave the image field blank. Thank you for your patience!'
          );
        }
      } else {
        alert(
          'Submiting this form will remove your current profile img and a default icon will be shown'
        );
      }
    } catch (err) {
      alert(err);
    }
  };
  return (
    <Card sx={{ bgcolor: '#f5f5f5', width: '100%' }}>
      <CardContent>API S3</CardContent>
      <div>
        <img
          src={`https://${REACT_APP_PROFILE_BUCKET_NAME}.s3.amazonaws.com/public/profile-img/${cur_uuid}.png`}
          className="tw-h-36 tw-w-36 tw-bg-gray-200 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-object-cover"
          alt="img"
        />
      </div>
      <Button
        variant="outlined"
        color="black"
        startIcon={<BorderColorOutlinedIcon />}
        component="label"
      >
        Edit Picture
        <input
          type="file"
          accept="image/png, image/jpeg"
          hidden
          onChange={handleImageUploadButton}
        />
      </Button>
    </Card>
  );
}
