import {
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { useAppContext_ } from '../../AppContextProvider';
import { NotificationHandler } from '../notification/NotificationHandler';

export function ApiNotification(props) {
  const [notificationContent, setNotificationContent] = useState(null);
  const [openNotification, setOpenNotification] = useState(false);
  const {
    user,
    globalNotificationListenerEnabled,
    setGlobalNotificationListenerEnabled,
  } = useAppContext_();
  const userId = user?.attributes?.sub;
  const [support, setSupport] = useState(false);

  const showNotification = () => {
    setNotificationContent({
      title:
        'Take a look at our new items. abcabc acbcda asfwe gjtibo wef s fref wef sfrtgiofnbslbnpqorig24 ',
      link: 'https://www.lordofsavings.com/deals',
      img_link: '/clothing.png',
      price: '$99.99',
      orig_price: '$200.00',
    });
    setOpenNotification(true);
  };

  React.useEffect(() => {
    // Save enabledListener state to localStorage whenever it changes
    localStorage.setItem(
      'GlobalNotificationListenerEnabled',
      globalNotificationListenerEnabled
    );
  }, [globalNotificationListenerEnabled]);

  React.useEffect(() => {
    if ('Notification' in window) {
      setSupport(true);
      // requestNotificationPermission();
    }
  }, []);

  async function requestNotificationPermission() {
    if (!support) {
      alert('Your browser does not support.');
      return;
    }

    console.log('Asking Desktop Notification permission.');
    Notification.requestPermission().then(function (permission) {
      if (permission === 'granted') {
        console.log('Desktop Notification permission granted.');
        alert('Permission granted.');
      } else if (permission === 'denied') {
        console.error('Desktop Notification permission denied.');
        alert('Permission denied.');
      }
    });
  }
  // const sendNotification = async () => {
  //   const content = {
  //     title: 'Take a look at our new items.',
  //     link: 'https://www.lordofsavings.com/alerts',
  //     img_link: '/white_icon.png',
  //     price: '99.99',
  //   };
  //   const contentStr = JSON.stringify(content);
  //   console.log('contentStr:', contentStr);

  //   const notificationDetails = {
  //     content: contentStr,
  //     userId,
  //   };
  //   console.log('Notification Details:', notificationDetails);

  //   const response = await API.graphql(
  //     graphqlOperation(createNotification, notificationDetails)
  //   );
  //   console.log('Response:', response);
  // };

  return (
    <Card sx={{ bgcolor: '#f5f5f5', width: '100%' }}>
      <CardContent>
        Desktop Notification
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{ margin: 1, textTransform: 'none' }}
            onClick={() => {
              requestNotificationPermission();
            }}
          >
            Request Notification Permission
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ margin: 1, textTransform: 'none' }}
            onClick={() => {
              showNotification();
            }}
          >
            Show Notification Bar
          </Button>
          <FormControlLabel
            control={
              <Switch
                checked={globalNotificationListenerEnabled}
                onChange={() =>
                  setGlobalNotificationListenerEnabled(
                    !globalNotificationListenerEnabled
                  )
                }
                name="notificationSwitch"
                color="primary"
              />
            }
            label="Enable Notification Listener"
          />
        </Box>
        <NotificationHandler
          notificationContent={notificationContent}
          openNotification={openNotification}
          setOpenNotification={setOpenNotification}
        />
        {/* <NotificationListener /> */}
      </CardContent>
    </Card>
  );
}
