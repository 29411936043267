import { Pagination } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import usePropState from '../../utils/usePropState';

function CustomPagination({ items, count, component }) {
  const [itemsCopy, setItemsCopy] = usePropState(items);
  const [page, setPage] = useState(0);
  const container = useRef(null);

  const getPage = (page) => {
    return items.slice(0 + count * (page - 1), count + count * (page - 1));
  };

  const handlePagination = (event, value) => {
    scrollToTop();
    setItemsCopy(getPage(value));
    setPage(value - 1);
  };

  const scrollToTop = () => {
    container.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  useEffect(() => {
    setItemsCopy(getPage(page + 1));
  }, []);

  return (
    <div
      className="tw-flex tw-flex-col tw-items-center tw-gap-y-8"
      ref={container}
    >
      {items && items.length > 0 && (
        <>
          {component(itemsCopy)}
          <Pagination
            count={Math.ceil(items.length / count)}
            page={page + 1}
            onChange={handlePagination}
            hidePrevButton
            hideNextButton
          />
        </>
      )}
    </div>
  );
}

export default CustomPagination;
