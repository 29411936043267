import { LoadingButton } from '@mui/lab';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@mui/material';
import Input from '@mui/material/Input';
// import InputLabel from '@mui/material/InputLabel';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useState } from 'react';
import { IMaskInput } from 'react-imask';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { postProfilePictureApi, postRegisterApi } from '../../api/account';
import Section from '../home/Section';
import PasswordTextField from '../utils/PasswordTextField';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function reverseChar(str) {
  if (str.length !== 14) {
    return 'invalid';
  } else {
    // (404) 539-1911
    // console.log(str.slice(1,4), str.slice(6,9), str.slice(10,14));
    return str.slice(1, 4) + str.slice(6, 9) + str.slice(10, 14);
  }
}

const Register = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    username: '',
    email: '',
    phoneNumber: '',
    password: '',
    reenterPassword: '',
    agreeToTerms: false,
  });

  const [formErrors, setFormErrors] = useState({
    username: '',
    email: '',
    phoneNumber: '',
    password: '',
    reenterPassword: '',
  });

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm() === true) {
      const { username, email, phoneNumber, password } = formData;
      //console.log(username, email, phoneNumber.length, password);
      //let phoneRes = reverseChar(phoneNumber);
      // console.log(phoneRes);

      let phoneRes = phoneNumber;

      try {
        setLoading(true);
        let phoneno = /^\d{10}$/;
        if (!phoneRes.match(phoneno)) {
          // console.log("catched");
          setLoading(false);
          setFormErrors((prevState) => ({
            ...prevState,
            phoneNumber:
              'Please enter a valid 10 digit US phone number, like 1234567890',
          }));
        } else {
          // console.log(phoneNumber);
          await postRegisterApi(username, password, {
            email: email,
            phone_number: '+1' + phoneRes,
            picture: await postProfilePictureApi(''),
          });

          if (location.state) {
            navigate(`/verify?username=${username}`, { state: location.state });
          } else {
            navigate(`/verify?username=${username}`);
          }
        }
      } catch (error) {
        setLoading(false);
        if (error.message === 'Invalid phone number format.') {
          setFormErrors((prevState) => ({
            ...prevState,
            phoneNumber:
              'Please enter a valid 10 digit US phone number, like 1234567890',
          }));
        } else if (error.code === 'InvalidPasswordException') {
          setFormErrors((prevState) => ({
            ...prevState,
            password: 'Please enter a password with 8+ characters/digit',
          }));
        } else if (error.code === 'UsernameExistsException') {
          setFormErrors((prevState) => ({
            ...prevState,
            username: 'The username already exists',
          }));
        } else {
          alert(error);
          // alert("Woops! Due to network outage registration is unavailable.");
        }
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [name]: '',
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));
  };

  const validateForm = () => {
    const errors = {};

    if (formData.username.trim() === '') {
      errors.username = 'Username is required';
    }

    if (formData.email.trim() === '') {
      errors.email = 'Email is required';
    }

    if (formData.phoneNumber.trim() === '') {
      errors.phoneNumber = 'Phone number is required';
    }

    if (formData.password.trim() === '') {
      errors.password = 'Password is required';
    }

    if (formData.reenterPassword.trim() === '') {
      errors.reenterPassword = 'Please reenter your password';
    } else if (formData.reenterPassword !== formData.password) {
      errors.reenterPassword = 'Passwords do not match';
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  function CustomInputComponent() {
    return (
      <Input
        value={formData.phoneNumber}
        onChange={handleChange}
        name="phoneNumber"
        id="formatted-text-mask-input"
        inputComponent={TextMaskCustom}
      />
    );
  }

  return (
    <Section
      content={
        <div className="tw-w-full tw-bg-transparent tw-p-4 tw-border-none md:tw-w-3/4 lg:tw-w-2/3 xl:tw-w-1/2 sm:tw-border-solid sm:tw-bg-surface-raised sm:tw-p-16 tw-rounded-lg sm:tw-border sm:tw-border-surface-border">
          <div className="tw-flex tw-flex-col tw-gap-y-4 tw-mb-10 tw-text-string-base">
            <div>
              <div className="tw-text-3xl tw-font-semibold">Register</div>
              <div className="tw-text-string-neutral">Stay ahead of saving</div>
            </div>
            {/*
            <div className="tw-text-center">Register with</div>
            <div className="tw-flex tw-justify-center tw-gap-x-4">
              <img
                src="/google_logo.png"
                alt="Register with Google"
                onClick={() => {
                  alert('Coming Soon. Thank you!');
                }}
                className="tw-transition-all hover:tw-opacity-75 hover:tw-shadow-md hover:tw-cursor-pointer tw-p-4 tw-h-20 tw-w-20 tw-rounded-full tw-bg-[#EFF6FF]"
              />
              <img
                src="/facebook_logo.png"
                alt="Register with Facebook"
                onClick={() => {
                  alert('Coming Soon. Thank you!');
                }}
                className="tw-transition-all hover:tw-opacity-75 hover:tw-shadow-md hover:tw-cursor-pointer tw-p-4 tw-h-20 tw-w-20 tw-rounded-full tw-bg-[#EFF6FF]"
              />
            </div>
            <div className="tw-text-center">or</div>
            */}
          </div>

          <div>
            <form className="tw-flex tw-flex-col tw-gap-y-4">
              <TextField
                name="username"
                label="Username"
                type="text"
                value={formData.username}
                onChange={handleChange}
                error={!!formErrors.username}
                helperText={formErrors.username || ' '}
              />
              <TextField
                name="email"
                label="Email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                error={!!formErrors.email}
                helperText={formErrors.email || ' '}
              />
              <TextField
                name="phoneNumber"
                label="Phone Number"
                placeholder="Please enter a 10-digit-US-phone number" // "Example: +12127363100"
                type="text"
                value={formData.phoneNumber}
                onChange={handleChange}
                error={!!formErrors.phoneNumber}
                helperText={formErrors.phoneNumber || ' '}
              />
              {/*
              <InputLabel htmlFor="formatted-text-mask-input">phone number</InputLabel>
              <Input
                value={formData.phoneNumber}
                onChange={handleChange}
                name="phoneNumber"
                id="formatted-text-mask-input"
                inputComponent={TextMaskCustom}
              />
              */}
              <PasswordTextField
                name="password"
                label="Password"
                value={formData.password}
                onChange={handleChange}
                error={!!formErrors.password}
                helperText={formErrors.password || ' '}
              />
              <PasswordTextField
                name="reenterPassword"
                label="Reenter Password"
                value={formData.reenterPassword}
                onChange={handleChange}
                error={!!formErrors.reenterPassword}
                helperText={formErrors.reenterPassword || ' '}
              />
              <div className="tw-flex tw-items-center">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.agreeToTerms}
                        onChange={handleChange}
                        name="agreeToTerms"
                      />
                    }
                    label={
                      <div className="tw-text-xs tw-text-string-neutral">
                        I am at least 13 years old and agree to Cookies Policy,
                        Disclaimer, Privacy Policy, Terms & Conditions
                      </div>
                    }
                  />
                </FormGroup>
              </div>

              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                onClick={handleSubmit}
                disabled={!formData.agreeToTerms}
                loading={loading}
                loadingPosition="end"
                fullWidth
              >
                Register
              </LoadingButton>
            </form>
            <div className="tw-text-string-primary tw-flex tw-justify-between tw-text-xs xs:tw-text-sm md:tw-text-base">
              <Link to="/login" state={location.state}>
                Already have an account?
              </Link>
              <Link to="/reset-password" state={location.state}>
                Forgot password?
              </Link>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default Register;
