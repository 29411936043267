import AddRoundedIcon from '@mui/icons-material/AddRounded';

import { Avatar, Collapse } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import React, { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppContext_ } from './AppContextProvider';

function BottomNavigation() {
  const { theme } = useAppContext_();
  const { pathname } = useLocation();
  const [action, setAction] = useState('default');
  const [page, setPage] = useState('home');
  const navigate = useNavigate();
  const { user, setUser } = useAppContext_();
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (pathname.includes('deal-details')) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [pathname]);

  const switchIcon = () => {
    // alert(event.target.innerText);
    if (theme === 'light') {
      if (user && user.attributes.picture) {
        // `${user?.attributes?.picture}?${Date.now()}`
        return `${user?.attributes?.picture}?${Date.now()}`;
      } else {
        return '/profileOutlined.svg';
      }
    } else {
      if (user && user.attributes.picture) {
        // `${user?.attributes?.picture}?${Date.now()}`
        return `${user?.attributes?.picture}?${Date.now()}`;
      } else {
        return '/profileOutlinedDark.svg';
      }
    }
  };

  const switchFilledIcon = () => {
    if (theme === 'light') {
      if (user && user.attributes.picture) {
        // `${user?.attributes?.picture}?${Date.now()}`
        return `${user?.attributes?.picture}?${Date.now()}`;
      } else {
        return '/profileFilled.svg';
      }
    } else {
      if (user && user.attributes.picture) {
        // `${user?.attributes?.picture}?${Date.now()}`
        return `${user?.attributes?.picture}?${Date.now()}`;
      } else {
        return '/profileFilledDark.svg';
      }
    }
  };

  const navigation = [
    {
      label: 'Home',
      inactiveIcon:
        theme === 'light' ? '/homeOutlined.svg' : '/homeOutlinedDark.svg',
      activeIcon: theme === 'light' ? '/homeFilled.svg' : '/homeFilledDark.svg',
      name: 'home',
      route: '/',
    },
    {
      label: 'Deals',
      inactiveIcon:
        theme === 'light' ? '/dealsOutlined.svg' : '/dealsOutlinedDark.svg',
      activeIcon:
        theme === 'light' ? '/dealsFilled.svg' : '/dealsFilledDark.svg',
      name: 'deals',
      route: '/deals',
    },
    {
      label: 'In-Store',
      inactiveIcon:
        theme === 'light' ? '/instoreOutlined.svg' : '/instoreOutlinedDark.svg',
      activeIcon:
        theme === 'light' ? '/instoreFilled.svg' : '/instoreFilledDark.svg',
      name: 'map',
      route: '/map',
    },
    {
      label: 'Me',
      inactiveIcon: switchIcon(),
      // theme === 'light' ? '/profileOutlined.svg' : '/profileOutlinedDark.svg',
      activeIcon: switchFilledIcon(),
      // theme === 'light' ? '/profileFilled.svg' : '/profileFilledDark.svg',
      name: 'profile',
      route: '/profile',
    },
  ];

  const handleClick = (item) => {
    setPage(item.name);
    navigate(item.route);
  };

  return (
    <div className="tw-fixed tw-z-50 tw-bottom-0 tw-w-full lg:tw-hidden">
      <Collapse in={show} timeout="auto">
        <div className="tw-flex tw-justify-evenly tw-border-t tw-border-t-surface-border tw-bg-surface-raised tw-py-3">
          {navigation.map((item, index) => (
            <Fragment key={item.name}>
              {index === Math.floor(navigation.length / 2) && (
                <Link
                  to="/share-deal"
                  className="tw-flex tw-justify-center tw-items-center tw-w-16"
                >
                  {/* <div className="tw-bg-blue-600 tw-w-12 tw-h-12 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-white"> */}
                  <div className="tw-bg-blue-600 tw-w-[39px] tw-h-[39px] tw-rounded-full tw-flex tw-justify-center tw-items-center tw-text-white">
                    <AddRoundedIcon />
                  </div>
                </Link>
              )}
              <div
                className={`tw-w-16 tw-h-fit tw-flex tw-flex-col tw-gap-y-1 tw-items-center tw-text-[10px] tw-text-string-base dark:tw-text-[#676D75] tw-no-underline ${
                  page === item.name && 'tw-font-semibold'
                }`}
                onClick={() => handleClick(item)}
              >
                {index === 3 && user && user.attributes.picture ? (
                  <Avatar
                    alt="profile_img"
                    src={`${user?.attributes?.picture}?${Date.now()}`}
                    sx={{ width: 20, height: 20 }}
                  />
                ) : (
                  <img
                    src={
                      page === item.name ? item.activeIcon : item.inactiveIcon
                    }
                    alt={item.label}
                    className="tw-h-5 tw-w-5"
                  />
                )}
                {item.label}
              </div>
            </Fragment>
          ))}
        </div>
      </Collapse>
    </div>
  );
}

export default BottomNavigation;
