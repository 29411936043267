import { InfoWindow, Marker } from '@react-google-maps/api';
import React, { useEffect, useRef, useState } from 'react';
import { storeIcon } from '../constants/StoreConstants';
import OnMapDealCard from './OnMapDealCard';
export default function MapStoreMarker({
  position,
  placeId,
  dealIds,
  dealId2Details,
  storeName,
  clusterer,
  index,
  selectedMarker,
  setSelectedMarker,
  hoveredMarker,
  setHoveredMarker,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const markerRef = useRef();

  const handleClick = () => {
    setSelectedMarker(placeId);
  };

  useEffect(() => {
    if (hoveredMarker !== null && hoveredMarker === index) {
      setIsOpen(true);
      return;
    }
    setIsOpen(false);
  }, [selectedMarker, hoveredMarker]);

  return (
    <>
      <Marker
        ref={markerRef}
        position={position}
        onClick={handleClick}
        clusterer={clusterer}
        metadata={{ dealIds }}
        onMouseOver={() => setHoveredMarker(index)}
        icon={{
          url: storeIcon[storeName]
            ? storeIcon[storeName]
            : '/map_location_dot.svg',
          scaledSize: new window.google.maps.Size(50, 50),
        }}
        zIndex={2}
      >
        {isOpen && dealIds && (
          <InfoWindow
            onCloseClick={() => setIsOpen(false)}
            onMouseOver={() => setHoveredMarker(index)}
          >
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {dealIds.map((dealId, index) => (
                <OnMapDealCard
                  key={index}
                  deal={dealId2Details[dealId]}
                  index={index}
                />
              ))}
            </div>
          </InfoWindow>
        )}
      </Marker>
      {/* {markerRef.current && (
                <OverlayView
                    position={position}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    zIndex={1}
                >
                    <div
                        style={{
                            backgroundColor: 'red',
                            color: 'white',
                            padding: '5px',
                            borderRadius: '50%',
                            width: '20px',
                            height: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                            top: '-55px',
                            left: '12px',
                            fontWeight: 'bold'
                        }}
                    >
                        {dealIds.length}
                    </div>
                </OverlayView>
            )} */}
    </>
  );
}
