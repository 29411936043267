import { API, Auth, Storage } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { useAppContext_ } from '../../AppContextProvider';
import { changePreferredName } from '../../graphql/mutations';
import { AUTH_MODE } from '../constants/AppSyncAuthMode';

function useProfileApis() {
  const REACT_APP_PROFILE_BUCKET_NAME =
    process.env.REACT_APP_PROFILE_BUCKET_NAME;

  const { user, setUser, getUser } = useAppContext_();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      console.log('logout');
      await Auth.signOut();
      setUser(null);
      //
      //await DataStore.clear();
      // await DataStore.start();
      localStorage.removeItem('federatedUsernameChanged');
      navigate('/');
    } catch (err) {
      console.log(err);
      navigate('/')
    }
  };

  const handleFileUpload = async (profilePic) => {
    try {
      if (profilePic !== '') {
        Storage.configure({ region: 'us-east-1' });
        if (profilePic) {
          const cur_uuid = user?.attributes?.sub;
          const { key } = await Storage.put(
            `profile-img/${cur_uuid}.png`,
            profilePic,
            { contentType: 'image/*' }
          );
          const profilePicPathURI = `https://${REACT_APP_PROFILE_BUCKET_NAME}.s3.amazonaws.com/public/${key}`;

          await Auth.updateUserAttributes(user, {
            picture: profilePicPathURI,
          });
          await getUser();
          window.location.reload(true);
        } else {
          alert(
            'Please re-select a valid profile image or leave the image field blank. Thank you for your patience!'
          );
        }
      } else {
        alert(
          'Submiting this form will remove your current profile img and a default icon will be shown'
        );
      }
    } catch (err) {
      alert(err);
    }
  };

  const sendVerificationCode = async () => {
    try {
      await Auth.forgotPassword(user?.username);
    } catch (err) {
      console.log(err);
    }
  };

  const resetPassword = async (verificationCode, newPassword) => {
    try {
      await Auth.forgotPasswordSubmit(
        user.username,
        verificationCode,
        newPassword
      );
      alert('Password has been reset. Thank you!');
      navigate('/login');
      // console.log(await Auth.forgotPasswordSubmit(user.username, verificationCode, newPassword))
    } catch (err) {
      if (err.code === 'InvalidPasswordException') {
        alert('Please enter a password with 8+ characters/digit');
      } else {
        alert(err);
      }
    }
  };

  const savePreferredName = async (newPreferredName) => {
    try {
      const response = await API.graphql({
        query: changePreferredName,
        variables: { newPreferredName: newPreferredName },
        authMode: AUTH_MODE.USER_POOLS,
      });
      console.log('response: ', response);
      if (response) {
        // Parse the response string correctly
        const res = parseResponse(response.data.changePreferredName);
        if (res.statusCode === '200') {
          const result = await Auth.updateUserAttributes(user, {
            preferred_username: newPreferredName,
          });
          getUser();
          return true;
        } else {
          alert(res.message);
          return false;
        }
      }
    } catch (error) {
      console.error('Error changing Preferred Username: ', error);
      alert('Error changing Preferred Username: ', error);
    }
    return false;
  };

  // Custom function to parse the response string
  function parseResponse(responseString) {
    const obj = {};
    const keyValuePairs = responseString.slice(1, -1).split(', ');
    keyValuePairs.forEach((pair) => {
      const [key, value] = pair.split('=');
      obj[key] = value;
    });
    return obj;
  }

  return {
    handleFileUpload: handleFileUpload,
    sendVerificationCode: sendVerificationCode,
    resetPassword: resetPassword,
    handleLogout: handleLogout,
    savePreferredName: savePreferredName,
  };
}

export default useProfileApis;
