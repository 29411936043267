import { API } from 'aws-amplify';
import { AUTH_MODE } from '../components/constants/AppSyncAuthMode';
import {
  createAlertSettings,
  deleteAlertSettings,
  lightningDealAlertSubscribe,
  topDealAlertSubscribe,
  updateAlertSettings,
} from '../graphql/mutations';
import {
  listAlertSettings,
  listLightningDealAlertSettings,
  listTopDealAlertSettings,
} from '../graphql/queries';

export async function getAlertsApi(user_id) {
  const alerts = await API.graphql({
    query: listAlertSettings,
    variables: {
      filter: {
        user_id: { eq: user_id },
        or: [
          { _deleted: { attributeExists: false } },
          { _deleted: { eq: false } },
        ],
      },
    },
    authMode: AUTH_MODE.USER_POOLS,
  });

  return alerts.data.listAlertSettings.items;
}

export async function postCreateAlertApi(alert) {
  const alert_ = await API.graphql({
    query: createAlertSettings,
    variables: { input: alert },
    authMode: AUTH_MODE.USER_POOLS,
  });

  return alert_.data.createAlertSettings;
}

export async function postDeleteAlertApi(alert) {
  const alert_ = await API.graphql({
    query: deleteAlertSettings,
    variables: {
      input: {
        id: alert.id,
        _version: alert._version,
      },
    },
    authMode: AUTH_MODE.USER_POOLS,
  });

  return alert_.data.deleteAlertSettings;
}

export async function postEditAlertApi(alert) {
  const alert_ = await API.graphql({
    query: updateAlertSettings,
    variables: { id: alert.id, input: alert },
    authMode: AUTH_MODE.USER_POOLS,
  });

  return alert_.data.updateAlertSettings;
}

export async function getTopDealAlertApi(user_id) {
  const alert = await API.graphql({
    query: listTopDealAlertSettings,
    variables: {
      filter: {
        user_id: {
          eq: user_id,
        },
        _deleted: {
          ne: true,
        },
      },
    },
    authMode: AUTH_MODE.USER_POOLS,
  });

  if (alert.data.listTopDealAlertSettings.items.length > 0) {
    return alert.data.listTopDealAlertSettings.items[0];
  } else {
    return null;
  }
}

export async function getLightningDealAlertApi(user_id) {
  const alert = await API.graphql({
    query: listLightningDealAlertSettings,
    variables: {
      filter: {
        user_id: {
          eq: user_id,
        },
        _deleted: {
          ne: true,
        },
      },
    },
    authMode: AUTH_MODE.USER_POOLS,
  });

  if (alert.data.listLightningDealAlertSettings.items.length > 0) {
    return alert.data.listLightningDealAlertSettings.items[0];
  } else {
    return null;
  }
}

export async function postEnableTopDealAlertApi() {
  await API.graphql({
    query: topDealAlertSubscribe,
    variables: {
      enabled: true,
      notification_email: true,
      notification_cell: true,
      notification_app: true,
      frequency_instant: true,
      frequency_daily: false,
      frequency_weekly: false,
    },
    authMode: AUTH_MODE.USER_POOLS,
  });
}

export async function postDisableTopDealAlertApi() {
  await API.graphql({
    query: topDealAlertSubscribe,
    variables: {
      enabled: false,
      notification_email: true,
      notification_cell: true,
      notification_app: true,
      frequency_instant: true,
      frequency_daily: false,
      frequency_weekly: false,
    },
    authMode: AUTH_MODE.USER_POOLS,
  });
}

export async function postEnableLightningDealAlertApi() {
  await API.graphql({
    query: lightningDealAlertSubscribe,
    variables: {
      enabled: true,
      notification_email: true,
      notification_cell: true,
      notification_app: true,
      frequency_instant: true,
      frequency_daily: false,
      frequency_weekly: false,
    },
    authMode: AUTH_MODE.USER_POOLS,
  });
}

export async function postDisableLightningDealAlertApi() {
  await API.graphql({
    query: lightningDealAlertSubscribe,
    variables: {
      enabled: false,
      notification_email: true,
      notification_cell: true,
      notification_app: true,
      frequency_instant: true,
      frequency_daily: false,
      frequency_weekly: false,
    },
    authMode: AUTH_MODE.USER_POOLS,
  });
}
