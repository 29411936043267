import { Button } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';

function Share({ deal_link }) {
  const [copied, setCopied] = useState('Copy');

  const platforms = [
    {
      name: 'Discord',
      link: 'https://discord.com/',
      img: '/discord_logo.png',
    },
    {
      name: 'Gmail',
      link: 'https://mail.google.com/',
      img: '/google_logo.png',
    },
    {
      name: 'Facebook',
      link: 'https://www.facebook.com/',
      img: '/facebook_logo.png',
    },
    {
      name: 'Whatsapp',
      link: 'https://web.whatsapp.com/',
      img: '/whatsapp_logo.png',
    },
  ];

  const handleShare = () => {
    setCopied('Copied!');
    navigator.clipboard.writeText(deal_link);
  };

  return (
    <div className="tw-p-4 md:tw-p-5 md:tw-pl-7 tw-gap-y-7 tw-flex tw-flex-col tw-h-[65vh] tw-w-full md:tw-h-[500px] md:tw-w-[80vw]  lg:tw-h-[500px] lg:tw-w-[50vw]">
      <h1 className="tw-text-lg md:tw-text-xl tw-mb-0 tw-font-semibold">
        Step1: Copy the deal url
      </h1>
      <div
        className="hover:tw-cursor-pointer tw-p-2 tw-rounded-md tw-border tw-border-black tw-flex tw-justify-between tw-items-center"
        onClick={handleShare}
      >
        <div className="tw-text-sm md:tw-text-base tw-w-[95%] tw-p-1 tw-truncate">
          {deal_link}
        </div>
        <Button
          className="tw-w-[30%] md:tw-w-[20%]"
          variant="contained"
          size="large"
          color={copied === 'Copy' ? 'primary' : 'success'}
        >
          <div className="tw-text-sm-button md:tw-text-base">{copied}</div>
        </Button>
      </div>
      <h1 className="tw-text-lg md:tw-text-xl tw-mb-0 tw-font-semibold">
        Step 2: Share the url to
      </h1>
      <div className="tw-flex tw-flex-wrap">
        {platforms.map((entry, index) => (
          <Link
            onClick={handleShare}
            to={entry.link}
            target="_blank"
            className="tw-flex-1 hover:tw-rounded-md hover:tw-bg-surface-base hover:tw-opacity-80 tw-transition-all tw-p-4 tw-text-string-base tw-underline tw-underline-offset-2 tw-flex tw-flex-col tw-items-center"
            key={index}
          >
            <img
              src={entry.img}
              className="tw-h-12 tw-w-12 md:tw-h-20 md:tw-w-20 lg:tw-h-24 lg:tw-w-24 tw-mb-4 tw-object-contain"
            />
            <div>{entry.name}</div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Share;