import * as React from 'react';

import { useEffect, useState } from 'react';

import { API, Auth } from 'aws-amplify';
import { deleteDeal } from '../../graphql/mutations';
import { dealsByPoster_idAndUpdated_date } from '../../graphql/queries';
import { AUTH_MODE } from '../constants/AppSyncAuthMode';

import { useNavigate } from 'react-router-dom';

import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { useAppContext_ } from '../../AppContextProvider';
// import HorizontalPostGrid from '../bookmarks/HorizontalPostGrid';
import VerticalPostGrid from '../bookmarks/VerticalPostGrid';

function MySharedDealListPage(props) {
  const [dealList, setDealList] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userUuid, setUserUuid] = useState(null);
  const [myUsername, setMyUsername] = useState(null);
  const navigate = useNavigate();

  const { HomeCategoryValue, setHomeCategoryValue } = useAppContext_();

  useEffect(() => {
    loadDealDetails();
    setHomeCategoryValue();
  }, []);

  const loadDealDetails = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const userUuid = user.attributes.sub;
    //console.log("userUuid: " + userUuid)
    const myDealList = await fetchDealList(userUuid);

    setDealList(myDealList);
    setUserUuid(user.attributes.sub);
    setMyUsername(user.username);
    setIsLoading(false);
  };

  const generateLastChangedDate = () => {
    const date = new Date();
    return date.toISOString().split('T')[0];
  };

  const handleDelete = async (myDeal) => {
    //console.log("deal to delete: ")
    //console.log(myDeal)
    if (window.confirm('Are you sure to delete?')) {
      try {
        await API.graphql({
          query: deleteDeal,
          variables: { input: { id: myDeal.id, _version: myDeal._version } },
          authMode: AUTH_MODE.USER_POOLS,
        });
        alert('Deal Deleted!');
      } catch (error) {
        console.error('Error deleting deal: ', error);
        alert('Error deleting deal: ', error);
      }
    }
  };

  const fetchDealList = async (userUuid) => {
    try {
      const dealData = await API.graphql({
        query: dealsByPoster_idAndUpdated_date,
        variables: {
          poster_id: userUuid,
          filter: {
            or: [
              { _deleted: { attributeExists: false } },
              { _deleted: { eq: false } },
            ],
          },
          limit: 100,
          sortDirection: 'DESC',
        },
        authMode: AUTH_MODE.USER_POOLS,
      });
      //console.log(dealData.data.dealsByPoster_idAndUpdated_date.items)
      const res = dealData.data.dealsByPoster_idAndUpdated_date.items;
      res.sort((a, b) =>
        String(b.updatedAt).localeCompare(String(a.updatedAt))
      );
      return res;
    } catch (error) {
      console.error('Error fetching deal details: ', error);
    }
  };

  if (isLoading) {
    return (
      <Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            px: 4,
            my: 5,
          }}
        >
          <Grid item xs={12}>
            <Typography variant="h4" className="!tw-text-string-base">
              Shared Deals
            </Typography>
          </Grid>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            px: 4,
            my: 5,
          }}
        >
          <Grid item xs={12}>
            <Typography variant="body1">loading...</Typography>
          </Grid>
        </Box>
        <Box style={{ height: '400px' }} />
      </Container>
    );
  }

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              px: 4,
              my: 3,
            }}
          >
            <Typography variant="h4" className="!tw-text-string-base">
              Shared Deals
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              px: 2,
              my: 1,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ margin: '5px' }}
              onClick={() => navigate('/share-deal')}
            >
              Add a Deal
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{ margin: '5px' }}
              onClick={() => navigate('/deals')}
            >
              All Deals
            </Button>
          </Box>
        </Grid>
      </Grid>
      <VerticalPostGrid posts={dealList} />
      <Typography
        variant="body1"
        sx={{ mt: 2, mb: 8, textAlign: 'center' }}
        className="!tw-text-string-base"
      >
        {'It will only display the most 100 recent deals shared,'}
        <br />
        {'for more, please use the filter to search on the Deals page'}
        <br />
        {'and edit them through there.'}
      </Typography>
      {/* <Fab size="medium" color="primary" aria-label="add" onClick={() => navigate("/sharedeal")} style={{ position: 'fixed', bottom: 100, right: 60 }}>
                <AddIcon />
            </Fab> */}
    </div>
  );
}

export default MySharedDealListPage;