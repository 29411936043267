import * as React from 'react';

import { useState } from 'react';

import { Box, Button, Card, CardContent, TextField } from '@mui/material';

export function ApiGetAffiliateLink(props) {
  const [dealLink, setDealLink] = useState('');
  const [title, setTitle] = useState('');

  return (
    <Card sx={{ bgcolor: '#f5f5f5', width: '100%' }}>
      <CardContent>
        Affiliate Link API
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <TextField
            sx={{ margin: 1 }}
            type="text"
            value={dealLink}
            onChange={(e) => setDealLink(e.target.value)}
            placeholder="url"
          />
          <TextField
            sx={{ margin: 1 }}
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Title"
          />
          <Button
            variant="contained"
            color="primary"
            sx={{ margin: 1, textTransform: 'none' }}
            // onClick={() => getAffiliateLinkHandler()}
          >
            get affiliate link
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}
