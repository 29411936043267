import { LoadingButton } from '@mui/lab';
import { Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { postVerifySendCodeApi, postVerifySignupApi } from '../../api/account';
import { ReactComponent as LockIcon } from '../../icons/lock.svg';
import Section from '../home/Section';

function Verify() {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParameters] = useSearchParams();

  const [formData, setFormData] = useState({
    authenticationCode: '',
  });

  const [formErrors, setFormErrors] = useState({
    authenticationCode: '',
  });

  const [verification, setVerification] = useState({
    isDisabled: false,
    countDown: 30,
  });

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isValidForm()) {
      try {
        setLoading(true);
        await postVerifySignupApi(
          searchParameters.get('username'),
          formData.authenticationCode
        );

        if (location.state) {
          alert('Verification Succeeded!');
          navigate('/login', { state: location.state });
        } else {
          alert('Verification Succeeded!');
          navigate('/login');
        }
      } catch (error) {
        setLoading(false);
        setFormErrors((prevState) => ({
          ...prevState,
          authenticationCode: 'Invalid verification code provided',
        }));
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [name]: '',
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const isValidForm = () => {
    const errors = {};

    if (formData.authenticationCode.trim() === '') {
      errors.authenticationCode = 'Authentication code is required';
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleCode = async () => {
    try {
      await postVerifySendCodeApi(searchParameters.get('username'));
    } catch {}
    setVerification((prevState) => ({
      ...prevState,
      isDisabled: true,
    }));
  };

  useEffect(() => {
    let timer;
    if (verification.isDisabled && verification.countDown > 0) {
      //window.scrollTo(0,0);
      timer = setTimeout(() => {
        setVerification((prevState) => ({
          ...prevState,
          countDown: prevState.countDown - 1,
        }));
      }, 1000);
    } else if (verification.countDown === 0) {
      //window.scrollTo(0,0);
      setVerification({
        isDisabled: false,
        countDown: 30,
      });
    }
    return () => clearTimeout(timer);
  }, [verification]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Section
      content={
        <div className="tw-w-full tw-bg-transparent tw-p-4 tw-border-none md:tw-w-3/4 lg:tw-w-2/3 xl:tw-w-1/2 sm:tw-border-solid sm:tw-bg-surface-raised sm:tw-p-16 tw-rounded-lg sm:tw-border sm:tw-border-surface-border">
          <div className="tw-flex tw-flex-col tw-gap-y-4 tw-mb-10 tw-items-center">
            <LockIcon className="tw-text-string-primary tw-bg-[rgba(48,84,245,0.13)] tw-rounded-xl tw-p-2 tw-h-11 tw-w-11 tw-mb-5" />
            <div>
              <div className="tw-text-3xl tw-font-semibold tw-text-string-base">
                Enter Verification Code
              </div>
              <div className="tw-text-string-neutral">
                Please check your email inbox or trash bin for the verification
                code. The email will be sent from
                "no-reply@verificationemail.com"
              </div>
            </div>
          </div>

          <div>
            <form className="tw-flex tw-flex-col tw-gap-y-4">
              <TextField
                name="authenticationCode"
                label="Authentication Code"
                value={formData.authenticationCode}
                onChange={handleChange}
                error={!!formErrors.authenticationCode}
                helperText={formErrors.authenticationCode || ' '}
              />
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                onClick={handleSubmit}
                loading={loading}
                loadingPosition="end"
                fullWidth
              >
                Validate
              </LoadingButton>
              <Button
                size="large"
                type="button"
                variant="text"
                color="primary"
                onClick={handleCode}
                disabled={verification.isDisabled}
              >
                {verification.isDisabled
                  ? `Get Code (${verification.countDown})`
                  : 'Get Code'}
              </Button>
            </form>
          </div>
        </div>
      }
    />
  );
}

export default Verify;
