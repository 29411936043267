import { AppBar, IconButton, Toolbar } from '@mui/material';

import { KeyboardArrowLeftRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useAppContext_ } from '../../AppContextProvider';
import Search from '../header/Search';
function TopAction({ expired, handleExpired, report, handleReport }) {
  const navigate = useNavigate();
  const { pathHistory } = useAppContext_();

  const handleGoBack = () => {
    if (
      pathHistory.previous.includes('share-deal') ||
      pathHistory.previous.includes('DealEditor') ||
      pathHistory.previous.includes('/')
    ) {
      navigate('/deals');
    } else {
      navigate(-1);
    }
  };

  return (
    <AppBar
      position="absolute"
      sx={{
        color: 'black',
        boxShadow: 'none',
        backgroundImage: '',
        width: '100vw',
      }}
      className="!tw-bg-surface-raised !tw-bg-none !tw-top-0 !tw-w-screen !tw-block sm:!tw-hidden"
    >
      <Toolbar className="!tw-w-screen !tw-py-2 !tw-px-0 !tw-min-h-0">
        <div className="tw-flex tw-text-nowrap tw-items-center tw-w-screen tw-gap-x-5 tw-pl-2 tw-pr-4">
          <div className="tw-flex tw-flex-1 tw-gap-x-1">
            <IconButton onClick={handleGoBack} sx={{ padding: '2px' }}>
              <KeyboardArrowLeftRounded className="!tw-text-string-base !tw-text-4xl" />
            </IconButton>
            <Search />
          </div>
          {/* <div className='tw-flex tw-gap-5'> */}
          <div
            className="tw-flex tw-flex-col tw-text-string-primary tw-items-center tw-font-semibold tw-text-xs"
            onClick={handleExpired}
          >
            {expired !== null ? (
              <img
                src="/expiredGradientOrange.svg"
                className="!tw-h-[30px] tw-p-1"
              />
            ) : (
              <img
                src="/expiredGradientBlue.svg"
                className="tw-h-[30px] tw-p-1"
              />
            )}
            <span
              className=""
              style={{
                backgroundClip: 'text',
                color: 'transparent',
                backgroundImage:
                  expired !== null
                    ? 'linear-gradient(180deg, #FF5F5C 0%, #F49D23 100%)'
                    : 'linear-gradient(180deg, #3054F5 0%, #2196F3 100%)',
              }}
            >
              Expired
            </span>
          </div>
          <div
            className="tw-flex tw-flex-col tw-text-string-primary tw-items-center tw-font-semibold tw-text-xs"
            onClick={handleReport}
          >
            {report !== null ? (
              <img
                src="/reportGradientYellow.svg"
                className="tw-h-[30px] tw-p-1"
              />
            ) : (
              <img
                src="/reportGradientBlue.svg"
                className="!tw-h-[30px] tw-p-1"
              />
            )}
            <span
              style={{
                backgroundClip: 'text',
                color: 'transparent',
                backgroundImage:
                  report !== null
                    ? 'linear-gradient(180deg, #8F8A00 0%, #E3B400 100%)'
                    : 'linear-gradient(180deg, #3054F5 0%, #2196F3 100%)',
              }}
            >
              Report
            </span>
          </div>
          {/* </div> */}
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default TopAction;
