import '@aws-amplify/ui-react/styles.css';
import { Amplify } from 'aws-amplify';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import config from './aws-exports';

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from "./App";
import { BrowserRouter } from 'react-router-dom';
import App__ from './App__';

Amplify.configure(config);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App__ />
  </BrowserRouter>
);
