import {
  KeyboardArrowRightRounded,
  ThumbDownOutlined,
  ThumbDownTwoTone,
  ThumbUpOutlined,
  ThumbUpTwoTone,
} from '@mui/icons-material';
import {
  Button,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import React, { Link } from 'react-router-dom';

function BottomAction({
  deal,
  bookmark,
  handleAddBookmark,
  upvoted,
  downvoted,
  handleVote,
}) {
  return (
    <div className="tw-fixed tw-z-50 tw-bottom-0 tw-w-full sm:tw-hidden">
      <div className="tw-flex tw-border-t tw-border-t-surface-border tw-bg-surface-raised tw-gap-x-4 tw-pt-1 tw-px-3">
        <table className="">
          <tbody>
            <tr className="">
              <td className="tw-pr-4 tw-py-0">
                <div
                  className="tw-p-[2px] tw-rounded-md"
                  style={{
                    background:
                      'linear-gradient(180deg, #3054F5 0%, #2196F3 100%)',
                  }}
                >
                  <ToggleButtonGroup
                    value={upvoted ? 'Upvoted' : 'Downvoted'}
                    onChange={handleVote}
                    className="tw-gap-x-[3px]"
                    exclusive
                  >
                    <ToggleButton
                      value="upvoted"
                      className={`${
                        upvoted ? '!tw-bg-green-200' : '!tw-bg-surface-raised'
                      } !tw-border-none !tw-p-[6px] !tw-px-[8px]`}
                    >
                      {upvoted ? (
                        <ThumbUpTwoTone className="!tw-text-2xl !tw-text-green-700" />
                      ) : (
                        <ThumbUpOutlined className="!tw-text-2xl !tw-text-string-primary" />
                      )}
                    </ToggleButton>
                    <ToggleButton
                      value="downvoted"
                      className={`${
                        downvoted ? '!tw-bg-red-100' : '!tw-bg-surface-raised'
                      } !tw-border-none !tw-p-[6px] !tw-px-[8px]`}
                    >
                      {downvoted ? (
                        <ThumbDownTwoTone className="!tw-text-2xl !tw-text-string-secondary" />
                      ) : (
                        <ThumbDownOutlined className="!tw-text-2xl !tw-text-string-primary" />
                      )}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </td>
              <td className="tw-h-0 tw-text-center">
                <IconButton onClick={handleAddBookmark}>
                  {bookmark !== null ? (
                    <img src="/bookmarkGradientFilledBlue.svg" className="" />
                  ) : (
                    <img src="/bookmarkGradientBlue.svg" className="" />
                  )}
                </IconButton>
              </td>
            </tr>
            <tr className="tw-text-[10px] tw-text-string-primary tw-font-medium tw-text-center">
              <td className="tw-pr-4">Good deal?</td>
              <td>Bookmark</td>
            </tr>
          </tbody>
        </table>
        {/* <div className=""> */}
        <Button
          component={Link}
          to={deal.affiliate_link ? deal.affiliate_link : deal.deal_link}
          fullWidth
          color="primary"
          variant="contained"
          sx={{
            textTransform: 'none',
            background:
              'linear-gradient(90deg, #2D3EFE 0%, #429F97 44.5%, #A77EEA 99%)',
          }}
          className="!tw-my-1 !tw-border-none"
        >
          <div className="tw-flex tw-flex-col tw-items-center">
            <span className="tw-text-xs">${deal.price}</span>
            <span className="tw-text-lg tw-leading-4 tw-whitespace-nowrap">
              View the deal
              <KeyboardArrowRightRounded />
            </span>
          </div>
        </Button>
        {/* </div> */}
      </div>
    </div>
  );
}

export default BottomAction;
