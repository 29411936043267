import React from 'react';
import { Link } from 'react-router-dom';

export default function OnMapDealCard({ deal, index }) {
  return (
    <div
      key={index}
      style={{
        position: 'relative',
        borderRadius: '0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Link to={`/deal-details/${deal?.id}`}>
        <div>
          <img
            src={deal?.img_link}
            alt={deal?.title}
            style={{ width: '120px', height: '120px', objectFit: 'contain' }}
          />
          <div
            style={{
              position: 'absolute',
              bottom: '0%',
              right: '0%',
              color: 'red',
              fontWeight: 'bold',
              backgroundColor: 'white',
              padding: '3px',
              opacity: 0.87,
            }}
          >
            <h6>${deal?.price}</h6>
          </div>
        </div>
      </Link>
    </div>
  );
}
