import React from "react";
import { API } from "aws-amplify";
import { createBookmark, deleteBookmark } from "./mutations";
import { useAppContext_ } from "../AppContextProvider";
import { AUTH_MODE } from "../components/constants/AppSyncAuthMode";
import { bookmarksByUser_id } from "./queries";
import { useNavigate } from "react-router-dom";

function useGraphql() {
  const { user } = useAppContext_();
  const navigate = useNavigate();

  /**
   * Creates a bookmark for a specific deal and user.
   *
   * @param {string} id - The ID of the deal for which the bookmark is to be created.
   * @returns {Promise<object>} A Promise that resolves to the created bookmark object.
   *
   * Example of returned bookmark object:
   * {
   *    "id": "2087ecf1-9144-4747-b7dc-01b3ee8ada89",
   *    "user_id": "1eaf76f0-c77e-4f4e-8fa6-90e13999edfc",
   *    "deal_id": "6599a167-b598-4c31-9bee-10a1763e78d0",
   *    "owner": "anthony",
   *    "createdAt": "2024-04-14T17:30:53.275Z",
   *    "updatedAt": "2024-04-14T17:30:53.275Z",
   *    "_version": 3,
   *    "_deleted": null,
   *    "_lastChangedAt": 1713115853307,
   *    "__typename": "Bookmark"
   * }
   */
  const createBookmarkApi = async (id) => {
    if(user){
      let res = await getBookmarkApi(id);
      if(!res){
        const bookmark = await API.graphql({
          query: createBookmark,
          variables: {
            input: {
              user_id: user.attributes.sub,
              deal_id: id,
              //_version: 2,
            },
          },
          authMode: AUTH_MODE.USER_POOLS,
        });
        return bookmark.data.createBookmark;
      }
      else{
        navigate('/login');
        return null
      }
    }
    else{
      navigate('/login')
      return null;
    }
  };

  /**
   * Deletes a bookmark from the database.
   *
   * @param {object} bookmark - The bookmark object to be deleted.
   * @param {string} bookmark.id - The ID of the bookmark to be deleted.
   * @param {number} bookmark._version - The version of the bookmark to be deleted.
   * @returns {Promise<null>} A Promise that resolves to null after the bookmark is successfully deleted.
   */
  const deleteBookmarkApi = async (bookmark) => {
    const { id, _version } = bookmark;
    await API.graphql({
      query: deleteBookmark,
      variables: {
        input: {
          id: id,
          _version: _version,
        },
      },
      authMode: AUTH_MODE.USER_POOLS,
    });
    return null;
  };

  /**
   * Fetches the bookmark for a specific deal and user.
   *
   * @param {string} id - The ID of the deal for which the bookmark is to be fetched.
   * @returns {Promise<object|null>} A Promise that resolves to either the bookmark object if found, or null if not found.
   *
   * Example of returned bookmark object:
   * {
   *    "id": "2087ecf1-9144-4747-b7dc-01b3ee8ada89",
   *    "user_id": "1eaf76f0-c77e-4f4e-8fa6-90e13999edfc",
   *    "deal_id": "6599a167-b598-4c31-9bee-10a1763e78d0",
   *    "owner": "anthony",
   *    "createdAt": "2024-04-14T17:30:53.275Z",
   *    "updatedAt": "2024-04-14T17:30:53.275Z",
   *    "_version": 3,
   *    "_deleted": null,
   *    "_lastChangedAt": 1713115853307,
   *    "__typename": "Bookmark"
   * }
   *
   * If no bookmark is found, it returns null.
   */
  const getBookmarkApi = async (id) => {
    if(user){
      // console.log('id ' + user.attributes.sub)
      const bookmark = await API.graphql({
        query: bookmarksByUser_id,
        variables: {
          user_id: user.attributes.sub,
          filter: {
            _deleted: { ne: true },
            deal_id: { eq: id },
          },
        },
        authMode: AUTH_MODE.USER_POOLS,
      });
  
      if (bookmark.data.bookmarksByUser_id.items.length > 0) {
        // console.log("bookmarked", bookmark.data.bookmarksByUser_id.items[0])
        return bookmark.data.bookmarksByUser_id.items[0];
      } else {
        //console.log('Not bookmarked');
        return null;
      }
    }
    else{
      // console.log('unauthorized');
      return null;
    }
  };

  return {
    createBookmarkApi: createBookmarkApi,
    deleteBookmarkApi: deleteBookmarkApi,
    getBookmarkApi: getBookmarkApi,
  };
}

export default useGraphql;
