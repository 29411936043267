import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Auth } from 'aws-amplify';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { twitterTemplateMap } from '../constants/SocialMeidaTemplate';

const availableAccounts = [
  'LordOfIndiana@Twitter',
  'LordOfSavings@Twitter',
  'LordOfRestocks@Twitter',
  'LordOfDiscounts@Twitter',
  'LordOfSavings@Threads',
  'LordOfRestocks@Threads',
  'LordOfDiscounts@Threads',
  'team-chat@Discord',
  'community-deals@Discord',
  'general-deals@Discord',
  'freebies@Discord',
  'Price-Errors@Discord',
  'Discounts@Discord',
  'Tech-Deals@Discord',
  'In-Store Deals@Discord',
  'Homegood-Deals@Discord',
  'Fashion-Deals@Discord',
  'Food-Deals@Discord',
  'Parenting-Deals@Discord',
  'Location Based Deals@Discord',
  'OTC-Coupons@Discord',
  'In-Demand Items@Discord',
];

export function DealEditorSocalManager({ dealDetails, setDealDetails }) {
  const [isSocialManager, setIsSocialManager] = useState(false);
  const [cognitoSub, setCognitoSub] = useState(null);

  const groupedAccounts = availableAccounts.reduce((acc, account) => {
    const [name, platform] = account.split('@');
    if (!acc[name]) acc[name] = {};
    acc[name][platform.toLowerCase()] = true;
    return acc;
  }, {});

  useEffect(() => {
    console.log('dealDetails.dealType: ', dealDetails.dealType);
    if (isSocialManager && dealDetails.dealType === 'lightning') {
      const updatedSocialsAccounts = new Set(dealDetails.socials_accounts);
      updatedSocialsAccounts.add('LordOfDiscounts@Twitter');
      updatedSocialsAccounts.add('LordOfDiscounts@Threads');

      setDealDetails({
        ...dealDetails,
        socials_accounts: Array.from(updatedSocialsAccounts),
      });
    }
  }, [dealDetails.dealType]);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const groups =
          user.signInUserSession.accessToken.payload['cognito:groups'];
        const sub = user.attributes.sub;
        setCognitoSub(sub);
        if (groups && groups.includes('social-manager')) {
          setIsSocialManager(true);
          setDealDetails({
            ...dealDetails,
            socials_delay: 'zero_min_delay',
            socials_template: 'RegularLinkCreation',
          });
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleTemplateChange = async (templateKey) => {
    const templateValue = twitterTemplateMap[templateKey];
    const calculateDiscountPercentage = (price, prevPrice) => {
      if (!price || !prevPrice || prevPrice <= 0) return '';
      const discount = ((prevPrice - price) / prevPrice) * 100;
      return discount.toFixed(0); // Return discount percentage with 2 decimal places
    };

    const discountPercentage = calculateDiscountPercentage(
      dealDetails.price,
      dealDetails.prev_price
    );

    const filledTemplate = templateValue
      .replace('{title}', dealDetails.title || '____')
      .replace('{dealer_type}', dealDetails.dealer_type || '____')
      .replace(
        '{discountPercentage}',
        isNaN(discountPercentage) ? '' : discountPercentage
      )
      .replace('{promoCode}', dealDetails.promotional_code || '____')
      .replace('{coupon}', dealDetails.coupon || '____')
      // .replace('{link}', dealDetails.deal_link)
      .replace('{price}', dealDetails.price || '____')
      .replace('{descritption}', dealDetails.description || '____');

    setDealDetails({
      ...dealDetails,
      socials_template: templateKey,
      socials_twitter_content: filledTemplate,
      socials_threads_content: filledTemplate,
      socials_discord_content: filledTemplate.replace('#LRDeal | #AD', ''),
    });
  };

  const rows = Object.keys(groupedAccounts)
    .map((name, index) => {
      const account = groupedAccounts[name];
      return {
        id: `account-${index}`,
        name,
        twitter: account.twitter || false,
        threads: account.threads || false,
        discord: account.discord || false,
      };
    })
    .filter((row) => row.twitter || row.threads || row.discord);

  const columns = [
    {
      field: 'name',
      headerName: 'Accounts',
      width: 245,
      sortable: false,
      renderCell: (params) => (
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={isAllChecked(params.row)}
            onChange={handleAllCheckboxChange(params.row)}
          />
          <Typography>{params.row.name}</Typography>
        </Box>
      ),
    },
    {
      field: 'twitter',
      headerName: 'Twitter',
      width: 75,
      sortable: false,
      renderCell: (params) =>
        params.row.twitter && (
          <Checkbox
            checked={isChecked(params.row.name, 'Twitter')}
            onChange={handleCheckboxChange(params.row.name, 'Twitter')}
          />
        ),
    },
    {
      field: 'threads',
      headerName: 'Threads',
      width: 75,
      sortable: false,
      renderCell: (params) =>
        params.row.threads && (
          <Checkbox
            checked={isChecked(params.row.name, 'Threads')}
            onChange={handleCheckboxChange(params.row.name, 'Threads')}
          />
        ),
    },
    {
      field: 'discord',
      headerName: 'Discord',
      width: 75,
      sortable: false,
      renderCell: (params) =>
        params.row.discord && (
          <Checkbox
            checked={isChecked(params.row.name, 'Discord')}
            onChange={handleCheckboxChange(params.row.name, 'Discord')}
          />
        ),
    },
  ];

  const handleAllCheckboxChange = (row) => (event) => {
    const platforms = ['Twitter', 'Threads', 'Discord'];
    const selectedAccounts = platforms
      .filter((platform) => row[platform.toLowerCase()])
      .map((platform) => `${row.name}@${platform}`);

    setDealDetails((prevDetails) => {
      const socialsAccounts = prevDetails.socials_accounts || [];
      const newAccounts = event.target.checked
        ? [...new Set([...socialsAccounts, ...selectedAccounts])]
        : socialsAccounts.filter((acc) => !selectedAccounts.includes(acc));
      return { ...prevDetails, socials_accounts: newAccounts };
    });
  };

  const isAllChecked = (row) => {
    const platforms = ['Twitter', 'Threads', 'Discord'];
    return platforms
      .filter((platform) => row[platform.toLowerCase()])
      .every((platform) => isChecked(row.name, platform));
  };

  const handleCheckboxChange = (name, platform) => (event) => {
    const selectedAccount = `${name}@${platform}`;
    setDealDetails((prevDetails) => {
      const socialsAccounts = prevDetails.socials_accounts || []; // Ensure it's an array
      const newAccounts = event.target.checked
        ? [...socialsAccounts, selectedAccount]
        : socialsAccounts.filter((acc) => acc !== selectedAccount);
      return { ...prevDetails, socials_accounts: newAccounts };
    });
  };

  const isChecked = (name, platform) => {
    return (dealDetails.socials_accounts || []).includes(`${name}@${platform}`);
  };

  const handleRadioChange = (evt) => {
    setDealDetails({
      ...dealDetails,
      socials_delay: evt.target.value,
    });
  };

  return (
    isSocialManager && (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant="h6" className="tw-text-string-base">
              {'Social Media Manager'}
            </Typography>
          </Grid>{' '}
          <Grid item xs={12} md={4} lg={4} className="tw-text-string-base">
            <Box display="start" flexDirection="column">
              <Box
                sx={{ mt: 1, minWidth: 200 }}
                display="start"
                flexDirection="row"
              >
                <FormLabel component="legend">Post on social media</FormLabel>
                <RadioGroup
                  value={dealDetails.socials_delay}
                  onChange={handleRadioChange}
                >
                  <Box display="flex" alignItems="center">
                    <FormControlLabel
                      value=""
                      control={<Radio />}
                      label="Don't Post"
                    />
                  </Box>
                  <Box display="flex" alignItems="center">
                    <FormControlLabel
                      value="zero_min_delay"
                      control={<Radio />}
                      label="Immediately"
                    />
                  </Box>
                  <Box display="flex" alignItems="center">
                    <FormControlLabel
                      value="five_min_delay"
                      control={<Radio />}
                      label="After 5 mins"
                    />
                  </Box>
                  <Box display="flex" alignItems="center">
                    <FormControlLabel
                      value="ten_min_delay"
                      control={<Radio />}
                      label="After 10 mins"
                    />
                  </Box>
                </RadioGroup>
              </Box>
              <FormControl sx={{ mt: 6, minWidth: 200 }}>
                <InputLabel id="template-select-label">Template</InputLabel>
                <Select
                  labelId="template-select-label"
                  id="template-select"
                  value={dealDetails.socials_template || ''}
                  onChange={(evt) => {
                    const templateKey = evt.target.value;
                    handleTemplateChange(templateKey);
                  }}
                  label="Template"
                >
                  {Object.keys(twitterTemplateMap).map((templateKey) => (
                    <MenuItem key={templateKey} value={templateKey}>
                      {templateKey}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <Box sx={{ mt: 1, mb: 5, width: '100%', height: 270 }}>
              <FormLabel component="legend">Accounts</FormLabel>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                hideFooter
                disableColumnMenu
                disableColumnSelector
                disableColumnFilter
              />
            </Box>
          </Grid>
        </Grid>
      </>
    )
  );
}

export default DealEditorSocalManager;
