import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@mui/material';
import * as React from 'react';
// import { useState } from 'react';
import { Dialog } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import { categoryList } from '../constants/CategoryConstants';
import { storeList } from '../constants/StoreConstants';
import SearchInputTextBox from './SearchInputTextBox';

function Search() {
  // const [focused, setFocused] = useState(false);
  const navigate = useNavigate();
  const [inputValue, setInputValue] = React.useState('');
  const [isSearchOpen, setIsSearchOpen] = React.useState(false);

  const handleSearch = (inputStr) => {
    navigate({
      pathname: '/deals',
      search: buildParam(inputStr),
      pathname: '/deals',
      search: buildParam(inputStr),
    });
    // localStorage.setItem('inputValue', inputStr);

    // window.location.reload(true);
    // setInputValue('');
  };

  const buildParam = (inputText) => {
    if (!inputText) {
      return '';
    }

    let parameterStr = '';
    const keywords = inputText.trim().split(' ');

    // for each keyword
    keywords.forEach((keyword) => {
      if (categoryList.includes(keyword)) {
        // add &forum_types=keyword
        parameterStr += `&forum_types=${keyword}`;
      } else if (storeList.includes(keyword)) {
        // add &dealer_types=keyword
        parameterStr += `&dealer_types=${keyword}`;
      } else if (keyword) {
        // add &q=
        parameterStr += `&q=${keyword}`;
      }
    });

    return parameterStr;
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch(event.target.value);
    }
  };

  return (
    <>
      <div className="tw-hidden tw-flex-1 lg:tw-flex md:tw-flex tw-justify-center">
        <div className="tw-w-full">
          <SearchInputTextBox
            isSearchOpen={isSearchOpen}
            setIsSearchOpen={setIsSearchOpen}
          />
        </div>
      </div>

      <div className="tw-flex-1 sm:tw-flex md:tw-hidden lg:tw-hidden tw-justify-center">
        <div className="tw-w-full">
          {isSearchOpen ? (
            <Dialog
              open={isSearchOpen}
              onClose={() => setIsSearchOpen(false)}
              fullWidth={true} // Add this line to make the dialog full width
              maxWidth="md" // Add this line to set the maximum width
              PaperProps={{
                // Add these lines to move the dialog to the top of the screen
                style: {
                  marginTop: '25px',
                  marginBottom: 'auto',
                },
              }}
            >
              <SearchInputTextBox
                isSearchOpen={isSearchOpen}
                setIsSearchOpen={setIsSearchOpen}
              />
            </Dialog>
          ) : (
            <TextField
              onClick={() => setIsSearchOpen(true)}
              variant="outlined"
              placeholder="Search"
              fullWidth
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      onClick={() => setIsSearchOpen(true)}
                      sx={{ padding: '0px' }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default Search;
