import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppContext_ } from '../../AppContextProvider';
import Section from '../home/Section';
import useProfileApis from '../profile/useProfileApis';

function SetUsername() {
  const { setUser } = useAppContext_();
  const { savePreferredName } = useProfileApis();
  const location = useLocation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const [formErrors, setFormErrors] = useState({
    username: '',
    password: '',
  });

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm() === true) {
      setLoading(true);

      const { username } = formData;

      try {
        await savePreferredName(username);
        if (location.state !== null) {
          navigate(location.state.destination);
        } else {
          navigate('/');
        }
      } catch {
        setLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [name]: '',
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue,
    }));
  };

  const validateForm = () => {
    const errors = {};

    if (formData.username.trim() === '') {
      errors.username = 'Username is required';
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Section
      content={
        <div className="tw-w-full tw-bg-transparent tw-p-4 tw-border-none md:tw-w-3/4 lg:tw-w-2/3 xl:tw-w-1/2 sm:tw-border-solid sm:tw-bg-surface-raised sm:tw-p-16 tw-rounded-lg sm:tw-border sm:tw-border-surface-border">
          <div className="tw-mb-10">
            <div className="tw-text-3xl tw-font-semibold tw-text-string-base">
              Set Username
            </div>
            <div className="tw-text-string-neutral">
              Let us know who to thank for posting!
            </div>
          </div>

          <div>
            <form className="tw-flex tw-flex-col tw-gap-y-4">
              <TextField
                name="username"
                label="Username"
                type="text"
                value={formData.username}
                onChange={handleChange}
                error={!!formErrors.username}
                helperText={formErrors.username || ' '}
              />
              <LoadingButton
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                onClick={handleSubmit}
                loading={loading}
                loadingPosition="end"
              >
                Set Username
              </LoadingButton>
            </form>
          </div>
        </div>
      }
    />
  );
}

export default SetUsername;
