import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppContext_ } from '../../AppContextProvider.jsx';
import {
  getCommentsApi,
  postCreateCommentApi,
  postDeleteCommentApi,
  postEditCommentApi,
} from '../../api/comments.js';
import useDealDetailsApi from './useDealDetailsApi.jsx';

function useDealComments() {
  const { id } = useParams();
  const { user, preferredUsername } = useAppContext_();
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const { postExpired } = useDealDetailsApi();

  const getComments = async () => {
    let comments = await getCommentsApi(id);

    // Sort the comments by creation time (most recent first)
    comments.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

    setComments(comments);
  };

  const handleExpiredComment = async (dealId, comment) => {
    if (comment && comment.text.length < 40) {
      const lowerCaseText = comment.text.toLowerCase();
      const keywords = ['expired', 'dead', 'not available'];

      if (keywords.some((keyword) => lowerCaseText.includes(keyword))) {
        await postExpired(dealId);
      }
    }
  };

  const postComment = async (comment) => {
    handleExpiredComment(id, comment);

    await postCreateCommentApi(
      id, // deal id
      preferredUsername,
      comment.text,
      comment.reply_to,
      user?.attributes?.picture
    );

    await getComments();
  };

  const postEditComment = async (comment) => {
    await postEditCommentApi(comment);
    await getComments();
  };

  const postDeleteComment = async (comment) => {
    await postDeleteCommentApi(comment);
    await getComments();
  };

  useEffect(() => {
    const asyncFunction = async () => {
      await getComments();
      setLoading(false);
    };
    asyncFunction();
  }, []);

  return {
    comments,
    loading,
    postComment,
    postEditComment,
    postDeleteComment,
  };
}

export default useDealComments;
