/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCommentVote = /* GraphQL */ `
  query GetCommentVote($id: ID!) {
    getCommentVote(id: $id) {
      id
      user_id
      comment_id
      comment_user_id
      owner
      upvote
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCommentVotes = /* GraphQL */ `
  query ListCommentVotes(
    $filter: ModelCommentVoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommentVotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        comment_id
        comment_user_id
        owner
        upvote
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCommentVotes = /* GraphQL */ `
  query SyncCommentVotes(
    $filter: ModelCommentVoteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCommentVotes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        user_id
        comment_id
        comment_user_id
        owner
        upvote
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const commentVotesByComment_id = /* GraphQL */ `
  query CommentVotesByComment_id(
    $comment_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCommentVoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentVotesByComment_id(
      comment_id: $comment_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        comment_id
        comment_user_id
        owner
        upvote
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const commentVotesByComment_user_id = /* GraphQL */ `
  query CommentVotesByComment_user_id(
    $comment_user_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCommentVoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentVotesByComment_user_id(
      comment_user_id: $comment_user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        comment_id
        comment_user_id
        owner
        upvote
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDealVote = /* GraphQL */ `
  query GetDealVote($id: ID!) {
    getDealVote(id: $id) {
      id
      user_id
      deal_id
      deal_user_id
      owner
      upvote
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDealVotes = /* GraphQL */ `
  query ListDealVotes(
    $filter: ModelDealVoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDealVotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        deal_id
        deal_user_id
        owner
        upvote
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDealVotes = /* GraphQL */ `
  query SyncDealVotes(
    $filter: ModelDealVoteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDealVotes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        user_id
        deal_id
        deal_user_id
        owner
        upvote
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dealVotesByDeal_id = /* GraphQL */ `
  query DealVotesByDeal_id(
    $deal_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDealVoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dealVotesByDeal_id(
      deal_id: $deal_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        deal_id
        deal_user_id
        owner
        upvote
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dealVotesByDeal_user_id = /* GraphQL */ `
  query DealVotesByDeal_user_id(
    $deal_user_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelDealVoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dealVotesByDeal_user_id(
      deal_user_id: $deal_user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        deal_id
        deal_user_id
        owner
        upvote
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getVotedExpired = /* GraphQL */ `
  query GetVotedExpired($id: ID!) {
    getVotedExpired(id: $id) {
      id
      user_id
      deal_id
      deal_user_id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listVotedExpireds = /* GraphQL */ `
  query ListVotedExpireds(
    $filter: ModelVotedExpiredFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVotedExpireds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        deal_id
        deal_user_id
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncVotedExpireds = /* GraphQL */ `
  query SyncVotedExpireds(
    $filter: ModelVotedExpiredFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncVotedExpireds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        user_id
        deal_id
        deal_user_id
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const votedExpiredsByDeal_id = /* GraphQL */ `
  query VotedExpiredsByDeal_id(
    $deal_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVotedExpiredFilterInput
    $limit: Int
    $nextToken: String
  ) {
    votedExpiredsByDeal_id(
      deal_id: $deal_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        deal_id
        deal_user_id
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const votedExpiredsByDeal_user_id = /* GraphQL */ `
  query VotedExpiredsByDeal_user_id(
    $deal_user_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelVotedExpiredFilterInput
    $limit: Int
    $nextToken: String
  ) {
    votedExpiredsByDeal_user_id(
      deal_user_id: $deal_user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        deal_id
        deal_user_id
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getReportedDeal = /* GraphQL */ `
  query GetReportedDeal($id: ID!) {
    getReportedDeal(id: $id) {
      id
      user_id
      deal_id
      deal_user_id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listReportedDeals = /* GraphQL */ `
  query ListReportedDeals(
    $filter: ModelReportedDealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportedDeals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        deal_id
        deal_user_id
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncReportedDeals = /* GraphQL */ `
  query SyncReportedDeals(
    $filter: ModelReportedDealFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReportedDeals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        user_id
        deal_id
        deal_user_id
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const reportedDealsByDeal_id = /* GraphQL */ `
  query ReportedDealsByDeal_id(
    $deal_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReportedDealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportedDealsByDeal_id(
      deal_id: $deal_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        deal_id
        deal_user_id
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const reportedDealsByDeal_user_id = /* GraphQL */ `
  query ReportedDealsByDeal_user_id(
    $deal_user_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelReportedDealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportedDealsByDeal_user_id(
      deal_user_id: $deal_user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        deal_id
        deal_user_id
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      deal_id
      user_name
      text
      reply_to
      likes
      dislikes
      poster_img_url
      createdAt
      updatedAt
      owner
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deal_id
        user_name
        text
        reply_to
        likes
        dislikes
        poster_img_url
        createdAt
        updatedAt
        owner
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncComments = /* GraphQL */ `
  query SyncComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        deal_id
        user_name
        text
        reply_to
        likes
        dislikes
        poster_img_url
        createdAt
        updatedAt
        owner
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const commentsByDeal_id = /* GraphQL */ `
  query CommentsByDeal_id(
    $deal_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByDeal_id(
      deal_id: $deal_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deal_id
        user_name
        text
        reply_to
        likes
        dislikes
        poster_img_url
        createdAt
        updatedAt
        owner
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDeal = /* GraphQL */ `
  query GetDeal($id: ID!) {
    getDeal(id: $id) {
      id
      store_sku
      title
      title_lowercase
      description
      description_lowercase
      price_drop
      deal_link
      affiliate_link
      img_link
      has_promotional_code
      promotional_code
      has_amazon_subscribe_save
      amazon_subscribe_save
      has_coupon
      coupon
      expiration_date
      poster_id
      poster_name
      forum_type
      forum_type_lowercase
      sub_category
      sub_category_lowercase
      dealer_type
      dealer_type_lowercase
      instore
      dealType
      isTopDeal
      isTrendingDeal
      isLightningDeal
      specific_states
      available_states
      specific_stores
      available_store_addresses
      available_store_zipcodes
      available_store_geohashes
      available_store_placeID
      vote
      price
      expired
      expired_status_string
      expired_ttl
      poster_img_url
      prev_price
      uploaded_img_links
      free_shipping
      free_pickup
      down_vote
      posted_date
      updated_date
      ExpiredVotedNumber
      ExpiredVotedNumberAccumulated
      ReportedNumber
      ReportedNumberAccumulated
      highest_votes
      highest_ratio
      createdAt
      updatedAt
      owner
      search_by_time
      search_by_vote
      additionalTitles
      additionalLinks
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDeals = /* GraphQL */ `
  query ListDeals(
    $filter: ModelDealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        store_sku
        title
        title_lowercase
        description
        description_lowercase
        price_drop
        deal_link
        affiliate_link
        img_link
        has_promotional_code
        promotional_code
        has_amazon_subscribe_save
        amazon_subscribe_save
        has_coupon
        coupon
        expiration_date
        poster_id
        poster_name
        forum_type
        forum_type_lowercase
        sub_category
        sub_category_lowercase
        dealer_type
        dealer_type_lowercase
        instore
        dealType
        isTopDeal
        isTrendingDeal
        isLightningDeal
        specific_states
        available_states
        specific_stores
        available_store_addresses
        available_store_zipcodes
        available_store_geohashes
        available_store_placeID
        vote
        price
        expired
        expired_status_string
        expired_ttl
        poster_img_url
        prev_price
        uploaded_img_links
        free_shipping
        free_pickup
        down_vote
        posted_date
        updated_date
        ExpiredVotedNumber
        ExpiredVotedNumberAccumulated
        ReportedNumber
        ReportedNumberAccumulated
        highest_votes
        highest_ratio
        createdAt
        updatedAt
        owner
        search_by_time
        search_by_vote
        additionalTitles
        additionalLinks
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDeals = /* GraphQL */ `
  query SyncDeals(
    $filter: ModelDealFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDeals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        store_sku
        title
        title_lowercase
        description
        description_lowercase
        price_drop
        deal_link
        affiliate_link
        img_link
        has_promotional_code
        promotional_code
        has_amazon_subscribe_save
        amazon_subscribe_save
        has_coupon
        coupon
        expiration_date
        poster_id
        poster_name
        forum_type
        forum_type_lowercase
        sub_category
        sub_category_lowercase
        dealer_type
        dealer_type_lowercase
        instore
        dealType
        isTopDeal
        isTrendingDeal
        isLightningDeal
        specific_states
        available_states
        specific_stores
        available_store_addresses
        available_store_zipcodes
        available_store_geohashes
        available_store_placeID
        vote
        price
        expired
        expired_status_string
        expired_ttl
        poster_img_url
        prev_price
        uploaded_img_links
        free_shipping
        free_pickup
        down_vote
        posted_date
        updated_date
        ExpiredVotedNumber
        ExpiredVotedNumberAccumulated
        ReportedNumber
        ReportedNumberAccumulated
        highest_votes
        highest_ratio
        createdAt
        updatedAt
        owner
        search_by_time
        search_by_vote
        additionalTitles
        additionalLinks
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dealsByStore_skuAndPoster_id = /* GraphQL */ `
  query DealsByStore_skuAndPoster_id(
    $store_sku: String!
    $poster_id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dealsByStore_skuAndPoster_id(
      store_sku: $store_sku
      poster_id: $poster_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        store_sku
        title
        title_lowercase
        description
        description_lowercase
        price_drop
        deal_link
        affiliate_link
        img_link
        has_promotional_code
        promotional_code
        has_amazon_subscribe_save
        amazon_subscribe_save
        has_coupon
        coupon
        expiration_date
        poster_id
        poster_name
        forum_type
        forum_type_lowercase
        sub_category
        sub_category_lowercase
        dealer_type
        dealer_type_lowercase
        instore
        dealType
        isTopDeal
        isTrendingDeal
        isLightningDeal
        specific_states
        available_states
        specific_stores
        available_store_addresses
        available_store_zipcodes
        available_store_geohashes
        available_store_placeID
        vote
        price
        expired
        expired_status_string
        expired_ttl
        poster_img_url
        prev_price
        uploaded_img_links
        free_shipping
        free_pickup
        down_vote
        posted_date
        updated_date
        ExpiredVotedNumber
        ExpiredVotedNumberAccumulated
        ReportedNumber
        ReportedNumberAccumulated
        highest_votes
        highest_ratio
        createdAt
        updatedAt
        owner
        search_by_time
        search_by_vote
        additionalTitles
        additionalLinks
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dealsByDeal_linkAndPoster_id = /* GraphQL */ `
  query DealsByDeal_linkAndPoster_id(
    $deal_link: String!
    $poster_id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dealsByDeal_linkAndPoster_id(
      deal_link: $deal_link
      poster_id: $poster_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        store_sku
        title
        title_lowercase
        description
        description_lowercase
        price_drop
        deal_link
        affiliate_link
        img_link
        has_promotional_code
        promotional_code
        has_amazon_subscribe_save
        amazon_subscribe_save
        has_coupon
        coupon
        expiration_date
        poster_id
        poster_name
        forum_type
        forum_type_lowercase
        sub_category
        sub_category_lowercase
        dealer_type
        dealer_type_lowercase
        instore
        dealType
        isTopDeal
        isTrendingDeal
        isLightningDeal
        specific_states
        available_states
        specific_stores
        available_store_addresses
        available_store_zipcodes
        available_store_geohashes
        available_store_placeID
        vote
        price
        expired
        expired_status_string
        expired_ttl
        poster_img_url
        prev_price
        uploaded_img_links
        free_shipping
        free_pickup
        down_vote
        posted_date
        updated_date
        ExpiredVotedNumber
        ExpiredVotedNumberAccumulated
        ReportedNumber
        ReportedNumberAccumulated
        highest_votes
        highest_ratio
        createdAt
        updatedAt
        owner
        search_by_time
        search_by_vote
        additionalTitles
        additionalLinks
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dealsByPoster_idAndUpdated_date = /* GraphQL */ `
  query DealsByPoster_idAndUpdated_date(
    $poster_id: ID!
    $updated_date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dealsByPoster_idAndUpdated_date(
      poster_id: $poster_id
      updated_date: $updated_date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        store_sku
        title
        title_lowercase
        description
        description_lowercase
        price_drop
        deal_link
        affiliate_link
        img_link
        has_promotional_code
        promotional_code
        has_amazon_subscribe_save
        amazon_subscribe_save
        has_coupon
        coupon
        expiration_date
        poster_id
        poster_name
        forum_type
        forum_type_lowercase
        sub_category
        sub_category_lowercase
        dealer_type
        dealer_type_lowercase
        instore
        dealType
        isTopDeal
        isTrendingDeal
        isLightningDeal
        specific_states
        available_states
        specific_stores
        available_store_addresses
        available_store_zipcodes
        available_store_geohashes
        available_store_placeID
        vote
        price
        expired
        expired_status_string
        expired_ttl
        poster_img_url
        prev_price
        uploaded_img_links
        free_shipping
        free_pickup
        down_vote
        posted_date
        updated_date
        ExpiredVotedNumber
        ExpiredVotedNumberAccumulated
        ReportedNumber
        ReportedNumberAccumulated
        highest_votes
        highest_ratio
        createdAt
        updatedAt
        owner
        search_by_time
        search_by_vote
        additionalTitles
        additionalLinks
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dealsByForum_typeAndCreatedAt = /* GraphQL */ `
  query DealsByForum_typeAndCreatedAt(
    $forum_type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dealsByForum_typeAndCreatedAt(
      forum_type: $forum_type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        store_sku
        title
        title_lowercase
        description
        description_lowercase
        price_drop
        deal_link
        affiliate_link
        img_link
        has_promotional_code
        promotional_code
        has_amazon_subscribe_save
        amazon_subscribe_save
        has_coupon
        coupon
        expiration_date
        poster_id
        poster_name
        forum_type
        forum_type_lowercase
        sub_category
        sub_category_lowercase
        dealer_type
        dealer_type_lowercase
        instore
        dealType
        isTopDeal
        isTrendingDeal
        isLightningDeal
        specific_states
        available_states
        specific_stores
        available_store_addresses
        available_store_zipcodes
        available_store_geohashes
        available_store_placeID
        vote
        price
        expired
        expired_status_string
        expired_ttl
        poster_img_url
        prev_price
        uploaded_img_links
        free_shipping
        free_pickup
        down_vote
        posted_date
        updated_date
        ExpiredVotedNumber
        ExpiredVotedNumberAccumulated
        ReportedNumber
        ReportedNumberAccumulated
        highest_votes
        highest_ratio
        createdAt
        updatedAt
        owner
        search_by_time
        search_by_vote
        additionalTitles
        additionalLinks
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dealsBySub_categoryAndCreatedAt = /* GraphQL */ `
  query DealsBySub_categoryAndCreatedAt(
    $sub_category: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dealsBySub_categoryAndCreatedAt(
      sub_category: $sub_category
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        store_sku
        title
        title_lowercase
        description
        description_lowercase
        price_drop
        deal_link
        affiliate_link
        img_link
        has_promotional_code
        promotional_code
        has_amazon_subscribe_save
        amazon_subscribe_save
        has_coupon
        coupon
        expiration_date
        poster_id
        poster_name
        forum_type
        forum_type_lowercase
        sub_category
        sub_category_lowercase
        dealer_type
        dealer_type_lowercase
        instore
        dealType
        isTopDeal
        isTrendingDeal
        isLightningDeal
        specific_states
        available_states
        specific_stores
        available_store_addresses
        available_store_zipcodes
        available_store_geohashes
        available_store_placeID
        vote
        price
        expired
        expired_status_string
        expired_ttl
        poster_img_url
        prev_price
        uploaded_img_links
        free_shipping
        free_pickup
        down_vote
        posted_date
        updated_date
        ExpiredVotedNumber
        ExpiredVotedNumberAccumulated
        ReportedNumber
        ReportedNumberAccumulated
        highest_votes
        highest_ratio
        createdAt
        updatedAt
        owner
        search_by_time
        search_by_vote
        additionalTitles
        additionalLinks
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dealsByDealer_typeAndCreatedAt = /* GraphQL */ `
  query DealsByDealer_typeAndCreatedAt(
    $dealer_type: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dealsByDealer_typeAndCreatedAt(
      dealer_type: $dealer_type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        store_sku
        title
        title_lowercase
        description
        description_lowercase
        price_drop
        deal_link
        affiliate_link
        img_link
        has_promotional_code
        promotional_code
        has_amazon_subscribe_save
        amazon_subscribe_save
        has_coupon
        coupon
        expiration_date
        poster_id
        poster_name
        forum_type
        forum_type_lowercase
        sub_category
        sub_category_lowercase
        dealer_type
        dealer_type_lowercase
        instore
        dealType
        isTopDeal
        isTrendingDeal
        isLightningDeal
        specific_states
        available_states
        specific_stores
        available_store_addresses
        available_store_zipcodes
        available_store_geohashes
        available_store_placeID
        vote
        price
        expired
        expired_status_string
        expired_ttl
        poster_img_url
        prev_price
        uploaded_img_links
        free_shipping
        free_pickup
        down_vote
        posted_date
        updated_date
        ExpiredVotedNumber
        ExpiredVotedNumberAccumulated
        ReportedNumber
        ReportedNumberAccumulated
        highest_votes
        highest_ratio
        createdAt
        updatedAt
        owner
        search_by_time
        search_by_vote
        additionalTitles
        additionalLinks
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dealsByDealTypeAndUpdatedAt = /* GraphQL */ `
  query DealsByDealTypeAndUpdatedAt(
    $dealType: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dealsByDealTypeAndUpdatedAt(
      dealType: $dealType
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        store_sku
        title
        title_lowercase
        description
        description_lowercase
        price_drop
        deal_link
        affiliate_link
        img_link
        has_promotional_code
        promotional_code
        has_amazon_subscribe_save
        amazon_subscribe_save
        has_coupon
        coupon
        expiration_date
        poster_id
        poster_name
        forum_type
        forum_type_lowercase
        sub_category
        sub_category_lowercase
        dealer_type
        dealer_type_lowercase
        instore
        dealType
        isTopDeal
        isTrendingDeal
        isLightningDeal
        specific_states
        available_states
        specific_stores
        available_store_addresses
        available_store_zipcodes
        available_store_geohashes
        available_store_placeID
        vote
        price
        expired
        expired_status_string
        expired_ttl
        poster_img_url
        prev_price
        uploaded_img_links
        free_shipping
        free_pickup
        down_vote
        posted_date
        updated_date
        ExpiredVotedNumber
        ExpiredVotedNumberAccumulated
        ReportedNumber
        ReportedNumberAccumulated
        highest_votes
        highest_ratio
        createdAt
        updatedAt
        owner
        search_by_time
        search_by_vote
        additionalTitles
        additionalLinks
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dealsByIsTopDealAndUpdatedAt = /* GraphQL */ `
  query DealsByIsTopDealAndUpdatedAt(
    $isTopDeal: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dealsByIsTopDealAndUpdatedAt(
      isTopDeal: $isTopDeal
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        store_sku
        title
        title_lowercase
        description
        description_lowercase
        price_drop
        deal_link
        affiliate_link
        img_link
        has_promotional_code
        promotional_code
        has_amazon_subscribe_save
        amazon_subscribe_save
        has_coupon
        coupon
        expiration_date
        poster_id
        poster_name
        forum_type
        forum_type_lowercase
        sub_category
        sub_category_lowercase
        dealer_type
        dealer_type_lowercase
        instore
        dealType
        isTopDeal
        isTrendingDeal
        isLightningDeal
        specific_states
        available_states
        specific_stores
        available_store_addresses
        available_store_zipcodes
        available_store_geohashes
        available_store_placeID
        vote
        price
        expired
        expired_status_string
        expired_ttl
        poster_img_url
        prev_price
        uploaded_img_links
        free_shipping
        free_pickup
        down_vote
        posted_date
        updated_date
        ExpiredVotedNumber
        ExpiredVotedNumberAccumulated
        ReportedNumber
        ReportedNumberAccumulated
        highest_votes
        highest_ratio
        createdAt
        updatedAt
        owner
        search_by_time
        search_by_vote
        additionalTitles
        additionalLinks
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dealsByIsTrendingDealAndUpdatedAt = /* GraphQL */ `
  query DealsByIsTrendingDealAndUpdatedAt(
    $isTrendingDeal: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dealsByIsTrendingDealAndUpdatedAt(
      isTrendingDeal: $isTrendingDeal
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        store_sku
        title
        title_lowercase
        description
        description_lowercase
        price_drop
        deal_link
        affiliate_link
        img_link
        has_promotional_code
        promotional_code
        has_amazon_subscribe_save
        amazon_subscribe_save
        has_coupon
        coupon
        expiration_date
        poster_id
        poster_name
        forum_type
        forum_type_lowercase
        sub_category
        sub_category_lowercase
        dealer_type
        dealer_type_lowercase
        instore
        dealType
        isTopDeal
        isTrendingDeal
        isLightningDeal
        specific_states
        available_states
        specific_stores
        available_store_addresses
        available_store_zipcodes
        available_store_geohashes
        available_store_placeID
        vote
        price
        expired
        expired_status_string
        expired_ttl
        poster_img_url
        prev_price
        uploaded_img_links
        free_shipping
        free_pickup
        down_vote
        posted_date
        updated_date
        ExpiredVotedNumber
        ExpiredVotedNumberAccumulated
        ReportedNumber
        ReportedNumberAccumulated
        highest_votes
        highest_ratio
        createdAt
        updatedAt
        owner
        search_by_time
        search_by_vote
        additionalTitles
        additionalLinks
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dealsByIsLightningDealAndUpdatedAt = /* GraphQL */ `
  query DealsByIsLightningDealAndUpdatedAt(
    $isLightningDeal: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dealsByIsLightningDealAndUpdatedAt(
      isLightningDeal: $isLightningDeal
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        store_sku
        title
        title_lowercase
        description
        description_lowercase
        price_drop
        deal_link
        affiliate_link
        img_link
        has_promotional_code
        promotional_code
        has_amazon_subscribe_save
        amazon_subscribe_save
        has_coupon
        coupon
        expiration_date
        poster_id
        poster_name
        forum_type
        forum_type_lowercase
        sub_category
        sub_category_lowercase
        dealer_type
        dealer_type_lowercase
        instore
        dealType
        isTopDeal
        isTrendingDeal
        isLightningDeal
        specific_states
        available_states
        specific_stores
        available_store_addresses
        available_store_zipcodes
        available_store_geohashes
        available_store_placeID
        vote
        price
        expired
        expired_status_string
        expired_ttl
        poster_img_url
        prev_price
        uploaded_img_links
        free_shipping
        free_pickup
        down_vote
        posted_date
        updated_date
        ExpiredVotedNumber
        ExpiredVotedNumberAccumulated
        ReportedNumber
        ReportedNumberAccumulated
        highest_votes
        highest_ratio
        createdAt
        updatedAt
        owner
        search_by_time
        search_by_vote
        additionalTitles
        additionalLinks
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dealsByExpired_status_stringAndExpired_ttl = /* GraphQL */ `
  query DealsByExpired_status_stringAndExpired_ttl(
    $expired_status_string: String!
    $expired_ttl: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dealsByExpired_status_stringAndExpired_ttl(
      expired_status_string: $expired_status_string
      expired_ttl: $expired_ttl
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        store_sku
        title
        title_lowercase
        description
        description_lowercase
        price_drop
        deal_link
        affiliate_link
        img_link
        has_promotional_code
        promotional_code
        has_amazon_subscribe_save
        amazon_subscribe_save
        has_coupon
        coupon
        expiration_date
        poster_id
        poster_name
        forum_type
        forum_type_lowercase
        sub_category
        sub_category_lowercase
        dealer_type
        dealer_type_lowercase
        instore
        dealType
        isTopDeal
        isTrendingDeal
        isLightningDeal
        specific_states
        available_states
        specific_stores
        available_store_addresses
        available_store_zipcodes
        available_store_geohashes
        available_store_placeID
        vote
        price
        expired
        expired_status_string
        expired_ttl
        poster_img_url
        prev_price
        uploaded_img_links
        free_shipping
        free_pickup
        down_vote
        posted_date
        updated_date
        ExpiredVotedNumber
        ExpiredVotedNumberAccumulated
        ReportedNumber
        ReportedNumberAccumulated
        highest_votes
        highest_ratio
        createdAt
        updatedAt
        owner
        search_by_time
        search_by_vote
        additionalTitles
        additionalLinks
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dealsByPosted_date = /* GraphQL */ `
  query DealsByPosted_date(
    $posted_date: String!
    $sortDirection: ModelSortDirection
    $filter: ModelDealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dealsByPosted_date(
      posted_date: $posted_date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        store_sku
        title
        title_lowercase
        description
        description_lowercase
        price_drop
        deal_link
        affiliate_link
        img_link
        has_promotional_code
        promotional_code
        has_amazon_subscribe_save
        amazon_subscribe_save
        has_coupon
        coupon
        expiration_date
        poster_id
        poster_name
        forum_type
        forum_type_lowercase
        sub_category
        sub_category_lowercase
        dealer_type
        dealer_type_lowercase
        instore
        dealType
        isTopDeal
        isTrendingDeal
        isLightningDeal
        specific_states
        available_states
        specific_stores
        available_store_addresses
        available_store_zipcodes
        available_store_geohashes
        available_store_placeID
        vote
        price
        expired
        expired_status_string
        expired_ttl
        poster_img_url
        prev_price
        uploaded_img_links
        free_shipping
        free_pickup
        down_vote
        posted_date
        updated_date
        ExpiredVotedNumber
        ExpiredVotedNumberAccumulated
        ReportedNumber
        ReportedNumberAccumulated
        highest_votes
        highest_ratio
        createdAt
        updatedAt
        owner
        search_by_time
        search_by_vote
        additionalTitles
        additionalLinks
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dealsByExpiredVotedNumberAndCreatedAt = /* GraphQL */ `
  query DealsByExpiredVotedNumberAndCreatedAt(
    $ExpiredVotedNumber: Int!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dealsByExpiredVotedNumberAndCreatedAt(
      ExpiredVotedNumber: $ExpiredVotedNumber
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        store_sku
        title
        title_lowercase
        description
        description_lowercase
        price_drop
        deal_link
        affiliate_link
        img_link
        has_promotional_code
        promotional_code
        has_amazon_subscribe_save
        amazon_subscribe_save
        has_coupon
        coupon
        expiration_date
        poster_id
        poster_name
        forum_type
        forum_type_lowercase
        sub_category
        sub_category_lowercase
        dealer_type
        dealer_type_lowercase
        instore
        dealType
        isTopDeal
        isTrendingDeal
        isLightningDeal
        specific_states
        available_states
        specific_stores
        available_store_addresses
        available_store_zipcodes
        available_store_geohashes
        available_store_placeID
        vote
        price
        expired
        expired_status_string
        expired_ttl
        poster_img_url
        prev_price
        uploaded_img_links
        free_shipping
        free_pickup
        down_vote
        posted_date
        updated_date
        ExpiredVotedNumber
        ExpiredVotedNumberAccumulated
        ReportedNumber
        ReportedNumberAccumulated
        highest_votes
        highest_ratio
        createdAt
        updatedAt
        owner
        search_by_time
        search_by_vote
        additionalTitles
        additionalLinks
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dealsByReportedNumberAndCreatedAt = /* GraphQL */ `
  query DealsByReportedNumberAndCreatedAt(
    $ReportedNumber: Int!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dealsByReportedNumberAndCreatedAt(
      ReportedNumber: $ReportedNumber
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        store_sku
        title
        title_lowercase
        description
        description_lowercase
        price_drop
        deal_link
        affiliate_link
        img_link
        has_promotional_code
        promotional_code
        has_amazon_subscribe_save
        amazon_subscribe_save
        has_coupon
        coupon
        expiration_date
        poster_id
        poster_name
        forum_type
        forum_type_lowercase
        sub_category
        sub_category_lowercase
        dealer_type
        dealer_type_lowercase
        instore
        dealType
        isTopDeal
        isTrendingDeal
        isLightningDeal
        specific_states
        available_states
        specific_stores
        available_store_addresses
        available_store_zipcodes
        available_store_geohashes
        available_store_placeID
        vote
        price
        expired
        expired_status_string
        expired_ttl
        poster_img_url
        prev_price
        uploaded_img_links
        free_shipping
        free_pickup
        down_vote
        posted_date
        updated_date
        ExpiredVotedNumber
        ExpiredVotedNumberAccumulated
        ReportedNumber
        ReportedNumberAccumulated
        highest_votes
        highest_ratio
        createdAt
        updatedAt
        owner
        search_by_time
        search_by_vote
        additionalTitles
        additionalLinks
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dealsByReportedNumberAccumulatedAndCreatedAt = /* GraphQL */ `
  query DealsByReportedNumberAccumulatedAndCreatedAt(
    $ReportedNumberAccumulated: Int!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dealsByReportedNumberAccumulatedAndCreatedAt(
      ReportedNumberAccumulated: $ReportedNumberAccumulated
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        store_sku
        title
        title_lowercase
        description
        description_lowercase
        price_drop
        deal_link
        affiliate_link
        img_link
        has_promotional_code
        promotional_code
        has_amazon_subscribe_save
        amazon_subscribe_save
        has_coupon
        coupon
        expiration_date
        poster_id
        poster_name
        forum_type
        forum_type_lowercase
        sub_category
        sub_category_lowercase
        dealer_type
        dealer_type_lowercase
        instore
        dealType
        isTopDeal
        isTrendingDeal
        isLightningDeal
        specific_states
        available_states
        specific_stores
        available_store_addresses
        available_store_zipcodes
        available_store_geohashes
        available_store_placeID
        vote
        price
        expired
        expired_status_string
        expired_ttl
        poster_img_url
        prev_price
        uploaded_img_links
        free_shipping
        free_pickup
        down_vote
        posted_date
        updated_date
        ExpiredVotedNumber
        ExpiredVotedNumberAccumulated
        ReportedNumber
        ReportedNumberAccumulated
        highest_votes
        highest_ratio
        createdAt
        updatedAt
        owner
        search_by_time
        search_by_vote
        additionalTitles
        additionalLinks
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dealsByDate = /* GraphQL */ `
  query DealsByDate(
    $search_by_time: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dealsByDate(
      search_by_time: $search_by_time
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        store_sku
        title
        title_lowercase
        description
        description_lowercase
        price_drop
        deal_link
        affiliate_link
        img_link
        has_promotional_code
        promotional_code
        has_amazon_subscribe_save
        amazon_subscribe_save
        has_coupon
        coupon
        expiration_date
        poster_id
        poster_name
        forum_type
        forum_type_lowercase
        sub_category
        sub_category_lowercase
        dealer_type
        dealer_type_lowercase
        instore
        dealType
        isTopDeal
        isTrendingDeal
        isLightningDeal
        specific_states
        available_states
        specific_stores
        available_store_addresses
        available_store_zipcodes
        available_store_geohashes
        available_store_placeID
        vote
        price
        expired
        expired_status_string
        expired_ttl
        poster_img_url
        prev_price
        uploaded_img_links
        free_shipping
        free_pickup
        down_vote
        posted_date
        updated_date
        ExpiredVotedNumber
        ExpiredVotedNumberAccumulated
        ReportedNumber
        ReportedNumberAccumulated
        highest_votes
        highest_ratio
        createdAt
        updatedAt
        owner
        search_by_time
        search_by_vote
        additionalTitles
        additionalLinks
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const dealsByVote = /* GraphQL */ `
  query DealsByVote(
    $search_by_vote: String!
    $vote: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDealFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dealsByVote(
      search_by_vote: $search_by_vote
      vote: $vote
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        store_sku
        title
        title_lowercase
        description
        description_lowercase
        price_drop
        deal_link
        affiliate_link
        img_link
        has_promotional_code
        promotional_code
        has_amazon_subscribe_save
        amazon_subscribe_save
        has_coupon
        coupon
        expiration_date
        poster_id
        poster_name
        forum_type
        forum_type_lowercase
        sub_category
        sub_category_lowercase
        dealer_type
        dealer_type_lowercase
        instore
        dealType
        isTopDeal
        isTrendingDeal
        isLightningDeal
        specific_states
        available_states
        specific_stores
        available_store_addresses
        available_store_zipcodes
        available_store_geohashes
        available_store_placeID
        vote
        price
        expired
        expired_status_string
        expired_ttl
        poster_img_url
        prev_price
        uploaded_img_links
        free_shipping
        free_pickup
        down_vote
        posted_date
        updated_date
        ExpiredVotedNumber
        ExpiredVotedNumberAccumulated
        ReportedNumber
        ReportedNumberAccumulated
        highest_votes
        highest_ratio
        createdAt
        updatedAt
        owner
        search_by_time
        search_by_vote
        additionalTitles
        additionalLinks
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getInStoreDealByState = /* GraphQL */ `
  query GetInStoreDealByState($id: ID!) {
    getInStoreDealByState(id: $id) {
      id
      deal_id
      store_name
      available_state
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listInStoreDealByStates = /* GraphQL */ `
  query ListInStoreDealByStates(
    $filter: ModelInStoreDealByStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInStoreDealByStates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deal_id
        store_name
        available_state
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncInStoreDealByStates = /* GraphQL */ `
  query SyncInStoreDealByStates(
    $filter: ModelInStoreDealByStateFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInStoreDealByStates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        deal_id
        store_name
        available_state
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const inStoreDealByStatesByDeal_id = /* GraphQL */ `
  query InStoreDealByStatesByDeal_id(
    $deal_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInStoreDealByStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inStoreDealByStatesByDeal_id(
      deal_id: $deal_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deal_id
        store_name
        available_state
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const inStoreDealByStatesByAvailable_state = /* GraphQL */ `
  query InStoreDealByStatesByAvailable_state(
    $available_state: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInStoreDealByStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inStoreDealByStatesByAvailable_state(
      available_state: $available_state
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deal_id
        store_name
        available_state
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getInStoreDealGeoHash = /* GraphQL */ `
  query GetInStoreDealGeoHash($id: ID!) {
    getInStoreDealGeoHash(id: $id) {
      id
      deal_id
      geohash
      address
      store_name
      zipcode
      googlemap_placeid
      geohash3
      geohash4
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listInStoreDealGeoHashes = /* GraphQL */ `
  query ListInStoreDealGeoHashes(
    $filter: ModelInStoreDealGeoHashFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInStoreDealGeoHashes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deal_id
        geohash
        address
        store_name
        zipcode
        googlemap_placeid
        geohash3
        geohash4
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncInStoreDealGeoHashes = /* GraphQL */ `
  query SyncInStoreDealGeoHashes(
    $filter: ModelInStoreDealGeoHashFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInStoreDealGeoHashes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        deal_id
        geohash
        address
        store_name
        zipcode
        googlemap_placeid
        geohash3
        geohash4
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const inStoreDealGeoHashesByDeal_id = /* GraphQL */ `
  query InStoreDealGeoHashesByDeal_id(
    $deal_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInStoreDealGeoHashFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inStoreDealGeoHashesByDeal_id(
      deal_id: $deal_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deal_id
        geohash
        address
        store_name
        zipcode
        googlemap_placeid
        geohash3
        geohash4
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const inStoreDealGeoHashesByGeohash = /* GraphQL */ `
  query InStoreDealGeoHashesByGeohash(
    $geohash: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInStoreDealGeoHashFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inStoreDealGeoHashesByGeohash(
      geohash: $geohash
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deal_id
        geohash
        address
        store_name
        zipcode
        googlemap_placeid
        geohash3
        geohash4
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const inStoreDealGeoHashesByGooglemap_placeid = /* GraphQL */ `
  query InStoreDealGeoHashesByGooglemap_placeid(
    $googlemap_placeid: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInStoreDealGeoHashFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inStoreDealGeoHashesByGooglemap_placeid(
      googlemap_placeid: $googlemap_placeid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deal_id
        geohash
        address
        store_name
        zipcode
        googlemap_placeid
        geohash3
        geohash4
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const inStoreDealGeoHashesByGeohash3 = /* GraphQL */ `
  query InStoreDealGeoHashesByGeohash3(
    $geohash3: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInStoreDealGeoHashFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inStoreDealGeoHashesByGeohash3(
      geohash3: $geohash3
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deal_id
        geohash
        address
        store_name
        zipcode
        googlemap_placeid
        geohash3
        geohash4
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const inStoreDealGeoHashesByGeohash4 = /* GraphQL */ `
  query InStoreDealGeoHashesByGeohash4(
    $geohash4: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInStoreDealGeoHashFilterInput
    $limit: Int
    $nextToken: String
  ) {
    inStoreDealGeoHashesByGeohash4(
      geohash4: $geohash4
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deal_id
        geohash
        address
        store_name
        zipcode
        googlemap_placeid
        geohash3
        geohash4
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAlertSettings = /* GraphQL */ `
  query GetAlertSettings($id: ID!) {
    getAlertSettings(id: $id) {
      id
      alert_title
      user_id
      username
      enabled
      keywords
      stores
      categories
      sub_categories
      price_min
      price_max
      threshold_net_upvote
      threshold_upvote_ratio
      notification_email
      notification_cell
      notification_app
      frequency_instant
      frequency_daily
      frequency_weekly
      last_changed_date
      hash_key
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAlertSettings = /* GraphQL */ `
  query ListAlertSettings(
    $filter: ModelAlertSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlertSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        alert_title
        user_id
        username
        enabled
        keywords
        stores
        categories
        sub_categories
        price_min
        price_max
        threshold_net_upvote
        threshold_upvote_ratio
        notification_email
        notification_cell
        notification_app
        frequency_instant
        frequency_daily
        frequency_weekly
        last_changed_date
        hash_key
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAlertSettings = /* GraphQL */ `
  query SyncAlertSettings(
    $filter: ModelAlertSettingsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAlertSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        alert_title
        user_id
        username
        enabled
        keywords
        stores
        categories
        sub_categories
        price_min
        price_max
        threshold_net_upvote
        threshold_upvote_ratio
        notification_email
        notification_cell
        notification_app
        frequency_instant
        frequency_daily
        frequency_weekly
        last_changed_date
        hash_key
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const alertSettingsByUser_id = /* GraphQL */ `
  query AlertSettingsByUser_id(
    $user_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAlertSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    alertSettingsByUser_id(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        alert_title
        user_id
        username
        enabled
        keywords
        stores
        categories
        sub_categories
        price_min
        price_max
        threshold_net_upvote
        threshold_upvote_ratio
        notification_email
        notification_cell
        notification_app
        frequency_instant
        frequency_daily
        frequency_weekly
        last_changed_date
        hash_key
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const alertSettingsByLast_changed_dateAndUser_id = /* GraphQL */ `
  query AlertSettingsByLast_changed_dateAndUser_id(
    $last_changed_date: String!
    $user_id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlertSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    alertSettingsByLast_changed_dateAndUser_id(
      last_changed_date: $last_changed_date
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        alert_title
        user_id
        username
        enabled
        keywords
        stores
        categories
        sub_categories
        price_min
        price_max
        threshold_net_upvote
        threshold_upvote_ratio
        notification_email
        notification_cell
        notification_app
        frequency_instant
        frequency_daily
        frequency_weekly
        last_changed_date
        hash_key
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const alertSettingsByHash_keyAndUser_id = /* GraphQL */ `
  query AlertSettingsByHash_keyAndUser_id(
    $hash_key: String!
    $user_id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlertSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    alertSettingsByHash_keyAndUser_id(
      hash_key: $hash_key
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        alert_title
        user_id
        username
        enabled
        keywords
        stores
        categories
        sub_categories
        price_min
        price_max
        threshold_net_upvote
        threshold_upvote_ratio
        notification_email
        notification_cell
        notification_app
        frequency_instant
        frequency_daily
        frequency_weekly
        last_changed_date
        hash_key
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTopDealAlertSettings = /* GraphQL */ `
  query GetTopDealAlertSettings($id: ID!) {
    getTopDealAlertSettings(id: $id) {
      id
      user_id
      username
      enabled
      notification_email
      notification_cell
      notification_app
      frequency_instant
      frequency_daily
      frequency_weekly
      hash_key
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listTopDealAlertSettings = /* GraphQL */ `
  query ListTopDealAlertSettings(
    $filter: ModelTopDealAlertSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTopDealAlertSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        username
        enabled
        notification_email
        notification_cell
        notification_app
        frequency_instant
        frequency_daily
        frequency_weekly
        hash_key
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTopDealAlertSettings = /* GraphQL */ `
  query SyncTopDealAlertSettings(
    $filter: ModelTopDealAlertSettingsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTopDealAlertSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        user_id
        username
        enabled
        notification_email
        notification_cell
        notification_app
        frequency_instant
        frequency_daily
        frequency_weekly
        hash_key
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const topDealAlertSettingsByUser_id = /* GraphQL */ `
  query TopDealAlertSettingsByUser_id(
    $user_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTopDealAlertSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    topDealAlertSettingsByUser_id(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        username
        enabled
        notification_email
        notification_cell
        notification_app
        frequency_instant
        frequency_daily
        frequency_weekly
        hash_key
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const topDealAlertSettingsByHash_keyAndUser_id = /* GraphQL */ `
  query TopDealAlertSettingsByHash_keyAndUser_id(
    $hash_key: String!
    $user_id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTopDealAlertSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    topDealAlertSettingsByHash_keyAndUser_id(
      hash_key: $hash_key
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        username
        enabled
        notification_email
        notification_cell
        notification_app
        frequency_instant
        frequency_daily
        frequency_weekly
        hash_key
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLightningDealAlertSettings = /* GraphQL */ `
  query GetLightningDealAlertSettings($id: ID!) {
    getLightningDealAlertSettings(id: $id) {
      id
      user_id
      username
      enabled
      notification_email
      notification_cell
      notification_app
      frequency_instant
      frequency_daily
      frequency_weekly
      hash_key
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listLightningDealAlertSettings = /* GraphQL */ `
  query ListLightningDealAlertSettings(
    $filter: ModelLightningDealAlertSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLightningDealAlertSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        username
        enabled
        notification_email
        notification_cell
        notification_app
        frequency_instant
        frequency_daily
        frequency_weekly
        hash_key
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLightningDealAlertSettings = /* GraphQL */ `
  query SyncLightningDealAlertSettings(
    $filter: ModelLightningDealAlertSettingsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLightningDealAlertSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        user_id
        username
        enabled
        notification_email
        notification_cell
        notification_app
        frequency_instant
        frequency_daily
        frequency_weekly
        hash_key
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const lightningDealAlertSettingsByUser_id = /* GraphQL */ `
  query LightningDealAlertSettingsByUser_id(
    $user_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLightningDealAlertSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lightningDealAlertSettingsByUser_id(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        username
        enabled
        notification_email
        notification_cell
        notification_app
        frequency_instant
        frequency_daily
        frequency_weekly
        hash_key
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const lightningDealAlertSettingsByHash_keyAndUser_id = /* GraphQL */ `
  query LightningDealAlertSettingsByHash_keyAndUser_id(
    $hash_key: String!
    $user_id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLightningDealAlertSettingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    lightningDealAlertSettingsByHash_keyAndUser_id(
      hash_key: $hash_key
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        username
        enabled
        notification_email
        notification_cell
        notification_app
        frequency_instant
        frequency_daily
        frequency_weekly
        hash_key
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBookmark = /* GraphQL */ `
  query GetBookmark($id: ID!) {
    getBookmark(id: $id) {
      id
      user_id
      deal_id
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listBookmarks = /* GraphQL */ `
  query ListBookmarks(
    $filter: ModelBookmarkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookmarks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        deal_id
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBookmarks = /* GraphQL */ `
  query SyncBookmarks(
    $filter: ModelBookmarkFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBookmarks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        user_id
        deal_id
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const bookmarksByUser_id = /* GraphQL */ `
  query BookmarksByUser_id(
    $user_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBookmarkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookmarksByUser_id(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        deal_id
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const bookmarksByDeal_id = /* GraphQL */ `
  query BookmarksByDeal_id(
    $deal_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBookmarkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookmarksByDeal_id(
      deal_id: $deal_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        deal_id
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPointToPointMessages = /* GraphQL */ `
  query GetPointToPointMessages($id: ID!) {
    getPointToPointMessages(id: $id) {
      id
      sender
      receiver
      createdAt
      _ttl
      type
      title
      body
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPointToPointMessages = /* GraphQL */ `
  query ListPointToPointMessages(
    $filter: ModelPointToPointMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPointToPointMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sender
        receiver
        createdAt
        _ttl
        type
        title
        body
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPointToPointMessages = /* GraphQL */ `
  query SyncPointToPointMessages(
    $filter: ModelPointToPointMessagesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPointToPointMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sender
        receiver
        createdAt
        _ttl
        type
        title
        body
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const pointToPointMessagesBySenderAndCreatedAt = /* GraphQL */ `
  query PointToPointMessagesBySenderAndCreatedAt(
    $sender: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPointToPointMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pointToPointMessagesBySenderAndCreatedAt(
      sender: $sender
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sender
        receiver
        createdAt
        _ttl
        type
        title
        body
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const pointToPointMessagesByReceiverAndCreatedAt = /* GraphQL */ `
  query PointToPointMessagesByReceiverAndCreatedAt(
    $receiver: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPointToPointMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    pointToPointMessagesByReceiverAndCreatedAt(
      receiver: $receiver
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sender
        receiver
        createdAt
        _ttl
        type
        title
        body
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getGlobalMessages = /* GraphQL */ `
  query GetGlobalMessages($id: ID!) {
    getGlobalMessages(id: $id) {
      id
      sender
      createdAt
      _ttl
      type
      title
      body
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listGlobalMessages = /* GraphQL */ `
  query ListGlobalMessages(
    $filter: ModelGlobalMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGlobalMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sender
        createdAt
        _ttl
        type
        title
        body
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncGlobalMessages = /* GraphQL */ `
  query SyncGlobalMessages(
    $filter: ModelGlobalMessagesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGlobalMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sender
        createdAt
        _ttl
        type
        title
        body
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const globalMessagesBySenderAndCreatedAt = /* GraphQL */ `
  query GlobalMessagesBySenderAndCreatedAt(
    $sender: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGlobalMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    globalMessagesBySenderAndCreatedAt(
      sender: $sender
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sender
        createdAt
        _ttl
        type
        title
        body
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPreferredUsernameMapping = /* GraphQL */ `
  query GetPreferredUsernameMapping($preferred_username: ID!) {
    getPreferredUsernameMapping(preferred_username: $preferred_username) {
      preferred_username
      owner
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPreferredUsernameMappings = /* GraphQL */ `
  query ListPreferredUsernameMappings(
    $preferred_username: ID
    $filter: ModelPreferredUsernameMappingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPreferredUsernameMappings(
      preferred_username: $preferred_username
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        preferred_username
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPreferredUsernameMappings = /* GraphQL */ `
  query SyncPreferredUsernameMappings(
    $filter: ModelPreferredUsernameMappingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPreferredUsernameMappings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        preferred_username
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const preferredUsernameMappingsByOwner = /* GraphQL */ `
  query PreferredUsernameMappingsByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelPreferredUsernameMappingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    preferredUsernameMappingsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        preferred_username
        owner
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getStore = /* GraphQL */ `
  query GetStore($id: ID!) {
    getStore(id: $id) {
      id
      storeName
      storeNameLowerCase
      count
      recentUpdatesDateTime
      updatedAt
      createdAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const listStores = /* GraphQL */ `
  query ListStores(
    $filter: ModelStoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        storeName
        storeNameLowerCase
        count
        recentUpdatesDateTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncStores = /* GraphQL */ `
  query SyncStores(
    $filter: ModelStoreFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStores(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        storeName
        storeNameLowerCase
        count
        recentUpdatesDateTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const storesByStoreNameLowerCaseAndUpdatedAt = /* GraphQL */ `
  query StoresByStoreNameLowerCaseAndUpdatedAt(
    $storeNameLowerCase: String!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    storesByStoreNameLowerCaseAndUpdatedAt(
      storeNameLowerCase: $storeNameLowerCase
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        storeName
        storeNameLowerCase
        count
        recentUpdatesDateTime
        updatedAt
        createdAt
        _version
        _deleted
        _lastChangedAt
        owner
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAutoFilling = /* GraphQL */ `
  query GetAutoFilling($linkUrl: String!) {
    getAutoFilling(linkUrl: $linkUrl)
  }
`;
