import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CheckIcon from '@mui/icons-material/Check';
// import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import { Button, Grid, IconButton, TextField } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppContext_ } from '../../AppContextProvider';
import Section from '../home/Section';

import useProfileApis from './useProfileApis';

function Profile() {
  const location = useLocation();
  const { user, preferredUsername } = useAppContext_();
  const {
    handleFileUpload,
    sendVerificationCode,
    resetPassword,
    handleLogout,
    savePreferredName,
  } = useProfileApis();
  const navigate = useNavigate();

  const [form, setForm] = useState({
    username: user?.username,
    email: user?.attributes?.email,
    phoneNumber: user?.attributes?.phone_number,
    profilePicture: user?.attributes?.picture,
    emailVerificationCode: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const [preferredName, setPreferredName] = useState(preferredUsername);

  const [changingUsername, setChangingUsername] = useState(false);

  const [verification, setVerification] = useState({
    isDisabled: false,
    countDown: 30,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const showChangePassword = () => {
    navigate(`/reset-password?username=${form.username}`, {
      state: location.pathname,
    });
  };

  const handleImageUploadButton = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (file.size > 4000000) {
      alert('Each image is limited to a maximum size of 4 MB.');
      return;
    }
    await handleFileUpload(file);
    // window.location.reload(true)
  };

  const handleGetEmailVerificationCode = () => {
    sendVerificationCode();
    setVerification((prevState) => ({
      ...prevState,
      isDisabled: true,
    }));
  };

  useEffect(() => {
    let timer;
    if (verification.isDisabled && verification.countDown > 0) {
      timer = setTimeout(() => {
        setVerification((prevState) => ({
          ...prevState,
          countDown: prevState.countDown - 1,
        }));
      }, 1000);
    } else if (verification.countDown === 0) {
      setVerification({
        isDisabled: false,
        countDown: 30,
      });
    }
    return () => clearTimeout(timer);
  }, [verification]);

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
    window.scrollTo(0, 0);
  }, []);

  const handleSave = () => {
    if (form.newPassword.trim() === '') {
      alert('Please enter a new password');
    } else if (form.confirmNewPassword.trim() === '') {
      alert('Please confirm entering the new password');
    } else if (form.emailVerificationCode.trim() === '') {
      alert('Please enter the verification code');
    } else if (form.newPassword !== form.confirmNewPassword) {
      alert('Passwords do not match');
    } else if (form.confirmNewPassword && form.emailVerificationCode) {
      try {
        resetPassword(form.emailVerificationCode, form.confirmNewPassword);
      } catch (err) {
        alert(err);
      }
    }
  };

  return (
    <Section
      startIcon="/profile_picture.svg"
      title={'Profile'}
      content={
        <div className="tw-w-full tw-flex tw-flex-col tw-gap-8 tw-p-4 tw-border-0 md:tw-px-36 md:tw-py-20 tw-justify-center tw-items-center sm:tw-border tw-bg-transparent sm:tw-bg-surface-raised sm:tw-border-surface-border tw-rounded-md">
          <div>
            {form.profilePicture ? (
              <img
                src={`${user?.attributes?.picture}?${Date.now()}`}
                className="tw-h-36 tw-w-36 tw-bg-gray-200 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-object-cover"
              />
            ) : (
              <img
                src={'/blank-profile-picture.png'}
                className="tw-h-36 tw-w-36 tw-bg-gray-200 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-object-cover"
              />
              /*
              <div className="tw-h-36 tw-w-36 tw-bg-gray-200 tw-rounded-full tw-flex tw-justify-center tw-items-center tw-object-cover">
                <PermIdentityOutlinedIcon sx={{ fontSize: '2.75rem' }} />
              </div>
              */
            )}
          </div>
          <Button
            variant="outlined"
            color="black"
            startIcon={<BorderColorOutlinedIcon />}
            component="label"
          >
            Edit Picture
            <input
              type="file"
              accept="image/png, image/jpeg"
              hidden
              onChange={handleImageUploadButton}
            />
          </Button>

          <Grid
            container
            spacing={2}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Grid item xs={12} sm={6} style={{ position: 'relative' }}>
              <TextField
                fullWidth
                label="Username"
                name="preferredName"
                variant="outlined"
                value={preferredName}
                onChange={(event) => setPreferredName(event.target.value)}
                disabled={!changingUsername}
              />
              <IconButton
                onClick={async () => {
                  if (changingUsername === true) {
                    if ((await savePreferredName(preferredName)) === true) {
                      setChangingUsername(false);
                    }
                  } else {
                    setChangingUsername(true);
                  }
                }}
                style={{
                  position: 'absolute',
                  right: 5,
                  top: '50%',
                  transform: 'translateY(-32%)',
                  opacity: 0.5,
                }}
              >
                {changingUsername ? <CheckIcon /> : <BorderColorOutlinedIcon />}
              </IconButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                variant="outlined"
                disabled
                value={form.email}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Phone Number"
                name="phoneNumber"
                variant="outlined"
                disabled
                value={form.phoneNumber}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                fullWidth
                size="large"
                type="submit"
                variant="outlined"
                color="black"
                startIcon={<BorderColorOutlinedIcon />}
                onClick={showChangePassword}
              >
                Change Password
              </Button>
            </Grid>
          </Grid>

          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleLogout}
          >
            Log out
          </Button>
        </div>
      }
    />
  );
}

export default Profile;
