import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


import { Container, Tooltip, Divider, Button, Box, Grid, Typography, Avatar, Card, CardActions, CardContent, Collapse, IconButton, Link } from '@mui/material';


import { Auth } from 'aws-amplify';


import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import ForumIcon from '@mui/icons-material/Forum';

import AdminDashboardPage from './AdminDashboardPage';
import AdminUsersPage from './AdminUsersPage';
import AdminPostsPage from './AdminPostsPage';

function AdminPage(props) {
    const navigate = useNavigate()
    const REACT_APP_PROFILE_BUCKET_NAME = process.env.REACT_APP_PROFILE_BUCKET_NAME;
    const [dialogInfo, setDialogInfo] = useState("");

    const [coguser, setCogUser] = useState(null);
    const [avatarSize, setAvatarSize] = useState(130);
    const [bodyFontSize, setBodyFontSize] = useState('1.2em');
    const [headerFontSize, setHeaderFontSize] = useState('2.4em');
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isLarge = useMediaQuery(theme.breakpoints.up('md'));
    const [tabIndex, setTabIndex] = useState(0);

    useEffect(() => {
        if (isSmall) {
            setAvatarSize(80);
            setHeaderFontSize('2em');
            setBodyFontSize('1em');
        } else if (isMedium) {
            setAvatarSize(80);
            setHeaderFontSize('2em');
            setBodyFontSize('1em');
        } else if (isLarge) {
            setAvatarSize(130);
            setHeaderFontSize('2.4em');
            setBodyFontSize('1.2em');
        }
    }, [isSmall, isMedium, isLarge]);

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleOpenDialog = (info) => {
        setDialogInfo(info);
    };

    const handleCloseDialog = () => {
        setDialogInfo("");
    };

    useEffect(() => {
        Auth.currentAuthenticatedUser()
          .then(user => {
            const groups = user.signInUserSession.accessToken.payload['cognito:groups'];
            if (! groups || ! groups.includes('admin')) {
                navigate('/'); // Redirect to home page if user is not an admin
            }
          })
          .catch(err => {
            console.log(err);
            navigate('/'); // Redirect to home page if user is not logged in
          });

      }, []);


    return (
        <Container>
            <Grid container spacing={2} >
                <Grid item xs={12} >
                    <Box sx={{mx: 'auto', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                        <Tabs
                            value={tabIndex}
                            onChange={handleChange}
                            variant="fullWidth"
                            scrollButtons={false}
                            aria-label="tabs"
                            centered
                        >
                            <Tab icon={<DashboardIcon />} label="Dashboard" />
                            <Tab icon={<PeopleIcon />} label="Users" />
                            <Tab icon={<ForumIcon />} label="Posts" />
                        </Tabs>

                    </Box>
                </Grid>
                <Grid item xs={12}>
                    {tabIndex === 0 && <AdminDashboardPage />}
                    {tabIndex === 1 && <AdminUsersPage />}
                    {tabIndex === 2 && <AdminPostsPage />}
                </Grid>

            </Grid>
            &nbsp;&nbsp;

        <Dialog
            open={dialogInfo}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            Info
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialogInfo}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
        </Container>
    );
  }
  export default AdminPage;
