import { storeList } from '../constants/StoreConstants';

export function URL2Store(deal_link) {
  const lowerCaseDealLink = deal_link.toLowerCase();

  // Check URL_Store_Mapping first
  for (const [key, value] of Object.entries(URL_Store_Mapping)) {
    if (lowerCaseDealLink.includes(key)) {
      console.log('matchedStore from URL_Store_Mapping: ' + value);
      return value;
    }
  }

  // Fallback to checking storeList
  const matchedStore = storeList.find((store) =>
    lowerCaseDealLink.includes(store.toLowerCase().replace(/[^a-zA-Z0-9]/g, ''))
  );
  console.log('matchedStore from storeList: ' + matchedStore);
  return matchedStore;
}

const URL_Store_Mapping = {
  // TODO
  'a.co': 'Amazon',
  'amzn.to': 'Amazon',
};
