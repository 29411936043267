import MoreHoriz from '@mui/icons-material/MoreHoriz';

import { Button, MenuItem, Pagination, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useAppContext_ } from '../../AppContextProvider';
import useDealComments from '../apiHooks/useDealComments';
import IconMenu from '../utils/Menu';

function Comment({
  comment,
  handleEditComment,
  handleDeleteComment,
  handleReportComment,
}) {
  const { user, preferredUsername } = useAppContext_();
  const [readMore, setReadMore] = useState(comment.text.length > 150);
  const [helpful, setHelpful] = useState(false);

  const handleReadMore = () => setReadMore(false);

  const handleHelpful = () => {
    setHelpful(true);
  };

  const daysDifference = (date) => {
    const past = new Date(date);
    const now = new Date();

    const seconds = Math.floor((now - past) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (seconds < 60) {
      return `${seconds} second${seconds === 1 ? '' : 's'} ago`;
    } else if (minutes < 60) {
      return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours === 1 ? '' : 's'} ago`;
    } else if (days < 7) {
      return `${days} day${days === 1 ? '' : 's'} ago`;
    } else if (weeks < 4) {
      return `${weeks} week${weeks === 1 ? '' : 's'} ago`;
    } else if (months < 12) {
      return `${months} month${months === 1 ? '' : 's'} ago`;
    } else {
      return `${years} year${years === 1 ? '' : 's'} ago`;
    }
  };
  let { dealId } = useParams();

  return (
    <div className="tw-relative tw-w-full">
      <div className="tw-w-full tw-p-4 tw-bg-surface-base md:tw-bg-surface-base tw-border tw-border-surface-border tw-rounded-lg tw-flex tw-flex-col tw-gap-y-4">
        <div className="tw-flex tw-gap-x-2 tw-items-center">
          <img
            className="tw-h-8 tw-w-8 tw-bg-gray-600 tw-rounded-full"
            src={comment.poster_img_url}
          />
          <div className="tw-flex tw-flex-col">
            <p className="tw-m-0 tw-text-string-base">{comment.user_name}</p>
            <p className="tw-m-0 tw-text-string-neutral tw-text-sm">
              {daysDifference(comment.updatedAt)}
            </p>
          </div>
        </div>
        <div>
          <div
            style={{ overflowWrap: 'break-word' }}
            className="tw-text-string-base"
          >
            {readMore ? comment.text.slice(0, 150) + '...' : comment.text}
            {readMore && (
              <p
                className="hover:tw-cursor-pointer tw-text-string-neutral tw-font-medium tw-m-0 tw-mt-2"
                onClick={handleReadMore}
              >
                Read more
              </p>
            )}
          </div>
        </div>
        <div className="tw-flex tw-gap-x-6">
          {!helpful ? (
            <p
              className="tw-m-0 tw-text-blue-600 hover:tw-opacity-75 hover:tw-cursor-pointer"
              onClick={handleHelpful}
            >
              Helpful?
            </p>
          ) : (
            <p className="tw-m-0 tw-text-green-600">
              Thanks for your feedback!
            </p>
          )}
          {/*
              <Checkbox
                name="Upvoted"
                // checked={like === null ? null : like.__typename === "Upvoted"}
                // onClick={}
                icon={<ThumbUpAltOutlined size="large" color="blue" />}
                checkedIcon={
                  <ThumbUpTwoToneIcon
                    size="large"
                    style={{
                      color: "#2F88FF",
                    }}
                  />
                }
              />
              <Checkbox
                name="Downvoted"
                // checked={like === null ? null : like.__typename === "Downvoted"}
                // onClick={handleLikeDislike}
                icon={<ThumbDownAltOutlined size="large" color="blue" />}
                checkedIcon={
                  <ThumbDownTwoToneIcon
                    size="large"
                    style={{
                      color: "#2F88FF",
                    }}
                  />
                }
              />
          */}
        </div>
      </div>

      <div className="tw-absolute tw-top-3 tw-right-3">
        <IconMenu
          icon={<MoreHoriz />}
          menuItems={
            preferredUsername === comment.user_name
              ? [
                  <MenuItem onClick={() => handleEditComment(comment)}>
                    Edit
                  </MenuItem>,
                  <MenuItem onClick={() => handleDeleteComment(comment)}>
                    Delete
                  </MenuItem>,
                  <MenuItem onClick={() => handleReportComment(comment)}>
                    Report
                  </MenuItem>,
                ]
              : [
                  <MenuItem onClick={() => handleReportComment(comment)}>
                    Report
                  </MenuItem>,
                ]
          }
        />
      </div>
    </div>
  );
}

function Comments() {
  const { user, preferredUsername } = useAppContext_();
  const { comments, loading, postComment, postEditComment, postDeleteComment } =
    useDealComments();

  const [comment, setComment] = useState({
    text: '',
    id: '',
    edit: false,
    parent: null,
  });
  const [page, setPage] = useState(0);
  const [commentsCopy, setCommentsCopy] = useState(comments);
  const commentsContainer = useRef(null);

  const handleCommentChange = (event) => {
    setComment((prevState) => {
      if (event.target.value.length === 0) {
        return {
          text: '',
          id: '',
          edit: false,
          parent: null,
        };
      } else {
        return { ...prevState, text: event.target.value };
      }
    });
  };

  useEffect(
    () => setCommentsCopy(comments.slice(0 + 10 * page, 10 + 10 * page)),
    [comments]
  );

  const handleSubmit = async () => {
    if (comment.edit) {
      await postEditComment(comment);
    } else {
      await postComment(comment);
    }
    setComment({
      text: '',
      id: '',
      edit: false,
      parent: null,
    });
  };

  const handleEditComment = async (comment) => {
    scrollTopCommentsContainer();
    setComment({
      text: comment.text,
      id: comment.id,
      edit: true,
      parent: comment.reply_to,
      _version: comment._version,
    });
  };

  const handleDeleteComment = async (comment) => {
    await postDeleteComment(comment);
  };

  const handleReportComment = async (comment) => {};

  const scrollTopCommentsContainer = () => {
    commentsContainer.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const handlePagination = (event, value) => {
    scrollTopCommentsContainer();
    setCommentsCopy(
      comments.slice(0 + 10 * (value - 1), 10 + 10 * (value - 1))
    );
    setPage(value - 1);
  };

  if (loading) {
    return (
      <div className="tw-w-full tw-h-96 tw-animate-pulse tw-bg-gray-300 tw-rounded-xl"></div>
    );
  }

  return (
    <div
      ref={commentsContainer}
      className="tw-text-sm tw-gap-3 tw-px-1 md:text-base tw-flex tw-flex-col md:tw-gap-8 tw-border-0 tw-bg-transparent tw-p-0 tw-w-full md:tw-bg-surface-raised md:tw-border tw-border-surface-border-solid tw-border-surface-border md:tw-p-8 tw-rounded-xl tw-items-center"
    >
      <div className="tw-w-full tw-p-4 tw-bg-surface-raised tw-border tw-border-surface-border md:tw-border-0 md:tw-bg-surface-raised tw-rounded-lg tw-flex tw-flex-col tw-gap-y-6">
        {user ? (
          <>
            <div className="tw-flex tw-gap-x-2 tw-items-center">
              <img
                className="tw-h-8 tw-w-8 tw-bg-gray-600 tw-rounded-full"
                src={
                  user.attributes.picture
                    ? user.attributes.picture
                    : '/blank-profile-picture.png'
                }
              />
              <p className="tw-m-0 tw-text-string-base">{preferredUsername}</p>
            </div>
            <TextField
              multiline
              name="text"
              variant="standard"
              placeholder="Add your comment"
              value={comment.text}
              onChange={handleCommentChange}
            />
            <div className="tw-flex tw-justify-end">
              <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={comment.text.length === 0}
              >
                {comment.edit ? 'Edit Comment' : 'Comment'}
              </Button>
            </div>
          </>
        ) : (
          <Link
            className="tw-text-center tw-py-4 tw-font-medium tw-text-string-base tw-no-underline"
            to="/login"
          >
            <span className="tw-text-blue-500 tw-underline tw-underline-offset-2">
              Login
            </span>{' '}
            to add your comment
          </Link>
        )}
        {commentsCopy.map((comment, index) => (
          <Comment
            key={index + Math.random(comments.length)}
            comment={comment}
            handleEditComment={handleEditComment}
            handleDeleteComment={handleDeleteComment}
            handleReportComment={handleReportComment}
          />
        ))}
      </div>

      {commentsCopy.length > 0 && (
        <Pagination
          count={Math.ceil(comments.length / 10)}
          page={page + 1}
          onChange={handlePagination}
          hidePrevButton
          hideNextButton
        />
      )}
    </div>
  );
}

export default Comments;
