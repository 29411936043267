import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, IconButton, TextField } from '@mui/material';
import * as React from 'react';
// import { useState } from 'react';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { useNavigate } from 'react-router-dom';
import { useAppContext_ } from '../../AppContextProvider';
import { categoryList } from '../constants/CategoryConstants';
import { storeList } from '../constants/StoreConstants';
import { cleanAndLowercase } from '../utils/cleanStringForSearch';

const topOptions = [
  'Electronics',
  'Sports and Outdoors',
  'Clothing, Shoes, and Jewelry',
  'Pet Supplies',
  "Home and Kitchen",
  "Health and Household",
  'Amazon',
  'Target',
  'Best Buy',
  'Woot',
  'Costco',
];

const Options = topOptions.concat(categoryList).concat(storeList);

export default function SearchInputTextBox({
  sx,
  isSearchOpen,
  setIsSearchOpen,
}) {
  const { searchHistory, setSearchHistory } = useAppContext_();

  // const [focused, setFocused] = useState(false);
  const navigate = useNavigate();
  const [inputValue, setInputValue] = React.useState('');
  // Create a ref for the TextField
  const inputRef = React.useRef();

  const myOptions = searchHistory
    ? searchHistory
        .filter((item) => item !== null && item !== undefined && item !== '')
        .concat(Options)
    : Options;
  // console.log('searchHistory', searchHistory);

  // Use useEffect to focus the TextField when isSearchOpen is true
  React.useEffect(() => {
    if (isSearchOpen) {
      inputRef.current.focus();
    }
  }, [isSearchOpen]);

  const saveHistory = async (inputStr) => {
    let newSearchHistory = searchHistory || [];
    newSearchHistory = [
      inputStr,
      ...newSearchHistory.filter(
        (item) =>
          item !== inputStr &&
          item !== null &&
          item !== undefined &&
          item !== ''
      ),
    ];
    if (newSearchHistory.length > 5) {
      newSearchHistory.length = 5;
    }
    setSearchHistory(newSearchHistory);
  };

  const handleSearch = (inputStr) => {
    setIsSearchOpen(false);
    if (inputStr !== null && inputStr !== undefined && inputStr !== '') {
      saveHistory(inputStr);
    }
    navigate({
      pathname: '/deals',
      search: buildParam(inputStr),
    });
  };

  const buildParam = (inputText) => {
    if (!inputText) {
      return '';
    }
    if (categoryList.includes(inputText)) {
      // add &forum_types=keyword
      return `&forum_types=${inputText}`;
    } else if (storeList.includes(inputText)) {
      // add &dealer_types=keyword
      return `&dealer_types=${inputText}`;
    }

    let parameterStr = '';
    const keywords = inputText.trim().split(' ');

    // for each keyword
    keywords.forEach((keyword) => {
      if (keyword) {
        const cleanedKeyword = cleanAndLowercase(keyword);
        // add &q=
        parameterStr += `&q=${cleanedKeyword}`;
      }
    });

    return parameterStr;
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch(event.target.value);
    }
  };

  return (
    <>
      <Autocomplete
        fullWidth
        sx={{
          '.MuiOutlinedInput-root': {
            paddingTop: '0px',
            paddingBottom: '0px',
            paddingLeft: '0px',
            paddingRight: '0px',
          },
          '.MuiAutocomplete-inputRoot': {
            paddingTop: '0px !important',
            paddingBottom: '0px !important',
          },
          '.MuiInputBase-input': {
            padding: '0px !important', // Adjust this value as needed
          },
        }}
        freeSolo
        options={myOptions}
        getOptionLabel={(option) => option}
        // renderOption={(props, option, { selected }) => (
        //   <li {...props}>
        //     <div
        //       style={{
        //         display: 'flex',
        //         justifyContent: 'space-between',
        //         width: '100%',
        //       }}
        //     >
        //       <span>{option}</span>
        //       {myOptions.indexOf(option) < searchHistory.length && (
        //         <IconButton size="small">
        //           <HistoryIcon style={{ fontSize: '1.1rem' }} />
        //         </IconButton>
        //       )}
        //     </div>
        //   </li>
        // )}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          // localStorage.setItem('inputValue', newInputValue);
        }}
        inputValue={inputValue}
        open={isSearchOpen ? true : undefined}
        onChange={(event, newValue) => {
          handleSearch(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            placeholder="Search deals, stores, and more..."
            onKeyDown={handleKeyDown}
            inputRef={inputRef}
            InputProps={{
              ...params.InputProps,
              style: { paddingLeft: inputValue.length > 0 ? '16px' : '2px' },
              startAdornment: inputValue.length === 0 && (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  {inputValue.length > 0 && (
                    <IconButton onClick={() => handleSearch(inputValue)}>
                      <SearchIcon />
                    </IconButton>
                  )}
                  {isSearchOpen && (
                    <IconButton onClick={() => setIsSearchOpen(false)}>
                      <CloseFullscreenIcon />
                    </IconButton>
                  )}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
}
