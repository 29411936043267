import { Autocomplete, Checkbox, Chip, TextField } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { useState } from 'react';

function AutocompleteChip(props) {
  const [selected_options, setSelected] = useState([]);
  const [inputValue, setInputValue] = useState("");

  // const [selectAll, setSelectAll] = useState(props.options? selected.length === props.options.length: false);

  const [prop_list, setProp_list] = useState([...props.options])
  const full_list  = [...props.options].unshift("Clear All");
  const normal_list = [...props.options].unshift("Select All");

  const [action, setAction] = useState(
    selected_options.length === props.options.length ? 'Clear All' : 'Select All'
  );

  const handleOptions = () => {
    let options = [...props.options]
    if(options.length === 0){
      return options;
    }
    else{
        if(selected_options.length === options.length){
          return full_list
        }
        else{
          return normal_list;
        }
    }
  };


  const handleChange = (event, newValue) => {
    if (
      props.maxSelections === undefined ||
      newValue.length <= props.maxSelections
    ) {
      props.onChange(props.name, newValue);
      setSelected(newValue);
    }
  };

  /**
   * Handle input event when the page is loading
   *
   * This element will only be rendered when the page is still loading.
   * Adding input inside will be overwritten by later input.
*/
  if(props.options === undefined){
    return (
      <Autocomplete
      {...props}
      multiple
      value={props.value}
      options={["loading"]}
      getOptionDisabled={(option) => {
        if (selected_options.length === props.maxSelections) {
          return selected_options.indexOf(option) === -1 ? true : false;
        }
      }}
      onChange={handleChange}
      disableCloseOnSelect
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox checked={selected} />
          {option}
        </li>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={props.inputRef}
          error={props.error}
          required={props.required}
          label={props.label}
          helperText={props.helperText}
          placeholder={props.placeholder}
          inputProps={{
            ...params.inputProps,
            onKeyDown: (e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            },
          }}
        />
      )}
    />
    )
  }
  /**
   * Handle input event for adding keywords
   *
   * This method is for users to add one/multiple search keywords for their alerts.
   * Different from Categories and Brands, which users are required to select from
   * available options, the Keywords input box allow users to 1. enter their own
   * keywords 2. use space or comma to end a search input
*/
  if(props.options && props.label === 'Keywords'){
    return (
    <Autocomplete
      {...props}
      multiple
      freeSolo
      value={props.value}
      inputValue={inputValue}
      options={props.options? props.options:["loading options"]}
      filterOptions={(options, params) => { // <<<--- inject the Select All option
          return options;
      }}
      getOptionDisabled={(option) => {
        if (selected_options.length === props.maxSelections) {
          return selected_options.indexOf(option) === -1 ? true : false;
        }
      }}
      isOptionEqualToValue={(option, value) => option === value}
      //onChange={handleChange}
      onChange={(event, newValue) => {
        // console.log(props);
        props.onChange(props.name, newValue);
        setSelected(newValue)
      }}
      onInputChange={(event, newInputValue) => {
        const options = newInputValue.split(" ");

        if (options.length > 1) {
          props.onChange(props.name,
            selected_options
              .concat(options)
              .map(x => x.trim())
              .filter(x => x)
          );
          setSelected(
            selected_options
              .concat(options)
              .map(x => x.trim())
              .filter(x => x)
          );
          setInputValue('');
        } else {
          setInputValue(newInputValue);
        }
      }}
      disableCloseOnSelect
      renderOption={(props, option, { selected }) => (
          <li {...props}>
            {option === 'Select All'? (
             <Checkbox
                // checked={selected}
                /**/
                checked={!!(prop_list.length === selected_options.length)}
              />
            ) : (
              <Checkbox
                // checked={selected}
                /**/
                checked={selected}
              />
            )}
            {option}
          </li>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          // inputRef={props.inputRef}
          error={props.error}
          required={props.required}
          label={props.label}
          helperText={props.helperText}
          placeholder={props.placeholder}
          inputProps={{
            ...params.inputProps,
            onKeyDown: (e) => {
              // console.log(e);
              if (e.key === 'Enter') {
                // alert('hello');
                e.preventDefault();
              }
              /*
              if(e.code === 'Space' || e.code === 'Comma'){
                e.preventDefault();
              }
              */
            },
          }}
        />
      )}
    />
    )
  }
  /**
   * Handle input event for Brands or Categories
   *
   * Users are allowed to type to search for brands and categories
   * They are then allowed to select one or multiple brands/categories
   * from the Dropdown list. We also provide a Select All option to
   * select/unselect all options. Users can only click options or press
   * ENTER to confirm an option.
*/
  return (
    <Autocomplete
      {...props}
      multiple
      freeSolo
      value={props.value}
      options={props.options? props.options:["loading options"]}
      filterOptions={(options, params) => { // <<<--- inject the Select All option
        if(props.label !== 'Keywords'){
          const filter = createFilterOptions()
          const filtered = filter(options, params);
          //console.log('filter:', filtered);
          let final =  ['Select All', ...filtered];
          //console.log('final',final);
          return final;
        }
        else{
          return options;
        }
      }}
      // getOptionLabel={(option) => option.title? option.title:option}
      getOptionDisabled={(option) => {
        if (selected_options.length === props.maxSelections) {
          return selected_options.indexOf(option) === -1 ? true : false;
        }
      }}
      isOptionEqualToValue={(option, value) => option === value}
      //onChange={handleChange}
      onChange={(event, newValue) => {
        //console.log('newValue:', newValue);
        //console.log(selected_options.length, selected_options);
        // newValue.find((option) => option === "Select All");
        if (newValue.find((option) => option === "Select All")){
          if(prop_list){
            //console.log('hello');
            //console.log(selected_options.length, prop_list.length);
            props.onChange(props.name, selected_options.length === prop_list.length? [] : prop_list);
            // setSelected(selected.length === props.options.length ? [] : props.options)

            // setSelectAll(props.options? selected.length === props.options.length: false);

            /**/
            // setSelected(selected.length === props.options.length? [] : ["Select All", ...props.options])
            return setSelected(selected_options.length === prop_list.length? [] : props.options)
          }
        }
        else{
          props.onChange(props.name, newValue);
          setSelected(newValue)
        }
      }}
      disableCloseOnSelect
      renderOption={(props, option, { selected }) => (
          <li {...props}>
            {option === 'Select All'? (
             <Checkbox
                // checked={selected}
                /**/
                checked={!!(prop_list.length === selected_options.length)}
              />
            ) : (
              <Checkbox
                // checked={selected}
                /**/
                checked={selected}
              />
            )}
            {option}
          </li>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          // inputRef={props.inputRef}
          error={props.error}
          required={props.required}
          label={props.label}
          helperText={props.helperText}
          placeholder={props.placeholder}
          inputProps={{
            ...params.inputProps,
            onKeyDown: (e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            },
          }}
        />
      )}
    />
  );
}

export default AutocompleteChip;
