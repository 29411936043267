import { Grid } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import ngeohash from 'ngeohash';
import React, { useEffect, useState } from 'react';
import { statesCenterList, statesList } from '../constants/StatesConstants';
import usCities from '../constants/us-cities.json';

const mapContainerStyle = {
  height: '450px',
};

const options = {
  // Your map options
};

const libraries = ['places'];

const REACT_APP_GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

export default function AddressSelector({ onAddressChange, storeName }) {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: REACT_APP_GOOGLE_MAP_API_KEY,
    libraries,
  });

  const [state, setState] = useState('');
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');

  const defaultCenter = { lat: 39.8283, lng: -98.5795 }; // Center of the US
  const defaultZoom = 4; // Zoom level to show the entire US

  // Define state for the map center and zoom level
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [zoom, setZoom] = useState(defaultZoom);

  const [markers, setMarkers] = useState([]);

  const mapRef = React.useRef();
  const [mapLoaded, setMapLoaded] = useState(false);

  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
    setMapLoaded(true);
  }, []);

  // setMapCenterByIpAddress
  useEffect(() => {
    const setMapCenterByIpAddress = async () => {
      try {
        const response = await fetch(
          `https://www.googleapis.com/geolocation/v1/geolocate?key=${REACT_APP_GOOGLE_MAP_API_KEY}`,
          {
            method: 'POST',
            body: JSON.stringify({
              considerIp: true,
            }),
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        const data = await response.json();
        const position = data.location;
        setMapCenter({
          lat: position.lat,
          lng: position.lng,
        });
        setZoom(9); // Zoom level to show the area around the IP address
      } catch (error) {
        console.error("Error getting user's location: ", error);
      }
    };
    setMapCenterByIpAddress();
  }, []);

  useEffect(() => {
    if (state) {
      const stateCities = usCities[state]; // Get the cities of the selected state
      setCities(stateCities);
    } else {
      setCities([]);
    }
  }, [state]);

  // This useEffect is for geocoding
  useEffect(() => {
    if (state && city) {
      // If a state and city are selected, geocode the address to get the lat/lng
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: `${city}, ${state}` }, (results, status) => {
        if (status === 'OK') {
          const position = results[0].geometry.location;
          setMapCenter({
            lat: position.lat(),
            lng: position.lng(),
          });
          setZoom(10); // Zoom level to show the city
        }
      });
    } else if (state) {
      // If only a state is selected, find the center of the state
      const stateObj = statesCenterList.find((s) => s.name === state);
      if (stateObj) {
        const stateCenter = stateObj.center;
        setMapCenter(stateCenter);
        setZoom(6); // Zoom level to show the state
      } else {
        // Handle the case where the state is not found in the list
        console.error(`State ${state} not found in statesList`);
      }
    } else {
      // If no state or city is selected, show the entire US
      setMapCenter(defaultCenter);
      setZoom(defaultZoom);
    }
  }, [state, city]);

  // This useEffect is for nearby search
  useEffect(() => {
    if (!mapLoaded) return;

    if ((!state || !city) && zoom < 9) return;

    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend(
      new window.google.maps.LatLng(mapCenter.lat + 1.45, mapCenter.lng + 1.45)
    );
    bounds.extend(
      new window.google.maps.LatLng(mapCenter.lat - 1.45, mapCenter.lng - 1.45)
    );
    const service = new window.google.maps.places.PlacesService(mapRef.current);

    service.nearbySearch(
      {
        bounds,
        type: ['store'],
        keyword: storeName,
      },
      (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const filteredResults = results.filter((result) =>
            result.name.toLowerCase().includes(storeName.toLowerCase())
          );
          setMarkers(
            filteredResults.map((result) => ({
              lat: result.geometry.location.lat(),
              lng: result.geometry.location.lng(),
              name: result.name, // store the name of the place
              address: result.vicinity, // store the address of the place
              place_id: result.place_id, // store the place_id of the place
            }))
          );
        }
      }
    );
  }, [mapCenter, mapLoaded]);

  useEffect(() => {
    if (!address) {
      return;
    }

    // setMapCenter({
    //     lat: address.lat,
    //     lng: address.lng,
    // });

    const service = new window.google.maps.places.PlacesService(mapRef.current);
    const request = {
      placeId: address.place_id,
      fields: ['address_component'],
    };
    service.getDetails(request, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const postalCode = place.address_components.find((component) =>
          component.types.includes('postal_code')
        )?.short_name;
        // var ngeohash = require('ngeohash');
        const geohashcode = ngeohash.encode(address.lat, address.lng);
        console.log('Address: ' + address.address);
        console.log('Zipcode: ' + postalCode);
        console.log('lat: ' + address.lat + '; lng: ' + address.lng);
        console.log('Geohash: ' + geohashcode);
        console.log('PlaceId: ' + address.place_id);
        onAddressChange &&
          onAddressChange(
            address.address,
            postalCode,
            geohashcode,
            address.place_id
          );
      }
    });
  }, [address]);

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading Maps';

  return (
    <Grid container>
      <Grid item xs={4} md={2} mt={1}>
        <Autocomplete
          options={statesList}
          getOptionLabel={(option) => option}
          style={{ width: '100%' }}
          value={state}
          size="small"
          onChange={(event, newValue) => {
            if (!newValue) {
              return;
            }
            setState(newValue);
            setCity('');
            setAddress('');
            onAddressChange && onAddressChange('', '');
          }}
          renderInput={(params) => (
            <TextField {...params} label="State" variant="outlined" />
          )}
        />
      </Grid>
      <Grid item xs={8} md={3} mt={1}>
        <Autocomplete
          options={cities}
          getOptionLabel={(option) => option}
          style={{ width: '100%' }}
          value={city}
          size="small"
          onChange={(event, newValue) => {
            if (!newValue) {
              return;
            }
            setCity(newValue);
            setAddress('');
            onAddressChange && onAddressChange('', '');
          }}
          renderInput={(params) => (
            <TextField {...params} label="City" variant="outlined" />
          )}
        />
      </Grid>
      <Grid item xs={12} md={7} mt={1}>
        <Autocomplete
          options={markers}
          getOptionLabel={(option) => (option ? option.address || '' : '')}
          style={{ width: '100%' }}
          value={address}
          size="small"
          onChange={(event, newValue) => {
            if (!newValue) {
              return;
            }
            setAddress(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Addresss" variant="outlined" />
          )}
        />
      </Grid>
      <Grid item xs={12} mt={1}>
        {mapCenter && (
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={zoom}
            center={mapCenter}
            options={options}
            onLoad={onMapLoad}
            style={{ width: '100%' }}
          >
            {markers.map((marker, index) => (
              <Marker
                key={index}
                position={{ lat: marker.lat, lng: marker.lng }}
                onClick={() => setAddress(marker)}
                icon={{
                  url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
                  scaledSize: new window.google.maps.Size(40, 40),
                  ...(marker === address && {
                    // Use the selected marker id for comparison
                    url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
                  }),
                }}
              />
            ))}
          </GoogleMap>
        )}
      </Grid>
    </Grid>
  );
}
