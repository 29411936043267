import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { statesList } from '../constants/StatesConstants';
import AddressSelector from '../map/AddressSelector2';

const ListDemo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export function DealEditorAddressSelector({
  dealDetails,
  setDealDetails,
  setDialogInfo,
}) {
  const [availabilityType, setAvailabilityType] = useState('most_location');

  const [addressSelectorOpen, setAddressSelectorOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedAddressZipCode, setSelectedAddressZipCode] = useState(null);
  const [selectedAddressGeoHash, setSelectedAddressGeoHash] = useState(null);
  const [selectedAddressPlaceId, setSelectedAddressPlaceId] = useState(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dense, setDense] = useState(false);

  const handleDeleteAddress = (index) => {
    setDealDetails((prevDealDetails) => ({
      ...prevDealDetails,
      available_store_addresses: prevDealDetails.available_store_addresses
        ? prevDealDetails.available_store_addresses.filter(
            (_, i) => i !== index
          )
        : [],
      available_store_zipcodes: prevDealDetails.available_store_zipcodes
        ? prevDealDetails.available_store_zipcodes.filter((_, i) => i !== index)
        : [],
      available_store_geohashes: prevDealDetails.available_store_geohashes
        ? prevDealDetails.available_store_geohashes.filter(
            (_, i) => i !== index
          )
        : [],
      available_store_placeID: prevDealDetails.available_store_placeID
        ? prevDealDetails.available_store_placeID.filter((_, i) => i !== index)
        : [],
    }));
  };

  const generateAvailableStoresList = () => {
    return dealDetails.available_store_addresses?.map((address, index) => {
      const zipcode = dealDetails.available_store_zipcodes[index];
      return (
        <ListItem key={index}>
          <ListItemText primary={`${address} (${zipcode})`} />
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => handleDeleteAddress(index)}
          >
            <DeleteIcon />
          </IconButton>
        </ListItem>
      );
    });
  };

  const handleAddAddress = (address, zipcode, geohash, placeId) => {
    setDealDetails((prevDealDetails) => {
      // Check if the address already exists in the array
      if (prevDealDetails.available_store_addresses.includes(address)) {
        return prevDealDetails;
      }
      return {
        ...prevDealDetails,
        available_store_addresses: [
          ...prevDealDetails.available_store_addresses,
          address,
        ],
        available_store_zipcodes: [
          ...prevDealDetails.available_store_zipcodes,
          zipcode,
        ],
        available_store_geohashes: [
          ...prevDealDetails.available_store_geohashes,
          geohash,
        ],
        available_store_placeID: [
          ...prevDealDetails.available_store_placeID,
          placeId,
        ],
      };
    });
    handleAddressSelectorClose();
    setSelectedAddress(null);
    setSelectedAddressZipCode(null);
    setSelectedAddressGeoHash(null);
    setSelectedAddressPlaceId(null);
  };

  // Function to handle closing the dialog
  const handleAddressSelectorClose = () => {
    setAddressSelectorOpen(false);
  };

  // Function to handle opening the dialog
  const handleAddressSelectorOpen = () => {
    if (!dealDetails.dealer_type) {
      setDialogInfo('Store is not selected. Please select a store.');
      return;
    }
    setAddressSelectorOpen(true);
  };

  const handleAvailabilityChange = (value) => {
    // Reset all availability types
    let newDealDetails = {
      ...dealDetails,
      instore: dealDetails.instore,
      specific_states: value === 'specific_stores' ? false : true,
      available_states: value === 'most_location' ? ['ALL'] : [],
      specific_stores: value === 'specific_stores' ? true : false,
    };

    setDealDetails(newDealDetails);
    // console.log('newDealDetails: ', newDealDetails);
  };

  useEffect(() => {
    const getAvailabilityValue = () => {
      // Reset all availability types
      if (dealDetails.specific_stores) return 'specific_stores';
      if (
        dealDetails.specific_states &&
        Array.isArray(dealDetails.available_states)
      ) {
        if (dealDetails.available_states.includes('ALL')) {
          return 'most_location';
        } else {
          return 'specific_states';
        }
      }
      return 'most_location';
    };
    const new_state = getAvailabilityValue();
    setAvailabilityType(new_state);
    handleAvailabilityChange(new_state);
  }, []);

  return (
    <>
      {' '}
      <FormGroup className="mb-3" controlId="formBasicText">
        <FormLabel>Address</FormLabel>
        <RadioGroup
          aria-label="options"
          name="options"
          value={availabilityType}
          onChange={(event) => {
            setAvailabilityType(event.target.value);
            handleAvailabilityChange(event.target.value);
            // setInStorePickup(event.target.value === 'instore');
          }}
          row
        >
          <FormControlLabel
            value="most_location"
            control={<Radio />}
            label="Most Locations"
            className='tw-text-string-base'
          />
          {/* <FormControlLabel
            value="specific_states"
            control={<Radio />}
            label="Some States"
          /> */}
          <FormControlLabel
            value="specific_stores"
            control={<Radio />}
            label="Some Addresses"
            className='tw-text-string-base'
          />
        </RadioGroup>
      </FormGroup>
      {availabilityType === 'specific_states' && (
        <FormGroup className="mb-3" controlId="formBasicText">
          <FormLabel>States *</FormLabel>
          <Autocomplete
            multiple
            id="tags-outlined"
            options={statesList}
            getOptionLabel={(option) => option}
            defaultValue={dealDetails.available_states}
            disableCloseOnSelect
            disablePortal
            // style={{ marginTop: '10px' }}
            // limitTags={5}
            onChange={(event, value) => {
              setDealDetails({
                ...dealDetails,
                available_states: value.sort(),
              });
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="" />
            )}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  // icon={icon}
                  // checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            style={{ marginBottom: '40px' }}
          />
        </FormGroup>
      )}
      {availabilityType === 'specific_stores' && (
        <>
          <FormGroup className="mb-3" controlId="formBasicText">
            <FormLabel>Addresses *</FormLabel>
            <div
              style={{
                border: isDragging ? '3px dashed #000' : '1px dashed #000',
                padding: isDragging ? '100px' : '10px',
                textAlign: 'center',
                backgroundColor: '#fff',
              }}
              className='tw-text-string-base'
            >
              <ListDemo>
                <List dense={dense}>
                  {generateAvailableStoresList()}
                  <ListItem
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <IconButton
                      edge="end"
                      aria-label="add"
                      onClick={() => handleAddressSelectorOpen()}
                    >
                      <AddIcon />
                    </IconButton>
                  </ListItem>
                </List>
              </ListDemo>
            </div>
          </FormGroup>
          <Dialog
            // sx={{ zIndex: 9901}}
            open={addressSelectorOpen}
            onClose={(event, reason) => {
              if (reason !== 'backdropClick') {
                handleAddressSelectorClose();
              }
            }}
            aria-labelledby="address-selector-dialog"
            maxWidth="md"
            fullWidth={true}
            PaperProps={{
              style: {
                height: '660px',
                width: '800px',
              },
            }}
          >
            <DialogTitle id="address-selector-dialog">
              Address Selector ({dealDetails.dealer_type})
            </DialogTitle>
            <DialogContent>
              <AddressSelector
                onAddressChange={(address, zipcode, geohash, placeId) => {
                  setSelectedAddress(address);
                  setSelectedAddressZipCode(zipcode);
                  setSelectedAddressGeoHash(geohash);
                  setSelectedAddressPlaceId(placeId);
                }}
                storeName={dealDetails.dealer_type}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  handleAddAddress(
                    selectedAddress,
                    selectedAddressZipCode,
                    selectedAddressGeoHash,
                    selectedAddressPlaceId
                  )
                }
                disabled={
                  !selectedAddress ||
                  !selectedAddressZipCode ||
                  !selectedAddressGeoHash ||
                  !selectedAddressPlaceId
                }
              >
                Add
              </Button>
              <Button
                onClick={() => {
                  handleAddressSelectorClose();
                  setSelectedAddress(null);
                  setSelectedAddressZipCode(null);
                  setSelectedAddressGeoHash(null);
                  setSelectedAddressPlaceId(null);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}

export default DealEditorAddressSelector;
