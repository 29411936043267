import { Box } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as React from 'react';
import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Link, useNavigate } from 'react-router-dom';
import { useAppContext_ } from '../../AppContextProvider';

import { useTheme } from '@mui/material';

function Banner() {
  const theme = useTheme();
  /*
  const {
    isAuthenticated,
    setIsAuthenticated,
    user_profile,
    setUserProfile,
  } = useAppContext();
  */
  const { user, setUser, HomeCategoryValue, setHomeCategoryValue } =
    useAppContext_();
  const navigate = useNavigate();

  /*Handler for clicking the header images*/
  const [HeaderImgValue, setHeaderImgValue] = useState();
  const handleHeaderImgChange = (event, newValue) => {
    setHeaderImgValue(newValue);
    if (newValue === 'cat') {
      navigate('/categoryForum', { state: { name: 'Electronics' } });
    } else if (newValue === 'post') {
      if (user) {
        // setIsAuthenticated(true);
        navigate('/share-deal');
        //setAnchorEl(null);
      } else {
        navigate('/login');
        //setAnchorEl(null);
        // console.log('hello');
      }
    } else if (newValue === 'alert') {
      if (!user) {
        navigate('/login?next_page=AlertList');
        //setAnchorEl(null);
      } else {
        // navigate('/AlertList');
        navigate('/alerts');
        //setAnchorEl(null);
      }
    } else if (newValue === 'map') {
      navigate('/threads');
    } else if (newValue === 'contact') {
      navigate('/contacts');
    } else if (newValue === 'featured') {
      window.open(
        'https://twitter.com/LordOfSavings?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
        '_blank',
        'noopener,noreferrer'
      );
    }
  };

  return (
    <Box fullWidth className="tw-py-1">
      <div className="tw-rounded-sm tw-flex tw-gap-x-1 tw-p-1">
        {/*
        <ul className="tw-flex tw-flex-col tw-whitespace-nowrap">
          {sections.map((entry, index) => (
            <li
              key={index}
              className="group hover:tw-cursor-pointer hover:tw-bg-purple-50 tw-flex-1 tw-flex tw-justify-between tw-items-center tw-gap-x-4 tw-px-3 tw-rounded-md"
            >
              {entry}
              <div className="group-hover:tw-opacity-100 tw-opacity-0">
                <KeyboardArrowRightRoundedIcon />
              </div>
            </li>
          ))}
        </ul>
        */}
        <Box sx={{ display: { xs: 'block', md: 'none' }, width: '100%' }}>
          <Carousel data-bs-theme="dark" slide={true} controls={false}>
            <Carousel.Item interval={5000}>
              <Link to={`/deals`}>
                <img
                  className="tw-w-full"
                  src="/new_banner1.jpg"
                  alt="First banner"
                />
              </Link>
            </Carousel.Item>
            <Carousel.Item interval={5000}>
              <Link to={`/map`}>
                <img
                  className="tw-w-full"
                  src={
                    theme.palette.mode === 'dark'
                      ? '/new_banner2_black.jpg'
                      : '/new_banner2.jpg'
                  }
                  alt="Second banner"
                />
              </Link>
            </Carousel.Item>
            <Carousel.Item interval={5000}>
              <Link to={user ? '/alerts' : '/login'}>
                <img
                  className="tw-w-full"
                  src="/new_banner3.jpg"
                  alt="Third slide"
                />
              </Link>
            </Carousel.Item>
            <Carousel.Item interval={5000}>
              <Link to={`/lightning`}>
                <img
                  className="tw-w-full"
                  src="/new_banner4_v2.jpg"
                  alt="Fourth slide"
                />
              </Link>
            </Carousel.Item>
          </Carousel>
        </Box>

        <Box
          sx={{ display: { xs: 'none', md: 'block' }, width: '100%' }}
          className="banner-carousel"
        >
          <Carousel id="carousel-banner" slide={true}>
            <Carousel.Item interval={5000}>
              <Link to={`/deals`}>
                <img
                  className="tw-w-full"
                  src="/new_banner1.jpg"
                  alt="First banner"
                />
              </Link>
            </Carousel.Item>
            <Carousel.Item interval={5000}>
              <Link to={`/map`}>
                <img
                  className="tw-w-full"
                  src={
                    theme.palette.mode === 'dark'
                      ? '/new_banner2_black.jpg'
                      : '/new_banner2.jpg'
                  }
                  alt="Second banner"
                />
              </Link>
            </Carousel.Item>
            <Carousel.Item interval={5000}>
              <Link to={user ? '/alerts' : '/login'}>
                <img
                  className="tw-w-full"
                  src="/new_banner3.jpg"
                  alt="Third slide"
                />
              </Link>
            </Carousel.Item>
            <Carousel.Item interval={5000}>
              <Link to={`/lightning`}>
                <img
                  className="tw-w-full"
                  src="/new_banner4_v2.jpg"
                  alt="Fourth slide"
                />
              </Link>
            </Carousel.Item>
          </Carousel>
        </Box>

        {/*
        <div className="tw-flex-1 tw-rounded-md tw-overflow-hidden">
          <Tabs value={HeaderImgValue} onChange={handleHeaderImgChange}
              variant="scrollable"
              allowScrollButtonsMobile
              className="tw-object-contain"
          >
            <Tab icon={<img src="/header_cat.png"
                          alt = "Category header"
                          // className="tw-object-contain"
                          style = {{ "width": "100%"}}
                      />
                  }
                value="cat"
                style = {{"width": "100%",
                "minWidth": "100%",}}
            />
            <Tab icon={<img src="/header_post.png"
                    alt = "Post A Deal header"
                    style = {{ "width": "100%"}}
                    />
                  }
                value="post"
                style = {{"width": "100%",
                "minWidth": "100%",}}
            />
            <Tab icon={<img src="/header_alert.png"
                    alt = "Alert Header"
                    style = {{ "width": "100%"}}
                    />
                  }
                value="alert"
                style = {{"width": "100%",
                "minWidth": "100%",}}
            />
            {
            <Tab icon={<img src="/header_recent.png"
                    alt = "Recent Deal Header"
                    style = {{ "width": "100%"}}
                    />
                  }
                value="map"
                style = {{"width": "100%",
                "minWidth": "100%",}}
            />
              }
            <Tab icon={<img src="/header_contact.png"
                    alt = "Contact Us Header"
                    style = {{ "width": "100%"}}
                    />
                  }
                value="contact"
                style = {{"width": "100%",
                "minWidth": "100%",}}
            />
            <Tab icon={<img src="/header_featured.png"
                    alt = "Featured Header"
                    style = {{ "width": "100%"}}
                    />
                  }
                value="featured"
                style = {{"width": "100%",
                "minWidth": "100%",}}
            />
          </Tabs>

        </div>
        */}
      </div>
    </Box>
  );
}

export default Banner;
