import {
  AppBar,
  Avatar,
  Badge,
  Button,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import LocalOfferTwoToneIcon from '@mui/icons-material/LocalOfferTwoTone';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import {
  Link,
  createSearchParams,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useAppContext_ } from '../../AppContextProvider';
import Search from './Search';

// import Topbar from "./components/forum/custom_ui/Topbar"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { categoryList } from '../constants/CategoryConstants';

const Header = () => {
  const theme = useTheme(); // Access the current theme
  const [show, setShow] = useState();

  // Function to get the background color based on the theme mode
  const backgroundColor = () => {
    return theme.palette.mode === 'light' ? '#FFFFFF' : undefined;
  };
  const { user, setUser, HomeCategoryValue, setHomeCategoryValue } =
    useAppContext_();
  const featuredCategories = [
    'Top Deals',
    'Lightning Deals', //
    'Brands',
    'Electronics',
    'Sports and Outdoors',
    'Clothing, Shoes, and Jewelry',
    'Pet Supplies',
    "Home and Kitchen",
    "Health and Household",
  ];
  // <div style={{position: 'absolute', top: '90%', left: '50%', transform: 'translate(-50%, -50%)', color: 'black', fontWeight: 'bold', backgroundColor: 'white'}}></div>
  const styles = {
    textTransform: 'none',
  };

  const navigate = useNavigate();
  const location = useLocation();
  // const history = createHistory();

  function handleNav(entry) {
    // alert(entry);
    //console.log(location.pathname);
    if (
      location.pathname == '/categoryForum' ||
      location.pathname == '/DealerForum'
    ) {
      // console.log('entry:', entry);
      if (entry == 'Top Deals') {
        navigate({
          pathname: '/deals',
          search: createSearchParams({
            sort_by: entry,
          }).toString(),
        });
      } else if (entry == 'Lightning Deals') {
        navigate({
          pathname: '/lightning',
          search: createSearchParams({
            sort_by: 'Most Recent',
          }).toString(),
        });
      }
      //
      else if (entry == 'Brands') {
        navigate({
          pathname: '/brands',
          /*
          search: createSearchParams({
            sort_by: 'Most Recent',
          }).toString(),
          */
        });
      } else {
        navigate({
          pathname: '/deals',
          search: createSearchParams({
            forum_types: [entry],
          }).toString(),
        });
      }
      window.location.reload(true);
    } else {
      // setHomeCategoryValue(entry);
      // console.log('entry:', entry);
      if (entry == 'Top Deals') {
        navigate({
          pathname: '/deals',
          search: createSearchParams({
            sort_by: entry,
          }).toString(),
        });
      } else if (entry == 'Lightning Deals') {
        navigate({
          pathname: '/lightning',
          search: createSearchParams({
            sort_by: 'Most Recent',
          }).toString(),
        });
      }
      //
      else if (entry == 'Brands') {
        navigate({
          pathname: '/brands',
          /*
          search: createSearchParams({
            sort_by: 'Most Recent',
          }).toString(),
          */
        });
      } else {
        navigate({
          pathname: '/deals',
          search: createSearchParams({
            forum_types: [entry],
          }).toString(),
        });
      }
      // window.location.reload(true);
    }
  }

  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = async (event, value) => {
    setAnchorEl(null);
    //navigate("/categoryForum", { state: { name: res } });
  };

  const toggleCatButton = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleButtonClick = async (event) => {
    // alert(event.target.innerText);
    setAnchorEl(null);
    // navigate('/categoryForum', { state: { name: event.target.innerText } });
    navigate({
      pathname: '/deals',
      search: createSearchParams({
        forum_types: [event.target.innerText.trim()],
      }).toString(),
    });
    // window.location.reload(true);
    //navigate("/");
  };

  const catDropdown = (
    <div>
      Category
      {/* <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      > */}
      <ExpandMoreIcon />
      {/* </IconButton> */}
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            // maxHeight: ITEM_HEIGHT * 4.5,
            width: '25ch',
          },
        }}
      >
        {categoryList.map((option) => (
          <MenuItem key={option} onClick={handleButtonClick} value={option}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );

  useEffect(() => {
    if (location.pathname.includes('deal-details')) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [location]);

  return (
    <>
      <div className="tw-w-full tw-text-center tw-bg-surface-base tw-text-string-base tw-py-1">
        <Typography
          variant="body1"
          component="body1"
          sx={{
            fontSize: '12px',
            padding: '3px',
          }}
        >
          LordOfSavings is a community supported website. We may earn from
          qualifying purchases.
        </Typography>
      </div>
      <div className={`${!show && 'tw-hidden sm:tw-block'}`}>
        <AppBar
          position="static"
          sx={{
            color: 'black',
            boxShadow: 'none',
            // backgroundColor: backgroundColor(),
            backgroundImage: '',
          }}
          className="!tw-bg-surface-raised !tw-bg-none"
        >
          <Toolbar>
            <div className="tw-w-full tw-flex tw-justify-between tw-items-center">
              <div className="tw-flex tw-text-nowrap tw-w-full tw-justify-between tw-items-center tw-gap-x-4">
                <div className="tw-flex tw-text-nowrap tw-flex-1 tw-items-center tw-gap-x-5">
                  {/* Logo */}
                  <Link to="/">
                    <img
                      src="/logo.png"
                      className="tw-h-10 tw-w-10 tw-object-contain"
                    />
                  </Link>

                  {/* Deals Button */}
                  <Button
                    className="!tw-hidden lg:!tw-flex"
                    // component={Link}
                    // to="/categoryForum"
                    color="inherit"
                    onClick={toggleCatButton}
                    startIcon={
                      <MenuTwoToneIcon
                        style={{
                          color: '#A57EE9', // Inside color
                          // border: "2px solid #F24E1E", // Outline color and width
                        }}
                      />
                    }
                    sx={styles}
                  >
                    <div className="tw-text-string-base tw-whitespace-nowrap">
                      {catDropdown}
                    </div>
                  </Button>

                  {/* Categories Button */}
                  <Button
                    className="!tw-hidden lg:!tw-flex"
                    component={Link}
                    to="/deals"
                    color="inherit"
                    startIcon={
                      <LocalOfferTwoToneIcon
                        style={{
                          color: '#F24E1E', // Inside color
                          // border: "2px solid #F24E1E", // Outline color and width
                        }}
                      />
                    }
                    sx={styles}
                  >
                    <div className="tw-text-string-base tw-whitespace-nowrap">
                      Deals
                    </div>
                  </Button>

                  {/* Find In-Store Deals Button */}
                  <Button
                    className="!tw-hidden lg:!tw-flex"
                    component={Link}
                    to="/map"
                    color="inherit"
                    sx={styles}
                    startIcon={
                      <StorefrontTwoToneIcon
                        style={{
                          color: '#0d7e12', // Inside color
                          // border: "2px solid #F24E1E", // Outline color and width
                        }}
                      />
                    }
                  >
                    <div className="tw-text-string-base tw-whitespace-nowrap">
                      In-Store Deals
                    </div>
                  </Button>

                  {/* Share a Deal Button */}
                  <Button
                    className="!tw-hidden lg:!tw-flex"
                    component={Link}
                    to={user ? '/share-deal' : '/login'}
                    color="inherit"
                    sx={styles}
                    startIcon={
                      <AddBoxTwoToneIcon
                        style={{
                          color: '#2F88FF', // Inside color
                          // border: "2px solid #F24E1E", // Outline color and width
                        }}
                      />
                    }
                  >
                    <div className="tw-text-string-base tw-whitespace-nowrap">
                      Share a Deal
                    </div>
                  </Button>

                  {/* Search Bar */}
                  <div className="tw-w-full lg:tw-w-[40%] xl:tw-w-[50%] tw-transition-all">
                    <Search />
                  </div>
                </div>

                <div className="tw-flex tw-gap-x-4 tw-items-center">
                  {user ? (
                    <Link
                      to="/alerts"
                      className="tw-no-underline tw-text-string-base"
                    >
                      <IconButton color="inherit">
                        <Badge badgeContent={0} color="error">
                          <NotificationsNoneOutlinedIcon />
                        </Badge>
                      </IconButton>
                    </Link>
                  ) : (
                    <Link
                      to="/register"
                      className="tw-no-underline tw-text-string-neutral tw-font-semibold"
                    >
                      Join now
                    </Link>
                  )}
                  {/* Bookmark Icon Button */}
                  {user ? (
                    <Link
                      to={user ? '/bookmarks' : '/login'}
                      className="tw-no-underline tw-text-string-base"
                    >
                      <IconButton color="inherit">
                        <BookmarkBorderOutlinedIcon />
                      </IconButton>
                    </Link>
                  ) : (
                    <Link
                      to="/login"
                      className="tw-no-underline tw-text-string-neutral tw-font-semibold"
                    >
                      <Button
                        type="submit"
                        variant="outlined"
                        color="primary"
                        startIcon={<PermIdentityOutlinedIcon />}
                        sx={{ textTransform: 'none' }}
                        className="!tw-font-semibold !tw-text-base"
                      >
                        Login
                      </Button>
                    </Link>
                  )}

                  {/* Account Icon Button */}
                  <div className="tw-hidden md:tw-block">
                    {user ? (
                      <Link
                        to={user ? '/profile' : '/login'}
                        className="tw-no-underline tw-text-string-base"
                      >
                        <IconButton color="inherit">
                          {user && user.attributes.picture ? (
                            <Avatar
                              alt="profile_img"
                              src={`${user?.attributes?.picture}?${Date.now()}`}
                              sx={{ width: 24, height: 24 }}
                            />
                          ) : (
                            <PermIdentityOutlinedIcon />
                          )}
                        </IconButton>
                      </Link>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Stack
          direction="row"
          className="tw-w-full tw-flex tw-px-4 tw-gap-x-4 tw-justify-around tw-bg-surface-primary tw-text-nowrap tw-overflow-auto"
        >
          {featuredCategories.map((entry, index) => (
            <div className="tw-text-string-white tw-font-bold tw-whitespace-nowrap">
              <Button
                key={index}
                color="inherit"
                sx={styles}
                onClick={() => handleNav(entry)}
              >
                {entry}
              </Button>
            </div>
          ))}
        </Stack>
      </div>
    </>
  );
};

export default Header;
