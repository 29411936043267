import { Autocomplete, FormControl, FormGroup } from '@mui/material';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import {
  SubCategoryKeyList,
  categoryList,
} from '../constants/CategoryConstants';
import { storeList } from '../constants/StoreConstants';

export function DealEditorStoreCategory({
  dealDetails,
  setDealDetails,
  inStorePickup,
}) {
  return (
    <>
      <FormGroup className="mb-3" controlId="formBasicText">
        <FormControl>
          <Autocomplete
            id="category-autocomplete"
            options={categoryList}
            value={dealDetails.forum_type}
            onChange={(event, newValue) => {
              setDealDetails({
                ...dealDetails,
                forum_type: newValue,
              });
            }}
            onInputChange={(event, newInputValue) => {
              setDealDetails({
                ...dealDetails,
                forum_type: newInputValue,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  dealDetails.forum_type ? (
                    ''
                  ) : (
                    <span>
                      Category <span style={{ color: 'red' }}>*</span>
                    </span>
                  )
                }
              />
            )}
          />
        </FormControl>
      </FormGroup>
      <FormGroup className="mb-3" controlId="formBasicText">
        <FormControl>
          {/* <InputLabel id="subcategory-select-label">
            {dealDetails.sub_category ? '' : <>Subcategory</>}
          </InputLabel> */}
          <Autocomplete
            id="subcategory-autocomplete"
            options={SubCategoryKeyList[dealDetails.forum_type] || []}
            label="Subcategory"
            value={dealDetails.sub_category}
            onChange={(event, newValue) => {
              setDealDetails({
                ...dealDetails,
                sub_category: newValue,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={dealDetails.sub_category ? '' : <span>Subcategory</span>}
              />
            )}
          />
        </FormControl>
      </FormGroup>
      <FormGroup className="mb-3" controlId="formBasicText">
        <FormControl>
          {/* <InputLabel id="store-select-label">
                    {dealDetails.dealer_type ? '' : 'Store *'}
                  </InputLabel>
                  <Select
                    labelId="store-select-label"
                    // label="Store *"
                    value={dealDetails.dealer_type}
                    onChange={(evt) =>
                      setDealDetails({
                        ...dealDetails,
                        dealer_type: evt.target.value,
                      })
                    }
                  >
                    {[...storeList].sort().map((store, index) => (
                      <MenuItem key={index} value={store}>
                        {store}
                      </MenuItem>
                    ))}
                  </Select> */}

          <Autocomplete
            id="store-autocomplete"
            freeSolo
            label="Store *"
            options={storeList.sort()}
            value={dealDetails.dealer_type}
            onChange={(event, newValue) => {
              setDealDetails({
                ...dealDetails,
                dealer_type: newValue,
              });
            }}
            onInputChange={(event, newInputValue) => {
              setDealDetails({
                ...dealDetails,
                dealer_type: newInputValue,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  dealDetails.dealer_type ? (
                    ''
                  ) : (
                    <span>
                      Store <span style={{ color: 'red' }}>*</span>
                    </span>
                  )
                }
              />
            )}
          />
        </FormControl>
      </FormGroup>
      {/* </FormControl>
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', px: 4}}>
                        <FormControl component="fieldset"  sx={{width: '315px'}}> */}
      {/* {! inStorePickup && (
                        <FormGroup className="mb-3" controlId="formBasicText">
                            <FormLabel>Availability</FormLabel>
                            <ToggleButtonGroup
                                color="primary"
                                value={availabilityAlignment}
                                exclusive
                                onChange={handleAvailabilityChange}
                                aria-label="Platform"
                                >
                                <ToggleButton value="All" style={{ textTransform: 'none' }} >All</ToggleButton>
                                <ToggleButton value="Specific_States" style={{ textTransform: 'none' }} >Specific States</ToggleButton>
                            </ToggleButtonGroup>
                            {dealDetails.specific_states && (
                            <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={statesList}
                                getOptionLabel={(option) => option}
                                defaultValue={dealDetails.available_states}
                                disableCloseOnSelect
                                disablePortal
                                // style={{ marginTop: '10px' }}
                                limitTags={5}
                                onChange={(event, value) => {
                                    setDealDetails({
                                        ...dealDetails,
                                        available_states : value.sort()
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label=""
                                    />
                                )}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option}
                                    </li>
                                )}
                            />
                            )}
                        </FormGroup>
                        )} */}
    </>
  );
}

export default DealEditorStoreCategory;
