import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Container, Grid, Typography } from '@mui/material';

import { Auth } from 'aws-amplify';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { DataGrid } from '@mui/x-data-grid';
import { API } from 'aws-amplify';
import { listDeals } from '../../graphql/queries'; // Import the query file

export default function AdminUsersPage(props) {
  const navigate = useNavigate();
  const REACT_APP_PROFILE_BUCKET_NAME =
    process.env.REACT_APP_PROFILE_BUCKET_NAME;
  const [dialogInfo, setDialogInfo] = useState('');

  const [cognitoUser, setCognitoUser] = useState(null);
  const [bodyFontSize, setBodyFontSize] = useState('1.2em');
  const [headerFontSize, setHeaderFontSize] = useState('1.8em');
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));
  const [expiredDeals, setExpiredDeals] = useState([]);
  const [reportedDeals, setReportedDeals] = useState([]);

  useEffect(() => {
    fetchDeals();
  }, []);

  const fetchDeals = async () => {
    try {
      // fetch reported deals
      const reportedDealData = await API.graphql({
        query: listDeals,
        variables: {
          limit: 50,
          filter: {
            ReportedNumber: {
              ne: null,
              gt: 0,
            },
          },
          sortDirection: 'DESC',
        },
      });
      const reportedDealList = reportedDealData.data.listDeals.items;
      setReportedDeals(reportedDealList);

      // fetch expired deals
      const expiredDealData = await API.graphql({
        query: listDeals,
        variables: {
          limit: 50,
          filter: {
            ExpiredVotedNumber: {
              ne: null,
              gt: 0,
            },
          },
          sortDirection: 'DESC',
        },
      });
      const expiredDealList = expiredDealData.data.listDeals.items;
      setExpiredDeals(expiredDealList);
    } catch (error) {
      console.log('Error fetching deals', error);
    }
  };

  const columns = [
    // { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'edit',
      headerName: '',
      sortable: false,
      width: 80,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() =>
            // navigate(`/dealDetails/${params.row.id}`)
            navigate(`/deal-details/${params.row.id}`)
          }
        >
          View
        </Button>
      ),
    },
    { field: 'ReportedNumber', headerName: '#Reports', width: 75 },
    { field: 'ExpiredVotedNumber', headerName: '#Expires', width: 75 },
    { field: 'title', headerName: 'Title', width: 140 },
    { field: 'poster_name', headerName: 'Poster', width: 60 },
    { field: 'posted_date', headerName: 'Posted Date', width: 100 },
    { field: 'updated_date', headerName: 'Updated Date', width: 100 },
    { field: 'price', headerName: 'Price', width: 60 },
  ];

  useEffect(() => {
    if (isSmall) {
      setHeaderFontSize('1.6em');
      setBodyFontSize('1em');
    } else if (isMedium) {
      setHeaderFontSize('1.6em');
      setBodyFontSize('1em');
    } else if (isLarge) {
      setHeaderFontSize('2em');
      setBodyFontSize('1.2em');
    }
  }, [isSmall, isMedium, isLarge]);

  const handleOpenDialog = (info) => {
    setDialogInfo(info);
  };

  const handleCloseDialog = () => {
    setDialogInfo('');
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        const groups =
          user.signInUserSession.accessToken.payload['cognito:groups'];
        if (!groups || !groups.includes('admin')) {
          navigate('/'); // Redirect to home page if user is not an admin
        } else {
          setCognitoUser(user); // Set the authenticated user to the coguser state
        }
      })
      .catch((err) => {
        console.log(err);
        navigate('/'); // Redirect to home page if user is not logged in
      });
  }, []);

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: 2,
              margin: 2,
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontSize: headerFontSize }}
              className="tw-text-string-base"
            >
              Reported Deals (in the past 7 days)
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <div style={{ width: '100%' }}>
            <DataGrid rows={reportedDeals} columns={columns} pageSize={5} />
          </div>
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: 2,
              margin: 2,
            }}
          >
            <Typography variant="h6" sx={{ fontSize: headerFontSize }}>
              Expiring Deals (in the past 7 days)
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <div style={{ width: '100%' }}>
            <DataGrid rows={expiredDeals} columns={columns} pageSize={5} />
          </div>
        </Grid>

        {/* <Grid item xs={12} ><Divider variant="middle" sx={{borderWidth: 0.8, borderColor: 'black'}} /></Grid> */}

        <Grid item xs={12} />
      </Grid>
      &nbsp;&nbsp;
      <Dialog
        open={dialogInfo}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Info</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogInfo}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
