import CancelIcon from '@mui/icons-material/Cancel';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
  Box,
  ButtonGroup,
  FormGroup,
  FormLabel,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { Storage } from 'aws-amplify';
import * as React from 'react';
import { useState } from 'react';
import { MdUploadFile } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

export function DealEditorImgUploader({
  dealDetails,
  setDealDetails,
  inStorePickup,
  handleOpenDialog,
}) {
  const [isDragging, setIsDragging] = useState(false);

  const REACT_APP_PROFILE_BUCKET_NAME =
    process.env.REACT_APP_PROFILE_BUCKET_NAME;
  const [isImgUploading, setIsImgUploading] = useState(false);

  const handleImageDelete = (index) => () => {
    console.log('deleting image index: ' + index);
    if (Array.isArray(dealDetails.uploaded_img_links)) {
      const uploaded_img_links = dealDetails.uploaded_img_links.filter(
        (_, i) => i !== index
      );
      setDealDetails({
        ...dealDetails,
        uploaded_img_links,
      });
    } else {
      console.error('dealDetails.img_link is not an array');
    }
  };

  const handleImageUploadButton = async (event) => {
    if (event.target.files.length === 0) {
      handleOpenDialog('Please select an Image.');
      return;
    }
    event.preventDefault();
    const files = Array.from(event.target.files).slice(0, 3);
    for (let i = 0; i < files.length; i++) {
      handleFileUpload({ target: { files: [files[i]] } });
    }
  };

  const handlePasteImg = async () => {
    const clipboardItems = await navigator.clipboard.read();
    console.log('clipboardItems: ');
    console.log(clipboardItems);
    for (const clipboardItem of clipboardItems) {
      for (const type of clipboardItem.types) {
        if (type === 'image/png') {
          console.log('copied an img');
          const blob = await clipboardItem.getType(type);
          const file = new File([blob], 'image.png', { type: 'image/png' });
          handleFileUpload({ target: { files: [file] } });
        } else if (type === 'text/plain') {
          const blob = await clipboardItem.getType(type);
          const text = await new Response(blob).text();
          console.log('copied text: ' + text);
          if (
            text.startsWith('http') &&
            (text.endsWith('.png') ||
              text.endsWith('.jpg') ||
              text.endsWith('.jpeg'))
          ) {
            setDealDetails((prevDealDetails) => {
              const newImages = [
                ...prevDealDetails.uploaded_img_links,
                text,
              ].slice(0, 3);
              return { ...prevDealDetails, uploaded_img_links: newImages };
            });
          } else {
            handleOpenDialog(
              'Due to system restrictions, you can only paste images copied from other websites. Please drag & drop to upload if images are from your computer.'
            );
          }
        }
      }
    }
  };

  const handleFileUpload = async (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      console.error('No files to upload.');
      return;
    }
    const file = event.target.files[0];
    if (!file.type.startsWith('image/')) {
      handleOpenDialog('Please upload an Image.');
      return;
    }
    if (file.size > 4000000) {
      handleOpenDialog('Each image is limited to a maximum size of 4 MB.');
      return;
    }
    setIsImgUploading(true);
    const files = event.target.files;
    Storage.configure({ region: 'us-east-1', level: 'public' });
    try {
      if (files.length > 0) {
        const imagePromises = Array.from(files).map(async (file) => {
          const cur_uuid = uuid();
          // upload image and get url
          const { key } = await Storage.put(
            'trial-deal-img/' + `${cur_uuid}` + '.png',
            file,
            {
              contentType: 'image/*',
            }
          );
          const signedUrl = `https://${REACT_APP_PROFILE_BUCKET_NAME}.s3.amazonaws.com/public/${key}`;
          console.log(`new url for img: ${signedUrl}`);
          return signedUrl;
        });
        const images = await Promise.all(imagePromises);
        // limit the number of images to 3
        setDealDetails((prevDealDetails) => {
          const newImages = [
            ...prevDealDetails.uploaded_img_links,
            ...images,
          ].slice(0, 3);
          return { ...prevDealDetails, uploaded_img_links: newImages };
        });
      }
    } catch (error) {
      console.error('Error while uploading images:', error);
    }
    setIsImgUploading(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    handleFileUpload({ target: { files } });
    setIsDragging(false);
  };

  return (
    <>
      {' '}
      <FormGroup className="mb-3" controlId="formBasicText">
        <FormLabel>
          Images (up to 3)
          {/* {inStorePickup ? '* (In-store deals required)' : ''} */}
        </FormLabel>
        <div
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          style={{
            border: isDragging
              ? '3px dashed rgba(0, 0, 0, 1)'
              : '1px dashed rgba(0, 0, 0, 0.3)',
            padding: isDragging ? '100px' : '10px',
            textAlign: 'center',
            // opacity: 0.5,
          }}
          className="!tw-bg-surface-raised !tw-text-string-base"
        >
          {/* {dealDetails.uploaded_img_links.length === 0 && "Drag and drop image here"} */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              overflowX: 'auto',
            }}
          >
            {dealDetails.uploaded_img_links.map((imgLink, index) => (
              <div style={{ position: 'relative', marginRight: '5px' }}>
                <img
                  key={index}
                  src={imgLink}
                  alt={`Uploaded Deal Image ${index + 1}`}
                  style={{ width: 'auto', height: '100px' }}
                />
                <IconButton
                  id={`deleteButton${index}`}
                  style={{
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    display: 'block',
                    width: 'auto',
                    height: '50px',
                    opacity: 1,
                  }}
                  onClick={handleImageDelete(index)}
                >
                  <CancelIcon
                    style={{ fontSize: 35 }}
                    className="tw-text-string-neutral"
                  />
                </IconButton>
              </div>
            ))}
            {isImgUploading && (
              <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box>
            )}
          </div>

          <ButtonGroup variant="outlined" aria-label="small button group">
            <input
              type="file"
              onChange={handleImageUploadButton}
              disabled={dealDetails.uploaded_img_links.length >= 3}
              style={{ display: 'none' }}
              id="fileInput"
              multiple
            />
            <IconButton
              onClick={() => document.getElementById('fileInput').click()}
              disabled={dealDetails.uploaded_img_links.length >= 3}
              style={{
                display:
                  dealDetails.uploaded_img_links.length >= 3 ? 'none' : 'block',
              }}
            >
              {/* <CloudUploadIcon /> */}
              <MdUploadFile />
            </IconButton>
            <IconButton
              onClick={handlePasteImg}
              disabled={dealDetails.uploaded_img_links.length >= 3}
              style={{
                display:
                  dealDetails.uploaded_img_links.length >= 3 ? 'none' : 'block',
              }}
            >
              <FileCopyIcon />
            </IconButton>
          </ButtonGroup>
          <Typography variant="body1">
            <Link
              component="button"
              variant="body1"
              onClick={() => document.getElementById('fileInput').click()}
              className="tw-text-string-primary"
            >
              Click to upload
            </Link>{' '}
            or drag and drop
          </Typography>
          <Typography variant="body2">
            SVG, PNG, JPG or GIF (max. 3MB)
          </Typography>
        </div>
        &nbsp;
      </FormGroup>
    </>
  );
}

export default DealEditorImgUploader;
