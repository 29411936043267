import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext_ } from '../../AppContextProvider';
import {
  getAlertsApi,
  getLightningDealAlertApi,
  getTopDealAlertApi,
  postCreateAlertApi,
  postDeleteAlertApi,
  postDisableLightningDealAlertApi,
  postDisableTopDealAlertApi,
  postEditAlertApi,
  postEnableLightningDealAlertApi,
  postEnableTopDealAlertApi,
} from '../../api/alerts';

function useAlerts() {
  const [alerts, setAlerts] = useState([]);
  const [topDealAlert, setTopDealAlert] = useState({ enabled: false });
  const [lightningDealAlert, setLightningDealAlert] = useState({
    enabled: false,
  });
  const [loading, setLoading] = useState(true);
  const { user } = useAppContext_();
  const navigate = useNavigate();

  const getAlerts = async () => {
    setAlerts(await getAlertsApi(user.attributes.sub));
  };

  const getTopDealAlert = async () => {
    setTopDealAlert(await getTopDealAlertApi(user.attributes.sub));
  };

  const getLightningDealAlert = async () => {
    setLightningDealAlert(await getLightningDealAlertApi(user.attributes.sub));
  };

  const postEnableDisableTopDeal = async () => {
    const topDealAlertCopy = structuredClone(topDealAlert);
    if (!topDealAlertCopy || topDealAlertCopy.enabled === false) {
      setTopDealAlert({ enabled: true });
      await postEnableTopDealAlertApi();
      await getTopDealAlert();
    } else {
      setTopDealAlert({ enabled: false });
      await postDisableTopDealAlertApi();
      await getTopDealAlert();
    }
  };

  const postEnableDisableLightningDeal = async () => {
    const lightningDealAlertCopy = structuredClone(lightningDealAlert);
    if (!lightningDealAlertCopy || lightningDealAlertCopy.enabled === false) {
      setLightningDealAlert({ enabled: true });
      await postEnableLightningDealAlertApi();
      await getLightningDealAlert();
    } else {
      setLightningDealAlert({ enabled: false });
      await postDisableLightningDealAlertApi();
      await getLightningDealAlert();
    }
  };

  const postCreateAlert = async (alert) => {
    const alertCopy = structuredClone(alert);
    alertCopy.user_id = user.attributes.sub;
    alertCopy.username = user.username;

    await postCreateAlertApi(alertCopy);
    await getAlerts();
  };

  const postDeleteAlert = async (alert) => {
    await postDeleteAlertApi(alert);
    await getAlerts();
  };

  const postEditAlert = async (alert) => {
    const alertCopy = structuredClone(alert);

    // Removes following fields because they are not defined
    // in the UpdateAlertSettingsInput schema
    delete alertCopy.createdAt;
    delete alertCopy.updatedAt;
    delete alertCopy._deleted;
    delete alertCopy._lastChangedAt;
    delete alertCopy.__typename;
    delete alertCopy.owner;

    await postEditAlertApi(alertCopy);
    await getAlerts();
  };

  useEffect(() => {
    const asyncFunction = async () => {
      await getAlerts();
      await getTopDealAlert();
      await getLightningDealAlert();
      setLoading(false);
    };
    if (!user) {
      navigate('/login');
    } else {
      asyncFunction();
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    // console.log('topDealAlert', topDealAlert);
  }, [topDealAlert]);

  return {
    alerts,
    topDealAlert,
    lightningDealAlert,
    loading,
    postCreateAlert,
    postDeleteAlert,
    postEditAlert,
    postEnableDisableTopDeal,
    postEnableDisableLightningDeal,
  };
}

export default useAlerts;
