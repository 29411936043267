import { LoadingButton } from '@mui/lab';
import { Button, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  postResetPasswordApi,
  postSendVerificationCodeApi,
} from '../../api/account';
import { ReactComponent as LockIcon } from '../../icons/lock.svg';
import Section from '../home/Section';
import PasswordTextField from '../utils/PasswordTextField';
import { getUsernameByPreferredName } from '../utils/Username';

function ResetPassword() {
  const location = useLocation();
  const [searchParameters] = useSearchParams();
  const [view, setView] = useState('username');
  const [title] = useState(
    searchParameters.get('username') ? 'Create New Password' : 'Reset Password'
  );
  const navigate = useNavigate();
  const usernameFromSearchParameters = useRef(false);

  const [formData, setFormData] = useState({
    username: '',
    preferredUsername: '',
    newPassword: '',
    authenticationCode: '',
  });

  const [formErrors, setFormErrors] = useState({
    username: '',
    newPassword: '',
    authenticationCode: '',
  });

  const [verification, setVerification] = useState({
    isDisabled: false,
    countDown: 30,
  });

  const [loading, setLoading] = useState({
    handleCode: false,
    handleSubmit: false,
  });

  useEffect(() => {
    if (searchParameters.get('username')) {
      setFormData((previousState) => ({
        ...previousState,
        preferredUsername: searchParameters.get('username'),
      }));
      usernameFromSearchParameters.current = true;
    }
  }, []);

  useEffect(() => {
    if (usernameFromSearchParameters.current) {
      setVerification((prevState) => ({
        ...prevState,
        isDisabled: true,
      }));
      setView('password');
    }
  }, [formData]);

  useEffect(() => {
    const asnycFunction = async () => await handleCode();
    if (view === 'password' && usernameFromSearchParameters.current) {
      asnycFunction();
    }
  }, [view]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormErrors((prevFormErrors) => ({
      ...prevFormErrors,
      [name]: '',
    }));

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleCode = async () => {
    try {
      setLoading({
        handleSubmit: false,
        handleCode: true,
      });
      const realUsername = await getUsernameByPreferredName(
        formData.preferredUsername
      );
      setFormData({
        ...formData,
        username: realUsername,
      });
      await postSendVerificationCodeApi(realUsername);
      setLoading({
        handleSubmit: false,
        handleCode: false,
      });
      setVerification((prevState) => ({
        ...prevState,
        isDisabled: true,
      }));
      setView('password');
    } catch {
      setLoading({
        handleSubmit: false,
        handleCode: false,
      });
      setFormErrors((prevState) => ({
        ...prevState,
        username: 'Username not found',
      }));
    }
  };

  useEffect(() => {
    let timer;
    if (verification.isDisabled && verification.countDown > 0) {
      timer = setTimeout(() => {
        setVerification((prevState) => ({
          ...prevState,
          countDown: prevState.countDown - 1,
        }));
      }, 1000);
    } else if (verification.countDown === 0) {
      setVerification({
        isDisabled: false,
        countDown: 30,
      });
    }
    return () => clearTimeout(timer);
  }, [verification]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isValidForm()) {
      try {
        setLoading({
          handleSubmit: true,
          handleCode: false,
        });
        await postResetPasswordApi(
          formData.username,
          formData.authenticationCode,
          formData.newPassword
        );
        alert('Password has been successfully reset!');
        if (location.state) {
          navigate(location.state);
        } else {
          navigate('/login');
        }
      } catch (error) {
        setLoading({
          handleSubmit: false,
          handleCode: false,
        });
        setFormErrors((prevState) => ({
          ...prevState,
          authenticationCode: 'Invalid verification code provided',
        }));
      }
    }
  };

  const isValidForm = () => {
    const errors = {};

    if (formData.newPassword.trim() === '') {
      errors.newPassword = 'New password is required';
    }

    if (formData.authenticationCode.trim() === '') {
      errors.authenticationCode = 'Authentication code is required';
    }

    setFormErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const renderUsernameView = () => (
    <>
      <TextField
        name="preferredUsername"
        label="Username"
        type="text"
        value={formData.preferredUsername}
        onChange={handleChange}
        error={!!formErrors.username}
        helperText={formErrors.username || ' '}
      />
      <LoadingButton
        size="large"
        variant="contained"
        color="primary"
        onClick={handleCode}
        disabled={formData.preferredUsername === ''}
        loading={loading.handleCode}
        loadingPosition="end"
        fullWidth
      >
        Get Code
      </LoadingButton>
    </>
  );

  const renderPasswordView = () => (
    <>
      <PasswordTextField
        name="newPassword"
        label="New Password"
        value={formData.newPassword}
        error={!!formErrors.newPassword}
        helperText={formErrors.newPassword || ' '}
        onChange={handleChange}
      />
      <TextField
        name="authenticationCode"
        label="Authentication Code"
        type="password"
        value={formData.authenticationCode}
        onChange={handleChange}
        error={!!formErrors.authenticationCode}
        helperText={formErrors.authenticationCode || ' '}
      />
      <LoadingButton
        fullWidth
        type="submit"
        variant="contained"
        color="primary"
        size="large"
        loading={loading.handleSubmit}
        loadingPosition="end"
      >
        Reset Password
      </LoadingButton>
      <Button
        size="large"
        type="button"
        variant="text"
        color="primary"
        onClick={handleCode}
        disabled={verification.isDisabled}
      >
        {verification.isDisabled
          ? `Get Code (${verification.countDown})`
          : 'Get Code'}
      </Button>
    </>
  );

  return (
    <Section
      content={
        <div className="tw-w-full tw-bg-transparent tw-p-4 tw-border-none md:tw-w-3/4 lg:tw-w-2/3 xl:tw-w-1/2 sm:tw-border-solid sm:tw-bg-surface-raised sm:tw-p-16 tw-rounded-lg sm:tw-border sm:tw-border-surface-border">
          <div className="tw-flex tw-flex-col tw-gap-y-4 tw-mb-10 tw-items-center">
            <LockIcon className="tw-text-string-primary tw-bg-[rgba(48,84,245,0.13)] tw-rounded-xl tw-p-2 tw-h-11 tw-w-11 tw-mb-5" />
            <div className="tw-w-full">
              <div className="tw-text-3xl tw-font-semibold tw-text-string-base">
                {title}
              </div>
              {view === 'username' && (
                <div className="tw-text-string-neutral">
                  Please enter your username to receive verification email
                </div>
              )}
              {view === 'password' && (
                <div className="tw-text-string-neutral">
                  Please check your email inbox or trash bin for the
                  verification code. The email was sent from
                  "no-reply@verificationemail.com"
                </div>
              )}
            </div>
          </div>

          <form
            className="tw-flex tw-flex-col tw-gap-y-4"
            onSubmit={handleSubmit}
          >
            {view === 'username' && renderUsernameView()}
            {view === 'password' && renderPasswordView()}
          </form>
        </div>
      }
    />
  );
}

export default ResetPassword;
