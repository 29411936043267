import { useEffect, useState } from 'react';

function getItem(key, init) {
  const value = JSON.parse(localStorage.getItem(key));

  if (value === null) {
    return init;
  }

  return value;
}

function useLocalStorage(key, init) {
  const [value, setValue] = useState(getItem(key, init));

  useEffect(() => localStorage.setItem(key, JSON.stringify(value)), [value]);

  return [value, setValue];
}

export default useLocalStorage;
