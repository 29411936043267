import Box from '@mui/material/Box';
import * as React from 'react';

import MapDealCard from './MapDealCard';

const REACT_APP_GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

export default function MapDealCardList({
  allDeals,
  dealId2Details,
  placeId,
  storeName,
}) {
  const [myDeals, setMyDeals] = React.useState([]);

  React.useEffect(() => {
    const filteredGeohashDeals = allDeals.filter(
      (deal) => deal && deal.googlemap_placeid === placeId
    );
    const filteredByStateDeals = allDeals.filter(
      (deal) => deal && deal.dealer_type === storeName
    );
    setMyDeals(filteredGeohashDeals.concat(filteredByStateDeals));
  }, [allDeals, placeId]);

  return (
    <Box mb={1.5}>
      {myDeals.length === 0 ? (
        <div>Loading...</div>
      ) : (
        myDeals.map((deal, index) => {
          const dealDetails =
            dealId2Details[deal.deal_id ? deal.deal_id : deal.id];
          if (!dealDetails) {
            console.error(`No details found for deal id ${deal.deal_id}`);
            return null;
          }
          return (
            <>
              <Box px={1.5} mb={0.5} key={index}>
                <MapDealCard
                  storeName={deal.storeName}
                  dealTitle={dealDetails.title}
                  dealPrice={dealDetails.price}
                  dealDealerType={dealDetails.dealer_type}
                  dealAddress={deal.address}
                  dealImageLink={dealDetails.img_link}
                  dealId={dealDetails.id}
                />
              </Box>
            </>
          );
        })
      )}
    </Box>
  );
}
