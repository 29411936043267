import { IconButton, Menu } from '@mui/material';
import { useState } from 'react';

function IconMenu({ icon, menuItems }) {
  const [anchorElement, setAnchorElement] = useState(null);

  const handleIconClick = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <>
      <IconButton size="small" aria-label="close" onClick={handleIconClick}>
        {icon}
      </IconButton>
      <Menu
        anchorEl={anchorElement}
        open={anchorElement !== null}
        onClose={handleClose}
      >
        {menuItems.map((entry, index) => (
          <div key={index} onClick={handleClose}>
            {entry}
          </div>
        ))}
      </Menu>
    </>
  );
}

export default IconMenu;
