import { Box, FormGroup, FormLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import * as React from 'react';

export function DealEditorCheckBoxes({ dealDetails, setDealDetails }) {
  return (
    <>
      <FormGroup className="mb-3" controlId="formBasicText">
        <Box display="flex" flexDirection="row" justifyContent="flex-start">
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={dealDetails.free_shipping === true}
              onChange={(evt) =>
                setDealDetails({
                  ...dealDetails,
                  free_shipping: evt.target.checked,
                })
              }
            />
            <FormLabel>Free Shipping Eligible</FormLabel>
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={dealDetails.free_pickup === true}
              onChange={(evt) =>
                setDealDetails({
                  ...dealDetails,
                  free_pickup: evt.target.checked,
                })
              }
            />
            <FormLabel>Pick Up Eligible</FormLabel>
          </Box>
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={dealDetails.expired}
              onChange={(evt) =>
                setDealDetails({
                  ...dealDetails,
                  expired: evt.target.checked,
                })
              }
            />
            <FormLabel>Expired</FormLabel>
          </Box>
        </Box>
      </FormGroup>
    </>
  );
}

export default DealEditorCheckBoxes;
