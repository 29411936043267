import { useNavigate } from 'react-router-dom';
import Post from '../utils/Post';

import { useState } from 'react';
import { emptyDemoDataList } from '../../api/deal';
import useListLightningDeals from '../apiHooks/useListLightningDeals';
import { ReactComponent as LightningHomeTime } from './homeIcons/LightningHomeIcon1.svg';
import { ReactComponent as LightningHomeGift } from './homeIcons/LightningHomeIcon2.svg';
import { ReactComponent as LightningHomeBell } from './homeIcons/LightningHomeIcon3.svg';

function LightningHome() {
    const {
        deals,
        homeLightningDeals,
        filter,
        loading,
        setLoading,
        setFilter,
        sortDeals,
        selectedCount,
        selectedFiltersCount,
        updateSearchParameters,
        getListDeals,
        setPageSize,
        nextTokenRef,
    } = useListLightningDeals();
    const navigate = useNavigate();
  // navigate("/categoryForum", {state:{name:demoData.forum_type}});
    const [DST,setDST] = useState(false);

    /*To filter out expired Lightning Deals.*/
    const hoursDifferenceVal = (date) => {
        const past = new Date(date); // posted time according to user current TimeFrame
        const now = new Date(); // Current EST TIME

        let threeAM;

        if (DST) {
        // + 4 UTC
            threeAM = new Date().setUTCHours(7, 0, 0, 0); // !!! actual is 7
            let threeAMDate = new Date(threeAM);
            if (threeAMDate.getDate() !== now.getDate()) {
                threeAM -= 24 * 60 * 60 * 1000;
            }
            threeAMDate = new Date(threeAM);
        } else {
        // + 5 UTC
            threeAM = new Date().setUTCHours(8, 0, 0, 0); // !!! actual is 8
            let threeAMDate = new Date(threeAM);
            if (threeAMDate.getDate() !== now.getDate()) {
                threeAM -= 24 * 60 * 60 * 1000;
            }
            threeAMDate = new Date(threeAM);
        }

        const secondsA = Math.floor((past - threeAM) / 1000);
        const minutesA = Math.floor(secondsA / 60);
        const hoursA = Math.floor(minutesA / 60);

        if (hoursA < 0) {
            return 'deleted';
        } else {
            const seconds = Math.floor((now - past) / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            // console.log('hours: ',hours);
            if (hours >= 0 && hours < 3) {
                /// ! actual is 3
                return `${hours} hour${hours === 1 ? '' : 's'} ago`;
            } else {
                return 'expired';
            }
        }
    };

    return (
        <div
        >
        {!loading?
            <>{ homeLightningDeals.length > 0?
                <div  className="tw-grid tw-gap-1 sm:tw-gap-2 md:tw-gap-3 xl:tw-gap-5
                    tw-grid-cols-2 sm:tw-grid-cols-3 md:tw-grid-cols-3 lg:tw-grid-cols-3 xl:tw-grid-cols-4 2xl:tw-grid-cols-5">{
                    homeLightningDeals.slice(0,8).map((item, index) => (
                        <Post key = {index} item={item} orientation="vertical" type="Lightning Deal" />
                    ))
                }</div>
                :
                <div className='tw-w-full tw-flex tw-flex-1 tw-flex-col tw-justify-start tw-gap-y-2'>
                    <div className='tw-w-full tw-flex tw-flex-row tw-gap-x-2 tw-items-center'>
                        <LightningHomeTime  className="tw-h-[32px] tw-w-[32px]"/>
                        <span className="tw-text-string-base">
                            Check back later for the <span className='tw-font-bold'>lightning deal</span> at <span className='tw-font-bold'>3 AM EST</span>
                        </span>
                    </div>
                    <div className='tw-w-full tw-flex tw-flex-row tw-gap-x-2 tw-items-center'>
                        <LightningHomeGift  className="tw-h-[32px] tw-w-[32px]"/>
                        <span className="tw-text-string-base">
                            <span className='tw-font-bold'>Surprise drops</span> can happen <span className='tw-font-bold'>anytime</span>
                        </span>
                    </div>
                    <div className='tw-w-full tw-flex tw-flex-row tw-gap-x-2 tw-items-center'>
                        <LightningHomeBell  className="tw-h-[32px] tw-w-[32px]"/>
                        <span className="tw-text-string-base">
                            <span className='tw-font-bold'>Add </span>
                            <a
                                className="tw-link-no-underline tw-text-string-primary tw-font-bold"
                                href="/alerts"
                                // rel="external nofollow noopener"
                                // target="_blank"
                                >
                                notifications
                            </a> to stay informed when lightning deals go live
                        </span>
                    </div>
                </div>
            }</>
            :
            <div  className="tw-grid tw-gap-1 sm:tw-gap-2 md:tw-gap-3 xl:tw-gap-5
                    tw-grid-cols-2 sm:tw-grid-cols-3 md:tw-grid-cols-3 lg:tw-grid-cols-3 xl:tw-grid-cols-4 2xl:tw-grid-cols-5">{
                emptyDemoDataList.slice(0,8).map((item, index) => (
                    <Post key = {index} item={item} orientation="vertical" type="Lightning Deal" />
                ))
            }</div>
        }
        </div>
    );
}

export default LightningHome;
