import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import MapDealCardList from './MapDealCardList';

const GoogleMapAddressURL = 'https://www.google.com/maps/place/?q=';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function MapStoreCard({
  storeName = 'Store Name',
  num_of_deals = '',
  rating = 0,
  address = '',
  status = '',
  closingTime = '',
  openingTime = '',
  phone = '',
  shipping = '',
  pickup = '',
  shopping = '',
  buttonText = 'See Their Deals',
  geohash = '',
  placeId = '',
  allDeals = [],
  dealId2Details = {},
  defaultExpanded = true,
  disabledExpanded = false,
}) {
  const [expanded, setExpanded] = React.useState(defaultExpanded);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [myDeals, setMyDeals] = React.useState([]);

  React.useEffect(() => {
    const filteredGeohashDeals = allDeals.filter(
      (deal) => deal && deal.googlemap_placeid === placeId
    );
    const filteredByStateDeals = allDeals.filter(
      (deal) => deal && deal.dealer_type === storeName
    );
    setMyDeals(filteredGeohashDeals.concat(filteredByStateDeals));
  }, [allDeals, placeId]);

  const card = (
    <React.Fragment>
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Typography
            sx={{ fontSize: 14, fontWeight: 'bold', color:"rgba(0,0,0,1)" }}
            //className = ""
            gutterBottom
          >
            {storeName}
          </Typography>
          <Typography
            sx={{ fontSize: 14, fontWeight: 'bold' }}
            color="error"
            gutterBottom
          >
            {myDeals ? `${myDeals.length} Deals` : `${num_of_deals} Deals`}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-start" sx={{ mb: 0.5 }}>
          <Typography sx={{ fontSize: 14, mt: '2px' }} color="error">
            {rating.toFixed(1)}{'\u00A0'}
          </Typography>
          <Rating name="read-only" value={rating} readOnly />
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ fontSize: 13, mb: 0 }} color="text.secondary">
            <a
              // Update the href to use storeName and address for the search query
              href={`${GoogleMapAddressURL}${storeName} ${address}`}
              target="_blank"
              rel="noopener noreferrer"
            >

              {address}
            </a>
          </Typography>
        </Box>
        <Typography
              sx={{ fontSize: 14, fontWeight: 'bold',  color: "rgba(108,114,127,1)" }}
              //className = ""
              gutterBottom
            >
              {status && (
                <>
                  <span style={{ color: status === 'Closed' ? 'red' : 'green' }}>
                    {status}{'\u00A0\u00A0\u00A0'}
                  </span>
                  {/* {status === 'Closed'
                    ? `, open at ${openingTime}`
                    : status === 'Opend'
                    ? `, close at ${closingTime}`
                    : ''} */}
                </>
              )}
              {phone}

        </Typography>
        <Box display="flex" justifyContent="space-between">
          <Typography
            sx={{ fontSize: 13, mb: 0 }}
            color="text.secondary"
            gutterBottom
          >
            {shipping}
          </Typography>
          <Typography
            sx={{ fontSize: 13, mb: 0 }}
            color="text.secondary"
            gutterBottom
          >
            {pickup}
          </Typography>
          <Typography
            sx={{ fontSize: 13, mb: 0 }}
            color="text.secondary"
            gutterBottom
          >
            {shopping}
          </Typography>
        </Box>
        {/* <Button variant="contained" size="small" sx={{ fontSize: 12}} style={{ backgroundColor: 'black', color: 'white', textTransform: 'none' }}>
                    {buttonText}
                </Button> */}
      </CardContent>
      {!disabledExpanded && (
        <>
          <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
              style={{ marginRight: '5px', marginTop: '-25px' }}
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </Box>
        </>
      )}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <MapDealCardList
          allDeals={allDeals}
          dealId2Details={dealId2Details}
          placeId={placeId}
          storeName={storeName}
        />
        {/* <CardContent>
                <Typography paragraph>Method:</Typography>
                <Typography paragraph>
                    Heat 1/2 cup of the broth in a pot until simmering, add saffron and set
                    aside for 10 minutes.
                </Typography>
                </CardContent> */}
      </Collapse>
    </React.Fragment>
  );

  return (
    <Box>
      <Card variant="outlined" sx={{ backgroundColor: '#F7FAFC' }}>
        {card}
      </Card>
    </Box>
  );
}
