/*This file leads to the Recent Deal page on mobile. We may remove it in future release*/
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';



import { useAppContext_ } from "../../AppContextProvider";
import { loadingDemoDataList } from '../../api/deal';
import useListAllDeals from '../apiHooks/useListAllDeals';
import Section from '../home/Section';
import CustomPaginationEndlessMode from '../utils/CustomPaginationEndlessMode';
import Post from '../utils/Post';

function DealThreads() {
    const {
        deals,
        filter,
        loading,
        setLoading,
        setFilter,
        sortDeals,
        selectedCount,
        selectedFiltersCount,
        updateSearchParameters,
        getListDeals,
        setPageSize,
        nextTokenRef,
      } = useListAllDeals();

    const navigate = useNavigate();
    /*Implement the top nav bar*/
    /*
    const {
        HomeCategoryValue,setHomeCategoryValue
    } = useAppContext();
    */
    const { HomeCategoryValue,setHomeCategoryValue, } = useAppContext_();

    const [filters, setFiltes] = useState(
        {
            and: [
                {_deleted: { ne: true }},
                { expired: { ne: true }}
            ]
        }
    );

    // dealsByDate
    /*
    const [deals,setDeals] = useState();
    const getDeals = async() => {
        try {
            const dealData = await API.graphql({
                //query: dealsByDate,
                query: dealsByVoteV2,
                variables: {
                    search_by_vote:"byVote",
                    filter: filters,
                    sortDirection: "DESC"
                },
            })
            let tempDeals = dealData.data.dealsByVote.items;
           // tempDeals.sort((a, b) => (b.vote - b.down_vote) - (a.vote - b.down_vote))
            tempDeals.sort((a, b) =>
                String(b.updatedAt).localeCompare(String(a.updatedAt))
            );
            setDeals(tempDeals);
           // console.log(tempDeals);
        } catch(err) {
            console.log('error', err);
        }
    }
    */
    const showPosts = (items) =>
    (
    <div
        className="tw-grid tw-gap-1 sm:tw-gap-2 md:tw-gap-3 xl:tw-gap-5
         tw-grid-cols-2 sm:tw-grid-cols-3 md:tw-grid-cols-3 lg:tw-grid-cols-3 xl:tw-grid-cols-4 2xl:tw-grid-cols-5"
    >
        {items.map((item, index) => (
          <Post item={item} orientation="vertical" />
        ))}
    </div>
    /*
    <Grid container spacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}>
        {items.map((item, index) => (
          <Grid
            key={index}
            item
            xs={6}
            sm={4}
            md={4}
            lg={3}
            xl={2.4} //2
            xxxl={1.5}
            display={'flex'}
          >
            <div style={{ width: '100%', maxWidth: '300px' }}>
              <Post item={item} orientation="vertical" />
            </div>
          </Grid>
        ))}
    </Grid>
    */
    );

    useEffect(() => {
        const loadData = async() => {
            window.scrollTo(0, 0);
            //await setIsLoading(true);
            setHomeCategoryValue();
            //getDeals();
            //await setIsLoading(false);
        }
        loadData();
    }, []);


    /*Handler function for each row deal card*/

    function threadOverrides(item){
        //console.log(item.poster_img_url);
        let threadOverrides = {overrides:  {
            "ImgEllipse":{
                src:item.poster_img_url,
                display:"none",
                onClick: () => {
                    //console.log('img')
                    // navigate("/dealDetails/" + item.id, {state:item});
                    navigate(`/deal-details/${item.id}`);
                }
            },
            "12/12/23":{
                children: item.createdAt.split("T")[0],
                as: 'button',
                onClick: () => {
                    //console.log('img')
                    // navigate("/dealDetails/" + item.id, {state:item});
                    navigate(`/deal-details/${item.id}`);
                }
            },
            "img2":{
                src:item.img_link?item.img_link:"/white_icon.png",
                onClick: () => {
                    //console.log('img')
                    // navigate("/dealDetails/" + item.id, {state:item});
                    navigate(`/deal-details/${item.id}`);
                }
            },
            "Audio": {
                as: 'button',
                onClick: () => {
                    //console.log('title')
                    //console.log(item)
                    navigate("/categoryForum", {state:{name:item.forum_type}});
                }
            },
            "title":{
                as: 'button',
                onClick: () => {
                    //console.log('img')
                    // navigate("/dealDetails/" + item.id, {state:item});
                    navigate(`/deal-details/${item.id}`);
                }
            },
            "Best Buy":{
                as: 'button',
                onClick: () => {
                    //console.log('price')
                    navigate("/dealerForum", {state:{dealerName:item.dealer_type}});
                }
            },
            "00K":{
                children: item.vote,
                as: 'button',
                onClick: () => {
                    //console.log('img')
                    // navigate("/dealDetails/" + item.id, {state:item});
                    navigate(`/deal-details/${item.id}`);
                }
            },
            "Username":{
                children: item.poster_name?item.poster_name: "Lord of Savings",
                as: 'button',
                onClick: () => {
                    //console.log('img')
                    // navigate("/dealDetails/" + item.id, {state:item});
                    navigate(`/deal-details/${item.id}`);
                }
            }
        }}
        if(item.title.length > 100){
            let newTitle = item.title.substring(0,66) + '...'
            threadOverrides.overrides["title"] = {children: newTitle}
        }
        return threadOverrides
    }

    return (
        <div>
            {loading == true ?
                <>
                <Section
                    title={"Current Deals"}
                    content={
                        <div className="tw-flex tw-flex-col tw-gap-y-4 lg:tw-gap-y-8 tw-w-full">
                            {/*
                            <CustomPagination
                                items={deals?deals:[]}
                                count={20}
                                component={(items) => showPosts(items)}
                            />
                            */}
                            <CustomPaginationEndlessMode
                                items={loadingDemoDataList}
                                component={(items) => showPosts(items)}
                                fetchNextPage={getListDeals}
                                hasMore={nextTokenRef.current === null ? false : true}
                                />
                        </div>
                    }
                />
                </>
                :
                <div>
                    <Section
                        title={"Current Deals"}
                        content={
                            <div className="tw-flex tw-flex-col tw-gap-y-4 lg:tw-gap-y-8 tw-w-full">
                                {/*
                                <CustomPagination
                                    items={deals?deals:[]}
                                    count={20}
                                    component={(items) => showPosts(items)}
                                />
                                */}
                                <CustomPaginationEndlessMode
                                    items={deals}
                                    component={(items) => showPosts(items)}
                                    fetchNextPage={getListDeals}
                                    hasMore={nextTokenRef.current === null ? false : true}
                                    />
                            </div>
                        }
                    />
                </div>
            }
        </div>
    );
  }
export default DealThreads;