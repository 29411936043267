import React, { useState } from 'react';

const DeskTopNotification = ({
  notificationContent,
  openDeskTopNotification,
  setOpenDeskTopNotification,
}) => {
  const [support, setSupport] = useState(false);

  React.useEffect(() => {
    if ('Notification' in window) {
      setSupport(true);
      // requestNotificationPermission();
    }
  }, []);

  React.useEffect(() => {
    if (openDeskTopNotification === true) {
      requestNotificationPermission();
      showNotification();
      setOpenDeskTopNotification(false);
    }
  }, [openDeskTopNotification, setOpenDeskTopNotification]);

  async function requestNotificationPermission() {
    if (!support) {
      return;
    }

    // Check the current permission status
    const permission = Notification.permission;

    if (permission === 'denied') {
      console.error(
        'Desktop Notification permission was previously denied. Please change the settings in your browser.'
      );
      return;
    }

    // console.log('Asking Desktop Notification permission.');
    Notification.requestPermission().then(function (permission) {
      if (permission === 'granted') {
        // console.log('Desktop Notification permission granted.');
      } else if (permission === 'denied') {
        // console.error('Desktop Notification permission denied.');
      }
    });
  }

  async function showNotification() {
    // await new Promise((resolve) => setTimeout(resolve, 1000));

    if (support && Notification.permission === 'granted') {
      var notification = new Notification(
        `New Deal: ${notificationContent?.price}`,
        {
          body: notificationContent?.title,
          icon: notificationContent?.img_link,
        }
      );

      notification.onclick = function () {
        window.open(notificationContent?.link);
      };
    }
  }
  return;
};

export default DeskTopNotification;
