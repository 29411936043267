import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import Section from '../home/Section';

function Contacts({}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Section
        // startIcon= {<BsFillPinMapFill style={{ color: 'rgba(13, 110, 253, 1)' }}/>}
        content={
          <div className="tw-text-string-base">
            <div className = "tw-contacts-img-section tw-contacts-img">
              <Box fullWidth sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                <img
                  className="tw-p-0 tw-bg-gray-100 tw-rounded-lg"
                  src="/contact_us.gif"
                ></img>
              </Box>
            </div>
            <div className="tw-contacts">
              <div className="tw-py-2 tw-gap-y-2">
                <p className="tw-contacts-title">LORD OF SAVINGS</p>
                <h1>About Us</h1>
                <p>
                  Let's Save You Money With Deals That You Didn't Think Were
                  Possible!
                </p>
              </div>
              <p>
                Welcome to LordOfSavings, where innovation meets excellence. We
                are a dynamic team of professionals dedicated to revolutionizing
                the way you share and receive deals. Our mission is to provide a
                space where users can share deals that they may find at their
                local store or anywhere on the internet and receive instant
                alerts when a posted deal meets your self-tailored notification
                settings.
              </p>
              <p>
                At LordOfSavings, we believe in pushing the boundaries of what's
                possible for deal notifications. Our team of experts combines
                years of industry experience with a passion for innovation to
                deliver an exceptional experience. We are committed to staying
                at the forefront of deals and constantly exploring new ways to
                enhance your experience.  
              </p>
              <p>
                What sets us apart is our unwavering commitment to providing our
                audience with deals that actually matter. We understand that
                almost everywhere you turn, there is an advertisement for a
                product that screams “THIS IS A CRAZY DEAL",  but in actuality,
                it is not a deal at all. Our customer-centric approach ensures
                that we listen to your needs, understand your challenges, and
                provide tailored solutions that drive results.   As a company,
                we are driven by a set of core values that guide everything we
                do. We believe in integrity, transparency, and accountability.
                We are dedicated to fostering a culture of collaboration where
                ideas are shared and creativity thrives. Our team is made up of
                diverse individuals who bring unique perspectives and expertise
                to the table to provide the ultimate deal experience
              </p>
              <p>
                At LordOfSavings, we are not just another deal-sharing company.
                We are a partner in your success, committed to providing a space
                where you can find the very best deals on the internet. We
                invite you to join us on this exciting journey as we continue to
                redefine the possibilities of what deals can be.
              </p>
              <p>
              If you have any questions about this page, you can contact us by email: nate@lordofrestock.com.
              Come join our Community{' '}
                <a
                  className="tw-link-no-underline tw-text-string-primary"
                  href="https://discord.com/invite/lordsavings"
                  rel="external nofollow noopener"
                  target="_blank"
                >
                  Discord
                </a>{' '}
                Server of 22k+ and follow our Twitter Channels of 500k+
                followers at{' '}
                <a
                  className="tw-link-no-underline tw-text-string-primary"
                  href="https://twitter.com/LordOfDiscounts?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                  rel="external nofollow noopener"
                  target="_blank"
                >
                  @LordOfDiscounts
                </a>
                ,{' '}
                <a
                  className="tw-link-no-underline tw-text-string-primary"
                  href="https://twitter.com/LordOfRestocks?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                  rel="external nofollow noopener"
                  target="_blank"
                >
                  @LordOfRestocks
                </a>
                , and{' '}
                <a
                  className="tw-link-no-underline tw-text-string-primary"
                  href="https://twitter.com/LordOfSavings?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                  rel="external nofollow noopener"
                  target="_blank"
                >
                  @LordOfSavings
                </a>
                . I could keep rambling on about how great our deals are, but I
                want you to join our communities RIGHT NOW and start scoring
                yourself to believe the deals we find. Let us introduce you to
                an #LRDeal!
              </p>
            </div>
          </div>
        }
      ></Section>
    </div>
  );
}

export default Contacts;
