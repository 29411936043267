import { API } from "aws-amplify";
import { useAppContext_ } from "../../AppContextProvider";
import {
  bookmarksByUser_id,
  getDeal
} from "../../graphql/queries";
import { AUTH_MODE } from "../constants/AppSyncAuthMode";

function useBookmarksApis() {
  const { user} = useAppContext_();

  const getBookmarks = async () => {
    if(user){
      const bookmarks = await API.graphql({
        query: bookmarksByUser_id,
        variables: {
          user_id: user.attributes.sub,
          filter: {
            // user_id: {eq : user.attributes.sub},
            _deleted: { ne: true },
          },
        },
        authMode: AUTH_MODE.USER_POOLS,
      });
      // console.log(bookmarks);
      let deals = [];
      for (let bookmark of bookmarks.data.bookmarksByUser_id.items) {
       //console.log(bookmark)
        let deal = await getOneDeal(bookmark.deal_id);
        //console.log(deal);
        /*
        if(bookmark.deal_id == ""){
          console.log('hello');
        }
        */
        if (deal !== null) {
          deals.push(deal);
        }
      }
      // console.log(deals);
      return deals;
    }
    else{
      return [];
    }
  };

  const getOneDeal = async (deal_id) => {
    const deal = await API.graphql({
      query: getDeal,
      variables: {
       id:deal_id,
      },
    });
    if (!deal.data.getDeal) {
      return null;
    }
    else if(deal.data.getDeal.expired){
      return null;
    }
    return deal.data.getDeal;
  };

  return {
    getBookmarks: getBookmarks,
    getOneDeal: getOneDeal,
  };
}

export default useBookmarksApis;
