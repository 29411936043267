import { Grid } from '@mui/material';
import * as React from 'react';
import Post from '../../components/utils/Post';

function HorizontalPostGrid({ posts }) {
  return (
    <Grid container spacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}>
      {posts.map((item, index) => (
        <Grid key={index} item xs={12} sm={12} md={12} lg={12}>
          <Post item={item} orientation="horizontal" />
        </Grid>
      ))}
    </Grid>
  );
}

export default HorizontalPostGrid;
