import { createTheme } from '@mui/material';
const light = createTheme({
  palette: {
    primary: {
      main: '#3054F5', // Royal Blue
    },
    blue: {
      main: '#3054F5', // Royal Blue
    },
    gray: {
      main: '#F7FAFC', // Light Gray
    },
    red: {
      main: '#C62828', // Crimson Red
    },
    black: {
      main: '#000000', // Black
    },
    mode: 'light',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 2116,
      xxxl: 3440,
    },
  },
  typography: {
    fontFamily: ['Inter', 'system-ui', 'sans-serif'].join(','),
  },
});

const dark = createTheme({
  palette: {
    primary: {
      main: '#398fed', // Royal Blue
    },
    blue: {
      main: '#398fed', // Royal Blue
    },
    gray: {
      main: '#F7FAFC', // Light Gray
    },
    red: {
      main: '#C62828', // Crimson Red
    },
    black: {
      main: '#ffffff80', // Black
    },
    mode: 'dark',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 2116,
      xxxl: 3440,
    },
  },
  typography: {
    fontFamily: ['Inter', 'system-ui', 'sans-serif'].join(','),
  },
});

export { dark, light };
