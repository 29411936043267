import { API } from 'aws-amplify';
import { AUTH_MODE } from '../components/constants/AppSyncAuthMode';
import {
  LosDealVoteFunc,
  createBookmark,
  createExpiredMarker,
  deleteBookmark,
  reportDeal,
} from '../graphql/mutations';
import {
  bookmarksByUser_id,
  dealsByDate,
  dealsByIsLightningDealAndUpdatedAt,
  dealsByVote,
  getDeal,
  getDealVote,
  reportedDealsByDeal_user_id,
  votedExpiredsByDeal_user_id,
} from '../graphql/queries';

const DefaultPageSize = 300;

const deletedDemoData = {
  id: '72010c76-8aaa-4f2f-9e28-74ac8e7db827',
  dealer_type: 'Deleted dealer.',
  deal_link: 'https://www.lordofsavings.com/deals',
  description:
    'We do have other awesome deals on the Deals page. Thank you for your patience',
  down_vote: 0,
  expiration_date: '2099-12-30T05:00:00Z',
  expired: true,
  forum_type: 'Groceries and Gourmet Food',
  free_shipping: false,
  highest_ratio: 0,
  highest_votes: 0,
  img_link: 'https://dealcard201533-staging.s3.amazonaws.com/image+63.png',
  poster_id: '9bcad141-790a-4301-bea8-d448cb8cdcc0',
  poster_img_url:
    'https://dealcard201533-staging.s3.amazonaws.com/default_user_icon.png',
  poster_name: 'Deleted poster',
  prev_price: 0,
  price: 0,
  price_drop: 0,
  promotional_code: '',
  title: 'Woops. This deal has expired or has been removed by its poster',
  uploaded_img_links: [
    'https://dealcard201533-staging.s3.amazonaws.com/image+63.png',
  ],
  vote: 0,
};

const loadingDemoData = {
  id: '72010c76-8aaa-4f2f-9e28-74ac8e7db827',
  dealer_type: 'Loading dealer.',
  deal_link: 'https://www.lordofsavings.com/deals',
  description:
    'We do have other awesome deals on the Deals page. Thank you for your patience',
  down_vote: 0,
  expiration_date: '2099-12-30T05:00:00Z',
  expired: true,
  forum_type: 'Groceries and Gourmet Food',
  free_shipping: false,
  highest_ratio: 0,
  highest_votes: 0,
  img_link: 'https://dealcard201533-staging.s3.amazonaws.com/image+63.png',
  poster_id: '9bcad141-790a-4301-bea8-d448cb8cdcc0',
  poster_img_url:
    'https://dealcard201533-staging.s3.amazonaws.com/default_user_icon.png',
  poster_name: 'Loading poster',
  prev_price: 0,
  price: 0,
  price_drop: 0,
  promotional_code: '',
  title: 'Woops. This deal title is still loading.Thank you for your patience',
  uploaded_img_links: [
    'https://dealcard201533-staging.s3.amazonaws.com/image+63.png',
  ],
  vote: 0,
};

export const loadingDemoDataList = Array(16).fill(loadingDemoData);
export const emptyDemoDataList = Array(16).fill(deletedDemoData);

// Limit does not currently work.
export async function getTopVotedDealsApi(deal_id, forum_type, limit) {
  const deals = await API.graphql({
    query: dealsByVote,
    variables: {
      search_by_vote: 'byVote',
      limit: DefaultPageSize,
      filter: {
        and: [
          { _deleted: { ne: true } },
          {
            id: {
              ne: deal_id,
            },
          },
          {
            forum_type: {
              eq: forum_type,
            },
          },
        ],
      },
      sortDirection: 'DESC',
    },
  });

  return deals.data.dealsByVote.items;
}

export async function listDealsByTimeApi(
  filter,
  limit = DefaultPageSize,
  nextToken = null
) {
  filter.and.push({ _deleted: { ne: true }, expired: { ne: true } });

  const deals = await API.graphql({
    query: dealsByDate,
    variables: {
      search_by_time: 'byTime',
      filter: filter,
      limit: limit >= 10 ? limit : DefaultPageSize,
      sortDirection: 'DESC',
      nextToken: nextToken,
    },
  });

  return deals;
}

export async function listLightningDealsByTimeApi(
  filter,
  limit = DefaultPageSize,
  nextToken = null
) {
  filter.and.push({ _deleted: { ne: true }, expired: { ne: true } });

  // console.log('filter: ', filter)

  const deals = await API.graphql({
    query: dealsByIsLightningDealAndUpdatedAt,
    variables: {
      isLightningDeal: 'true',
      filter: filter,
      limit: limit >= 10 ? limit : DefaultPageSize,
      sortDirection: 'DESC',
      nextToken: nextToken,
    },
  });
  // console.log('nextToken: ', nextToken);
  // console.log('fetched deals:');
  // console.log(deals);
  return deals;
}

export async function listDealsByVoteApi(
  filter,
  limit = DefaultPageSize,
  nextToken = null
) {
  filter.and.push({ _deleted: { ne: true }, expired: { ne: true } });
  const deals = await API.graphql({
    //query: dealsByDateV2,
    query: dealsByVote,
    variables: {
      filter: filter,
      search_by_vote: 'byVote',
      limit: limit >= 10 ? limit : DefaultPageSize,
      sortDirection: 'DESC',
    },
  });

  return deals.data.dealsByVote.items;
}

export async function getDealApi(deal_id) {
  const deal = await API.graphql({
    query: getDeal,
    variables: {
      id: deal_id,
    },
  });
  // return deal.data.getDeal;
  if (deal.data.getDeal) {
    return deal.data.getDeal;
  } else {
    return deletedDemoData;
  }
}

export async function getBookmarkApi(deal_id, user_id) {
  if (user_id) {
    const bookmarks = await API.graphql({
      query: bookmarksByUser_id,
      variables: {
        user_id: user_id,
        filter: {
          _deleted: { ne: true },
          deal_id: { eq: deal_id },
        },
      },
      authMode: AUTH_MODE.USER_POOLS,
    });

    return bookmarks.data.bookmarksByUser_id.items;
  } else {
    return [];
  }
}

export async function getExpiredApi(deal_id, user_id) {
  if (user_id) {
    const expired = await API.graphql({
      query: votedExpiredsByDeal_user_id,
      variables: {
        deal_user_id: deal_id + '-' + user_id,
        filter: {
          and: [
            {
              _deleted: {
                ne: true,
              },
            },
          ],
        },
      },
      authMode: AUTH_MODE.USER_POOLS,
    });

    return expired.data.votedExpiredsByDeal_user_id.items;
  } else {
    return [];
  }
}

export async function getReportApi(deal_id, user_id) {
  if (user_id) {
    const report = await API.graphql({
      query: reportedDealsByDeal_user_id,
      variables: {
        deal_user_id: deal_id + '-' + user_id,
        filter: {
          and: [
            {
              _deleted: {
                ne: true,
              },
            },
          ],
        },
      },
      authMode: AUTH_MODE.USER_POOLS,
    });

    return report.data.reportedDealsByDeal_user_id.items;
  } else {
    return [];
  }
}

export async function postCreateBookmarkApi(deal_id, user_id) {
  const bookmark = await API.graphql({
    query: createBookmark,
    variables: {
      input: {
        deal_id: deal_id,
        user_id: user_id,
      },
    },
    authMode: AUTH_MODE.USER_POOLS,
  });

  return bookmark.data.createBookmark;
}

export async function postDeleteBookmarkApi(bookmark) {
  await API.graphql({
    query: deleteBookmark,
    variables: {
      input: {
        id: bookmark.id,
        _version: bookmark._version,
      },
    },
    authMode: AUTH_MODE.USER_POOLS,
  });
}

export async function postCreateExpiredApi(deal_id) {
  await API.graphql({
    query: createExpiredMarker,
    variables: {
      deal_id: deal_id,
      to_delete: false,
    },
    authMode: AUTH_MODE.USER_POOLS,
  });
}

export async function postDeleteExpiredApi(deal_id) {
  await API.graphql({
    query: createExpiredMarker,
    variables: {
      deal_id: deal_id,
      to_delete: true,
    },
    authMode: AUTH_MODE.USER_POOLS,
  });
}

export async function postCreateReportApi(deal_id) {
  const report = await API.graphql({
    query: reportDeal,
    variables: {
      deal_id: deal_id,
      to_delete: false,
    },
    authMode: AUTH_MODE.USER_POOLS,
  });

  return report.data.reportDeal;
}

export async function postDeleteReportApi(deal_id) {
  const report = await API.graphql({
    query: reportDeal,
    variables: {
      deal_id: deal_id,
      to_delete: true,
    },
    authMode: AUTH_MODE.USER_POOLS,
  });

  return report.data.reportDeal;
}

export async function postVoteApi(deal_id, upvote, downvote) {
  const response = await API.graphql({
    query: LosDealVoteFunc,
    variables: {
      deal_id: deal_id, // string
      upvote: upvote, // boolean
      downvote: downvote, // boolean
    },
    authMode: AUTH_MODE.USER_POOLS,
  });

  return response.data.LosDealVoteFunc;
}

export async function fetchDealVote(dealId, userId) {
  try {
    const id = `${dealId}-${userId}`;
    const response = await API.graphql({
      query: getDealVote,
      variables: { id },
      authMode: AUTH_MODE.USER_POOLS,
    });
    // console.log('response: ', response);
    return response.data.getDealVote;
  } catch (error) {
    console.error('Error fetching deal vote: ', error);
  }
}
