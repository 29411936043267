/*used for catgorizer.js, unused now*/
export const categoryKeywords_1 = {
    "Arts & Craft Materials": ["Arts", "paints", "sketchbooks", "yarn", "brushes", "canvas", "easels", "charcoal", "glue", "scissors", "markers", "stencils", "pastels", "ink", "ribbons", "fabric", "watercolor", "acrylic", "oil paint", "sketchpads"],
    "Automotives":["motor oil","antifreeze", "coolant","brake fluid","transmission fluid", "car batteries", "inflators", "sealants", "wiper blades", "air filters", "spark plugs", "car wash soap", "wax", "glass cleaner", "tire shine","suspension kits", "dash cams", "seat covers","sunshades", "floor mats"],
    "Baby Products":["nipples","breast pumps", "bibs","burp cloths", "diapers", "rash cream", "crib", "bassinet", "swaddles", "baby lotion", "stroller", "diaper bag", "sleep sacks", "wipes"],
    "Beauty & Personal Care": ["Beauty", "cosmetics", "foundation", "eyeliner",  "moisturizer", "skincare", "fragrance", "lipstick", "mascara", "shampoo", "conditioner", "serum", "toner", "exfoliants", "cleansers", "brushes", "sponges", "clippers"],
    "Electronics": ["Electronics", "headphones", "phone", "smartwatch", "laptop", "drone", "cameras", "smartphones", "tablets", "chargers", "batteries", "televisions", "speakers", "drones", "keyboards", "receivers", "amplifiers", "consoles"],
    "Computers & Tablets": ["Tablets", "Computers", "laptops", "hard drives", "SSD", "graphics cards", "monitors", "keyboards", "mice", "printers", "scanners", "modems", "routers", "drives", "tablets", "cpus", "motherboards", "fans"],
    "Clothing & Fashion": ["Clothing", "Fashion", "jeans", "handbags", "jackets", "t-shirts", "skirts", "dresses", "shirts", "suits", "coats", "shoes", "boots", "sneakers", "hats", "scarves", "belts", "socks", "ties", "gloves", "sunglasses"],
    "Health & Wellness": ["Health", "Wellness", "vitamins",  "yoga mat", "protein powder", "treadmill",  "fitness tracker", "massage gun", "wellness", "exercise equipment", "supplements", "treadmills", "weights", "mats", "bands", "monitors", "massagers", "scales", "inhalers", "braces", "creams", "ointments"],
    "Home & Furniture": ["Home", "Furniture", "mattresses", "home decor", "sofas", "chairs", "tables", "beds", "lamps", "rugs", "curtains", "bookcases", "pillows", "frames", "vases", "mirrors", "clocks", "shelves"],
    "Gift Cards & Digital Goods": ["Digital", "cards", "gift card", "ebook", "music streaming", "game credits", "subscription service", "digital goods", "online course", "ebooks", "music", "games", "subscriptions", "credits", "coupons", "vouchers", "memberships", "tokens", "points", "passes"],
    "Grocery, Food, & Drinks": ["Grocery", "Food", "Drinks", "snacks", "organic", "coffee", "tea", "bottled water", "wine", "beer", "chocolate", "pasta", "cereal", "fruits", "vegetables", "meats", "cheeses", "breads", "drinks", "wines", "beers", "spirits", "condiments", "cereals", "pastas"],
    "Kitchen & Appliances": ["Kitchen", "Appliances", "blenders", "cookware", "bakeware", "utensils", "coffee makers", "dishwashers", "toasters", "ovens", "stoves", "microwaves", "refrigerators", "grills", "pans", "pots", "knives", "mixers", "kettles", "cutlery"],
    "Miscellaneous (Other)": ["office", "party decorations", "car accessories", "novelty items", "educational materials", "craft kits", "gadgets", "gadgets", "accessories", "supplies", "tools", "kits", "gifts", "novelty", "collectibles", "antiques", "crafts", "hobbies", "stationery"],
    "Movies, Music, & Television": ["vinyl records", "streaming devices", "headphones", "musical instruments", "concerts", "box sets", "Movies", "Music", "Television", "dvds", "cds", "vinyls", "streams", "players", "instruments", "headsets", "amplifiers", "soundbars", "remotes", "receivers", "antennas"],
    "Patio & Outdoor": ["Patio", "grills", "patio furniture", "outdoor lighting", "fire pits", "planters", "garden tools", "insect repellents", "outdoor heaters", "Outdoor", "grills", "furniture", "lights", "tools", "hammocks", "tents", "firepits", "heaters", "umbrellas", "pools", "decorations", "fences"],
    "Pet Supplies": ["Pet", "dog food", "cat food", "aquariums", "pet toys",  "grooming tools", "pet beds",  "flea treatment", "pet carriers", "Supplies", "foods", "toys", "beds", "cages", "leashes", "treats", "bowls", "scratching", "litters", "carriers", "grooming", "collars", "aquariums"],
    "Television & Video": ["Television", "streaming media players", "TV mounts", "Blu-ray players", "soundbars", "remote controls", "HDMI cables", "Video","tvs", "projectors", "receivers", "speakers", "cables", "antennas", "remotes", "mounts", "blu-rays", "streaming", "dvrs", "consoles"],
    "Tools & Home Improvement": ["Tools", "power tools", "hand tools", "paint", "lighting fixtures", "plumbing", "electrical tools", "door hardware", "smart home devices", "Home Improvement", "drills", "saws", "hammers", "wrenches", "pliers", "ladders", "lights", "paints", "tiles", "locks", "alarms", "faucets", "fans"],
    "Toys, Games, & Collectibles": ["board games", "action figures", "LEGO", "video games", "RC cars", "trading cards", "model kits", "collectible figurines", "Toys", "Games", "Collectibles", "dolls", "cars", "puzzles", "games", "blocks", "figures", "cards", "plush", "models", "trains", "crafts", "books", "robots"]
};

/*used for catgorizer.js, unused now*/
export const categoryKeywords_2 = {
    "Arts & Craft Materials": ["Crayola", "Faber-Castell", "Winsor & Newton", "Prismacolor", "Strathmore", "Liquitex", "Sakura"],
    "Automotives":["Castrol","Prestone", "Pennzoil","Bosch","Meguiar's","Chemical Guys","Mothers", "Armor All","Michelin","Craftsman",
        "Snap-on","Dewalt","Makita", "Bridgestone","Garmin","Pioneer","K&N","Borla"],
    "Baby Products":["Pampers","Huggies","Johnson & Johnson", "Philips Avent", "Fisher-Price", "Graco", "Chicco", "Gerber",
        "The Honest Company", "Britax"],
    "Beauty & Personal Care": ["L'Oreal", "Estee Lauder", "Olay", "Gillette", "Neutrogena", "Maybelline", "Nivea", "Clinique"],
    "Electronics": ["Sony", "Samsung", "Apple", "Bose", "LG", "Panasonic", "JBL", "Philips", "Amazon"],
    "Computers & Tablets": ["Dell", "HP", "Lenovo", "Microsoft", "Asus", "Acer", "Apple", "Razer"],
    "Clothing & Fashion": ["Nike", "Adidas", "Levi's", "Gucci", "Under Armour", "H&M", "Zara", "Prada"],
    "Health & Wellness": ["Centrum", "Nature Made", "Fitbit", "Optimum Nutrition", "GNC", "MuscleTech", "Garden of Life"],
    "Home & Furniture": ["Ikea", "Wayfair", "Pottery Barn", "Ashley Furniture", "West Elm", "Restoration Hardware", "Crate & Barrel"],
    "Gift Cards & Digital Goods": ["Amazon", "iTunes", "Google Play", "Netflix", "Hulu", "Spotify", "Xbox", "PlayStation"],
    "Grocery, Food, & Drinks": ["Kraft", "Coca-Cola", "Pepsi", "Nestle", "Heinz", "General Mills", "Kellogg's", "Tyson Foods"],
    "Kitchen & Appliances": ["Whirlpool", "KitchenAid", "Hamilton Beach", "GE Appliances", "Breville", "Bosch", "Frigidaire", "Dyson"],
    "Miscellaneous (Other)": ["3M", "Sharpie", "Bic", "Post-it", "Scotch", "Pilot", "Rubbermaid", "Leatherman"],
    "Movies, Music, & Television": ["Disney", "Warner Bros", "Sony Music", "Universal Pictures", "Paramount", "Netflix", "HBO", "Spotify"],
    "Patio & Outdoor": ["Weber", "Coleman", "John Deere", "Husqvarna", "Toro", "Black & Decker", "Greenworks", "Char-Broil"],
    "Pet Supplies": ["Pedigree", "Purina", "Whiskas", "Blue Buffalo", "Royal Canin", "Hill's Pet Nutrition", "Frontline", "Kong"],
    "Television & Video": ["LG", "Panasonic", "Sharp", "Sony", "Vizio", "TCL", "Samsung", "Philips"],
    "Tools & Home Improvement": ["Bosch", "DeWalt", "Black & Decker", "Makita", "Stanley", "Ryobi", "Craftsman", "Milwaukee"],
    "Toys, Games, & Collectibles": ["Lego", "Hasbro", "Mattel", "Funko", "Bandai", "Playmobil", "Nerf", "Fisher-Price"]
};

export const SubCategoryKeyList = {
    "Arts and Crafts":[
        'Apparel Crafting',
        'Art Supplies',
        'Artificial Plants',
        'Beading and Jewelry',
        'Craft Furniture',
        'Craft Supplies',
        'Fabrics and Decoration',
        'Gift Wrapping',
        'Knitting, Crocheting and Needlework Supplies',
        'Party Supplies and Decorations',
        'Scrapbooking and Stamping',
        'Sewing Machines',
        'Sewing Supplies',
    ],
    "Automotive":[
        'Car Care',
        'Car Electronics',
        'Interior Accessories',
        'Exterior Accessories',
        'Lights and Lighting Accessories',
        'Oils and Fluids',
        'Paint and Paint Supplies',
        'Replacement Parts',
        'Tires and Wheels',
        'Tools and Equipment',
        'Vehicle Equipment',
    ],
    "Baby Products" : [
        'Activity and Entertainment',
        'Apparel and Accessories',
        'Baby Care',
        'Baby and Toddler Toys',
        'Baby Stationary',
        'Car Seats and Accessories',
        'Health and Safety',
        'Nursing and Feeding',
        'Prenatal and Postnatal Care',
        'Presents and Gifts',
        'Strollers and Accessories',
        'Toddler Furniture',
        'Travel Products'
    ],
    'Beauty and Personal Care':[
        'Beauty Tools and Tech',
        'Fragrances',
        'Foot and Hand Care',
        'Hair Care',
        'Makeup',
        'Nail Polish and Care',
        'Salon and Spa',
        'Shave and Hair Removal Products',
        'Skin Care',
    ],
    "Books and Fine Art":[
        'Arts and Photography',
        'Audible Books',
        'Biographies',
        'Calendars',
        'Comics',
        'Fiction',
        'Non-Fiction',
        'Religion and Spirituality',
    ],
    'Camera and Photo':[
        'Action Cameras',
        'Camcorders',
        'Digital SLR Cameras',
        'Disposable Cameras',
        'Instant Cameras',
        'Mirrorless Cameras',
        'Point and Shoot Cameras',
        'Binoculars and Scapes',
        'Camera Accessories',
        'Camera Bags and Cases',
        'Film, Film Slides, and Film Photography',
        'Flashes and Lenses',
        'Printers and Scanners',
        'Tripods and Monopods',
        'Video Surveillance',
    ],
    'Cell Phones and Accessories':[
        'Cases and Protection',
        'Cell Phones',
        'Cell Phone Mounts',
        'Grips and Phone Stands',
        'Phone Repair Kits',
        'Phone Sanitizers',
        'Portable Chargers and Power Banks',
        'Power Adapters and Cables',
        'Screen Protectors',
        'Selfie Sticks and Tripods',
        'Stylus Pens',
    ],
    'Clothing, Shoes, and Jewelry':[
        'Belts, Ties, and Other Accessories',
        'Gloves and Mittens',
        'Hats and Caps',
        "Kid's Clothing",
        "Kid's Shoes and Footwear",
        "Men's Clothing",
        "Men's Shoes and Footwear",
        "Men's Underwear",
        "Rings, Necklaces, and Accessories",
        "Sunglasses and Eyewear Accessories",
        'Wallets',
        'Watches',
        "Women's Clothing",
        "Women's Shoes and Footwear",
        "Women's Underwear",
    ],
    "Electronics": [
        'Computers and Accessories',
        'DVD and Blu-Ray Players',
        'E-Book Readers and Accessories',
        'Headphones, Earbuds, and Accessories',
        'Home Audio and Accessories',
        'Household Batteries and Chargers',
        'GPS, Finders, and Accessories',
        'Portable Audio and Accessories',
        'Power Accessories',
        'Tablets and Accessories',
        'Televisions',
        'Television Accessories',
        'Video, DVD, and Blu-ray',
        'Video Projectors',
        'Wearable Technology',
    ],
    'Entertainment Collectibles':[
        'Apparel and Accessories',
        'Figurines and Dolls',
        'Historical and Political Collectibles',
        'Other Collectibles',
        'Photographs',
        'Prints and Posters',
        'Props and Scripts',
        'Signed Memorabilia',
        'Sports Collectibles',
        'Trading Cards',
        'Wardrobe and Costumes',
    ],
    "Groceries and Gourmet Food": [
        'Alcoholic Beverages',
        'Beverages',
        'Breads and Bakery',
        'Breakfast Cereal',
        'Breakfast Foods',
        'Canned Goods',
        'Candy and Chocolate',
        'Coffee and Tea',
        'Dairy, Eggs, and Plant-Based',
        'Deli and Prepared Foods',
        'Fast Food',
        'Fresh Flowers and Live Indoor Plants',
        'Frozen Foods',
        'Gum',
        'Meal Kits',
        'Meat and Seafood',
        'Meat Substitutes',
        'Pantry Staples',
        'Produce',
        'Snacks',

    ],
    "Health and Household": [
        'Air Fresheners' ,
        'Cleaning Supplies',
        'Diet and Nutrition',
        'Dishwashing Care',
        'Ear and Eye Care',
        'First Aid and Accessories',
        'Feminine Care',
        'Foot Care',
        'Laundry Care',
        'Over The Counter Medication',
        'Paper and Plastic Accessories',
        'Sexual Wellness',
        'Vitamins and Supplements'
    ],
    "Home and Kitchen": [
        'Bath Products',
        'Bedding Products',
        'Baking and Cookware',
        'Dining Accessories',
        'Furniture',
        'Home Decor',
        'Mirrors',
        'Rugs and Mats',
        'Seasonal Decor',
        'Storage and Organization',
    ],
    'In Demand Items':[
        'In Demand Appliances',
        'In Demand Collectibles',
        'In Demand Consumer Electronics',
        'In Demand Fashion',
        'In Demand Lego',
        'In Demand Nintendo',
        'In Demand PlayStation',
        'In Demand Xbox',
        'In Demand Miscellaneous',
    ],
    'Industrial and Scientific':[
        '3D Printers and Accessories',
        'Cleaning and Janitorial Supplies',
        'Commercial Accessories',
        'Dental Supplies',
        'Digital Signs',
        'Filtration and Flow Control',
        'Food Service And Restaurant Supplies',
        'Lab Equipment and Accessories',
        'Maintenance and Operation Supplies',
        'Retail Store Equipment and Fixtures',
        'Shipping and Packaging Supplies',
        'Testing and Measuring Supplies',
    ],
    'Luggage and Travel':[
        'Backpacks',
        'Briefcases',
        'Duffel Bags',
        'Gym Bags',
        'Laptop Bags',
        'Luggage & Suitcases',
        'Travel Accessories',
        'Travel Storage',
        'Travel Totes',
        'Umbrellas',
        'Waist Bags',
    ],
    "Kitchen and Home Appliances":[
        'Beverage Centers',
        'Dishwasher',
        'Freezers',
        'Heating, Cooling, and Air Quality',
        'Ice Makers',
        'Laundry Appliances',
        'Microwave Ovens',
        'Ranges, Ovens, and Cooktops',
        'Refrigerators',
        'Small Kitchen Appliances',
        'Vacuums and Floor Care',
        'Wine Cellars',
    ],
    'Musical Instruments':[
        'Amplifiers and Effects',
        'Band and Orchestra',
        'DJ and Karaoke Accessories',
        'Drums and Percussion',
        "Guitars, Ukuleles, Banjo's, and Mandolins",
        'Instrument Accessories',
        'Keyboards and Accessories',
        'Microphones and Accessories',
        'Songbooks and Lyrics',
        'Sound and Stage Accessories',
        'Studio Equipment',
        'Wind and Woodwind instruments',
    ],
    'Office and School Supplies':[
        'Business Supplies',
        'Calculators',
        'Classroom Supplies',
        'Ink and Toner',
        'Label Makers',
        'Laminators',
        'Office Furniture',
        'Office Organization',
        'Paper',
        'Printers',
        'Scanners',
        'Shredders',
        'Writing Instruments',
    ],
    'Patio, Lawn, and Garden':[
        'Fireplaces and Fire Pits',
        'Garden Tools',
        'Generators and Accessories',
        'Grills and Grill Accessories',
        'Lawn Mowers and Tractors',
        'Outdoor Decorations',
        'Outdoor Furniture',
        'Outdoor Lighting',
        'Outdoor Power Tools',
        'Outdoor Storage and Sheds',
        'Patio Heaters',
        'Pest Control',
        'Pools, Hot Tubs, and Supplies',
    ],
    "Pet Supplies":[
        'Bird Supplies and Food',
        'Cat Apparel',
        'Cat Food and Supplies',
        'Cat Furniture',
        'Cat Toys and Treats',
        'Dog Apparel',
        'Dog Food and Supplies',
        'Dog Furniture',
        'Dog Toys and Treats',
        'Fish, Aquatic Pet Supplies and Food',
        'Horse Supplies and Food',
        'Reptiles , Amphibians Supplies and Food',
        'Small Animals Supplies and Food',
    ],
    "Sports and Outdoors": [
        'Air Mattresses',
        'Airsoft Guns and Accessories',
        'Archery Equipment',
        'Backdoor Games and Activities',
        'Camping Equipment',
        'Coolers and Cups',
        'Climbing Equipment',
        'Exercise And Fitness Equipment',
        'Fishing Equipment',
        'Golf Equipment',
        'Hiking Equipment',
        'Skates, Skateboards and Scooters',
        'Tailgate Equipment',
        'Team Sports Equipment',
        'Team Sports Cleats',
        'Treadmills',
        'Water Sports',
        'Weights and Weight Sets',
        'Yoga',
    ],
    "Tools and Home Improvement": [
        'Building Supplies',
        'Ceiling Fans',
        'Electrical Supplies',
        'Hardware',
        'Kitchen and Bath Fixtures',
        'Light Bulbs',
        'Lighting',
        'Paint and Wall Treatment',
        'Plumbing Supplies',
        'Power Tools',
        'Safety and Security',
        'Tool Storage and Organization',
        'Welding and Soldering',
    ],

    "Toys and Games":[
        'Arts and Craft Toys',
        'Building Toys',
        'Dolls and Accessories',
        'Dress Up and Costumes',
        'Games and Accessories',
        "Kids' Electronic Games",
        'Learning and Education',
        'Novelty and Gag Toys',
        'Puppet Toys',
        'Puzzles',
        'Remote and App Controlled Vehicles',
        'Outdoor Play Toys',
        'Stuffed and Plush Animals',
        'Toy Figures and Playsets',
        'Tricycles, Scooters, and Wagons',
        'Vehicles',
    ],
    "Video Games": [
        'Digital Currency',
        'Handheld Game Systems',
        'Legacy Systems',
        'Nintendo Switch Accessories',
        'Nintendo Switch Consoles',
        'Nintendo Switch Games',
        'Online Game Services',
        'PC Accessories',
        'PC Games',
        'PlayStation 5 Accessories',
        'PlayStation 5 Consoles',
        'PlayStation 5 Games',
        'PlayStation 4 Accessories',
        'PlayStation 4 Consoles',
        'PlayStation 4 Games',
        'Xbox Series X and S Accessories',
        'Xbox Series X and S Consoles',
        'Xbox Series X and S Games',
        'Xbox One Accessories',
        'Xbox One Consoles',
        'Xbox One Games',
        'Virtual Reality Accessories',
        'Virtual Reality Consoles',
        'Virtual Reality Games',
    ],
    'Gift Cards':[
        //'Gift Cards',
    ],
    'Everything Else':[
        //'Everything Else',
    ],
};
/*This is a legacy code now*/
export const SubCategoryObject = {
    "Automotive":{
        'Car Accessories':'/sub_category_icons/automotives_car_accessories.png',
        'Car Care':'/sub_category_icons/automotives_car_care.png',
        'Motorcycle Gear':'/sub_category_icons/automotives_motorcycle.png',
        'Tools and Equipment':'/sub_category_icons/automotives_tools.png'
    },
    "Books and Media":{
        'Books':'/sub_category_icons/books_.png',
        'Magazines and Newspapers':'/sub_category_icons/books_magazines.png',
        'Movies and TV Shows':'/sub_category_icons/books_movies.png',
        'Music':'/sub_category_icons/books_music.png'
    },
    "Electronics": {
        "Cameras and Photography":"/sub_category_icons/elec_camera.png",
        "Gaming Consoles and Accessories":"/sub_category_icons/elec_gaming.png",
        "Laptops and Computers":"/sub_category_icons/elec_laptop.png",
        "Smartphones and Accessories":"/sub_category_icons/elec_smart.png",
        "TVs and Home Theatre":"/sub_category_icons/elec_tv.png",
        "Wearable Technology":"/sub_category_icons/elec_wearable.png",
    },
    "Fashion": {
        "Accessories (Bags, Jewelry, Watches)":"/sub_category_icons/fashion_accessories.png",
        "Kid's Clothing":"/sub_category_icons/fashion_kid.png",
        "Men's Clothing":"/sub_category_icons/fashion_men.png",
        'Shoes':"/sub_category_icons/fashion_shoes.png",
        "Women's Clothing":"/sub_category_icons/fashion_women.png",
    },
    "Gifts and Occasions":{
        'Gift Cards':'/sub_category_icons/gift_card.png',
        'Party Supplies':'/sub_category_icons/gift_party.png',
        'Personalised Gifts':'/sub_category_icons/gift_personal_gift.png',
        'Seasonal Decor':'/sub_category_icons/gift_seasonal_decor.png',
    },
    "Groceries and Gourmet Food": {
        'Fresh Produce':'/sub_category_icons/grocery_fresh.png',
        'Organic and Health Foods':'/sub_category_icons/grocery_organic.png',
        'Snacks and Beverages':'/sub_category_icons/grocery_snacks.png',
    },
    "Health and Beauty": {
        'Haircare':'/sub_category_icons/health_haircare.png',
        'Health Supplements':'/sub_category_icons/health_supplement.png',
        'Makeup':'/sub_category_icons/health_makeup.png',
        'Personal Care':'/sub_category_icons/health_personal_care.png',
        'Skincare':'/sub_category_icons/health_skincare.png',
    },
    "Home and Garden": {
        "Bed and Bath":'/sub_category_icons/home_bed.png',
        "Furniture":'/sub_category_icons/home_furniture.png',
        "Gardening Tools and Supplies":'/sub_category_icons/home_gardening.png',
        "Home Decor":'/sub_category_icons/home_decor.png',
        "Kitchen Appliances":'/sub_category_icons/home_kitchen.png',
        "Lighting and Ceiling":'/sub_category_icons/home_lightning.png',
    },
    "Office Supplies":{
        'Office Furniture':'/sub_category_icons/office_furniture.png',
        'Office Supplies':'/sub_category_icons/office_supplies.png',
        'Paper and Notebooks':'/sub_category_icons/office_paper.png',
        'Writing Instruments':'/sub_category_icons/office_writing_instruments.png',
    },
    "Pet Supplies":{
        'Pet Accessories':'/sub_category_icons/pet_accessories.png',
        'Pet Beds and Furniture':'/sub_category_icons/pet_beds.png',
        'Pet Care and Grooming':'/sub_category_icons/pet_care.png',
        'Pet Food':'/sub_category_icons/pet_food.png',
        'Pet Health and Wellness':'/sub_category_icons/pet_health.png',
        'Pet Toys':'/sub_category_icons/pet_toys.png',
    },
    "Sports and Outdoors": {
        'Camping and Hiking Supplies':'/sub_category_icons/sports_camping.png',
        'Cycling':'/sub_category_icons/sports_cycling.png',
        'Fitness Equipment':'/sub_category_icons/sports_fitness.png',
        'Outdoor Gear':'/sub_category_icons/sports_outdoor.png',
        'Sports Apparel':'/sub_category_icons/sports_sports_apparel.png',
    },
    "Software":{
        'Business Software':'/sub_category_icons/software_business.png',
        'Creative Software (Graphic Design, Video Editing)':'/sub_category_icons/software_creative.png',
        'Educational Software':'/sub_category_icons/software_edu.png',
        'Games':'/sub_category_icons/software_games.png',
        'Productivity Software':'/sub_category_icons/software_productivity.png',
        'Security Software':'/sub_category_icons/software_security.png',
    },
    "Travel and Luggage": {
        'Luggage and Travel Gear':'/sub_category_icons/travel_luggage.png',
        'Travel Accessories':'/sub_category_icons/travel_accessories.png',
        'Travel Deals':'/sub_category_icons/travel_deals.png',
    },
    "Toys and Games":{
        'Action Figures':'/sub_category_icons/toys_action.png',
        'Board Games':'/sub_category_icons/toys_board.png',
        'Educational Toys':'/sub_category_icons/toys_edu.png',
        'Outdoor Play':'/sub_category_icons/toys_outdoor.png',
        'Puzzles':'/sub_category_icons/toys_puzzles.png',
    },
};


export const categoryList = [
    "Arts and Crafts",
    "Automotive",
    'Baby Products',
    'Beauty and Personal Care',
    "Books and Fine Art",
    'Camera and Photo',
    'Cell Phones and Accessories',
    'Clothing, Shoes, and Jewelry',
    "Electronics",
    'Entertainment Collectibles',
    "Groceries and Gourmet Food",
    "Health and Household",
    "Home and Kitchen",
    'In Demand Items',
    'Industrial and Scientific',
    "Kitchen and Home Appliances",
    'Luggage and Travel',
    'Musical Instruments',
    'Office and School Supplies',
    'Patio, Lawn, and Garden',
    "Pet Supplies",
    "Sports and Outdoors",
    "Tools and Home Improvement",
    "Toys and Games",
    "Video Games",
    'Gift Cards',
    'Everything Else'
    /*
    "Appliances",
    "Arts, Crafts, and Sewing",
    "Automotive",
    "Baby Products",
    "Beauty and Personal Care",
    "Books",
    "CDs and Vinyl",
    "Cell Phones and Accessories",
    "Clothing, Shoes, and Jewelry",
    "Electronics",
    "Grocery, Gourmet, and Food",
    "Handmade Products",
    "Health and Household",
    "Home and Kitchen",
    "Movies and TV",
    "Musical Instruments",
    "Office Products",
    "Patio, Lawn, and Garden",
    "Pet Supplies",
    "Software",
    "Sports and Outdoors",
    "Tools and Home Improvement",
    "Toys and Games",
    "Video Games",
    "Miscellaneous(Other)"
    */
];

export const lowerCaseCategoryList = categoryList.map(category => category.toLowerCase());



/*used in legacy share deal and deals page, unused now*/
export const categorySet = new Set([
    "Automotive",
    'Baby Products',
    "Books and Media",
    "Electronics",
    "Fashion",
    "Gifts and Occasions",
    "Groceries and Gourmet Food",
    "Health and Beauty",
    "Home and Garden",
    "Office Supplies",
    "Pet Supplies",
    "Sports and Outdoors",
    "Software",
    "Travel and Luggage",
    "Tools and Home Improvement",
    "Toys and Games",
    /*
    "Arts & Craft Materials",
    "Automotives",
    "Baby Products",
    "Beauty & Personal Care",
    "Electronics",
    "Computers & Tablets",
    "Clothing & Fashion",
    "Health & Wellness",
    "Home & Furniture",
    "Gift Cards & Digital Goods",
    "Grocery, Food, & Drinks",
    "Kitchen & Appliances",
    "Miscellaneous (Other)",
    "Movies, Music, & Television",
    "Patio & Outdoor",
    "Pet Supplies",
    "Television & Video",
    "Tools & Home Improvement",
    "Toys, Games, & Collectibles"
    */
]);

/*used in legacy share deal and deals page, unused now*/
export const categoryOptions = [
        { id: "Arts & Craft Materials", label: "Arts & Craft Materials" },
        { id: "Automotives", label: "Automotives"},
        { id: "Baby Products", label: "Baby Products"},
        { id: "Beauty & Personal Care", label: "Beauty & Personal Care" },
        { id: "Electronics", label: "Electronics" },
        { id: "Computers & Tablets", label: "Computers & Tablets" },
        { id: "Clothing & Fashion", label: "Clothing & Fashion" },
        { id: "Health & Wellness", label: "Health & Wellness" },
        { id: "Home & Furniture", label: "Home & Furniture" },
        { id: "Gift Cards & Digital Goods", label: "Gift Cards & Digital Goods" },
        { id: "Grocery, Food, & Drinks", label: "Grocery, Food, & Drinks" },
        { id: "Kitchen & Appliances", label: "Kitchen & Appliances" },
        { id: "Miscellaneous (Other)", label: "Miscellaneous (Other)" },
        { id: "Movies, Music, & Television", label: "Movies, Music, & Television" },
        { id: "Patio & Outdoor", label: "Patio & Outdoor" },
        { id: "Pet Supplies", label: "Pet Supplies" },
        { id: "Television & Video", label: "Television & Video" },
        { id: "Tools & Home Improvement", label: "Tools & Home Improvement" },
        { id: "Toys, Games, & Collectibles", label: "Toys, Games, & Collectibles" }
    ];

/*unused*/
export const categoryIntros = [
        {id:"Arts & Craft Materials",label:"Painting supplies, DIY craft kits & More.",
            url:"https://dealcard201533-staging.s3.amazonaws.com/public/cat_icon/art.png"},
        {id:"Automotives",label:"Auto Care, Wheels, Auto Accessories, & More.",
            url:"https://dealcard201533-staging.s3.amazonaws.com/public/cat_icon/automotives1.png"},
        {id:"Baby Products",label:"Formula, Diapers, Clothes, & More.",
            url:"https://dealcard201533-staging.s3.amazonaws.com/public/cat_icon/baby_products1.png"},
        {id:"Beauty & Personal Care",label:"Skincare, Makeup & More.",
            url:"https://dealcard201533-staging.s3.amazonaws.com/public/cat_icon/beauty.png"},
        {id:"Electronics",label:"Computers, Tablets, Cameras, & More.",
            url:"https://dealcard201533-staging.s3.amazonaws.com/public/cat_icon/electro.png"},
        {id:"Computers & Tablets",label:"Computers, Tablets, Docking Stations, & More.",
            url:"https://dealcard201533-staging.s3.amazonaws.com/public/cat_icon/computers.png"},
        {id:"Clothing & Fashion",label:"T-shirts, Jeans, Shoes, Watches, & Sunglasses",
            url:"https://dealcard201533-staging.s3.amazonaws.com/public/cat_icon/clothing.png"},
        {id:"Health & Wellness",label:"Vitamins, Medicine, Fitness, & More.",
            url:"https://dealcard201533-staging.s3.amazonaws.com/public/cat_icon/health.png"},
        {id:"Home & Furniture",label:"Furniture, Home Goods, Decorations, & More.",
            url:"https://dealcard201533-staging.s3.amazonaws.com/public/cat_icon/home.png"},
        {id:"Gift Cards & Digital Goods",label:"Gift Cards, Digital Currency, Digital Goods, & More.",
            url:"https://dealcard201533-staging.s3.amazonaws.com/public/cat_icon/gift.png"},
        {id:"Grocery, Food, & Drinks",label:"Grocery Products, Food, Drinks, & More.",
            url:"https://dealcard201533-staging.s3.amazonaws.com/public/cat_icon/grocery.png"},
        {id:"Kitchen & Appliances",label:"Major Appliances, Kitchen Accessories, Utensils, & More.",
            url:"https://dealcard201533-staging.s3.amazonaws.com/public/cat_icon/kitchen.png"},
        {id:"Miscellaneous (Other)",label:"Antiques, Niche Goods, & More.",
            url:"https://dealcard201533-staging.s3.amazonaws.com/public/cat_icon/misc.png"},
        {id:"Movies, Music, & Television",label:"DVDs, CDs, Streaming, & More.",
            url:"https://dealcard201533-staging.s3.amazonaws.com/public/cat_icon/movies.png"},
        {id:"Patio & Outdoor",label:"Soccer Balls, Fishing Rods & More",
            url:"https://dealcard201533-staging.s3.amazonaws.com/public/cat_icon/patio.png"},
        {id:"Pet Supplies",label:"Pet Food, Pet Toys, Pet Equipment, & More.",
            url:"https://dealcard201533-staging.s3.amazonaws.com/public/cat_icon/pet.png"},
        {id:"Television & Video",label:"Televisions, TV Mounts, Streaming Devices, & More.",
            url:"https://dealcard201533-staging.s3.amazonaws.com/public/cat_icon/tv.png"},
        {id:"Tools & Home Improvement",label:"Tools, Light Bulbs, Home Fixtures, & More.",
            url:"https://dealcard201533-staging.s3.amazonaws.com/public/cat_icon/tools.png"},
        {id:"Toys, Games, & Collectibles",label:"Toys, Board/Video Games, Actio Figures, & More",
            url:"https://dealcard201533-staging.s3.amazonaws.com/public/cat_icon/toys.png"}
    ];
