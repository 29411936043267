import {
  Stack,
} from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import {
  listStores
} from '../../graphql/queries';
import { storeIcon } from '../constants/StoreConstants';

function Brands() {
  const navigate = useNavigate();
  const [available_stores, setStores]= useState({});
  const [pageLoading, setPageLoading]= useState(true);
  const [brandNum, setbrandNum] = useState(0);
  const charList = ['0','A','B', 'C', 'D', 'E', 'F', 'G', 'H', 'I','J', 'K', 'L','M', 'N',
    'O', 'P', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Z'
  ]
  const brandIcons = {
    Amazon: '/brand_icons/brand_icons_amazon.png',
    'Best Buy': '/brand_icons/brand_icons_bestbuy.png',
    Target: '/brand_icons/brand_icons_target.png',
    Walmart: '/brand_icons/brand_icons_walmart.png',
    Costco: '/brand_icons/brand_icons_costco.png',
    "Sam's Club": '/brand_icons/brand_icons_SamsClub.png',
    Adorama: '/brand_icons/brand_icons_adorama.png',
    'B&H Photo': '/brand_icons/brand_icons_bh.png',
    MicroCenter: '/brand_icons/brand_icons_micro_center.png',
    "IKEA": "/brand_icons/brand_icons_ikea.png",
  };

  const onSelect = (option) => {
    // console.log(option)
    if (option) {
      navigate({
        pathname: '/deals',
        search: createSearchParams({
          dealer_types: [option],
        }).toString(),
      });
      // window.location.reload(true)
    }
  };

  const handleIconClick = (event, newValue) => {
    // console.log(newValue);
    navigate({
        pathname: '/deals',
        search: createSearchParams({
          dealer_types: [newValue],
        }).toString(),
    });
    // console.log(newValue);
    //navigate("/categoryForum", { state: { name: newValue } });
  };

  useEffect(() => {
    const loadData = async() => {
      const fetched_stores = await API.graphql({
          query: listStores,
          variables: {
            filter: {
              _deleted: { ne: true },
              count: { ge: 1 },
            },
          }
      });
      let sorted_tempVal = fetched_stores.data.listStores.items.sort((a, b) => String(b._lastChangedAt).localeCompare(String(a._lastChangedAt)));
      setbrandNum(sorted_tempVal.length);
      // console.log(sorted_tempVal);
      let tempStores = {};
      let item_count = 0;
      sorted_tempVal.map((option, index) => {
        // console.log(option.storeName,storeIcon[option.storeName]);
        if(storeIcon[option.storeName] && item_count < 10){
          tempStores[option.storeName] = storeIcon[option.storeName];
          item_count += 1;
        }
      })
      if(tempStores.length < 10){
        // console.log('no sorting')
        setStores(brandIcons);
        setPageLoading(false);
        return;
      }
      else{
        // console.log('sorting');
        setStores(tempStores);
        setPageLoading(false);
      }
    }
    loadData();
  }, []);
  return (
    <div className='tw-overflow-auto'>
      { pageLoading == true || (pageLoading == false && brandNum < 10)?
        <Stack
          direction="row"
          className="tw-w-full tw-justify-around tw-text-nowrap tw-overflow-auto"
        >
          <Tabs value = {false}
            variant="scrollable"
            // scrollButtons = "auto"
            onChange = {handleIconClick}
            className='tw-flex tw-flex-wrap tw-gap-x-15 md:tw-gap-x-15'>
              {Object.keys(brandIcons).map((key2) => (
                  <Tab
                      icon={<img
                          key={key2}
                          src={brandIcons[key2]}
                          className="hover:tw-cursor-pointer tw-h-29-icon tw-w-29-icon md:tw-w-29-icon md:tw-h-29-icon"
                      />}
                      value = {key2}
                      label= {<p className='tw-text-xs'>{key2}</p>}
                      sx={{ textTransform: 'none'}}
                  />
              ))}
          </Tabs>
        </Stack>
        :
        <Stack
          direction="row"
          className="tw-w-full tw-justify-around tw-text-nowrap tw-overflow-auto"
        >
          <Tabs value = {false}
            variant="scrollable"
            // scrollButtons = "auto"
            onChange = {handleIconClick}
            className='tw-flex tw-flex-wrap tw-gap-x-15 md:tw-gap-x-15'>
              {Object.keys(available_stores).map((key2) => (
                  <Tab
                      icon={<img
                          key={key2}
                          src={available_stores[key2]}
                          className="hover:tw-cursor-pointer tw-h-29-icon tw-w-29-icon md:tw-w-29-icon md:tw-h-29-icon"
                      />}
                      value = {key2}
                      label= {<p className='tw-text-xs'>{key2}</p>}
                      sx={{ textTransform: 'none'}}
                  />
              ))}
          </Tabs>
        </Stack>
      }
    </div>
  );
}

export default Brands;
