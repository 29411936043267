import { useNavigate } from 'react-router-dom';

import { useAppContext_ } from '../../AppContextProvider';

import { Button, IconButton, Switch, styled } from '@mui/material';
import * as React from 'react';
import { Link, createSearchParams } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate();
  const { user, setUser, theme, setTheme } = useAppContext_();

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor:
            theme.palette.mode === 'dark' ? '#398fed' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#2563eb' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  function AlertNav() {
    if (user) {
      navigate('/alerts');
      // navigate("/AlertList");
    } else {
      navigate('/login');
    }
  }

  /*
  const MUIFooterOverrides = {
    Subscribe: {
      as: "button",
      onClick: async () => {
        if (user) {
          navigate("/login?next_page=AlertList");
        } else {
          navigate("/AlertList");
        }
      },
    },
    "Stay Ahead of Saving": {
      as: "button",
      onClick: async () => {
        // setIsAuthenticated(true);
        navigate("/threads");
      },
    },
    Categories: {
      as: "button",
      onClick: async () => {
        // setIsAuthenticated(true);
        navigate("/categoryForum");
      },
    },
    "Top Deals": {
      as: "button",
      onClick: async () => {
        // setIsAuthenticated(true);
        alert("Coming soon! Thank you");
      },
    },
    "Current Deals": {
      as: "button",
      onClick: async () => {
        // setIsAuthenticated(true);
        navigate("/threads");
      },
    },
    "Share a Deal": {
      as: "button",
      onClick: async () => {
        if (user) {
          // setIsAuthenticated(true);
          navigate("/shareDeal");
        } else {
          navigate("/login");
        }
      },
    },
    "Cookies Policy": {
      as: "button",
      onClick: async () => {
        window.open("/CookiesPolicy", "_blank", "rel=noopener noreferrer");
      },
    },
    "Top Disclaimer": {
      as: "button",
      onClick: async () => {
        window.open("/Disclaimer", "_blank", "rel=noopener noreferrer");
      },
    },
    Privacy: {
      as: "button",
      onClick: async () => {
        window.open("/PrivacyPolicy", "_blank", "rel=noopener noreferrer");
      },
    },
    "T&Cs": {
      as: "button",
      onClick: async () => {
        window.open("/TermsConditions", "_blank", "rel=noopener noreferrer");
      },
    },
    "My Account": {
      as: "button",
      onClick: async () => {
        if (user) {
          navigate("/profile");
        } else {
          navigate("/login");
          //setAnchorEl(null);
        }
      },
    },
    "Login / Register": {
      as: "button",
      onClick: async () => {
        navigate("/login");
      },
    },
    "My Deal Alerts": {
      as: "button",
      onClick: async () => {
        if (user) {
          navigate("/AlertList");
        } else {
          navigate("/login?next_page=AlertList");
        }
      },
    },
    "x_logo.svg": {
      className: "img-hover",
      onClick: async () => {
        window.open(
          "https://twitter.com/LordOfSavings?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
          "_blank",
          "noopener,noreferrer"
        );
      },
    },
    Group2294991: {
      className: "img-hover",
      onClick: async () => {
        window.location.href = "mailto:nate@lordofrestock.com";
      },
    },
    "discord_symbol.svg": {
      className: "img-hover",
      onClick: async () => {
        window.open(
          "https://discord.gg/lordsavings",
          "_blank",
          "noopener,noreferrer"
        );
      },
    },
    "instagram_logo.svg": {
      className: "img-hover",
      onClick: async () => {
        alert("Coming soon! Thank you");
      },
    },
  };

  const footerOverrides = {
    "IMG_4831 11539981": {
      src: "/logo3.png",
      className: "img-hover",
      onClick: async () => {
        // setIsAuthenticated(true);
        navigate("/");
      },
    },
    "IMG_4831 11541922": {
      src: "/Social_Icons_Discord.png",
      className: "img-hover",
      onClick: async () => {
        window.open(
          "https://discord.gg/lordsavings",
          "_blank",
          "noopener,noreferrer"
        );
      },
    },
    "IMG_4831 2": {
      src: "/Social_Icons_X.png",
      className: "img-hover",
      onClick: async () => {
        window.open(
          "https://twitter.com/LordOfSavings?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor",
          "_blank",
          "noopener,noreferrer"
        );
      },
    },
    "Deal Forums": {
      as: "button",
      onClick: async () => {
        navigate("/dealerForum");
      },
    },
    "Recent Deals": {
      as: "button",
      onClick: async () => {
        navigate("/threads");
      },
    },
    "Contact Us": {
      as: "button",
      onClick: async () => {
        navigate("/contacts");
      },
    },
    "Cookies Policy": {
      as: "button",
      onClick: async () => {
        window.open("/CookiesPolicy", "_blank", "rel=noopener noreferrer");
      },
    },
    Disclaimer: {
      as: "button",
      onClick: async () => {
        window.open("/Disclaimer", "_blank", "rel=noopener noreferrer");
      },
    },
    Privacy: {
      as: "button",
      onClick: async () => {
        window.open("/PrivacyPolicy", "_blank", "rel=noopener noreferrer");
      },
    },
    "Terms & Conditions": {
      as: "button",
      onClick: async () => {
        window.open("/TermsConditions", "_blank", "rel=noopener noreferrer");
      },
    },
  };
  return(
  <div>
    <Breakpoint customQuery="(min-width: 0px) and (max-width: 767px)">
      <div style={{ overflowX: "hidden", marginTop: "auto" }}>
        <Box sx={{ justifyContent: "center" }}>
          <OldFooter
            overrides={footerOverrides}
            width={"100%"}
            property1="Variant3"
          />
        </Box>
      </div>
    </Breakpoint>
    <Breakpoint customQuery="(min-width: 767px) and (max-width: 1023px)">
      <div style={{ overflowX: "hidden", marginTop: "auto" }}>
        <Box sx={{ justifyContent: "center" }}>
          <OldFooter
            overrides={footerOverrides}
            width={"100%"}
            property1="Variant2"
          />
        </Box>
      </div>
    </Breakpoint>
    <Breakpoint customQuery="(min-width: 1023px)">
      <Box
        sx={{
          overflowX: "hidden",
          marginTop: "auto",
          justifyContent: "center",
        }}
      >
        <FooterMUI overrides={MUIFooterOverrides} width={"100%"} />
      </Box>
    </Breakpoint>
  </div>
  );
  */
  const quickLinks = [
    {
      name: 'Categories',
      link: '/deals',
    },
    {
      name: 'Top Deals',
      link: {
        pathname: '/deals',
        search: createSearchParams({
          sort_by: 'Top Deals',
        }).toString(),
      },
    },
    {
      name: 'Current Deals',
      link: '/threads',
    },
    {
      name: 'Share a Deal',
      link: '/share-deal',
    },
  ];

  const company = [
    {
      name: 'Cookies Policy',
      link: '/cookies-policy',
    },
    {
      name: 'Top Disclaimer',
      link: '/disclaimer',
    },
    {
      name: 'Privacy',
      link: '/privacy-policy',
    },
    {
      name: 'T&Cs',
      link: '/terms-and-conditions',
    },
  ];

  const account = [
    {
      name: 'My Account',
      link: user ? '/profile' : '/login',
    },
    {
      name: 'Login / Register',
      link: '/login',
    },
    {
      name: 'My Deal Alerts',
      link: '/alerts', //"/AlertList",
    },
  ];

  return (
    <div className="tw-text-center md:tw-text-left tw-bg-gray-950 tw-px-10 tw-py-12 md:tw-px-36 tw-text-white">
      <div className="tw-flex tw-flex-wrap tw-gap-y-10 lg:tw-gap-12 tw-justify-evenly tw-pb-5">
        <div className="tw-w-full md:tw-w-1/2 lg:tw-w-auto">
          <p className="tw-text-xl tw-text-white tw-font-semibold">Exclusive</p>
          <Link
            to="/alerts"
            className=" tw-block tw-pb-2 tw-text-white tw-no-underline tw-underline-offset-2 hover:tw-underline hover:tw-outline-white"
          >
            Deal Alerts
          </Link>
          <Link
            to="/threads"
            className="tw-block tw-pb-2 tw-text-white tw-no-underline tw-underline-offset-1 hover:tw-underline hover:tw-outline-white"
          >
            Stay Ahead of Saving
          </Link>
          <Button variant="contained" onClick={AlertNav}>
            Add Notification
          </Button>
        </div>
        <div className="tw-w-full md:tw-w-1/2 lg:tw-w-auto">
          <p className="tw-text-xl tw-text-white tw-font-semibold">
            Quick Links
          </p>
          <ul className="tw-p-0">
            {quickLinks.map((entry, index) => (
              <Link
                key={index}
                to={entry.link}
                className="tw-block tw-pb-2 tw-text-white tw-no-underline tw-underline-offset-1 hover:tw-underline hover:tw-outline-white last:tw-pb-0"
              >
                <li key={index}>{entry.name}</li>
              </Link>
            ))}
          </ul>
        </div>
        <div className="tw-w-full md:tw-w-1/2 lg:tw-w-auto">
          <p className="tw-text-xl tw-text-white tw-font-semibold">Company</p>
          <ul className="tw-p-0">
            {company.map((entry, index) => (
              <Link
                key={index}
                to={entry.link}
                className="tw-block tw-pb-2 tw-text-white tw-no-underline tw-underline-offset-1 hover:tw-underline hover:tw-outline-white last:tw-pb-0"
              >
                <li key={index}>{entry.name}</li>
              </Link>
            ))}
          </ul>
        </div>
        <div className="tw-w-full md:tw-w-1/2 lg:tw-w-auto">
          <p className="tw-text-xl tw-text-white tw-font-semibold">Account</p>
          <ul className="tw-p-0">
            {account.map((entry, index) => (
              <Link
                key={index}
                to={entry.link}
                className="tw-block tw-pb-2 tw-text-white tw-no-underline tw-underline-offset-1 hover:tw-underline hover:tw-outline-white last:tw-pb-0"
              >
                <li key={index}>{entry.name}</li>
              </Link>
            ))}
          </ul>
        </div>
        <div className="tw-w-full md:tw-w-1/2 lg:tw-w-auto">
          <p className="tw-text-xl tw-text-white tw-font-semibold">Support</p>
          <Link
            to="/contacts"
            className=" tw-block tw-pb-2 tw-text-white tw-no-underline tw-underline-offset-2 hover:tw-underline hover:tw-outline-white"
          >
            Contact Us
          </Link>
        </div>
        <div className="tw-w-full md:tw-w-1/2 lg:tw-w-auto">
          <p className="tw-font-semibold tw-flex tw-gap-x-4 tw-justify-center md:tw-justify-start tw-pb-7 md:tw-pb-0">
            <span
              className={`${
                theme === 'light'
                  ? 'tw-text-string-white'
                  : 'tw-text-string-neutral'
              }`}
            >
              Light
            </span>
            <IOSSwitch
              checked={theme === 'light' ? false : true}
              onChange={setTheme}
            />
            <span
              className={`${
                theme === 'dark'
                  ? 'tw-text-string-base'
                  : 'tw-text-string-neutral'
              }`}
            >
              Dark
            </span>
          </p>
          <p className="tw-text-xl tw-text-white tw-font-semibold">Follow Us</p>
          <ul className="tw-p-0 tw-flex tw-gap-x-3 tw-items-center tw-justify-center md:tw-justify-normal">
            <Link
              to="https://twitter.com/LordOfSavings?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
              target="_blank"
            >
              <IconButton>
                <div className="tw-group">
                  <img
                    className="md:tw-h-8 md:tw-w-8 group-hover:tw-hidden"
                    src="/x-default.svg"
                  />
                  <img
                    className="md:tw-h-8 md:tw-w-8 tw-hidden group-hover:tw-block"
                    src="/x-hover.svg"
                  />
                </div>
              </IconButton>
            </Link>
            <Link to="https://discord.com/invite/lordsavings" target="_blank">
              <IconButton>
                <div className="tw-group">
                  <img
                    className="md:tw-h-9 md:tw-w-9 group-hover:tw-hidden"
                    src="/discord-default.svg"
                  />
                  <img
                    className="md:tw-h-9 md:tw-w-9 tw-hidden group-hover:tw-block"
                    src="/discord-hover.svg"
                  />
                </div>
              </IconButton>
            </Link>
          </ul>
        </div>
      </div>
      <p className="tw-text-center tw-text-white">
        © 2024 Lord of Savings. All rights reserved. This website is owned and
        operated by Restocks, LLC.
      </p>
    </div>
  );
}

export default Footer;
