import Box from '@mui/material/Box';
import * as React from 'react';

import MapStoreCard from './MapStoreCard';

const REACT_APP_GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

export default function MapStoreCardList({
  allDeals,
  dealId2Details,
  allStoreDetails,
  defaultExpanded = true,
  disabledExpanded = false,
}) {
  return (
    <Box>
      {!allStoreDetails
        ? 'No available store data'
        : Object.values(allStoreDetails).map((store, index) => (
            <Box mt={1} mb={1} key={index}>
              <MapStoreCard
                storeName={store.storeName}
                num_of_deals={
                  store.num_of_deals
                    ? store.num_of_deals
                    : store.dealIds?.length
                }
                rating={store.rating}
                address={store.address}
                status={store.status}
                closingTime={store.closingTime}
                openingTime={store.openingTime}
                phone={store.phone}
                geohash={store.geohash}
                placeId={store.placeId}
                allDeals={allDeals}
                dealId2Details={dealId2Details}
                defaultExpanded={defaultExpanded}
                disabledExpanded={disabledExpanded}
              />
            </Box>
          ))}
    </Box>
  );
}
