export const statesList = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY"
];
export const statesCenterList = [
    { name: 'AL', center: { lat: 32.806671, lng: -86.791130 } }, // Alabama
    { name: 'AK', center: { lat: 61.370716, lng: -152.404419 } }, // Alaska
    { name: 'AZ', center: { lat: 33.729759, lng: -111.431221 } }, // Arizona
    { name: 'AR', center: { lat: 34.969704, lng: -92.373123 } }, // Arkansas
    { name: 'CA', center: { lat: 36.116203, lng: -119.681564 } }, // California
    { name: 'CO', center: { lat: 39.059811, lng: -105.311104 } }, // Colorado
    { name: 'CT', center: { lat: 41.597782, lng: -72.755371 } }, // Connecticut
    { name: 'DE', center: { lat: 39.318523, lng: -75.507141 } }, // Delaware
    { name: 'FL', center: { lat: 27.766279, lng: -81.686783 } }, // Florida
    { name: 'GA', center: { lat: 33.040619, lng: -83.643074 } }, // Georgia
    { name: 'HI', center: { lat: 21.094318, lng: -157.498337 } }, // Hawaii
    { name: 'ID', center: { lat: 44.240459, lng: -114.478828 } }, // Idaho
    { name: 'IL', center: { lat: 40.349457, lng: -88.986137 } }, // Illinois
    { name: 'IN', center: { lat: 39.849426, lng: -86.258278 } }, // Indiana
    { name: 'IA', center: { lat: 42.011539, lng: -93.210526 } }, // Iowa
    { name: 'KS', center: { lat: 38.526600, lng: -96.726486 } }, // Kansas
    { name: 'KY', center: { lat: 37.668140, lng: -84.670067 } }, // Kentucky
    { name: 'LA', center: { lat: 31.169546, lng: -91.867805 } }, // Louisiana
    { name: 'ME', center: { lat: 44.693947, lng: -69.381927 } }, // Maine
    { name: 'MD', center: { lat: 39.063946, lng: -76.802101 } }, // Maryland
    { name: 'MA', center: { lat: 42.230171, lng: -71.530106 } }, // Massachusetts
    { name: 'MI', center: { lat: 43.326618, lng: -84.536095 } }, // Michigan
    { name: 'MN', center: { lat: 45.694454, lng: -93.900192 } }, // Minnesota
    { name: 'MS', center: { lat: 32.741646, lng: -89.678696 } }, // Mississippi
    { name: 'MO', center: { lat: 38.456085, lng: -92.288368 } }, // Missouri
    { name: 'MT', center: { lat: 46.921925, lng: -110.454353 } }, // Montana
    { name: 'NE', center: { lat: 41.125370, lng: -98.268082 } }, // Nebraska
    { name: 'NV', center: { lat: 38.313515, lng: -117.055374 } }, // Nevada
    { name: 'NH', center: { lat: 43.452492, lng: -71.563896 } }, // New Hampshire
    { name: 'NJ', center: { lat: 40.298904, lng: -74.521011 } }, // New Jersey
    { name: 'NM', center: { lat: 34.840515, lng: -106.248482 } }, // New Mexico
    { name: 'NY', center: { lat: 42.165726, lng: -74.948051 } }, // New York
    { name: 'NC', center: { lat: 35.630066, lng: -79.806419 } }, // North Carolina
    { name: 'ND', center: { lat: 47.528912, lng: -99.784012 } }, // North Dakota
    { name: 'OH', center: { lat: 40.388783, lng: -82.764915 } }, // Ohio
    { name: 'OK', center: { lat: 35.565342, lng: -96.928917 } }, // Oklahoma
    { name: 'OR', center: { lat: 44.572021, lng: -122.070938 } }, // Oregon
    { name: 'PA', center: { lat: 40.590752, lng: -77.209755 } }, // Pennsylvania
    { name: 'RI', center: { lat: 41.680893, lng: -71.511780 } }, // Rhode Island
    { name: 'SC', center: { lat: 33.856892, lng: -80.945007 } }, // South Carolina
    { name: 'SD', center: { lat: 44.299782, lng: -99.438828 } }, // South Dakota
    { name: 'TN', center: { lat: 35.747845, lng: -86.692345 } }, // Tennessee
    { name: 'TX', center: { lat: 31.054487, lng: -97.563461 } }, // Texas
    { name: 'UT', center: { lat: 40.150032, lng: -111.862434 } }, // Utah
    { name: 'VT', center: { lat: 44.045876, lng: -72.710686 } }, // Vermont
    { name: 'VA', center: { lat: 37.769337, lng: -78.169968 } }, // Virginia
    { name: 'WA', center: { lat: 47.400902, lng: -121.490494 } }, // Washington
    { name: 'WV', center: { lat: 38.491226, lng: -80.954805 } }, // West Virginia
    { name: 'WI', center: { lat: 44.268543, lng: -89.616508 } }, // Wisconsin
    { name: 'WY', center: { lat: 42.755966, lng: -107.302490 } }, // Wyoming
];
