import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  Collapse,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

const filterFontStyle = {
  fontSize: '13px',
};

function Accordian({ title, content }) {
  const [show, setShow] = useState(true);
  const handleClick = () => setShow(!show);
  return (
    <div>
      <ListItemButton onClick={handleClick}>
        <ListItemText primary={title} />
        {show ? <ExpandMore /> : <ExpandLess />}
      </ListItemButton>
      <Collapse in={show} timeout="auto" unmountOnExit>
        {content}
      </Collapse>
    </div>
  );
}

/**
 * Renders a set of checkboxes for the provided items.
 *
 * @param {String[]} options - The array of items to render checkboxes for.
 *
 * @returns {JSX.Element} The rendered checkboxes as JSX elements.
 *
 * @example const items = ["Arts & Craft Materials", "Automotives", "Baby Products"];
 */
function Checkboxes({ itemsType, items, type, setFilter }) {
  /*
   * Converts ["Arts & Craft Materials", "Automotives", "Baby Products"];
   * Into the following object array
   * [
   *    { type: "dealer_type", label: "Arts & Craft Materials", checked: true }
   *    { type: "dealer_type", label: "Automotives", checked: true }
   *    { type: "dealer_type", label: "Baby Products"checked: true }
   * ]
   */
  const [options, setOptions] = useState(
    [...items].map((item) => ({ type: type, label: item, checked: true }))
  );

  const handleCheck = (index) => {
    const optionsTemp = [...options];
    optionsTemp[index] = {
      ...optionsTemp[index], // Copy other properties from the original object
      checked: !optionsTemp[index].checked, // Toggle the checked property
    };
    setOptions(optionsTemp);
  };

  // Updates parent state by passing copy, not reference, of state object
  useEffect(
    () =>
      setFilter(
        options.map((option) => ({ ...option })),
        type
      ),
    [options]
  );

  return (
    <FormControl
      required
      component="fieldset"
      sx={{ marginTop: 1, marginLeft: 3 }}
      variant="standard"
    >
      <Box
        sx={{
          mb: 2,
          display: 'flex',
          flexDirection: 'column',
          height: 210,
          width: 210,
          overflow: 'hidden',
          overflowY: 'scroll',
        }}
      >
        <FormGroup>
          {options.map((option, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={option.checked}
                  onChange={() => handleCheck(index)}
                  name={option.label}
                  sx={{ marginBottom: '3px' }}
                />
              }
              label={
                <Typography sx={filterFontStyle}>{option.label}</Typography>
              }
            />
          ))}
        </FormGroup>
      </Box>
    </FormControl>
  );
}

function CheckboxesDelivery({ handleDelivery }) {
  const [delivery, setDelivery] = useState({
    free_shipping: true,
    instore: true,
  });

  const handleChange = (event) => {
    const key = event.target.name;
    setDelivery((prevState) => ({ ...prevState, [key]: !prevState[key] }));
  };

  useEffect(() => handleDelivery({ ...delivery }), [delivery]);

  return (
    <FormControl
      required
      component="fieldset"
      sx={{ marginTop: 1, marginLeft: 3 }}
      variant="standard"
    >
      <Box
        sx={{
          mb: 2,
          display: 'flex',
          flexDirection: 'column',
          height: 210,
          width: 210,
          overflow: 'hidden',
          overflowY: 'scroll',
        }}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                name="free_shipping"
                checked={delivery.free_shipping}
                onChange={handleChange}
                sx={{ marginBottom: '3px' }}
              />
            }
            label={<Typography sx={filterFontStyle}>Free Shipping</Typography>}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={delivery.instore}
                onChange={handleChange}
                name="instore"
                sx={{ marginBottom: '3px' }}
              />
            }
            label={<Typography sx={filterFontStyle}>In-store Deals</Typography>}
          />
        </FormGroup>
      </Box>
    </FormControl>
  );
}

function Price({ handlePrice }) {
  const [price, setPrice] = useState({
    min: 0,
    max: 0,
  });

  const handleChange = (event) => {
    const key = event.target.name;
    setPrice((prevState) => ({ ...prevState, [key]: event.target.value }));
  };

  useEffect(() => handlePrice(price), [price]);

  return (
    <Stack>
      <TextField
        size="small"
        name="min"
        value={price.min}
        onChange={handleChange}
      />
      <TextField
        size="small"
        name="max"
        value={price.max}
        onChange={handleChange}
      />
    </Stack>
  );
}

function Filter_v2({ show, onClose }) {
  // Populates object
  const [filter, setFilter] = useState({
    forum_type: [],
    dealer_type: [],
    price: { min: 0, max: 0 },
    free_shipping: {},
    instore: {},
  });

  const handlePrice = (price) => {
    setFilter((prevState) => ({ ...prevState, price: price }));
  };

  const handleFilter = (options, type) => {
    let queries = [];

    if (type === 'forum_type') {
      options.forEach((option) => {
        if (option.checked === true) {
          const query = {
            [option.type]: {
              eq: option.label,
            },
          };
          queries.push(query);
        }
      });
    } else if (type === 'dealer_type') {
      options.forEach((option) => {
        if (option.checked === false) {
          const query = {
            [option.type]: {
              ne: option.label,
            },
          };
          queries.push(query);
        }
      });
    } else if (type === '') {
      options.forEach((option) => {
        if (option.checked === false) {
          const query = {
            [option.type]: {
              ne: option.label,
            },
          };
          queries.push(query);
        }
      });
    }

    setFilter((prevState) => ({ ...prevState, [type]: queries }));
  };

  const handleDelivery = (delivery) => {
    setFilter((prevState) => ({
      ...prevState,
      free_shipping: { free_shipping: { ne: !delivery.free_shipping } },
      instore: { instore: { ne: !delivery.instore } },
    }));
  };

  const createQuery = () => {
    let query = {};

    let and = [];
    filter.dealer_type.forEach((option) => and.push(option));
    and.push(filter.free_shipping);
    and.push(filter.instore);
    and.push({ price: { ge: filter.price.min } });
    and.push({ price: { le: filter.price.max } });
    and.push({ _deleted: { ne: true } });

    query.and = and;

    let or = [];
    filter.forum_type.forEach((option) => or.push(option));

    if (or.length > 0) {
      query.or = or;
    }

    return { filter: query };
  };

  useEffect(() => {
    console.log(createQuery());
  }, [filter]);

  const categories = [
    'Arts & Craft Materials',
    'Automotives',
    'Baby Products',
    'Beauty & Personal Care',
    'Electronics',
    'Computers & Tablets',
    'Clothing & Fashion',
    'Health & Wellness',
    'Home & Furniture',
    'Gift Cards & Digital Goods',
    'Grocery, Food, & Drinks',
    'Kitchen & Appliances',
    'Miscellaneous (Other)',
    'Movies, Music, & Television',
    'Patio & Outdoor',
    'Pet Supplies',
    'Television & Video',
    'Tools & Home Improvement',
    'Toys, Games, & Collectibles',
  ];

  const brands = [
    'Arts & Craft Materials',
    'Automotives',
    'Baby Products',
    'Beauty & Personal Care',
    'Electronics',
    'Computers & Tablets',
    'Clothing & Fashion',
    'Health & Wellness',
    'Home & Furniture',
    'Gift Cards & Digital Goods',
    'Grocery, Food, & Drinks',
    'Kitchen & Appliances',
    'Miscellaneous (Other)',
    'Movies, Music, & Television',
    'Patio & Outdoor',
    'Pet Supplies',
    'Television & Video',
    'Tools & Home Improvement',
    'Toys, Games, & Collectibles',
  ];

  const deliveryRequirements = [
    { type: 'free_shipping', label: 'Free Shipping' },
    { type: 'instore', label: 'In-Store Deals' },
  ];

  return (
    <Drawer open={true} onClose={onClose}>
      {/* <Drawer open={open} onClose={toggleDrawer(false)}> */}
      <Accordian
        title={'Categories'}
        content={
          <Checkboxes
            items={categories}
            type={'forum_type'}
            setFilter={handleFilter}
          />
        }
      />
      <Accordian
        title={'Brands'}
        content={
          <Checkboxes
            items={brands}
            type="dealer_type"
            setFilter={handleFilter}
          />
        }
      />
      <Accordian
        title={'Prices (Min-Max)'}
        content={<Price handlePrice={handlePrice} />}
      />
      <Accordian
        title={'Delivery Requirements'}
        content={<CheckboxesDelivery handleDelivery={handleDelivery} />}
      />
    </Drawer>
  );
}

export default Filter_v2;

/*
{
  "and": [
      {
          "price": {
              "ge": 0
          }
      },
      {
          "price": {
              "le": 9999
          }
      },
      {
          "free_shipping": {
              "ne": true
          }
      },
      {
          "instore": {
              "ne": true
          }
      },
      {
          "_deleted": {
              "ne": true
          }
      },
      {
          "dealer_type": {
              "ne": "Amazon"
          }
      },
      {
          "dealer_type": {
              "ne": "Best Buy"
          }
      },
      {
          "dealer_type": {
              "ne": "Target"
          }
      },
      {
          "dealer_type": {
              "ne": "Walmart"
          }
      },
      {
          "dealer_type": {
              "ne": "Costco"
          }
      },
      {
          "dealer_type": {
              "ne": "Sam's Club"
          }
      },
      {
          "dealer_type": {
              "ne": "Adorama"
          }
      },
      {
          "dealer_type": {
              "ne": "B&H Photo"
          }
      },
      {
          "dealer_type": {
              "ne": "MicroCenter"
          }
      },
      {
          "dealer_type": {
              "ne": "BrandsMartUSA"
          }
      },
      {
          "dealer_type": {
              "ne": "Woot!"
          }
      },
      {
          "dealer_type": {
              "ne": "Lowe's"
          }
      },
      {
          "dealer_type": {
              "ne": "The Home Depot"
          }
      },
      {
          "dealer_type": {
              "ne": "CVS Pharmacy"
          }
      },
      {
          "dealer_type": {
              "ne": "Walgreens"
          }
      },
      {
          "dealer_type": {
              "ne": "PC Richard & Son"
          }
      },
      {
          "dealer_type": {
              "ne": "Macy's"
          }
      },
      {
          "dealer_type": {
              "ne": "Kohl's"
          }
      },
      {
          "dealer_type": {
              "ne": "Verizon"
          }
      },
      {
          "dealer_type": {
              "ne": "BJ's Wholesale Club"
          }
      },
      {
          "dealer_type": {
              "ne": "Wayfair"
          }
      },
      {
          "dealer_type": {
              "ne": "Dick's Sporting Goods"
          }
      },
      {
          "dealer_type": {
              "ne": "Staples"
          }
      },
      {
          "dealer_type": {
              "ne": "Nike"
          }
      },
      {
          "dealer_type": {
              "ne": "Champs Sports"
          }
      },
      {
          "dealer_type": {
              "ne": "JC Penney's"
          }
      },
      {
          "dealer_type": {
              "ne": "Lululemon"
          }
      },
      {
          "dealer_type": {
              "ne": "Shop My Exchange"
          }
      },
      {
          "dealer_type": {
              "ne": "PetSmart"
          }
      },
      {
          "dealer_type": {
              "ne": "Petco"
          }
      },
      {
          "dealer_type": {
              "ne": "Tractor Supply Co."
          }
      },
      {
          "dealer_type": {
              "ne": "AT&T"
          }
      },
      {
          "dealer_type": {
              "ne": "GameStop"
          }
      },
      {
          "dealer_type": {
              "ne": "Sephora"
          }
      },
      {
          "dealer_type": {
              "ne": "Foot Locker"
          }
      },
      {
          "dealer_type": {
              "ne": "GAP"
          }
      },
      {
          "dealer_type": {
              "ne": "Big Lots"
          }
      },
      {
          "dealer_type": {
              "ne": "Belk"
          }
      },
      {
          "dealer_type": {
              "ne": "Neiman Marcus"
          }
      },
      {
          "dealer_type": {
              "ne": "IKEA"
          }
      },
      {
          "dealer_type": {
              "ne": "Shop Premium Outlets"
          }
      },
      {
          "dealer_type": {
              "ne": "Ace Hardware"
          }
      },
      {
          "dealer_type": {
              "ne": "AutoZone"
          }
      },
      {
          "dealer_type": {
              "ne": "Other"
          }
      }
  ],
  "or": [
      {
          "forum_type": {
              "eq": "Arts & Craft Materials"
          }
      }
  ]
}
*/



/*

?free_shipping=true&instore=true&priceMin=0&priceMax=0

?free_shipping=true&instore=true&price_min=0&price_max=0&dealer_type=Beauty%20%26%20Personal%20Care

http://localhost:3000/deals?free_shipping=true&instore=true&priceMin=0&priceMax=0&dealer_type=[Beauty%20%26%20Personal%20Care,Baby%20Products]

*/