import { Box, Button, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import { GoogleMap, MarkerClusterer } from '@react-google-maps/api';
import React from 'react';
import CustomPagination from '../utils/CustomPagination';
// import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import { BsFillPinMapFill } from 'react-icons/bs';
import MapStoreCardList from './MapStoreCardList';
import MapStoreMarker from './MapStoreMarker';
// import { LuRectangleVertical } from "react-icons/lu";
import Section from '../home/Section';
import Map_v2_API from './Map_v2_API';

const MAP_ID = '982c1d319487e8e4';

function Map_v2() {
  const {
    isLoaded,
    loadError,
    options,
    mapCenter,
    deals,
    storeDetails,
    geoHashStoreDetails,
    myZipcode,
    setMyZipcode,
    zoom,
    markers,
    dealId2DetailsDict,
    dealsInMostLocation,
    selectedMarkerPlaceId,
    setSelectedMarkerPlaceId,
    hoveredMarker,
    setHoveredMarker,
    storeDetailOpen,
    setStoreDetailOpen,
    onMapLoad,
    showPosts,
  } = Map_v2_API();

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return <Box sx={{ height: 1000, backgroundColor: 'white' }} />;
  return (
    <div>
      <Section
        startIcon={
          <BsFillPinMapFill style={{ color: 'rgba(13, 110, 253, 1)' }} />
        }
        title="Find In-Store Deal"
        content={
          <div className="tw-flex tw-flex-col tw-w-full">
            <div className="tw-flex tw-flex-col md:tw-flex-row lg:tw-flex-row tw-h-full md:tw-h-[700px] lg:tw-h-[700px] tw-w-full tw-gap-3">
              <div className="tw-w-full tw-h-[500px] md:tw-h-full md:tw-w-[60%] lg:tw-w-[70%] tw-rounded-xl tw-overflow-hidden">
                {/* <div className="tw-flex tw-gap-x-4 tw-items-center">
                  <div className="tw-w-3 md:tw-w-6 tw-h-9 md:tw-h-12 tw-rounded-md tw-bg-blue-600"></div>
                  <h1 className="tw-text-xs md:tw-text-base tw-text-blue-600 tw-font-semibold">
                    {'Deals near you'}
                  </h1>
                </div> */}
                {/* <h2 className="tw-text-xl md:tw-text-4xl tw-font-semibold tw-flex tw-items-center tw-gap-6">
                  {'Find stores or deals'}
                </h2> */}
                <GoogleMap
                  mapContainerStyle={{
                    height: '100%',
                    width: '100%',
                  }}
                  zoom={zoom}
                  center={mapCenter}
                  options={options}
                  onLoad={onMapLoad}
                  style={{ width: '100%' }}
                  minZoom={3}
                  maxZoom={15}
                  onZoomChanged={() => setSelectedMarkerPlaceId(null)}
                  mapId={MAP_ID}
                >
                  <MarkerClusterer
                    zoomOnClick={true}
                    enableRetinaIcons
                    styles={[
                      {
                        url: '/map_location_dot.svg',
                        textColor: '#fff',
                        width: 80,
                        height: 80,
                        textSize: 22,
                      },
                    ]}
                  >
                    {(clusterer) =>
                      markers.map((marker, index) => {
                        // // console.log("Loading Marker: ", marker)
                        // // console.log("marker.deal_id: ")
                        // // console.log(marker.deal_id)

                        return (
                          <MapStoreMarker
                            key={index}
                            placeId={marker.placeId}
                            position={{ lat: marker.lat, lng: marker.lng }}
                            dealIds={marker?.dealIds}
                            dealId2Details={dealId2DetailsDict}
                            storeName={marker.name}
                            clusterer={clusterer}
                            index={index}
                            selectedMarker={selectedMarkerPlaceId}
                            setSelectedMarker={setSelectedMarkerPlaceId}
                            hoveredMarker={hoveredMarker}
                            setHoveredMarker={setHoveredMarker}
                          />
                        );
                      })
                    }
                  </MarkerClusterer>
                </GoogleMap>
              </div>
              <div className="tw-w-full md:tw-w-[38%] lg:tw-w-[30%] tw-h-full tw-rounded-xl tw-bg-surface-raised tw-p-4 tw-flex tw-flex-col tw-gap-4 tw-border tw-border-surface-border tw-text-string-base">
                <div className="tw-flex tw-gap-3 tw-items-center">
                  <TextField
                    label="Zipcode"
                    variant="standard"
                    value={myZipcode}
                    size="small"
                    onChange={(event) => {
                      const newValue = event.target.value;
                      // Only allow up to 5 digits
                      if (newValue.length <= 5 && /^[0-9]*$/.test(newValue)) {
                        setMyZipcode(newValue);
                      }
                    }}
                    style={{ width: '100%' }}
                  />
                  {/* {myZipcode && (
                    <IconButton
                      onClick={() => setMyZipcode("")}
                      style={{
                        position: "absolute",
                        right: 0,
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  )} */}
                  {/*
                  <div className="tw-flex-1">
                  <Button onClick = {handleMap} variant="outlined"  startIcon={<TuneRoundedIcon/>} size="large">
                    Filter
                  </Button>
                  </div>
                  */}
                </div>
                <div className="tw-h-full tw-w-full tw-overflow-auto">
                  <Typography variant="body1">Stores Specific:</Typography>
                  {deals.length === 0 ? (
                    <div className="tw-flex tw-justify-center tw-items-center tw-w-full tw-h-full">
                      <Typography variant="body1">
                        {/* No in-store deal nearby, try another zipcode? */}
                      </Typography>
                    </div>
                  ) : (
                    <MapStoreCardList
                      allDeals={deals}
                      dealId2Details={dealId2DetailsDict}
                      allStoreDetails={geoHashStoreDetails}
                    />
                  )}
                </div>
              </div>

            </div>
            <div className="tw-flex tw-flex-col tw-gap-y-8 lg:tw-gap-y-8 tw-w-full tw-py-8">
              <div className="tw-flex tw-flex-col md:tw-flex-row tw-h-full md:tw-w-full tw-gap-8">
                <div className="tw-text-string-base tw-w-full md:tw-h-full md:tw-w-[70%] tw-rounded-xl tw-overflow-hidden">
                  {/* <div className="tw-flex tw-gap-x-4 tw-items-center">
                      <div className="tw-w-3 md:tw-w-6 tw-h-9 md:tw-h-12 tw-rounded-md tw-bg-blue-600"></div>
                      <h1 className="tw-text-xs md:tw-text-base tw-text-blue-600 tw-font-semibold">
                        {'Deals in most location'}
                      </h1>
                    </div> */}
                  <h2 className="tw-text-xl md:tw-text-4xl tw-font-semibold tw-flex tw-items-center tw-gap-6">
                    {'Deals in most location'}
                  </h2>
                </div>
              </div>
              <CustomPagination
                items={dealsInMostLocation}
                count={6}
                component={(items) => showPosts(items)}
              />
            </div>
          </div>
        }
      ></Section>
              <Dialog
                // sx={{ zIndex: 9901}}
                open={storeDetailOpen}
                onClose={(event, reason) => {
                  // if (reason !== 'backdropClick') {
                  //     handleAddressSelectorClose();
                  // }
                  setStoreDetailOpen(false);
                  setSelectedMarkerPlaceId(null);
                }}
                aria-labelledby="address-selector-dialog"
                maxWidth="md"
                fullWidth={true}
                PaperProps={{
                  style: {
                    // height: '300px',
                    width: '400px',
                  },
                }}
              >
                {/* <DialogTitle id="address-selector-dialog">{storeDetailStoreName}</DialogTitle> */}
                <DialogContent>
                  <MapStoreCardList
                    allDeals={deals}
                    dealId2Details={dealId2DetailsDict}
                    allStoreDetails={storeDetails.filter(
                      (store) => store.placeId === selectedMarkerPlaceId
                    )}
                    defaultExpanded={true}
                    disabledExpanded={true}
                  />
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <Button
                      style={{ marginRight: '0px', marginTop: '0px' }}
                      onClick={() => {
                        setStoreDetailOpen(false);
                        setSelectedMarkerPlaceId(null);
                      }}
                    >
                      Close
                    </Button>
                  </Box>
                </DialogContent>
              </Dialog>
    </div>
  );
}

export default Map_v2;
